import { compose, withProps } from 'recompact';
import mutation from 'mutations/UpdateProductCategoryMutation';
import withUpdateEnhance from 'hoc/withUpdateEnhance';
import withNotFound from 'hoc/withNotFound';

export default compose(
  withNotFound(),
  withProps({
    title: 'Изменить',
  }),
  withUpdateEnhance({
    mutation,
  })
);
