import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateShopOrderMkbTransactionMutation($input: UpdateShopOrderMkbTransactionInput!) {
    updateShopOrderMkbTransaction(input: $input) {
      shopOrderMkbTransaction {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateShopOrderMkbTransaction', 'shopOrderMkbTransaction');
