exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media print {\n  .src-pages-BalanceDocuments-Show-NewItem-___NewItem__root___329cz {\n    display: none;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-pages-BalanceDocuments-Show-NewItem-___NewItem__root___329cz"
};