import React from 'react';
import PropTypes from 'prop-types';
import { Table, Icon } from 'antd';
import { Link } from 'found';
import { withState, compose } from 'recompact';

const Control = ({ fetchMyFreshProducts, fetchMoscowFreshProducts, allProducts, table, setTable }) => {
  if (fetchMoscowFreshProducts.pending || fetchMyFreshProducts.pending) {
    return 'Loading...';
  }

  if (fetchMyFreshProducts.rejected) {
    // eslint-disable-next-line no-console
    console.error(fetchMyFreshProducts.reason);
    return <div>Error</div>;
  }

  if (fetchMoscowFreshProducts.rejected) {
    // eslint-disable-next-line no-console
    console.error(fetchMoscowFreshProducts.reason);
    return <div>Error</div>;
  }

  const myFreshProducts = {};
  const moscowFreshProducts = {};

  fetchMyFreshProducts.value.forEach(p => {
    myFreshProducts[p.stock_id] = p;
  });

  fetchMoscowFreshProducts.value.forEach(p => {
    moscowFreshProducts[p.stock_id] = p;
  });

  const columns = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'На складе',
      render: (name, row) => <Link to={`/products/${row.rowId}`}>{name}</Link>,
      sorter: (a, b) => a.categoryId - b.categoryId,
      sortOrder: table.sorter.columnKey === 'name' && table.sorter.order,
    },
    {
      key: 'unit',
      dataIndex: 'unit',
      title: 'Ед',
      width: 70,
      sorter: (a, b) => a.unit.length - b.unit.length,
      sortOrder: table.sorter.columnKey === 'unit' && table.sorter.order,
    },
    {
      key: 'shopOrderPrices',
      dataIndex: 'shopOrderPrices',
      title: 'Ед',
      width: 150,
      render: prices => prices.join(', '),
    },
    {
      key: 'moscowFreshName',
      title: 'MoscowFresh',
      render: (_, row) => {
        const p = moscowFreshProducts[row.rowId];

        if (p) {
          const url = `https://adm.moscowfresh.ru/index.php/admin/catalog_product/edit/id/${p.id}`;
          return <a href={url}>{p.name}</a>;
        }

        return null;
      },
    },
    {
      key: 'moscowFreshPrice',
      title: 'Цена MF',
      width: 100,
      render: (_, row) => moscowFreshProducts[row.rowId] && parseFloat(moscowFreshProducts[row.rowId].price).toFixed(3),
    },
    {
      key: 'moscowFreshCheck',
      title: 'Весовой для MF',
      width: 70,
      sorter: (a, b) =>
        (moscowFreshProducts[a.rowId] ? moscowFreshProducts[a.rowId].stock_unit_kg : 0) -
        (moscowFreshProducts[b.rowId] ? moscowFreshProducts[b.rowId].stock_unit_kg : 0),
      sortOrder: table.sorter.columnKey === 'moscowFreshCheck' && table.sorter.order,
      render: (_, row) =>
        moscowFreshProducts[row.rowId] && moscowFreshProducts[row.rowId].stock_unit_kg === '1' && <Icon type="check" />,
    },
    {
      title: 'MyFresh',
      render: (_, row) => {
        const p = myFreshProducts[row.rowId];

        if (p) {
          return <a href={`https://myfresh.ru/erp/products/${p.id}/edit`}>{p.title}</a>;
        }

        return null;
      },
    },
  ];

  return (
    <Table
      dataSource={allProducts.nodes}
      rowKey="rowId"
      columns={columns}
      pagination={false}
      onChange={(_, filters, sorter) => {
        console.log(sorter);
        setTable({ filters, sorter });
      }}
    />
  );
};

Control.propTypes = {
  fetchMoscowFreshProducts: PropTypes.object.isRequired,
  fetchMyFreshProducts: PropTypes.object.isRequired,
  allProducts: PropTypes.object.isRequired,
  table: PropTypes.object.isRequired,
  setTable: PropTypes.func.isRequired,
};

const enhance = compose(
  withState('table', 'setTable', {
    filters: {},
    sorter: {
      columnKey: 'name',
      field: 'name',
      order: 'ascend',
    },
  })
);

export default enhance(Control);
