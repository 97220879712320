import { graphql } from 'relay-runtime';

export default graphql`
  query PrintShopOrderDocumentsQuery($filter: ShopOrderDocumentFilter) {
    orders: allShopOrderDocuments(filter: $filter) {
      nodes {
        id
        rowId
        owner
        label
        deadlineAt
        deliveryAt
        comment
        asap
        ecoPack
        withoutOverweight
        withGlassCoupon

        yandexOrders: yandexOrdersByDocumentId(first: 1) {
          nodes {
            externalId
          }
        }

        items: shopOrderItemsByDocumentId {
          nodes {
            id
            orderedQuantity
            warehouseId
            comment
            productId

            product: productByProductId {
              name
              categoryId
              unit

              productGroups: productGroupsProductsByProductId {
                nodes {
                  id
                  groupId

                  group: productGroupByGroupId {
                    id
                    warehouseId
                  }
                }
              }
            }
          }
        }
      }
    }

    allProductCategories(orderBy: [POSITION_ASC]) {
      nodes {
        rowId
        name
        position
      }
    }

    allProductGroups {
      nodes {
        rowId
        name
        position
      }
    }
  }
`;
