/**
 * @flow
 * @relayHash 87f3800d1a1415bde615a828a9e2f804
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShowProductGroupQueryVariables = {|
  rowId: number
|};
export type ShowProductGroupQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +name: string,
    +warehouse: ?{|
      +rowId: number,
      +name: string,
    |},
    +items: {|
      +nodes: $ReadOnlyArray<{|
        +id: string,
        +productId: number,
        +product: ?{|
          +rowId: number,
          +name: string,
        |},
      |}>
    |},
  |}
|};
export type ShowProductGroupQuery = {|
  variables: ShowProductGroupQueryVariables,
  response: ShowProductGroupQueryResponse,
|};
*/


/*
query ShowProductGroupQuery(
  $rowId: Int!
) {
  data: productGroupByRowId(rowId: $rowId) {
    id
    rowId
    name
    warehouse: warehouseByWarehouseId {
      rowId
      name
      id
    }
    items: productGroupsProductsByGroupId {
      nodes {
        id
        productId
        product: productByProductId {
          rowId
          name
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = [
  v3,
  v4
],
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "productId",
  "args": null,
  "storageKey": null
},
v7 = [
  v3,
  v4,
  v2
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ShowProductGroupQuery",
  "id": null,
  "text": "query ShowProductGroupQuery(\n  $rowId: Int!\n) {\n  data: productGroupByRowId(rowId: $rowId) {\n    id\n    rowId\n    name\n    warehouse: warehouseByWarehouseId {\n      rowId\n      name\n      id\n    }\n    items: productGroupsProductsByGroupId {\n      nodes {\n        id\n        productId\n        product: productByProductId {\n          rowId\n          name\n          id\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ShowProductGroupQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "productGroupByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProductGroup",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": "warehouse",
            "name": "warehouseByWarehouseId",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": false,
            "selections": v5
          },
          {
            "kind": "LinkedField",
            "alias": "items",
            "name": "productGroupsProductsByGroupId",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductGroupsProductsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductGroupsProduct",
                "plural": true,
                "selections": [
                  v2,
                  v6,
                  {
                    "kind": "LinkedField",
                    "alias": "product",
                    "name": "productByProductId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": v5
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ShowProductGroupQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "productGroupByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProductGroup",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": "warehouse",
            "name": "warehouseByWarehouseId",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": false,
            "selections": v7
          },
          {
            "kind": "LinkedField",
            "alias": "items",
            "name": "productGroupsProductsByGroupId",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductGroupsProductsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductGroupsProduct",
                "plural": true,
                "selections": [
                  v2,
                  v6,
                  {
                    "kind": "LinkedField",
                    "alias": "product",
                    "name": "productByProductId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": v7
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6bd9951ac5d03641888ef4d3efd55723';
module.exports = node;
