/**
 * @flow
 * @relayHash b68bac46cdf1d10a8e69e5831104acff
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type LastSupplyItemsSupplyContractorsShow$ref = any;
export type SupplyContractorStatus = "ARCHIVE" | "DANGEROUS" | "FRIENDS" | "UNSET" | "%future added value";
export type ShowSupplyContractorQueryVariables = {|
  rowId: number
|};
export type ShowSupplyContractorQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +name: string,
    +status: SupplyContractorStatus,
    +description: string,
    +owner: ?{|
      +firstName: string,
      +lastName: string,
    |},
    +documents: {|
      +nodes: $ReadOnlyArray<{|
        +id: string,
        +rowId: number,
        +eventedAt: any,
        +totalSum: any,
        +comment: string,
      |}>
    |},
  |},
  +allLastContractorSupplyItems: ?{|
    +nodes: $ReadOnlyArray<{|
      +$fragmentRefs: LastSupplyItemsSupplyContractorsShow$ref
    |}>
  |},
|};
export type ShowSupplyContractorQuery = {|
  variables: ShowSupplyContractorQueryVariables,
  response: ShowSupplyContractorQueryResponse,
|};
*/


/*
query ShowSupplyContractorQuery(
  $rowId: Int!
) {
  data: supplyContractorByRowId(rowId: $rowId) {
    id
    rowId
    name
    status
    description
    owner: employeeByOwnerId {
      firstName
      lastName
      id
    }
    documents: supplyDocumentsByContractorId {
      nodes {
        id
        rowId
        eventedAt
        totalSum
        comment
      }
    }
  }
  allLastContractorSupplyItems(id: $rowId) {
    nodes {
      ...LastSupplyItemsSupplyContractorsShow
      id
    }
  }
}

fragment LastSupplyItemsSupplyContractorsShow on SupplyItem {
  id
  quantity
  price
  warehouseId
  warehouse: warehouseByWarehouseId {
    name
    id
  }
  document: supplyDocumentByDocumentId {
    rowId
    eventedAt
    id
  }
  product: productByProductId {
    rowId
    name
    unit
    quantities: productWarehouseQuantitiesByProductId {
      nodes {
        warehouseId
        value
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "eventedAt",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": "documents",
  "name": "supplyDocumentsByContractorId",
  "storageKey": null,
  "args": null,
  "concreteType": "SupplyDocumentsConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nodes",
      "storageKey": null,
      "args": null,
      "concreteType": "SupplyDocument",
      "plural": true,
      "selections": [
        v2,
        v3,
        v9,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalSum",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "comment",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
},
v11 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "rowId",
    "type": "Int"
  }
],
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "warehouseId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ShowSupplyContractorQuery",
  "id": null,
  "text": "query ShowSupplyContractorQuery(\n  $rowId: Int!\n) {\n  data: supplyContractorByRowId(rowId: $rowId) {\n    id\n    rowId\n    name\n    status\n    description\n    owner: employeeByOwnerId {\n      firstName\n      lastName\n      id\n    }\n    documents: supplyDocumentsByContractorId {\n      nodes {\n        id\n        rowId\n        eventedAt\n        totalSum\n        comment\n      }\n    }\n  }\n  allLastContractorSupplyItems(id: $rowId) {\n    nodes {\n      ...LastSupplyItemsSupplyContractorsShow\n      id\n    }\n  }\n}\n\nfragment LastSupplyItemsSupplyContractorsShow on SupplyItem {\n  id\n  quantity\n  price\n  warehouseId\n  warehouse: warehouseByWarehouseId {\n    name\n    id\n  }\n  document: supplyDocumentByDocumentId {\n    rowId\n    eventedAt\n    id\n  }\n  product: productByProductId {\n    rowId\n    name\n    unit\n    quantities: productWarehouseQuantitiesByProductId {\n      nodes {\n        warehouseId\n        value\n        id\n      }\n    }\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ShowSupplyContractorQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "supplyContractorByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "SupplyContractor",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "LinkedField",
            "alias": "owner",
            "name": "employeeByOwnerId",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": false,
            "selections": [
              v7,
              v8
            ]
          },
          v10
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allLastContractorSupplyItems",
        "storageKey": null,
        "args": v11,
        "concreteType": "SupplyItemsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyItem",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "LastSupplyItemsSupplyContractorsShow",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ShowSupplyContractorQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "supplyContractorByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "SupplyContractor",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "LinkedField",
            "alias": "owner",
            "name": "employeeByOwnerId",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": false,
            "selections": [
              v7,
              v8,
              v2
            ]
          },
          v10
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allLastContractorSupplyItems",
        "storageKey": null,
        "args": v11,
        "concreteType": "SupplyItemsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyItem",
            "plural": true,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "quantity",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "price",
                "args": null,
                "storageKey": null
              },
              v12,
              {
                "kind": "LinkedField",
                "alias": "warehouse",
                "name": "warehouseByWarehouseId",
                "storageKey": null,
                "args": null,
                "concreteType": "Warehouse",
                "plural": false,
                "selections": [
                  v4,
                  v2
                ]
              },
              {
                "kind": "LinkedField",
                "alias": "document",
                "name": "supplyDocumentByDocumentId",
                "storageKey": null,
                "args": null,
                "concreteType": "SupplyDocument",
                "plural": false,
                "selections": [
                  v3,
                  v9,
                  v2
                ]
              },
              {
                "kind": "LinkedField",
                "alias": "product",
                "name": "productByProductId",
                "storageKey": null,
                "args": null,
                "concreteType": "Product",
                "plural": false,
                "selections": [
                  v3,
                  v4,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "unit",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": "quantities",
                    "name": "productWarehouseQuantitiesByProductId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProductWarehouseQuantitiesConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "nodes",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ProductWarehouseQuantity",
                        "plural": true,
                        "selections": [
                          v12,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "value",
                            "args": null,
                            "storageKey": null
                          },
                          v2
                        ]
                      }
                    ]
                  },
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '11380df5ce0fc12ca2cf1f4379f68b3a';
module.exports = node;
