/**
 * @flow
 * @relayHash d1d2c7e7e5792d6e96e5caba584f3df7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteScaleInput = {
  clientMutationId?: ?string,
  id: string,
};
export type DestroyScaleMutationVariables = {|
  input: DeleteScaleInput
|};
export type DestroyScaleMutationResponse = {|
  +deleteScale: ?{|
    +deletedScaleId: ?string
  |}
|};
export type DestroyScaleMutation = {|
  variables: DestroyScaleMutationVariables,
  response: DestroyScaleMutationResponse,
|};
*/


/*
mutation DestroyScaleMutation(
  $input: DeleteScaleInput!
) {
  deleteScale(input: $input) {
    deletedScaleId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteScaleInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteScale",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteScaleInput!"
      }
    ],
    "concreteType": "DeleteScalePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedScaleId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DestroyScaleMutation",
  "id": null,
  "text": "mutation DestroyScaleMutation(\n  $input: DeleteScaleInput!\n) {\n  deleteScale(input: $input) {\n    deletedScaleId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DestroyScaleMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DestroyScaleMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1b0369bd9575a109a550a2975dee2a6e';
module.exports = node;
