import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateProductGroupsProductMutation($input: UpdateProductGroupsProductInput!) {
    updateProductGroupsProduct(input: $input) {
      productGroupsProduct {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateProductGroupsProduct', 'productGroupsProduct');
