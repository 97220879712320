import { graphql } from 'relay-runtime';

export default graphql`
  query EditShopOrderDocumentQuery($rowId: Int!) {
    data: shopOrderDocumentByRowId(rowId: $rowId) {
      id
      rowId
      deliveryAt
      status
      groupPosition
      groupId
      deliveryPointBlocked
      confirmedAmount
      totalSumMultiplier
      paymentMethod
      discount
      userId
      prevDeliveryPoint {
        x
        y
      }
      deliveryPoint {
        x
        y
      }
    }
  }
`;
