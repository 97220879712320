import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';
import './InlineForm.css';

const InlineForm = ({ buttons, children, ...props }) => (
  <Form styleName="root" {...props}>
    {children}
    <div styleName="button-item">{buttons}</div>
  </Form>
);

InlineForm.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.any,
  buttons: PropTypes.any,
};

InlineForm.defaultProps = {
  buttons: (
    <Button type="primary" htmlType="submit" icon="save">
      Сохранить
    </Button>
  ),
};

export default InlineForm;
