/**
 * @flow
 * @relayHash 83e7c7cd49244c9f736a5eb6fc6b8e1d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteShopOrderGroupInput = {
  clientMutationId?: ?string,
  id: string,
};
export type DestroyShopOrderGroupMutationVariables = {|
  input: DeleteShopOrderGroupInput
|};
export type DestroyShopOrderGroupMutationResponse = {|
  +deleteShopOrderGroup: ?{|
    +deletedShopOrderGroupId: ?string
  |}
|};
export type DestroyShopOrderGroupMutation = {|
  variables: DestroyShopOrderGroupMutationVariables,
  response: DestroyShopOrderGroupMutationResponse,
|};
*/


/*
mutation DestroyShopOrderGroupMutation(
  $input: DeleteShopOrderGroupInput!
) {
  deleteShopOrderGroup(input: $input) {
    deletedShopOrderGroupId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteShopOrderGroupInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteShopOrderGroup",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteShopOrderGroupInput!"
      }
    ],
    "concreteType": "DeleteShopOrderGroupPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedShopOrderGroupId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DestroyShopOrderGroupMutation",
  "id": null,
  "text": "mutation DestroyShopOrderGroupMutation(\n  $input: DeleteShopOrderGroupInput!\n) {\n  deleteShopOrderGroup(input: $input) {\n    deletedShopOrderGroupId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DestroyShopOrderGroupMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DestroyShopOrderGroupMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3a3a29388fcdd123e9533a5f5f7be9a9';
module.exports = node;
