import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import InlineForm from 'components/InlineForm';
import CollectionSelect from 'components/CollectionSelect';

const NewGroup = ({ form, context, handleSubmit, groups, saveRef }) => (
  <InlineForm onSubmit={handleSubmit}>
    <Form.Item label="Добавить в группу">
      {form.getFieldDecorator('groupId')(
        <CollectionSelect
          showSearch
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          environment={context.environment}
          showAction={['click', 'focus']}
          ref={saveRef}
          items={groups}
        />
      )}
    </Form.Item>
  </InlineForm>
);

NewGroup.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
  context: PropTypes.shape({
    environment: PropTypes.object.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  saveRef: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
};

export default NewGroup;
