import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroyProductGroupsProductMutation($input: DeleteProductGroupsProductInput!) {
    deleteProductGroupsProduct(input: $input) {
      deletedProductGroupsProductId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteProductGroupsProduct', 'deletedProductGroupsProductId');
