/**
 * @flow
 * @relayHash 23b478cda9f8c5e8325642743110e90c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductUnit = "KG" | "PCS" | "%future added value";
export type ExtendMoveCondition = {
  rowId?: ?number,
  eventedAt?: ?any,
  quantity?: ?any,
  warehouseId?: ?number,
  productId?: ?number,
  actualProductId?: ?number,
  cost?: ?any,
  supplyDocumentId?: ?number,
  shopOrderDocumentId?: ?number,
  writeOffDocumentId?: ?number,
  sourceWarehouseMovementDocumentId?: ?number,
  targetWarehouseMovementDocumentId?: ?number,
  remain?: ?any,
};
export type MovesQueryVariables = {|
  rowId: number,
  condition: ExtendMoveCondition,
  limit?: ?number,
|};
export type MovesQueryResponse = {|
  +product: ?{|
    +id: string,
    +name: string,
    +unit: ProductUnit,
    +parent: ?{|
      +rowId: number,
      +name: string,
    |},
    +quantities: {|
      +nodes: $ReadOnlyArray<{|
        +warehouseId: number,
        +value: any,
      |}>
    |},
  |},
  +allWarehouses: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +name: string,
    |}>
  |},
  +rows: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: ?number,
      +warehouseId: ?number,
      +actualProductId: ?number,
      +eventedAt: ?any,
      +quantity: ?any,
      +remain: ?any,
      +cost: ?any,
      +supplyDocumentId: ?number,
      +shopOrderDocumentId: ?number,
      +writeOffDocumentId: ?number,
      +sourceWarehouseMovementDocumentId: ?number,
      +targetWarehouseMovementDocumentId: ?number,
    |}>,
    +totalCount: number,
  |},
|};
export type MovesQuery = {|
  variables: MovesQueryVariables,
  response: MovesQueryResponse,
|};
*/


/*
query MovesQuery(
  $rowId: Int!
  $condition: ExtendMoveCondition!
  $limit: Int
) {
  product: productByRowId(rowId: $rowId) {
    id
    name
    unit
    parent: productByParentId {
      rowId
      name
      id
    }
    quantities: productWarehouseQuantitiesByProductId {
      nodes {
        warehouseId
        value
        id
      }
    }
  }
  allWarehouses {
    nodes {
      rowId
      name
      id
    }
  }
  rows: allExtendMoves(first: $limit, orderBy: EVENTED_AT_DESC, condition: $condition) {
    nodes {
      rowId
      warehouseId
      actualProductId
      eventedAt
      quantity
      remain
      cost
      supplyDocumentId
      shopOrderDocumentId
      writeOffDocumentId
      sourceWarehouseMovementDocumentId
      targetWarehouseMovementDocumentId
    }
    totalCount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "condition",
    "type": "ExtendMoveCondition!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "limit",
    "type": "Int",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "unit",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v6 = [
  v5,
  v3
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "warehouseId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "LinkedField",
  "alias": "rows",
  "name": "allExtendMoves",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "condition",
      "variableName": "condition",
      "type": "ExtendMoveCondition"
    },
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "limit",
      "type": "Int"
    },
    {
      "kind": "Literal",
      "name": "orderBy",
      "value": "EVENTED_AT_DESC",
      "type": "[ExtendMovesOrderBy!]"
    }
  ],
  "concreteType": "ExtendMovesConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nodes",
      "storageKey": null,
      "args": null,
      "concreteType": "ExtendMove",
      "plural": true,
      "selections": [
        v5,
        v7,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "actualProductId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "eventedAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quantity",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "remain",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "cost",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "supplyDocumentId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "shopOrderDocumentId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "writeOffDocumentId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "sourceWarehouseMovementDocumentId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "targetWarehouseMovementDocumentId",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalCount",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = [
  v5,
  v3,
  v2
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "MovesQuery",
  "id": null,
  "text": "query MovesQuery(\n  $rowId: Int!\n  $condition: ExtendMoveCondition!\n  $limit: Int\n) {\n  product: productByRowId(rowId: $rowId) {\n    id\n    name\n    unit\n    parent: productByParentId {\n      rowId\n      name\n      id\n    }\n    quantities: productWarehouseQuantitiesByProductId {\n      nodes {\n        warehouseId\n        value\n        id\n      }\n    }\n  }\n  allWarehouses {\n    nodes {\n      rowId\n      name\n      id\n    }\n  }\n  rows: allExtendMoves(first: $limit, orderBy: EVENTED_AT_DESC, condition: $condition) {\n    nodes {\n      rowId\n      warehouseId\n      actualProductId\n      eventedAt\n      quantity\n      remain\n      cost\n      supplyDocumentId\n      shopOrderDocumentId\n      writeOffDocumentId\n      sourceWarehouseMovementDocumentId\n      targetWarehouseMovementDocumentId\n    }\n    totalCount\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MovesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "product",
        "name": "productByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "Product",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": "parent",
            "name": "productByParentId",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": false,
            "selections": v6
          },
          {
            "kind": "LinkedField",
            "alias": "quantities",
            "name": "productWarehouseQuantitiesByProductId",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductWarehouseQuantitiesConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductWarehouseQuantity",
                "plural": true,
                "selections": [
                  v7,
                  v8
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allWarehouses",
        "storageKey": null,
        "args": null,
        "concreteType": "WarehousesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": true,
            "selections": v6
          }
        ]
      },
      v9
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "MovesQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "product",
        "name": "productByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "Product",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": "parent",
            "name": "productByParentId",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": false,
            "selections": v10
          },
          {
            "kind": "LinkedField",
            "alias": "quantities",
            "name": "productWarehouseQuantitiesByProductId",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductWarehouseQuantitiesConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductWarehouseQuantity",
                "plural": true,
                "selections": [
                  v7,
                  v8,
                  v2
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allWarehouses",
        "storageKey": null,
        "args": null,
        "concreteType": "WarehousesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": true,
            "selections": v10
          }
        ]
      },
      v9
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f32518150813a058e5d2eab850cafd20';
module.exports = node;
