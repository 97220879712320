import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import CollectionSelect from 'components/CollectionSelect';
import InlineForm from 'components/InlineForm';
import ProductSelect from 'containers/ProductSelect';
import QuantityInput from 'components/QuantityInput';
import TotalSumInput from './TotalSumInput';
import './NewItem.css';

const NewItem = ({ form, context, allWarehouses, handleSubmit, defaultWarehouseId, saveRef, quantity }) => (
  <div styleName="root">
    <h3 style={{ marginTop: 50 }}>Добавить позицию</h3>
    <InlineForm onSubmit={handleSubmit}>
      <Form.Item label="Склад">
        {form.getFieldDecorator('warehouseId', { initialValue: defaultWarehouseId })(
          <CollectionSelect items={allWarehouses} />
        )}
      </Form.Item>
      <Form.Item label="Позиция">
        {form.getFieldDecorator('productId')(
          <ProductSelect environment={context.environment} showAction={['click', 'focus']} autoFocus ref={saveRef} />
        )}
      </Form.Item>
      <Form.Item label="Количество">
        {form.getFieldDecorator('quantity')(<QuantityInput />)}
        <div styleName="hint">{quantity && `Текущий остаток: ${quantity.value}`}</div>
      </Form.Item>
      <Form.Item label="Цена">
        {form.getFieldDecorator('price', { initialValue: (quantity && quantity.lastSupplyPrice) || '' })(<QuantityInput />)}
        <div styleName="hint">{quantity && `Последняя цена: ${quantity.lastSupplyPrice}`}</div>
      </Form.Item>
      <Form.Item label="Сумма">
        <TotalSumInput
          onChange={v => {
            const q = form.getFieldValue('quantity');

            if (q > 0) {
              const price = (v / q).toFixed(2);
              form.setFieldsValue({ price });
            }
          }}
        />
      </Form.Item>
    </InlineForm>
  </div>
);

NewItem.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
  context: PropTypes.shape({
    environment: PropTypes.object.isRequired,
  }).isRequired,
  allWarehouses: PropTypes.array.isRequired,
  defaultWarehouseId: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
  saveRef: PropTypes.func.isRequired,
  quantity: PropTypes.object,
};

NewItem.defaultProps = {
  defaultWarehouseId: null,
  quantity: null,
};

export default NewItem;
