/**
 * @flow
 * @relayHash c8ef6ced354d9552d1821a89277c35bf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CopyBalanceDocumentInput = {
  clientMutationId?: ?string,
  sourceDocumentId: number,
};
export type CopyBalanceDocumentMutationVariables = {|
  input: CopyBalanceDocumentInput
|};
export type CopyBalanceDocumentMutationResponse = {|
  +copyBalanceDocument: ?{|
    +balanceDocument: ?{|
      +rowId: number
    |}
  |}
|};
export type CopyBalanceDocumentMutation = {|
  variables: CopyBalanceDocumentMutationVariables,
  response: CopyBalanceDocumentMutationResponse,
|};
*/


/*
mutation CopyBalanceDocumentMutation(
  $input: CopyBalanceDocumentInput!
) {
  copyBalanceDocument(input: $input) {
    balanceDocument {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CopyBalanceDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CopyBalanceDocumentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CopyBalanceDocumentMutation",
  "id": null,
  "text": "mutation CopyBalanceDocumentMutation(\n  $input: CopyBalanceDocumentInput!\n) {\n  copyBalanceDocument(input: $input) {\n    balanceDocument {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CopyBalanceDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "copyBalanceDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "CopyBalanceDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "balanceDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "BalanceDocument",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CopyBalanceDocumentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "copyBalanceDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "CopyBalanceDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "balanceDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "BalanceDocument",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3d4e2c16ba5ddd53ec3131f4de093a42';
module.exports = node;
