import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateProductWarehouseQuantityMutation($input: UpdateProductWarehouseQuantityInput!) {
    updateProductWarehouseQuantity(input: $input) {
      productWarehouseQuantity {
        id
        sustainableValue
        value
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateProductWarehouseQuantity', 'productWarehouseQuantity');
