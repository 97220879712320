/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type OrderBalloonShopOrderDocumentMap$ref = any;
export type ShopOrderDocumentStatus = "CANCELED" | "COMPLETED" | "DELIVERED" | "PACKING" | "PENDING" | "SHIPPED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type MapShopOrderDocument_orders$ref: FragmentReference;
export type MapShopOrderDocument_orders = {|
  +nodes: $ReadOnlyArray<{|
    +id: string,
    +rowId: number,
    +deliveryAt: ?any,
    +deadlineAt: ?string,
    +ready: boolean,
    +owner: ?string,
    +label: ?string,
    +asap: boolean,
    +sourceUrl: ?string,
    +status: ShopOrderDocumentStatus,
    +heavy: ?boolean,
    +totalWeight: any,
    +totalSum: ?any,
    +mainWarehouseId: ?number,
    +groupId: ?number,
    +groupPosition: number,
    +yandexOrdersByDocumentId: {|
      +totalCount: number
    |},
    +group: ?{|
      +id: string,
      +rowId: number,
      +courierId: ?number,
      +deadlineAt: any,
    |},
    +deliveryMaxDelay: {|
      +hours: ?number
    |},
    +deliveryPoint: ?{|
      +x: number,
      +y: number,
    |},
    +point: ?{|
      +x: number,
      +y: number,
    |},
    +$fragmentRefs: OrderBalloonShopOrderDocumentMap$ref,
  |}>,
  +$refType: MapShopOrderDocument_orders$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deadlineAt",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "x",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "y",
    "args": null,
    "storageKey": null
  }
];
return {
  "kind": "Fragment",
  "name": "MapShopOrderDocument_orders",
  "type": "ShopOrderDocumentsConnection",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nodes",
      "storageKey": null,
      "args": null,
      "concreteType": "ShopOrderDocument",
      "plural": true,
      "selections": [
        {
          "kind": "FragmentSpread",
          "name": "OrderBalloonShopOrderDocumentMap",
          "args": null
        },
        v0,
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "deliveryAt",
          "args": null,
          "storageKey": null
        },
        v2,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "ready",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "owner",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "label",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "asap",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "sourceUrl",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "status",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "heavy",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalWeight",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "totalSum",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "mainWarehouseId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "groupId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "groupPosition",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "yandexOrdersByDocumentId",
          "storageKey": null,
          "args": null,
          "concreteType": "YandexOrdersConnection",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "totalCount",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": "group",
          "name": "shopOrderGroupByGroupId",
          "storageKey": null,
          "args": null,
          "concreteType": "ShopOrderGroup",
          "plural": false,
          "selections": [
            v0,
            v1,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "courierId",
              "args": null,
              "storageKey": null
            },
            v2
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "deliveryMaxDelay",
          "storageKey": null,
          "args": null,
          "concreteType": "Interval",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hours",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "deliveryPoint",
          "storageKey": null,
          "args": null,
          "concreteType": "Point",
          "plural": false,
          "selections": v3
        },
        {
          "kind": "LinkedField",
          "alias": "point",
          "name": "deliveryPoint",
          "storageKey": null,
          "args": null,
          "concreteType": "Point",
          "plural": false,
          "selections": v3
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '3048d2109bd48119e3209a47258e627e';
module.exports = node;
