exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-containers-App-___Header__root___3cXFC {\n  background: #f4f5f6;\n  border-bottom: 0.1rem solid #d1d1d1;\n  display: block;\n  height: 4rem;\n  display: flex;\n  padding: 0 30px;\n}\n\n.src-containers-App-___Header__logo___s4A6i {\n  font-size: 2rem;\n  font-weight: 200;\n  margin-right: 2rem;\n  text-decoration: none;\n  color: #222;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n@media (max-width: 700px) {\n  .src-containers-App-___Header__logo___s4A6i {\n    display: none;\n  }\n}\n\n.src-containers-App-___Header__menu-item___LfcQv,\n.src-containers-App-___Header__logo___s4A6i {\n  line-height: 4rem;\n}\n\n.src-containers-App-___Header__menu-item___LfcQv {\n  text-decoration: none;\n  display: flex;\n  align-items: center;\n  padding: 0 0.5rem;\n  margin: 0 0.5rem;\n  vertical-align: middle;\n  color: #222;\n}\n\n.src-containers-App-___Header__menu-item___LfcQv:hover {\n  color: #555;\n}\n\n.src-containers-App-___Header__menu-item___LfcQv:focus {\n  text-decoration: none !important;\n}\n\n.src-containers-App-___Header__menu-text___EwzhO,\n.src-containers-App-___Header__menu-icon___konNN {\n  vertical-align: middle;\n}\n\n.src-containers-App-___Header__menu-icon___konNN {\n  margin-right: 0.2rem;\n  font-size: 1.5rem;\n}\n\n.src-containers-App-___Header__activeMenuItem___1Wfn4 {\n  color: #e00;\n}\n\n.src-containers-App-___Header__right___1SMWD {\n  flex: 1;\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n}\n\n.src-containers-App-___Header__signout___1_GpR {\n  margin-left: 20px;\n  cursor: pointer;\n}\n\n@media print {\n  .src-containers-App-___Header__root___3cXFC {\n    display: none;\n  }\n}\n\n@media (max-width: 768px) {\n  .src-containers-App-___Header__menu-text___EwzhO {\n    display: none;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-containers-App-___Header__root___3cXFC",
	"logo": "src-containers-App-___Header__logo___s4A6i",
	"menu-item": "src-containers-App-___Header__menu-item___LfcQv",
	"menu-text": "src-containers-App-___Header__menu-text___EwzhO",
	"menu-icon": "src-containers-App-___Header__menu-icon___konNN",
	"activeMenuItem": "src-containers-App-___Header__activeMenuItem___1Wfn4",
	"right": "src-containers-App-___Header__right___1SMWD",
	"signout": "src-containers-App-___Header__signout___1_GpR"
};