import { graphql } from 'relay-runtime';

export default graphql`
  query EditShopDepositReplenishmentQuery($rowId: Int!) {
    data: shopDepositReplenishmentByRowId(rowId: $rowId) {
      id
      rowId
      createdAt
      userOwner
      userId
      sellerId
      value
      comment
    }

    allEmployees {
      nodes {
        id
        rowId
        firstName
        lastName
      }
    }
  }
`;
