import { graphql } from 'relay-runtime';

export default graphql`
  query ShowProductGroupQuery($rowId: Int!) {
    data: productGroupByRowId(rowId: $rowId) {
      id
      rowId
      name

      warehouse: warehouseByWarehouseId {
        rowId
        name
      }

      items: productGroupsProductsByGroupId {
        nodes {
          id

          productId
          product: productByProductId {
            rowId
            name
          }
        }
      }
    }
  }
`;
