/**
 * @flow
 * @relayHash 2b162c8a55365aa2bac5e74cf1ee15d5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteProductInput = {
  clientMutationId?: ?string,
  id: string,
};
export type DestroyProductMutationVariables = {|
  input: DeleteProductInput
|};
export type DestroyProductMutationResponse = {|
  +deleteProduct: ?{|
    +deletedProductId: ?string
  |}
|};
export type DestroyProductMutation = {|
  variables: DestroyProductMutationVariables,
  response: DestroyProductMutationResponse,
|};
*/


/*
mutation DestroyProductMutation(
  $input: DeleteProductInput!
) {
  deleteProduct(input: $input) {
    deletedProductId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteProductInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteProduct",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteProductInput!"
      }
    ],
    "concreteType": "DeleteProductPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedProductId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DestroyProductMutation",
  "id": null,
  "text": "mutation DestroyProductMutation(\n  $input: DeleteProductInput!\n) {\n  deleteProduct(input: $input) {\n    deletedProductId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DestroyProductMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DestroyProductMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a1232de45c3b0d400de170ee6f48bf44';
module.exports = node;
