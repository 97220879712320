// @flow
/* global $NonMaybeType, $ElementType */

import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import AttributeTable from 'components/AttributeTable';
import DataTable from 'components/DataTable';
import FullCopyMutation from 'mutations/FullCopySupplyDocumentMutation';
import { getShowActions } from 'utils/crud';
import { renderEnum, renderPrice, renderDate } from 'components/helpers';

import type { PageProps } from 'types';
import type { ShowSupplyContractorQueryResponse } from './__generated__/ShowSupplyContractorQuery.graphql';

import statuses from '../statuses';
import LastSupplyItems from './LastSupplyItems';

type Props = PageProps & {
  data: $NonMaybeType<$ElementType<ShowSupplyContractorQueryResponse, 'data'>>,
  location: Location,
};

const attributeRows = [
  {
    dataKey: 'name',
    label: 'Название',
  },
  {
    dataKey: 'status',
    label: 'Статус',
    render: renderEnum(statuses),
  },
  {
    dataKey: 'description',
    label: 'Описание',
  },
  {
    dataKey: 'owner',
    label: 'Владелец',
    render: (owner) => owner ? `${owner.firstName} ${owner.lastName}` : '???',
  },
];

const getActions = props => ({
  createSupply: {
    type: 'button',
    onClick: () =>
      FullCopyMutation.commit(props.context.environment, parseInt(props.params.rowId, 10)).then(data =>
        props.router.push(`/supply/documents/${data.rowId}?hideCopy`)
      ),
    icon: 'copy',
    children: 'Новая поставка',
  },
  ...getShowActions(props),
});

const Show = (props: Props) => (
  <Page title={`Поставщик #${props.data.rowId}`} actions={getActions(props)} backUrl={props.route.baseUrl}>
    <AttributeTable data={props.data} rows={attributeRows} />
    <h3 style={{ marginTop: 50, pageBreakBefore: 'always' }}>Все последние позиции</h3>
    <LastSupplyItems data={props.allLastContractorSupplyItems.nodes} />
    <h3 style={{ marginTop: 50 }}>Поставки</h3>
    <DataTable
      {...props}
      route={{ ...props.route, baseUrl: '/supply/documents' }}
      rowKey="id"
      onlyTable
      rows={props.data.documents}
      actions={['show']}
      columns={{
        totalSum: {
          title: 'Сумма',
          width: 150,
          render: renderPrice,
        },
        eventedAt: {
          title: 'Принято',
          render: renderDate,
        },
        comment: {
          title: 'Коментарий',
        },
      }}
    />
  </Page>
);

Show.propTypes = {
  route: PropTypes.shape({
    baseUrl: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    rowId: PropTypes.number.isRequired,
    documents: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  allLastContractorSupplyItems: PropTypes.object.isRequired,
};

export default Show;
