import { compose, withProps } from 'recompact';
import mutation from 'mutations/UpdateSupplyDocumentMutation';
import withUpdateEnhance from 'hoc/withUpdateEnhance';
import withNotFound from 'hoc/withNotFound';
import moment from 'moment';
import transform from 'utils/transform';

export default compose(
  withNotFound(),
  withProps({
    title: 'Изменить',
  }),
  withProps(transform('data.eventedAt', moment)),
  withUpdateEnhance({
    mutation,
  })
);
