/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type MkbPaymentAction$ref = any;
type MkbPaymentAction_order$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MkbPayments_order$ref: FragmentReference;
export type MkbPayments_order = {|
  +rowId: number,
  +userId: ?string,
  +shopId: ?number,
  +totalSum: ?any,
  +confirmedAmount: any,
  +owner: ?string,
  +mkbTransactions: {|
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +createdAt: any,
      +amount: any,
      +confirmed: boolean,
      +confirmData: ?any,
      +documentId: ?number,
      +refunds: {|
        +nodes: $ReadOnlyArray<{|
          +id: string,
          +amount: any,
          +confirmed: boolean,
          +confirmData: ?any,
        |}>
      |},
      +$fragmentRefs: MkbPaymentAction$ref,
    |}>
  |},
  +$fragmentRefs: MkbPaymentAction_order$ref,
  +$refType: MkbPayments_order$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "amount",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "confirmed",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "confirmData",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "MkbPayments_order",
  "type": "ShopOrderDocument",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "MkbPaymentAction_order",
      "args": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "rowId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "userId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "shopId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalSum",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "confirmedAmount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "owner",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "mkbTransactions",
      "name": "shopOrderMkbTransactionsByDocumentId",
      "storageKey": null,
      "args": null,
      "concreteType": "ShopOrderMkbTransactionsConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "nodes",
          "storageKey": null,
          "args": null,
          "concreteType": "ShopOrderMkbTransaction",
          "plural": true,
          "selections": [
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "createdAt",
              "args": null,
              "storageKey": null
            },
            v1,
            v2,
            v3,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "documentId",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "FragmentSpread",
              "name": "MkbPaymentAction",
              "args": null
            },
            {
              "kind": "LinkedField",
              "alias": "refunds",
              "name": "shopOrderMkbRefundsByTransactionId",
              "storageKey": "shopOrderMkbRefundsByTransactionId(orderBy:[\"CREATED_AT_ASC\"])",
              "args": [
                {
                  "kind": "Literal",
                  "name": "orderBy",
                  "value": [
                    "CREATED_AT_ASC"
                  ],
                  "type": "[ShopOrderMkbRefundsOrderBy!]"
                }
              ],
              "concreteType": "ShopOrderMkbRefundsConnection",
              "plural": false,
              "selections": [
                {
                  "kind": "LinkedField",
                  "alias": null,
                  "name": "nodes",
                  "storageKey": null,
                  "args": null,
                  "concreteType": "ShopOrderMkbRefund",
                  "plural": true,
                  "selections": [
                    v0,
                    v1,
                    v2,
                    v3
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bd85f29397b4a630614ce2053874e188';
module.exports = node;
