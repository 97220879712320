/**
 * @flow
 * @relayHash f486e523f663ad305359854aee8c422a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type CourierMap_allCouriers$ref = any;
export type CourierFilter = {
  rowId?: ?IntFilter,
  firstName?: ?StringFilter,
  middleName?: ?StringFilter,
  lastName?: ?StringFilter,
  hasDriversLicense?: ?BooleanFilter,
  workPhone?: ?StringFilter,
  privateCar?: ?StringFilter,
  telegramToken?: ?StringFilter,
  telegramChatId?: ?BigIntFilter,
  lastPositionedAt?: ?DatetimeFilter,
  hideContactsFromClients?: ?BooleanFilter,
  paymentTerminalId?: ?StringFilter,
  activeGroupId?: ?IntFilter,
  fired?: ?BooleanFilter,
  basePayoff?: ?BigFloatFilter,
  extraPayoff?: ?BigFloatFilter,
  mobileCashierId?: ?StringFilter,
  and?: ?$ReadOnlyArray<CourierFilter>,
  or?: ?$ReadOnlyArray<CourierFilter>,
  not?: ?CourierFilter,
};
export type IntFilter = {
  isNull?: ?boolean,
  equalTo?: ?number,
  notEqualTo?: ?number,
  distinctFrom?: ?number,
  notDistinctFrom?: ?number,
  in?: ?$ReadOnlyArray<number>,
  notIn?: ?$ReadOnlyArray<number>,
  lessThan?: ?number,
  lessThanOrEqualTo?: ?number,
  greaterThan?: ?number,
  greaterThanOrEqualTo?: ?number,
};
export type StringFilter = {
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  equalToInsensitive?: ?string,
  notEqualToInsensitive?: ?string,
  distinctFromInsensitive?: ?string,
  notDistinctFromInsensitive?: ?string,
  inInsensitive?: ?$ReadOnlyArray<string>,
  notInInsensitive?: ?$ReadOnlyArray<string>,
  lessThanInsensitive?: ?string,
  lessThanOrEqualToInsensitive?: ?string,
  greaterThanInsensitive?: ?string,
  greaterThanOrEqualToInsensitive?: ?string,
};
export type BooleanFilter = {
  isNull?: ?boolean,
  equalTo?: ?boolean,
  notEqualTo?: ?boolean,
  distinctFrom?: ?boolean,
  notDistinctFrom?: ?boolean,
  in?: ?$ReadOnlyArray<boolean>,
  notIn?: ?$ReadOnlyArray<boolean>,
  lessThan?: ?boolean,
  lessThanOrEqualTo?: ?boolean,
  greaterThan?: ?boolean,
  greaterThanOrEqualTo?: ?boolean,
};
export type BigIntFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type DatetimeFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type BigFloatFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type CourierMapQueryShopOrderDocumentsQueryVariables = {|
  filter?: ?CourierFilter
|};
export type CourierMapQueryShopOrderDocumentsQueryResponse = {|
  +allCouriers: ?{|
    +$fragmentRefs: CourierMap_allCouriers$ref
  |}
|};
export type CourierMapQueryShopOrderDocumentsQuery = {|
  variables: CourierMapQueryShopOrderDocumentsQueryVariables,
  response: CourierMapQueryShopOrderDocumentsQueryResponse,
|};
*/


/*
query CourierMapQueryShopOrderDocumentsQuery(
  $filter: CourierFilter
) {
  allCouriers(filter: $filter) {
    ...CourierMap_allCouriers
  }
}

fragment CourierMap_allCouriers on CouriersConnection {
  nodes {
    rowId
    lastPosition {
      x
      y
    }
    lastPositionedAt
    firstName
    lastName
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "CourierFilter",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter",
    "type": "CourierFilter"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "CourierMapQueryShopOrderDocumentsQuery",
  "id": null,
  "text": "query CourierMapQueryShopOrderDocumentsQuery(\n  $filter: CourierFilter\n) {\n  allCouriers(filter: $filter) {\n    ...CourierMap_allCouriers\n  }\n}\n\nfragment CourierMap_allCouriers on CouriersConnection {\n  nodes {\n    rowId\n    lastPosition {\n      x\n      y\n    }\n    lastPositionedAt\n    firstName\n    lastName\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CourierMapQueryShopOrderDocumentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allCouriers",
        "storageKey": null,
        "args": v1,
        "concreteType": "CouriersConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "CourierMap_allCouriers",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CourierMapQueryShopOrderDocumentsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allCouriers",
        "storageKey": null,
        "args": v1,
        "concreteType": "CouriersConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Courier",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "rowId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "lastPosition",
                "storageKey": null,
                "args": null,
                "concreteType": "Point",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "x",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "y",
                    "args": null,
                    "storageKey": null
                  }
                ]
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastPositionedAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ef17b93254c3bdf644fbdfb79c1fd339';
module.exports = node;
