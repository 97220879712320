import { compose, withProps } from 'recompact';
import mutation from 'mutations/UpdateProductMutation';
import withUpdateEnhance from 'hoc/withUpdateEnhance';
import withNotFound from 'hoc/withNotFound';
import normalize from '../normalizeForm';

export default compose(
  withNotFound(),
  withProps({
    title: 'Изменить',
  }),
  withUpdateEnhance({
    normalize,
    mutation,
  })
);
