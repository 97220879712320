/**
 * @flow
 * @relayHash 4d8a9d91b18590778d6c629cbb373f87
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShowBalanceDocumentQueryVariables = {|
  rowId: number
|};
export type ShowBalanceDocumentQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +eventedAt: any,
    +comment: ?string,
    +items: {|
      +nodes: $ReadOnlyArray<{|
        +id: string,
        +productId: number,
        +warehouseId: number,
        +quantity: any,
      |}>
    |},
  |},
  +allWarehouses: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +name: string,
    |}>
  |},
|};
export type ShowBalanceDocumentQuery = {|
  variables: ShowBalanceDocumentQueryVariables,
  response: ShowBalanceDocumentQueryResponse,
|};
*/


/*
query ShowBalanceDocumentQuery(
  $rowId: Int!
) {
  data: balanceDocumentByRowId(rowId: $rowId) {
    id
    rowId
    eventedAt
    comment
    items: balanceItemsByDocumentId {
      nodes {
        id
        productId
        warehouseId
        quantity
      }
    }
  }
  allWarehouses {
    nodes {
      rowId
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": "data",
  "name": "balanceDocumentByRowId",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "rowId",
      "variableName": "rowId",
      "type": "Int!"
    }
  ],
  "concreteType": "BalanceDocument",
  "plural": false,
  "selections": [
    v1,
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "eventedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "comment",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "items",
      "name": "balanceItemsByDocumentId",
      "storageKey": null,
      "args": null,
      "concreteType": "BalanceItemsConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "nodes",
          "storageKey": null,
          "args": null,
          "concreteType": "BalanceItem",
          "plural": true,
          "selections": [
            v1,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "productId",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "warehouseId",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "quantity",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ShowBalanceDocumentQuery",
  "id": null,
  "text": "query ShowBalanceDocumentQuery(\n  $rowId: Int!\n) {\n  data: balanceDocumentByRowId(rowId: $rowId) {\n    id\n    rowId\n    eventedAt\n    comment\n    items: balanceItemsByDocumentId {\n      nodes {\n        id\n        productId\n        warehouseId\n        quantity\n      }\n    }\n  }\n  allWarehouses {\n    nodes {\n      rowId\n      name\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ShowBalanceDocumentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v3,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allWarehouses",
        "storageKey": null,
        "args": null,
        "concreteType": "WarehousesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": true,
            "selections": [
              v2,
              v4
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ShowBalanceDocumentQuery",
    "argumentDefinitions": v0,
    "selections": [
      v3,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allWarehouses",
        "storageKey": null,
        "args": null,
        "concreteType": "WarehousesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": true,
            "selections": [
              v2,
              v4,
              v1
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '545a919de3e765d458906b2b67cf0fdf';
module.exports = node;
