/**
 * @flow
 * @relayHash d6efd31c199e0c3c6155e2d23c12b402
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateProductGroupsProductInput = {
  clientMutationId?: ?string,
  productGroupsProduct: ProductGroupsProductInput,
};
export type ProductGroupsProductInput = {
  rowId?: ?number,
  productId: number,
  groupId: number,
};
export type CreateProductGroupsProductMutationVariables = {|
  input: CreateProductGroupsProductInput
|};
export type CreateProductGroupsProductMutationResponse = {|
  +createProductGroupsProduct: ?{|
    +productGroupsProduct: ?{|
      +rowId: number
    |}
  |}
|};
export type CreateProductGroupsProductMutation = {|
  variables: CreateProductGroupsProductMutationVariables,
  response: CreateProductGroupsProductMutationResponse,
|};
*/


/*
mutation CreateProductGroupsProductMutation(
  $input: CreateProductGroupsProductInput!
) {
  createProductGroupsProduct(input: $input) {
    productGroupsProduct {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateProductGroupsProductInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateProductGroupsProductInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateProductGroupsProductMutation",
  "id": null,
  "text": "mutation CreateProductGroupsProductMutation(\n  $input: CreateProductGroupsProductInput!\n) {\n  createProductGroupsProduct(input: $input) {\n    productGroupsProduct {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateProductGroupsProductMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createProductGroupsProduct",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateProductGroupsProductPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productGroupsProduct",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductGroupsProduct",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateProductGroupsProductMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createProductGroupsProduct",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateProductGroupsProductPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productGroupsProduct",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductGroupsProduct",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6e7b0f8cb21186afe2f4b9fa4073bc85';
module.exports = node;
