import { graphql } from 'relay-runtime';

export default graphql`
  query EditEmployeeQuery($rowId: Int!) {
    data: employeeByRowId(rowId: $rowId) {
      id
      rowId
      firstName
      middleName
      lastName
      email
      role
    }
  }
`;
