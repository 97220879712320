/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { Placemark } from 'react-yandex-maps';
import { createPortal } from 'react-dom';

let i = 0;

class ActivePlacemark extends React.Component {
  static contextTypes = {
    ymaps: PropTypes.object.isRequired,
  };

  state = {
    opened: false,
  };

  constructor(props) {
    super(props);

    const self = this;

    this.balloonEl = document.createElement('div');

    this.balloonLayout = ymaps.templateLayoutFactory.createClass(`<div></div>`, {
      build: function() {
        this.constructor.superclass.build.call(this);
        self.setState({ opened: true });
        this._element.querySelector('div').appendChild(self.balloonEl);
      },
      clear: function() {
        this._element.querySelector('div').removeChild(self.balloonEl);
        self.setState({ opened: false });
        this.constructor.superclass.clear.call(this);
      },
    });
  }

  render() {
    return [
      <Placemark
        key="placemark"
        {...this.props}
        properties={{
          reactBalloonContent: this.props.reactBalloonContent,
          ...this.props.properties,
        }}
        options={{
          balloonContentLayout: this.balloonLayout,
          balloonContentHeaderLayout: this.balloonLayout,
          balloonPanelMaxMapArea: 0,
          ...this.props.options,
        }}
      />,
      this.state.opened && createPortal(this.props.reactBalloonContent, this.balloonEl),
    ];
  }
}

export default ActivePlacemark;
