/**
 * @flow
 * @relayHash f3a63a3c6e50347007f74362a3ffd5cf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupplyDocumentsOrderBy = "COMMENT_ASC" | "COMMENT_DESC" | "CONTRACTOR_ID_ASC" | "CONTRACTOR_ID_DESC" | "EVENTED_AT_ASC" | "EVENTED_AT_DESC" | "ID_ASC" | "ID_DESC" | "META_ASC" | "META_DESC" | "NATURAL" | "ORGANIZATION_ID_ASC" | "ORGANIZATION_ID_DESC" | "OWNER_ID_ASC" | "OWNER_ID_DESC" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "TOTAL_SUM_ASC" | "TOTAL_SUM_DESC" | "%future added value";
export type SupplyDocumentFilter = {
  rowId?: ?IntFilter,
  contractorId?: ?IntFilter,
  eventedAt?: ?DatetimeFilter,
  totalSum?: ?BigFloatFilter,
  comment?: ?StringFilter,
  meta?: ?JSONFilter,
  ownerId?: ?IntFilter,
  organizationId?: ?IntFilter,
  eventedOn?: ?DateFilter,
  and?: ?$ReadOnlyArray<SupplyDocumentFilter>,
  or?: ?$ReadOnlyArray<SupplyDocumentFilter>,
  not?: ?SupplyDocumentFilter,
};
export type IntFilter = {
  isNull?: ?boolean,
  equalTo?: ?number,
  notEqualTo?: ?number,
  distinctFrom?: ?number,
  notDistinctFrom?: ?number,
  in?: ?$ReadOnlyArray<number>,
  notIn?: ?$ReadOnlyArray<number>,
  lessThan?: ?number,
  lessThanOrEqualTo?: ?number,
  greaterThan?: ?number,
  greaterThanOrEqualTo?: ?number,
};
export type DatetimeFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type BigFloatFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type StringFilter = {
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  equalToInsensitive?: ?string,
  notEqualToInsensitive?: ?string,
  distinctFromInsensitive?: ?string,
  notDistinctFromInsensitive?: ?string,
  inInsensitive?: ?$ReadOnlyArray<string>,
  notInInsensitive?: ?$ReadOnlyArray<string>,
  lessThanInsensitive?: ?string,
  lessThanOrEqualToInsensitive?: ?string,
  greaterThanInsensitive?: ?string,
  greaterThanOrEqualToInsensitive?: ?string,
};
export type JSONFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
  contains?: ?any,
  containsKey?: ?string,
  containsAllKeys?: ?$ReadOnlyArray<string>,
  containsAnyKeys?: ?$ReadOnlyArray<string>,
  containedBy?: ?any,
};
export type DateFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type SupplyDocumentsQueryVariables = {|
  first: number,
  offset: number,
  orderBy?: ?$ReadOnlyArray<SupplyDocumentsOrderBy>,
  filter?: ?SupplyDocumentFilter,
|};
export type SupplyDocumentsQueryResponse = {|
  +rows: ?{|
    +totalCount: number,
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +eventedAt: any,
      +totalSum: any,
      +comment: string,
      +contractor: ?{|
        +rowId: number,
        +name: string,
      |},
    |}>,
  |},
  +allContractors: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +name: string,
    |}>
  |},
|};
export type SupplyDocumentsQuery = {|
  variables: SupplyDocumentsQueryVariables,
  response: SupplyDocumentsQueryResponse,
|};
*/


/*
query SupplyDocumentsQuery(
  $first: Int!
  $offset: Int!
  $orderBy: [SupplyDocumentsOrderBy!]
  $filter: SupplyDocumentFilter
) {
  rows: allSupplyDocuments(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
    totalCount
    nodes {
      id
      rowId
      eventedAt
      totalSum
      comment
      contractor: supplyContractorByContractorId {
        rowId
        name
        id
      }
    }
  }
  allContractors: allSupplyContractors {
    nodes {
      rowId
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[SupplyDocumentsOrderBy!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "SupplyDocumentFilter",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter",
    "type": "SupplyDocumentFilter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy",
    "type": "[SupplyDocumentsOrderBy!]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "eventedAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalSum",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "comment",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v9 = [
  v4,
  v8
],
v10 = [
  v4,
  v8,
  v3
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SupplyDocumentsQuery",
  "id": null,
  "text": "query SupplyDocumentsQuery(\n  $first: Int!\n  $offset: Int!\n  $orderBy: [SupplyDocumentsOrderBy!]\n  $filter: SupplyDocumentFilter\n) {\n  rows: allSupplyDocuments(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {\n    totalCount\n    nodes {\n      id\n      rowId\n      eventedAt\n      totalSum\n      comment\n      contractor: supplyContractorByContractorId {\n        rowId\n        name\n        id\n      }\n    }\n  }\n  allContractors: allSupplyContractors {\n    nodes {\n      rowId\n      name\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SupplyDocumentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "rows",
        "name": "allSupplyDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "SupplyDocumentsConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyDocument",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              v7,
              {
                "kind": "LinkedField",
                "alias": "contractor",
                "name": "supplyContractorByContractorId",
                "storageKey": null,
                "args": null,
                "concreteType": "SupplyContractor",
                "plural": false,
                "selections": v9
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "allContractors",
        "name": "allSupplyContractors",
        "storageKey": null,
        "args": null,
        "concreteType": "SupplyContractorsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyContractor",
            "plural": true,
            "selections": v9
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SupplyDocumentsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "rows",
        "name": "allSupplyDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "SupplyDocumentsConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyDocument",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              v7,
              {
                "kind": "LinkedField",
                "alias": "contractor",
                "name": "supplyContractorByContractorId",
                "storageKey": null,
                "args": null,
                "concreteType": "SupplyContractor",
                "plural": false,
                "selections": v10
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "allContractors",
        "name": "allSupplyContractors",
        "storageKey": null,
        "args": null,
        "concreteType": "SupplyContractorsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyContractor",
            "plural": true,
            "selections": v10
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '566f87d4c299ebfbcbabc8d6e5904516';
module.exports = node;
