/* eslint-disable react/prop-types */
// @flow

import React from 'react';
import { compose, withState, withProps } from 'recompact';
import destroyMutation from 'mutations/DestroyProductCategoryMutation';
import InlineInput from 'components/InlineInput';
import updateMutation from 'mutations/UpdateProductCategoryMutation';

export default compose(
  withProps(props => ({
    title: 'Категории продуктов',
    destroyMutation,
    columns: {
      name: {
        title: 'Название',
      },
      position: {
        title: 'Позиция',
        width: 100,
        render: (value, row, i) => (
          <InlineInput
            tabIndex={i + 1}
            autoSelect
            onUpdate={v => updateMutation.commitValue(props.context.environment, row.id, 'position', parseInt(v, 10))}
            value={value}
          />
        ),
      },
    },
  })),
  withState('selectedRowKeys', 'setSelectedRowKeys', []),
  withProps(props => ({
    tableProps: {
      rowKey: 'rowId',
      rowSelection: {
        selectedRowKeys: props.selectedRowKeys,
        onChange: v => props.setSelectedRowKeys(v),
      },
    },
    pageActions: {
      printProduction: props.selectedRowKeys.length > 0 && {
        type: 'link',
        to: {
          pathname: '/product/categories/print',
          query: { ids: props.selectedRowKeys, onlyProduction: true },
        },
        children: 'Печать только производства',
        icon: 'file',
      },
      print: props.selectedRowKeys.length > 0 && {
        type: 'link',
        to: {
          pathname: '/product/categories/print',
          query: { ids: props.selectedRowKeys },
        },
        children: 'Печать',
        icon: 'file',
      },
    },
  }))
);
