/**
 * @flow
 * @relayHash 437a42fa204c37afc2d5001c3a7a8923
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteBalanceDocumentInput = {
  clientMutationId?: ?string,
  id: string,
};
export type DestroyBalanceDocumentMutationVariables = {|
  input: DeleteBalanceDocumentInput
|};
export type DestroyBalanceDocumentMutationResponse = {|
  +deleteBalanceDocument: ?{|
    +deletedBalanceDocumentId: ?string
  |}
|};
export type DestroyBalanceDocumentMutation = {|
  variables: DestroyBalanceDocumentMutationVariables,
  response: DestroyBalanceDocumentMutationResponse,
|};
*/


/*
mutation DestroyBalanceDocumentMutation(
  $input: DeleteBalanceDocumentInput!
) {
  deleteBalanceDocument(input: $input) {
    deletedBalanceDocumentId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteBalanceDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteBalanceDocument",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteBalanceDocumentInput!"
      }
    ],
    "concreteType": "DeleteBalanceDocumentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedBalanceDocumentId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DestroyBalanceDocumentMutation",
  "id": null,
  "text": "mutation DestroyBalanceDocumentMutation(\n  $input: DeleteBalanceDocumentInput!\n) {\n  deleteBalanceDocument(input: $input) {\n    deletedBalanceDocumentId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DestroyBalanceDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DestroyBalanceDocumentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '12f2998fdc2bd2dec75810a559e84051';
module.exports = node;
