/**
 * @flow
 * @relayHash 7d49eeadce383e7f9dc6fac823c36694
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type GroupsCouriersShow$ref = any;
export type ShopOrderGroupStatus = "COMPLETED" | "PENDING" | "SENDED" | "%future added value";
export type ShopOrderGroupCondition = {
  rowId?: ?number,
  deadlineAt?: ?any,
  owner?: ?string,
  comment?: ?string,
  courierId?: ?number,
  status?: ?ShopOrderGroupStatus,
  courierToken?: ?any,
  senderId?: ?number,
  completerId?: ?number,
  totalChange?: ?any,
  totalCashSum?: ?any,
  activeDocumentId?: ?number,
  courierBasePayoff?: ?any,
  courierExtraPayoff?: ?any,
};
export type ShowCourierQueryVariables = {|
  rowId: number,
  showGroupCondition: ShopOrderGroupCondition,
|};
export type ShowCourierQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +firstName: string,
    +lastName: ?string,
    +privateCar: ?string,
    +middleName: ?string,
    +telegramChatId: ?any,
    +workPhone: ?string,
    +hasDriversLicense: ?boolean,
  |},
  +groups: ?{|
    +nodes: $ReadOnlyArray<{|
      +$fragmentRefs: GroupsCouriersShow$ref
    |}>
  |},
|};
export type ShowCourierQuery = {|
  variables: ShowCourierQueryVariables,
  response: ShowCourierQueryResponse,
|};
*/


/*
query ShowCourierQuery(
  $rowId: Int!
  $showGroupCondition: ShopOrderGroupCondition!
) {
  data: courierByRowId(rowId: $rowId) {
    id
    rowId
    firstName
    lastName
    privateCar
    middleName
    telegramChatId
    workPhone
    hasDriversLicense
  }
  groups: allShopOrderGroups(condition: $showGroupCondition) {
    nodes {
      ...GroupsCouriersShow
      id
    }
  }
}

fragment GroupsCouriersShow on ShopOrderGroup {
  deadlineAt
  orders: shopOrderDocumentsByGroupId(orderBy: GROUP_POSITION_ASC) {
    nodes {
      rowId
      deliveryAt
      deliveryMaxDelay {
        seconds
        minutes
        hours
      }
      deliveredAt
      status
      label
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "showGroupCondition",
    "type": "ShopOrderGroupCondition!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": "data",
  "name": "courierByRowId",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "rowId",
      "variableName": "rowId",
      "type": "Int!"
    }
  ],
  "concreteType": "Courier",
  "plural": false,
  "selections": [
    v1,
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "firstName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "lastName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "privateCar",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "middleName",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "telegramChatId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "workPhone",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hasDriversLicense",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = [
  {
    "kind": "Variable",
    "name": "condition",
    "variableName": "showGroupCondition",
    "type": "ShopOrderGroupCondition"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ShowCourierQuery",
  "id": null,
  "text": "query ShowCourierQuery(\n  $rowId: Int!\n  $showGroupCondition: ShopOrderGroupCondition!\n) {\n  data: courierByRowId(rowId: $rowId) {\n    id\n    rowId\n    firstName\n    lastName\n    privateCar\n    middleName\n    telegramChatId\n    workPhone\n    hasDriversLicense\n  }\n  groups: allShopOrderGroups(condition: $showGroupCondition) {\n    nodes {\n      ...GroupsCouriersShow\n      id\n    }\n  }\n}\n\nfragment GroupsCouriersShow on ShopOrderGroup {\n  deadlineAt\n  orders: shopOrderDocumentsByGroupId(orderBy: GROUP_POSITION_ASC) {\n    nodes {\n      rowId\n      deliveryAt\n      deliveryMaxDelay {\n        seconds\n        minutes\n        hours\n      }\n      deliveredAt\n      status\n      label\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ShowCourierQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v3,
      {
        "kind": "LinkedField",
        "alias": "groups",
        "name": "allShopOrderGroups",
        "storageKey": null,
        "args": v4,
        "concreteType": "ShopOrderGroupsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderGroup",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "GroupsCouriersShow",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ShowCourierQuery",
    "argumentDefinitions": v0,
    "selections": [
      v3,
      {
        "kind": "LinkedField",
        "alias": "groups",
        "name": "allShopOrderGroups",
        "storageKey": null,
        "args": v4,
        "concreteType": "ShopOrderGroupsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderGroup",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "deadlineAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": "orders",
                "name": "shopOrderDocumentsByGroupId",
                "storageKey": "shopOrderDocumentsByGroupId(orderBy:\"GROUP_POSITION_ASC\")",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": "GROUP_POSITION_ASC",
                    "type": "[ShopOrderDocumentsOrderBy!]"
                  }
                ],
                "concreteType": "ShopOrderDocumentsConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "nodes",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ShopOrderDocument",
                    "plural": true,
                    "selections": [
                      v2,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "deliveryAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "deliveryMaxDelay",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Interval",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "seconds",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "minutes",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "hours",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "deliveredAt",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "status",
                        "args": null,
                        "storageKey": null
                      },
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "label",
                        "args": null,
                        "storageKey": null
                      },
                      v1
                    ]
                  }
                ]
              },
              v1
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'edf24aa2ada66485f8ad1416e5a9c52d';
module.exports = node;
