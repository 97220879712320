import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateShopOrderGroupMutation($input: UpdateShopOrderGroupInput!) {
    updateShopOrderGroup(input: $input) {
      shopOrderGroup {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateShopOrderGroup', 'shopOrderGroup');
