import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { QueryRenderer, createFragmentContainer, graphql } from 'react-relay';
import { Button, Row, Col } from 'antd';
import { Link } from 'found';
import DataTable from 'components/DataTable';
import { renderPrice, renderDate } from 'components/helpers';

const query = graphql`
  query DepositTransactionsQuery($condition: AllShopDepositTransactionCondition!) {
    allAllShopDepositTransactions(condition: $condition) {
      nodes {
        createdAt
        value
        stepValue
        documentId
        replenishmentId
      }
    }
  }
`;

const DepositTransactions = ({ order, relay, ...rest }) => {
  const [all, showAll] = useReducer(() => true, false);

  if (!order.userId) {
    return <div>Не привязан к пользователю</div>;
  }

  return (
    <QueryRenderer
      query={query}
      environment={relay.environment}
      variables={{
        condition: {
          userId: order.userId,
          userOwner: order.owner,
        },
      }}
      render={({ props, error }) => {
        if (error) {
          return <div>{error}</div>;
        }

        if (!props) {
          return null;
        }

        const allButtonVisible = !all && props.allAllShopDepositTransactions.nodes.length > 5;

        return (
          <div>
            <Row gutter={16}>
              <Col span={12}>
                <h3>
                  Депозит <small style={{ marginLeft: 20 }}>остаток: {renderPrice(order.depositBalance)}</small>
                </h3>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {allButtonVisible && <Button size="small" onClick={showAll} style={{ marginRight: 10 }}>Показать все</Button>}
                <Link
                  to={{
                    pathname: '/shop/deposit/replenishments/new',
                    query: { userId: order.userId, userOwner: order.owner },
                  }}
                >
                  <Button size="small">Пополнить</Button>
                </Link>
              </Col>
            </Row>
            <DataTable
              {...rest}
              rowKey="id"
              onlyTable
              rows={{
                nodes: all ? props.allAllShopDepositTransactions.nodes : props.allAllShopDepositTransactions.nodes.slice(0, 5),
              }}
              actions={[]}
              columns={{
                rowId: {
                  hidden: true,
                },
                createdAt: {
                  title: 'Время',
                  render: renderDate,
                },
                value: {
                  title: 'Сумма',
                  sorter: false,
                  render: renderPrice,
                },
                stepValue: {
                  title: 'Значение',
                  sorter: false,
                  render: renderPrice,
                },
              }}
            />
          </div>
        );
      }}
    />
  );
}

DepositTransactions.propTypes = {
  order: PropTypes.object.isRequired,
  relay: PropTypes.object.isRequired,
};

export default createFragmentContainer(
  DepositTransactions,
  graphql`
    fragment DepositTransactions_order on ShopOrderDocument {
      userId
      owner
      depositBalance
    }
  `
);
