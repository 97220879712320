import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateWriteOffItemMutation($input: UpdateWriteOffItemInput!) {
    updateWriteOffItem(input: $input) {
      writeOffItem {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateWriteOffItem', 'writeOffItem');
