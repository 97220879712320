import { graphql } from 'relay-runtime';

export default graphql`
  query PrintProductsQuery($filter: ProductFilter!) {
    allProducts(filter: $filter) {
      nodes {
        id
        name
        unit

        quantities: productWarehouseQuantitiesByProductId(condition: { warehouseId: 2 }) {
          nodes {
            value
          }
        }

        productGroups: productGroupsProductsByProductId {
          nodes {
            group: productGroupByGroupId {
              id
              name
            }
          }
        }
      }
    }
  }
`;
