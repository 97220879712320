/**
 * @flow
 * @relayHash 087bd6744e3df16e33dc3ea7d3827586
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CopyWarehouseMovementDocumentInput = {
  clientMutationId?: ?string,
  sourceDocumentId: number,
};
export type CopyWarehouseMovementDocumentMutationVariables = {|
  input: CopyWarehouseMovementDocumentInput
|};
export type CopyWarehouseMovementDocumentMutationResponse = {|
  +copyWarehouseMovementDocument: ?{|
    +warehouseMovementDocument: ?{|
      +rowId: number
    |}
  |}
|};
export type CopyWarehouseMovementDocumentMutation = {|
  variables: CopyWarehouseMovementDocumentMutationVariables,
  response: CopyWarehouseMovementDocumentMutationResponse,
|};
*/


/*
mutation CopyWarehouseMovementDocumentMutation(
  $input: CopyWarehouseMovementDocumentInput!
) {
  copyWarehouseMovementDocument(input: $input) {
    warehouseMovementDocument {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CopyWarehouseMovementDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CopyWarehouseMovementDocumentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CopyWarehouseMovementDocumentMutation",
  "id": null,
  "text": "mutation CopyWarehouseMovementDocumentMutation(\n  $input: CopyWarehouseMovementDocumentInput!\n) {\n  copyWarehouseMovementDocument(input: $input) {\n    warehouseMovementDocument {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CopyWarehouseMovementDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "copyWarehouseMovementDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "CopyWarehouseMovementDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "warehouseMovementDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "WarehouseMovementDocument",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CopyWarehouseMovementDocumentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "copyWarehouseMovementDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "CopyWarehouseMovementDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "warehouseMovementDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "WarehouseMovementDocument",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aecf13003ddee0bc140841053dcaa7e3';
module.exports = node;
