import React from 'react';
import PropTypes from 'prop-types';
import { formatQuantity } from 'utils/format';
import './Print.css';

const Print = ({ allProducts }) => (
  <table styleName="table">
    <tbody>
      {allProducts.nodes.map(p => (
        <tr key={p.id}>
          <td width={100}>{p.productGroups.nodes.map(n => n && n.group.name).join(', ')}</td>
          <td>{p.name}</td>
          <td width={100} styleName="number">
            {p.quantities.nodes.map(q => q && formatQuantity(q.value, p.unit)).join(', ') || '-'}
          </td>
          <td width={100} />
        </tr>
      ))}
    </tbody>
  </table>
);

Print.propTypes = {
  allProducts: PropTypes.object.isRequired,
};

export default Print;
