import React from 'react';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import PropTypes from 'prop-types';
import { createRefetchContainer, graphql } from 'react-relay';
import AutoReload from 'components/AutoReload';
import query from './CourierMapQuery';
import prepareVariables from './prepareVariables';

const CourierMap = ({ allCouriers, relay, setMapState, router, location }) => {
  const mapProps = {
    width: 'calc(100vw)',
    height: 'calc(100vh - 52px)',
    state: {
      zoom: 10,
      center: [55.76, 37.64],
      ...location.query,
    },
    onBoundsChange: e => {
      router.push({
        pathname: location.pathname,
        query: {
          center: e.get('newCenter'),
          zoom: e.get('newZoom'),
        },
      });
    },
  };

  const refetch = () => {
    relay.refetch(prepareVariables());
  };

  return (
    <YMaps>
      <React.Fragment>
        <AutoReload refetch={refetch} />
        <Map {...mapProps}>
          {allCouriers.nodes.map(c => (
            <Placemark
              key={c.rowId}
              geometry={{
                coordinates: [c.lastPosition.x, c.lastPosition.y],
              }}
              properties={{
                iconContent: [c.firstName, c.lastName].filter(Boolean).join(' '),
              }}
              options={{
                preset: 'islands#blueStretchyIcon'
              }}
            />
          ))}
        </Map>
      </React.Fragment>
    </YMaps>
  );
};

export default createRefetchContainer(
  CourierMap,
  graphql`
    fragment CourierMap_allCouriers on CouriersConnection {
      nodes {
        rowId
        lastPosition { x, y }
        lastPositionedAt
        firstName
        lastName
      }
    }
  `,
  query
);
