/**
 * @flow
 * @relayHash 59e670d187c1d1fc84d19f3ae2f21653
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditScaleQueryVariables = {|
  rowId: number
|};
export type EditScaleQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +addr: ?string,
    +name: string,
    +comment: string,
  |}
|};
export type EditScaleQuery = {|
  variables: EditScaleQueryVariables,
  response: EditScaleQueryResponse,
|};
*/


/*
query EditScaleQuery(
  $rowId: Int!
) {
  data: scaleByRowId(rowId: $rowId) {
    id
    rowId
    addr
    name
    comment
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "data",
    "name": "scaleByRowId",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "rowId",
        "variableName": "rowId",
        "type": "Int!"
      }
    ],
    "concreteType": "Scale",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rowId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "addr",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "comment",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditScaleQuery",
  "id": null,
  "text": "query EditScaleQuery(\n  $rowId: Int!\n) {\n  data: scaleByRowId(rowId: $rowId) {\n    id\n    rowId\n    addr\n    name\n    comment\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditScaleQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "EditScaleQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5807298149398f95e33b13114ef45ca1';
module.exports = node;
