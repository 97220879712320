/**
 * @flow
 * @relayHash 0f8706500d07bccc7b058d10dc15a4d7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WriteOffCategoriesOrderBy = "ID_ASC" | "ID_DESC" | "IGNORE_IN_REPORTS_ASC" | "IGNORE_IN_REPORTS_DESC" | "NAME_ASC" | "NAME_DESC" | "NATURAL" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "%future added value";
export type WriteOffCategoriesQueryVariables = {|
  first: number,
  offset: number,
  orderBy?: ?$ReadOnlyArray<WriteOffCategoriesOrderBy>,
|};
export type WriteOffCategoriesQueryResponse = {|
  +rows: ?{|
    +totalCount: number,
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +name: string,
    |}>,
  |}
|};
export type WriteOffCategoriesQuery = {|
  variables: WriteOffCategoriesQueryVariables,
  response: WriteOffCategoriesQueryResponse,
|};
*/


/*
query WriteOffCategoriesQuery(
  $first: Int!
  $offset: Int!
  $orderBy: [WriteOffCategoriesOrderBy!]
) {
  rows: allWriteOffCategories(first: $first, offset: $offset, orderBy: $orderBy) {
    totalCount
    nodes {
      id
      rowId
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[WriteOffCategoriesOrderBy!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "rows",
    "name": "allWriteOffCategories",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "offset",
        "variableName": "offset",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy",
        "type": "[WriteOffCategoriesOrderBy!]"
      }
    ],
    "concreteType": "WriteOffCategoriesConnection",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": null,
        "concreteType": "WriteOffCategory",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rowId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "WriteOffCategoriesQuery",
  "id": null,
  "text": "query WriteOffCategoriesQuery(\n  $first: Int!\n  $offset: Int!\n  $orderBy: [WriteOffCategoriesOrderBy!]\n) {\n  rows: allWriteOffCategories(first: $first, offset: $offset, orderBy: $orderBy) {\n    totalCount\n    nodes {\n      id\n      rowId\n      name\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "WriteOffCategoriesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "WriteOffCategoriesQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '13bafbe1b6d671e02e0dd3605a24b682';
module.exports = node;
