// @flow

import type { ShopOrderDocumentPaymentMethod } from './__generated__/ShopOrderDocumentsQuery.graphql';

const paymentMethods: { [ShopOrderDocumentPaymentMethod]: string } = {
  BY_CARD: 'Картой курьеру',
  BY_CASH: 'Наличные',
  ONLINE: 'Онлайн',
  DEPOSIT: 'Депозит',
  EMPLOYEE: 'Сотрудник',
};

export default paymentMethods;
