// @flow

import { compose, withProps } from 'recompact';
import destroyMutation from 'mutations/DestroyBalanceDocumentMutation';
import { renderDate } from 'components/helpers';

export default compose(
  withProps({
    title: 'Выравнивания',
    destroyMutation,
    columns: {
      eventedAt: {
        title: 'Дата',
        render: renderDate,
        width: 150,
      },
      comment: {
        title: 'Коментарий',
      },
    },
  })
);
