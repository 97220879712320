// @flow
/* global $NonMaybeType, $ElementType */

import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import AttributeTable from 'components/AttributeTable';
import { getShowActions } from 'utils/crud';
import { renderIcon } from 'components/helpers';

import type { PageProps } from 'types';
import type { ShowCourierQueryResponse } from './__generated__/ShowCourierQuery.graphql';
import Groups from './Groups';

type Props = PageProps & {
  data: $NonMaybeType<$ElementType<ShowCourierQueryResponse, 'data'>>,
};

const attributeRows = [
  {
    dataKey: 'firstName',
    label: 'Имя',
  },
  {
    dataKey: 'lastName',
    label: 'Фамилия',
  },
  {
    dataKey: 'middleName',
    label: 'Отчество',
  },
  {
    dataKey: 'workPhone',
    label: 'Рабочий телефон',
  },
  {
    dataKey: 'privateCar',
    label: 'Личная машина',
  },
  {
    dataKey: 'hasDriversLicense',
    label: 'Водительские права',
    render: renderIcon({ type: 'idcard' }),
  },
  {
    dataKey: 'telegramChatId',
    label: 'Telegram чат ид',
  },
];

const getExtraActions = (props: Props) => ({
  transactions: ['stock_employee_senior', 'stock_employee_god'].includes(props.context.viewer.role) && ({
    type: 'a',
    href: `/couriers/${props.data.rowId}/transactions`,
    icon: 'rocket',
    children: 'Доп выплаты',
  }),
});

const Show = (props: Props) => (
  <Page title={`Курьеры #${props.data.rowId}`} actions={{ ...getExtraActions(props), ...getShowActions(props) }} backUrl="/couriers">
    <AttributeTable data={props.data} rows={attributeRows} relayEnvironment={props.context.environment} />
    <h3 style={{ marginTop: 50 }}>Рабочие дни</h3>
    <Groups data={props.groups.nodes} />
  </Page>
);

Show.propTypes = {
  data: PropTypes.shape({
    rowId: PropTypes.number.isRequired,
  }).isRequired,
  context: PropTypes.object.isRequired,
  groups: PropTypes.object.isRequired,
};

export default Show;
