// @flow

import { compose, withProps } from 'recompact';
import destroyMutation from 'mutations/DestroyShopDepositReplenishmentMutation';
import { renderDate, renderPrice } from 'components/helpers';
import Filters from './Filters';

export default compose(
  withProps({
    title: 'Депозиты',
    destroyMutation,
    Header: Filters,
    columns: {
      userOwner: {
        title: 'Витрина',
      },
      userId: {
        title: 'ID пользователя',
      },
      createdAt: {
        title: 'Дата создания',
        render: renderDate,
      },
      value: {
        title: 'Сумма',
        render: renderPrice,
      },
    },
  })
);
