/**
 * @flow
 * @relayHash 74777d4b493dc8247a661237191c692b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditProductGroupQueryVariables = {|
  rowId: number
|};
export type EditProductGroupQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +warehouseId: number,
    +name: string,
  |},
  +allWarehouses: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +name: string,
    |}>
  |},
|};
export type EditProductGroupQuery = {|
  variables: EditProductGroupQueryVariables,
  response: EditProductGroupQueryResponse,
|};
*/


/*
query EditProductGroupQuery(
  $rowId: Int!
) {
  data: productGroupByRowId(rowId: $rowId) {
    id
    rowId
    warehouseId
    name
  }
  allWarehouses {
    nodes {
      rowId
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": "data",
  "name": "productGroupByRowId",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "rowId",
      "variableName": "rowId",
      "type": "Int!"
    }
  ],
  "concreteType": "ProductGroup",
  "plural": false,
  "selections": [
    v1,
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "warehouseId",
      "args": null,
      "storageKey": null
    },
    v3
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditProductGroupQuery",
  "id": null,
  "text": "query EditProductGroupQuery(\n  $rowId: Int!\n) {\n  data: productGroupByRowId(rowId: $rowId) {\n    id\n    rowId\n    warehouseId\n    name\n  }\n  allWarehouses {\n    nodes {\n      rowId\n      name\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditProductGroupQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v4,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allWarehouses",
        "storageKey": null,
        "args": null,
        "concreteType": "WarehousesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": true,
            "selections": [
              v2,
              v3
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditProductGroupQuery",
    "argumentDefinitions": v0,
    "selections": [
      v4,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allWarehouses",
        "storageKey": null,
        "args": null,
        "concreteType": "WarehousesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": true,
            "selections": [
              v2,
              v3,
              v1
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b0b1d3c41fe73674040c06fe5224544e';
module.exports = node;
