import { Form } from 'antd';
import { compose, withProps } from 'recompact';
import { remove, merge } from 'unchanged';
import { withRouter } from 'found';
import mapObject from 'fbjs/lib/mapObject';

export default textFields =>
  compose(
    withRouter,
    Form.create({
      mapPropsToFields: props => {
        if (!props.match.location.query.filter) {
          if (props.transformFilter) {
            return mapObject(props.transformFilter({}), value => Form.createFormField({ value }));
          }

          return {};
        }
        const filter = JSON.parse(props.match.location.query.filter);
        const tfilter = props.transformFilter ? props.transformFilter(filter) : filter;

        return mapObject(tfilter, value => Form.createFormField({ value }));
      },
      //   onFieldsChange: (props, changedFields) => {
      //     if (!textFields.includes(Object.keys(changedFields)[0])) {
      //       if (props.location.query.filter) {
      //         const newFilter = JSON.parse(props.location.query.filter);
      //         const newStatus = {};
      //         if (Object.keys(changedFields)[0] === 'status') {
      //           if (changedFields[Object.keys(changedFields)[0]].value.in.length === 0) {
      //             newFilter[Object.keys(changedFields)[0]] = null;
      //           } else {
      //             newFilter[Object.keys(changedFields)[0]] = changedFields[Object.keys(changedFields)[0]].value;
      //           }
      //           newStatus.filter = JSON.stringify(newFilter);
      //           newStatus[Object.keys(changedFields)[0]] = JSON.stringify(changedFields[Object.keys(changedFields)[0]]);
      //           props.router.push(merge('query', newStatus, props.location));
      //         } else {
      //           const value =
      //             changedFields[Object.keys(changedFields)[0]].value[
      //               Object.keys(changedFields[Object.keys(changedFields)[0]].value)[0]
      //             ];
      //           if (value === false) {
      //             delete newFilter[Object.keys(changedFields)[0]];
      //             newStatus.filter = JSON.stringify(newFilter);
      //           } else {
      //             newFilter[Object.keys(changedFields)[0]] = changedFields[Object.keys(changedFields)[0]].value;
      //             newStatus.filter = JSON.stringify(newFilter);
      //           }
      //           props.router.push(merge('query', newStatus, props.location));
      //         }
      //       } else {
      //         const newStatus = {};
      //         newStatus[Object.keys(changedFields)[0]] = changedFields[Object.keys(changedFields)[0]].value;
      //         props.router.push({
      //           ...props.location,
      //           query: {
      //             filter: JSON.stringify(newStatus),
      //           },
      //         });
      //       }
      //     }
      //   },
    }),
    withProps(({ router, match: { location }, form }) => ({
      handleSubmit: e => {
        e.preventDefault();

        form.validateFieldsAndScroll(async (err, values) => {
          if (err) {
            return;
          }

          router.push(
            merge(
              'query',
              {
                filter: JSON.stringify(values),
                page: 1,
              },
              location
            )
          );
        });
      },
      handleClear:
        location.query.filter &&
        (e => {
          e.preventDefault();
          form.resetFields();
          router.push(remove('query.filter', location));
        }),
    }))
  );
