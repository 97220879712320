exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-ShopOrderDocuments-Deficit-___Deficit__selected___2oXjb {\n  background: #e1cfff;\n}\n\n.src-pages-ShopOrderDocuments-Deficit-___Deficit__begin___1Z9LU td {\n  border-top: 2px solid #9e9a9a;\n}\n", ""]);

// exports
exports.locals = {
	"selected": "src-pages-ShopOrderDocuments-Deficit-___Deficit__selected___2oXjb",
	"begin": "src-pages-ShopOrderDocuments-Deficit-___Deficit__begin___1Z9LU"
};