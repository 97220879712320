import React from 'react';
import PropTypes from 'prop-types';
import { graphql, createFragmentContainer } from 'react-relay';
import { Table } from 'antd';
import { Link } from 'found';
import moment from 'moment';
import s from './Groups.css';

const Groups = ({ data }) => {
  const orders = {};
  const dataSource = [];

  data.forEach(group => {
    group.orders.nodes.forEach(order => {
      const day = moment(order.deliveryAt).format('DD MMM YYYY');

      if (!orders[day]) {
        orders[day] = [];
        dataSource.push({ day, deadlineAt: order.deliveryAt });
      }

      orders[day].push(order);
    });
  });

  dataSource.sort((a, b) => moment(b.deadlineAt).diff(a.deadlineAt, 'hours'));

  return (
    <Table
      dataSource={dataSource}
      rowKey="day"
      pagination={false}
      columns={[
        {
          key: 'day',
          dataIndex: 'day',
          title: 'День',
          width: 150,
        },
        {
          key: 'orders',
          dataIndex: 'day',
          title: 'Заказы',
          render: day =>
          orders[day].map((order, i) => {
            let title;
            let timeClass = '';

            if (order.deliveredAt) {
              const deliveredAt = moment(order.deliveredAt);
              const maxAt = moment(order.deliveryAt).add(order.deliveryMaxDelay.seconds, 'seconds').add(order.deliveryMaxDelay.minutes, 'minutes').add(order.deliveryMaxDelay.hours, 'hours');
              const startDiff = moment(deliveredAt).diff(order.deliveryAt, 'minutes');
              const endDiff = moment(maxAt).diff(deliveredAt, 'minutes');

              if (startDiff < 0) {
                title = `Заказ завершен раньше на ${-startDiff} минут`;
                timeClass = s.timeWarning;
              } else if (endDiff < 0) {
                title = `Заказ завершен позже на ${-endDiff} минут`;
                timeClass = s.timeError;
              } else {
                timeClass = s.timeSuccess;
              }
            }

            return [
              i !== 0 && ', ',
              <Link
                title={title}
                className={`${s.order} ${timeClass} ${s[order.status.toLowerCase()]}`}
                to={`/shop/order/documents/${order.rowId}`}
                key={order.rowId}
              >
                {order.label}
              </Link>,
            ];
          }),
        },
        {
          key: 'count',
          dataIndex: 'day',
          title: 'Всего',
          width: 150,
          render: day => orders[day].length,
        },
      ]}
    />
  );
};

Groups.propTypes = {
  data: PropTypes.array.isRequired,
};

export default createFragmentContainer(
  Groups,
  graphql`
    fragment GroupsCouriersShow on ShopOrderGroup @relay(plural: true) {
      deadlineAt

      orders: shopOrderDocumentsByGroupId(orderBy: GROUP_POSITION_ASC) {
        nodes {
          rowId
          deliveryAt
          deliveryMaxDelay { seconds, minutes, hours }
          deliveredAt
          status
          label
        }
      }
    }
  `
);
