/* global Raven */

import React from 'react';
import { fetchQuery, graphql } from 'relay-runtime';
import SigninForm from './SigninForm';

// undefined is a loading state
const defaultViewer = process.env.ENABLE_AUTH ? undefined : { role: 'stock_employee_god' };

const query = graphql`
  query AuthQuery {
    viewer {
      rowId
      role
      firstName
      lastName
    }
  }
`;

class Auth extends React.Component {
  state = {
    viewer: defaultViewer,
    loading: false,
  };

  componentDidMount() {
    this.loadViewer();
  }

  componentDidUpdate(props) {
    if (this.props.token !== props.token) {
      this.loadViewer();
    }
  }

  loadViewer() {
    if (!this.props.token) {
      if (this.state.viewer) {
        this.setState({ viewer: defaultViewer });
      }

      return;
    }

    this.setState({ loading: true });

    fetchQuery(this.props.matchContext.environment, query).then(data => {
      this.setState({ viewer: data.viewer || defaultViewer, loading: false });
      // Raven.setUserContext(data.viewer);
    });
  }

  render() {
    const { privateComponent, ...props } = this.props;

    if (this.state.viewer) {
      return React.createElement(privateComponent, {
        ...props,
        matchContext: {
          ...props.matchContext,
          viewer: this.state.viewer,
        },
      });
    }

    // loading
    if (this.state.loading) {
      return null;
    }

    return <SigninForm {...props} />;
  }
}

export default Auth;
