// @flow

import type { ProductStatus } from './__generated__/ProductsQuery.graphql';

const statuses: { [ProductStatus]: string } = {
  ACTIVE: 'Активный',
  ARCHIVE: 'Архивный',
  DEPRECATED: 'Скрытый',
};

export default statuses;
