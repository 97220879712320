import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'antd';
import { formatQuantity } from 'utils/format';
import moment from 'moment';
import './Print.css';

const emptyCategory = {
  rowId: null,
  name: 'Без категории',
};

const Print = ({ orders, groups, allProductCategories }) => (
  <div styleName="root">
    <div styleName="right">
      <Button type="primary" styleName="print" size="large" onClick={() => window.print()}>
        Распечтать
      </Button>
    </div>

    {orders.nodes.map((order, index) => (
      <div key={order.rowId} styleName="order">
        <div styleName="header">
          <h3 styleName="title">
            {order.label}
            <br />№{order.rowId}
            <br />
            {moment(order.deliveryAt)
              .tz('Europe/Moscow')
              .format('HH:mm')}
            {order.ecoPack && (
              <div>
                <span styleName="eco">БЕЗ ПЛАСТИКА</span>
              </div>
            )}
            {order.withoutOverweight && (
              <div>
                <span styleName="eco">Без перевеса</span>
              </div>
            )}
            {order.withGlassCoupon && (
              <div>
                <span styleName="eco">СТЕКЛОТАРА</span>
              </div>
            )}
          </h3>
          {order.asap && <Icon type="clock-circle" style={{ fontSize: 80 }} />}
          <div styleName="responsible">
            {order.yandexOrders.nodes.map(y => <div key={y.externalId}>ИД доставки:<br />{y.externalId}</div>)}
          </div>
          <img
            src={`${process.env.BARCODE_ENDPOINT}/code128/200/80/order${order.rowId}`}
            alt={order.rowId}
            width={200}
            height={80}
          />
        </div>
        {order.comment && <div styleName="comment">{order.comment}</div>}

        {[...allProductCategories.nodes, emptyCategory].map(category => {
          const items = order.items.nodes.filter(i => i.product.categoryId === category.rowId).map(i => {
            const sameWaresouse = pg => pg.group.warehouseId === i.warehouseId;
            const productGroup = i.product.productGroups.nodes.find(sameWaresouse);
            const group = productGroup ? groups[productGroup.groupId] : {};

            return { ...i, group };
          });

          if (items.length === 0) {
            return null;
          }

          items.sort((a, b) => a.group.position - b.group.position);

          return (
            <div key={category.rowId}>
              <h4 styleName="category-title">{category.name}</h4>
              <table styleName="table">
                <tbody>
                  {items.map(item => (
                    <tr key={item.id}>
                      <td width="50">{item.group.name || '-'}</td>
                      <td width="50" align="right">
                        {item.productId}
                      </td>
                      <td>
                        <div>{item.product.name}</div>
                        {item.comment && <div>{item.comment}</div>}
                      </td>
                      <td width="100" styleName="right">
                        {formatQuantity(item.orderedQuantity, item.product.unit)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          );
        })}

        {index < orders.nodes.length - 1 && <div styleName="footer" />}
      </div>
    ))}
  </div>
);

Print.propTypes = {
  orders: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  groups: PropTypes.object.isRequired,
  allProductCategories: PropTypes.object.isRequired,
};

export default Print;
