import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Form, Input, Button } from 'antd';
import PageForm from 'components/PageForm';

const WarehouseMovementDocumentForm = ({ title, form, handleSubmit, submitting }) => (
  <PageForm onSubmit={handleSubmit} title={title}>
    <Form.Item label="Дата обмена">{form.getFieldDecorator('eventedAt')(<DatePicker />)}</Form.Item>
    <Form.Item label="Комментарий">{form.getFieldDecorator('comment')(<Input.TextArea rows={4} />)}</Form.Item>
    <Form.Item>
      <Button disabled={submitting} type="primary" htmlType="submit">
        Сохранить
      </Button>
    </Form.Item>
  </PageForm>
);

WarehouseMovementDocumentForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default WarehouseMovementDocumentForm;
