import { graphql } from 'relay-runtime';

export default graphql`
  query NewProductGroupQuery {
    allWarehouses {
      nodes {
        rowId
        name
      }
    }
  }
`;
