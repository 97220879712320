import { getRoutes } from 'utils/crud';
import { preparePaginationVariables } from 'utils/pagination';

import Index from './index';
import New from './New';
import Edit from './Edit';
import Show from './Show';
import Transactions from './Transactions';
import TransactionsQuery from './Transactions/query';

import IndexQuery from './CouriersQuery';
import EditQuery from './Edit/EditCourierQuery';
import ShowQuery from './Show/ShowCourierQuery';

export default [
  ...getRoutes({
    baseUrl: '/couriers',
    Index,
    IndexQuery,
    Edit,
    EditQuery,
    Show,
    ShowQuery,
    New,
    prepareShowVariables: (vars, match) => ({
      ...preparePaginationVariables(vars, match),
      showGroupCondition: {
        courierId: parseInt(match.params.rowId, 10),
      },
      rowId: parseInt(match.params.rowId, 10),
    }),
  }),
  {
    path: '/couriers/:rowId/transactions',
    Component: Transactions,
    query: TransactionsQuery,
    prepareVariables: (vars, match) => ({
      ...vars,
      condition: {
        courierId: parseInt(vars.rowId, 10),
      },
      rowId: parseInt(vars.rowId, 10),
      addedItemId: match.location.query.addedItemId,
    }),
  },
];
