import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';
import withUpdateEnhance from 'hoc/withUpdateEnhance';
import mutation from 'mutations/UpdateShopOrderItemMutation';

const EditQuantity = ({ handleSubmit, form, submitting, data, field }) => (
  <Form onSubmit={handleSubmit}>
    {form.getFieldDecorator(field)(
      <Input size="small" />
    )}
    <Button hidden={submitting || form.getFieldValue(field) === data[field]} type="primary" htmlType="submit" size="small">
      Сохранить
    </Button>
  </Form>
);

EditQuantity.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  field: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
};

EditQuantity.defaultProps = {
  field: 'quantity',
};

const enhance = withUpdateEnhance({
  mutation,
});

export default enhance(EditQuantity);
