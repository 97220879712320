import { graphql } from 'relay-runtime';
import commitMutation from 'relay-commit-mutation-promise';

const mutation = graphql`
  mutation SyncScaleMutation($input: SyncScaleInput!) {
    syncScale(input: $input) {
      clientMutationId
    }
  }
`;

const commit = (environment, id, options) => {
  const config = {
    mutation,
    variables: {
      input: { id },
    },
    ...options,
  };

  return commitMutation(environment, config);
};

export default { commit };
