import { graphql } from 'relay-runtime';

export default graphql`
  query CouriersQuery($first: Int!, $offset: Int!, $orderBy: [CouriersOrderBy!], $filter: CourierFilter) {
    rows: allCouriers(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
      totalCount

      nodes {
        id
        rowId
        firstName
        lastName
        middleName
        workPhone
        hasDriversLicense
        telegramToken
        telegramChatId
        privateCar
      }
    }
  }
`;
