import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateCourierMutation($input: CreateCourierInput!) {
    createCourier(input: $input) {
      courier {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createCourier', 'courier');
