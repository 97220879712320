// @flow

import { compose, withProps } from 'recompact';
import destroyMutation from 'mutations/DestroyWarehouseMovementDocumentMutation';
import { renderDate } from 'components/helpers';

export default compose(
  withProps({
    title: 'Перемещения между складами',
    destroyMutation,
    columns: {
      eventedAt: {
        title: 'Дата',
        render: renderDate,
        width: 200,
      },
      comment: {
        title: 'Коментарий',
      },
    },
  })
);
