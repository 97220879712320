// @flow

import React from 'react';
import { compose, withProps } from 'recompact';
import destroyMutation from 'mutations/DestroyCourierMutation';
import { renderIcon } from 'components/helpers';
import SendMessage from './SendMessage';
import Filters from './Filters';

export default compose(
  withProps(props => ({
    title: 'Курьеры',
    destroyMutation,
    Header: Filters,
    columns: {
      lastName: {
        title: 'Фамилия',
      },
      firstName: {
        title: 'Имя',
      },
      workPhone: {
        title: 'Рабочий телефон',
      },
      hasDriversLicense: {
        title: 'Вод. права',
        render: renderIcon({ type: 'idcard' }),
      },
      privateCar: {
        title: 'Личная машина',
      },
      telegramToken: {
        title: 'Telegram',
        width: 500,
        render: (token, row) => {
          if (row.telegramChatId) {
            return <SendMessage courierId={row.rowId} environment={props.context.environment} />;
          }

          if (token) {
            return `http://t.me/fresh_alliance_stock_bot?start=${token}`;
          }

          return '-';
        },
      },
    },
  }))
);
