import { getRoutes } from 'utils/crud';

import Index from './index';
import New from './New';
import Edit from './Edit';

import IndexQuery from './ShopDepositReplenishmentsQuery';
import NewQuery from './New/NewShopDepositReplenishmentQuery';
import EditQuery from './Edit/EditShopDepositReplenishmentQuery';

export default getRoutes({
  baseUrl: '/shop/deposit/replenishments',
  Index,
  IndexQuery,
  Edit,
  EditQuery,
  New,
  NewQuery,
});
