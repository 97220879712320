/**
 * @flow
 * @relayHash dc9dfb3ab83617859af1f948a314db97
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteEmployeeInput = {
  clientMutationId?: ?string,
  id: string,
};
export type DestroyEmployeeMutationVariables = {|
  input: DeleteEmployeeInput
|};
export type DestroyEmployeeMutationResponse = {|
  +deleteEmployee: ?{|
    +deletedEmployeeId: ?string
  |}
|};
export type DestroyEmployeeMutation = {|
  variables: DestroyEmployeeMutationVariables,
  response: DestroyEmployeeMutationResponse,
|};
*/


/*
mutation DestroyEmployeeMutation(
  $input: DeleteEmployeeInput!
) {
  deleteEmployee(input: $input) {
    deletedEmployeeId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteEmployeeInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteEmployee",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteEmployeeInput!"
      }
    ],
    "concreteType": "DeleteEmployeePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedEmployeeId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DestroyEmployeeMutation",
  "id": null,
  "text": "mutation DestroyEmployeeMutation(\n  $input: DeleteEmployeeInput!\n) {\n  deleteEmployee(input: $input) {\n    deletedEmployeeId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DestroyEmployeeMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DestroyEmployeeMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e6f759c516a8a4eb1e9db2cbea8fd69c';
module.exports = node;
