/**
 * @flow
 * @relayHash b5619247aa13a4bbdaf08fbf451f8d38
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductExternalStatus = "ALWAYS_AVAILABLE" | "AVAILABLE" | "BY_REQUEST" | "FROM_PARENT" | "HIDDEN" | "%future added value";
export type ProductStatus = "ACTIVE" | "ARCHIVE" | "DEPRECATED" | "%future added value";
export type ProductUnit = "KG" | "PCS" | "%future added value";
export type ProductFilter = {
  rowId?: ?IntFilter,
  parentId?: ?IntFilter,
  name?: ?StringFilter,
  barcodes?: ?StringListFilter,
  unit?: ?ProductUnitFilter,
  status?: ?ProductStatusFilter,
  pcsWeight?: ?BigFloatFilter,
  shelfLife?: ?IntervalFilter,
  meta?: ?JSONFilter,
  packingTolerance?: ?BigFloatFilter,
  requiresPackaging?: ?BooleanFilter,
  ownPackaging?: ?BooleanFilter,
  tags?: ?StringListFilter,
  categoryId?: ?IntFilter,
  ownProduction?: ?BooleanFilter,
  externalStatus?: ?ProductExternalStatusFilter,
  availableQuantityThreshold?: ?BigFloatFilter,
  country?: ?StringFilter,
  syncId?: ?UUIDFilter,
  vat?: ?IntFilter,
  shopOrderPrices?: ?BigFloatListFilter,
  and?: ?$ReadOnlyArray<ProductFilter>,
  or?: ?$ReadOnlyArray<ProductFilter>,
  not?: ?ProductFilter,
};
export type IntFilter = {
  isNull?: ?boolean,
  equalTo?: ?number,
  notEqualTo?: ?number,
  distinctFrom?: ?number,
  notDistinctFrom?: ?number,
  in?: ?$ReadOnlyArray<number>,
  notIn?: ?$ReadOnlyArray<number>,
  lessThan?: ?number,
  lessThanOrEqualTo?: ?number,
  greaterThan?: ?number,
  greaterThanOrEqualTo?: ?number,
};
export type StringFilter = {
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  equalToInsensitive?: ?string,
  notEqualToInsensitive?: ?string,
  distinctFromInsensitive?: ?string,
  notDistinctFromInsensitive?: ?string,
  inInsensitive?: ?$ReadOnlyArray<string>,
  notInInsensitive?: ?$ReadOnlyArray<string>,
  lessThanInsensitive?: ?string,
  lessThanOrEqualToInsensitive?: ?string,
  greaterThanInsensitive?: ?string,
  greaterThanOrEqualToInsensitive?: ?string,
};
export type StringListFilter = {
  isNull?: ?boolean,
  equalTo?: ?$ReadOnlyArray<?string>,
  notEqualTo?: ?$ReadOnlyArray<?string>,
  distinctFrom?: ?$ReadOnlyArray<?string>,
  notDistinctFrom?: ?$ReadOnlyArray<?string>,
  lessThan?: ?$ReadOnlyArray<?string>,
  lessThanOrEqualTo?: ?$ReadOnlyArray<?string>,
  greaterThan?: ?$ReadOnlyArray<?string>,
  greaterThanOrEqualTo?: ?$ReadOnlyArray<?string>,
  contains?: ?$ReadOnlyArray<?string>,
  containedBy?: ?$ReadOnlyArray<?string>,
  overlaps?: ?$ReadOnlyArray<?string>,
  anyEqualTo?: ?string,
  anyNotEqualTo?: ?string,
  anyLessThan?: ?string,
  anyLessThanOrEqualTo?: ?string,
  anyGreaterThan?: ?string,
  anyGreaterThanOrEqualTo?: ?string,
};
export type ProductUnitFilter = {
  isNull?: ?boolean,
  equalTo?: ?ProductUnit,
  notEqualTo?: ?ProductUnit,
  distinctFrom?: ?ProductUnit,
  notDistinctFrom?: ?ProductUnit,
  in?: ?$ReadOnlyArray<ProductUnit>,
  notIn?: ?$ReadOnlyArray<ProductUnit>,
  lessThan?: ?ProductUnit,
  lessThanOrEqualTo?: ?ProductUnit,
  greaterThan?: ?ProductUnit,
  greaterThanOrEqualTo?: ?ProductUnit,
};
export type ProductStatusFilter = {
  isNull?: ?boolean,
  equalTo?: ?ProductStatus,
  notEqualTo?: ?ProductStatus,
  distinctFrom?: ?ProductStatus,
  notDistinctFrom?: ?ProductStatus,
  in?: ?$ReadOnlyArray<ProductStatus>,
  notIn?: ?$ReadOnlyArray<ProductStatus>,
  lessThan?: ?ProductStatus,
  lessThanOrEqualTo?: ?ProductStatus,
  greaterThan?: ?ProductStatus,
  greaterThanOrEqualTo?: ?ProductStatus,
};
export type BigFloatFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type IntervalFilter = {
  isNull?: ?boolean,
  equalTo?: ?IntervalInput,
  notEqualTo?: ?IntervalInput,
  distinctFrom?: ?IntervalInput,
  notDistinctFrom?: ?IntervalInput,
  in?: ?$ReadOnlyArray<IntervalInput>,
  notIn?: ?$ReadOnlyArray<IntervalInput>,
  lessThan?: ?IntervalInput,
  lessThanOrEqualTo?: ?IntervalInput,
  greaterThan?: ?IntervalInput,
  greaterThanOrEqualTo?: ?IntervalInput,
};
export type IntervalInput = {
  seconds?: ?number,
  minutes?: ?number,
  hours?: ?number,
  days?: ?number,
  months?: ?number,
  years?: ?number,
};
export type JSONFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
  contains?: ?any,
  containsKey?: ?string,
  containsAllKeys?: ?$ReadOnlyArray<string>,
  containsAnyKeys?: ?$ReadOnlyArray<string>,
  containedBy?: ?any,
};
export type BooleanFilter = {
  isNull?: ?boolean,
  equalTo?: ?boolean,
  notEqualTo?: ?boolean,
  distinctFrom?: ?boolean,
  notDistinctFrom?: ?boolean,
  in?: ?$ReadOnlyArray<boolean>,
  notIn?: ?$ReadOnlyArray<boolean>,
  lessThan?: ?boolean,
  lessThanOrEqualTo?: ?boolean,
  greaterThan?: ?boolean,
  greaterThanOrEqualTo?: ?boolean,
};
export type ProductExternalStatusFilter = {
  isNull?: ?boolean,
  equalTo?: ?ProductExternalStatus,
  notEqualTo?: ?ProductExternalStatus,
  distinctFrom?: ?ProductExternalStatus,
  notDistinctFrom?: ?ProductExternalStatus,
  in?: ?$ReadOnlyArray<ProductExternalStatus>,
  notIn?: ?$ReadOnlyArray<ProductExternalStatus>,
  lessThan?: ?ProductExternalStatus,
  lessThanOrEqualTo?: ?ProductExternalStatus,
  greaterThan?: ?ProductExternalStatus,
  greaterThanOrEqualTo?: ?ProductExternalStatus,
};
export type UUIDFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type BigFloatListFilter = {
  isNull?: ?boolean,
  equalTo?: ?$ReadOnlyArray<?any>,
  notEqualTo?: ?$ReadOnlyArray<?any>,
  distinctFrom?: ?$ReadOnlyArray<?any>,
  notDistinctFrom?: ?$ReadOnlyArray<?any>,
  lessThan?: ?$ReadOnlyArray<?any>,
  lessThanOrEqualTo?: ?$ReadOnlyArray<?any>,
  greaterThan?: ?$ReadOnlyArray<?any>,
  greaterThanOrEqualTo?: ?$ReadOnlyArray<?any>,
  contains?: ?$ReadOnlyArray<?any>,
  containedBy?: ?$ReadOnlyArray<?any>,
  overlaps?: ?$ReadOnlyArray<?any>,
  anyEqualTo?: ?any,
  anyNotEqualTo?: ?any,
  anyLessThan?: ?any,
  anyLessThanOrEqualTo?: ?any,
  anyGreaterThan?: ?any,
  anyGreaterThanOrEqualTo?: ?any,
};
export type ProductSelectQueryVariables = {|
  withTerm: boolean,
  filter?: ?ProductFilter,
|};
export type ProductSelectQueryResponse = {|
  +allProducts?: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +name: string,
    |}>
  |}
|};
export type ProductSelectQuery = {|
  variables: ProductSelectQueryVariables,
  response: ProductSelectQueryResponse,
|};
*/


/*
query ProductSelectQuery(
  $withTerm: Boolean!
  $filter: ProductFilter
) {
  allProducts(filter: $filter) @include(if: $withTerm) {
    nodes {
      rowId
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "withTerm",
    "type": "Boolean!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "ProductFilter",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter",
    "type": "ProductFilter"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ProductSelectQuery",
  "id": null,
  "text": "query ProductSelectQuery(\n  $withTerm: Boolean!\n  $filter: ProductFilter\n) {\n  allProducts(filter: $filter) @include(if: $withTerm) {\n    nodes {\n      rowId\n      name\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ProductSelectQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "withTerm",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "allProducts",
            "storageKey": null,
            "args": v1,
            "concreteType": "ProductsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "Product",
                "plural": true,
                "selections": [
                  v2,
                  v3
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProductSelectQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "Condition",
        "passingValue": true,
        "condition": "withTerm",
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "allProducts",
            "storageKey": null,
            "args": v1,
            "concreteType": "ProductsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "Product",
                "plural": true,
                "selections": [
                  v2,
                  v3,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '40cb79e4372187324f59e2ab1b1d7480';
module.exports = node;
