import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateProductCategoryMutation($input: UpdateProductCategoryInput!) {
    updateProductCategory(input: $input) {
      productCategory {
        id
        rowId
        position
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateProductCategory', 'productCategory');
