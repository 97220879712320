// @flow

import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import pick from 'just-pick';
import type { IEnvironment } from 'relay-runtime';
import DocumentLoader from './DocumentLoader';
import type { State, NewState } from './Types';

type Props = {
  context: {
    environment: IEnvironment,
  },
  router: {},
};

const storageKey = 'packingmonitor';
const queryWhitekeys = ['orderKey', 'orderDir', 'statuses', 'day'];

class PackingMonitor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      orderKey: 'deliveryAt',
      orderDir: 'ascend',
      statuses: ['PENDING'],
      ...JSON.parse(localStorage.getItem(storageKey) || '{}'),
      ...pick(queryString.parse(location.search), queryWhitekeys),
    };
  }

  save = () => {
    const query = queryString.stringify(pick(this.state, queryWhitekeys), {
      arrayFormat: 'bracket',
    });

    window.history.pushState({}, 'Сборка', `${window.location.pathname}?${query}`);
  };

  updateState = (newState: NewState): void => {
    this.setState(newState, this.save);
  };

  render() {
    return (
      <div style={{ padding: 20 }}>
        <DocumentLoader
          key="loader"
          router={this.props.router}
          {...this.state}
          updateState={this.updateState}
          environment={this.props.context.environment}
        />
      </div>
    );
  }
}

PackingMonitor.propTypes = {
  context: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default PackingMonitor;
