import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroyScaleProductMutation($input: DeleteScaleProductInput!) {
    deleteScaleProduct(input: $input) {
      deletedScaleProductId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteScaleProduct', 'deletedScaleProductId');
