exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__root___2BOP3 {\n  font-size: 18px;\n}\n\n.src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__header___228Fd {\n  display: flex;\n}\n\n.src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__number___uaozd {\n  flex: 1;\n  font-size: 40px;\n}\n\n.src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__button___3gHBs {\n  margin: 20px;\n}\n\n.src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__footer___3-A4l {\n  page-break-before: always;\n}\n\n.src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__label___3KCuV {\n  border: 3px solid #666;\n  color: #666;\n  border-radius: 4px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 50px;\n  padding: 0 10px;\n  height: 70px;\n  position: relative;\n}\n\n.src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__current-time___1C_4R {\n  position: absolute;\n  bottom: -30px;\n  left: 0px;\n  font-size: 14px;\n}\n\n.src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__barcode___1vaub {\n  position: absolute;\n  bottom: -120px;\n  left: 0px;\n}\n\n.src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__position___3E_UO {\n  border: 3px solid #666;\n  color: #666;\n  border-radius: 4px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 70px;\n  padding: 0 30px;\n  margin-right: 20px;\n}\n\n@media print {\n  .src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__button___3gHBs {\n    display: none;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__root___2BOP3",
	"header": "src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__header___228Fd",
	"number": "src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__number___uaozd",
	"button": "src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__button___3gHBs",
	"footer": "src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__footer___3-A4l",
	"label": "src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__label___3KCuV",
	"current-time": "src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__current-time___1C_4R",
	"barcode": "src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__barcode___1vaub",
	"position": "src-pages-ShopOrderDocuments-CourierPrint-___CourierPrint__position___3E_UO"
};