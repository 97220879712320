import React from 'react';
import { Polyline } from 'react-yandex-maps';
import { coords, colors, strokeWidth } from './DeliveryBorders.data';

const keys = Object.keys(coords);

export default () =>
  keys.map(color => (
    <Polyline
      geometry={{ coordinates: coords[color] }}
      options={{
        strokeWidth,
        ...colors[color]
      }}
      key={color}
    />
  ));
