/**
 * @flow
 * @relayHash c0f6b33024ac3032fb67c0f07858ad8f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductUnit = "KG" | "PCS" | "%future added value";
export type ControlProductsQueryVariables = {||};
export type ControlProductsQueryResponse = {|
  +allProducts: ?{|
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +categoryId: ?number,
      +unit: ProductUnit,
      +name: string,
      +shopOrderPrices: ?$ReadOnlyArray<?any>,
    |}>
  |}
|};
export type ControlProductsQuery = {|
  variables: ControlProductsQueryVariables,
  response: ControlProductsQueryResponse,
|};
*/


/*
query ControlProductsQuery {
  allProducts(orderBy: [CATEGORY_ID_ASC]) {
    nodes {
      id
      rowId
      categoryId
      unit
      name
      shopOrderPrices
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "allProducts",
    "storageKey": "allProducts(orderBy:[\"CATEGORY_ID_ASC\"])",
    "args": [
      {
        "kind": "Literal",
        "name": "orderBy",
        "value": [
          "CATEGORY_ID_ASC"
        ],
        "type": "[ProductsOrderBy!]"
      }
    ],
    "concreteType": "ProductsConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": null,
        "concreteType": "Product",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rowId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "categoryId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "unit",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "shopOrderPrices",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ControlProductsQuery",
  "id": null,
  "text": "query ControlProductsQuery {\n  allProducts(orderBy: [CATEGORY_ID_ASC]) {\n    nodes {\n      id\n      rowId\n      categoryId\n      unit\n      name\n      shopOrderPrices\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ControlProductsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "ControlProductsQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ff383bbf523ddeefecaca64590a7229a';
module.exports = node;
