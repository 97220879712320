import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateWriteOffDocumentMutation($input: CreateWriteOffDocumentInput!) {
    createWriteOffDocument(input: $input) {
      writeOffDocument {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createWriteOffDocument', 'writeOffDocument');
