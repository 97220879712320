import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateSupplyContractorMutation($input: CreateSupplyContractorInput!) {
    createSupplyContractor(input: $input) {
      supplyContractor {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createSupplyContractor', 'supplyContractor');
