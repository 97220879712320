import React from 'react';
import PropTypes from 'prop-types';
import DataTable from 'components/DataTable';
import ProductSelect from 'containers/ProductSelect';
import CollectionSelect from 'components/CollectionSelect';
import InlineInput from 'components/InlineInput';
import { Row, Col, Button } from 'antd';
import { Link } from 'found';

import updateMutation from 'mutations/UpdateBalanceItemMutation';
import destroyMutation from 'mutations/DestroyBalanceItemMutation';

const Items = props => (
  <div>
    <Row gutter={24} style={{ marginTop: 50 }}>
      <Col span={10}>
        <h3>Позиции</h3>
      </Col>
      <Col span={14} style={{ textAlign: 'right' }}>
        <Link to={{ pathname: '/products/print', query: { ids: props.rows.nodes.map(i => i && i.productId) } }}>
          <Button size="small">Печать</Button>
        </Link>
      </Col>
    </Row>
    <DataTable
      {...props}
      rowKey="id"
      onlyTable
      skipReloadAfterDestroy
      destroyMutation={destroyMutation}
      actions={['destroy']}
      columns={{
        rowId: {
          title: '№',
          width: 60,
          render: (_, row, index) => {
            if (props.location.query.sortColumn === 'rowId' && props.location.query.sortOrder === 'descend') {
              return `${props.rows.nodes.length - index}.`;
            }

            return `${index + 1}.`;
          },
        },
        warehouseId: {
          title: 'Склад',
          width: 200,
          render: (value, row) => (
            <CollectionSelect
              style={{ width: '100%' }}
              items={props.allWarehouses.nodes.filter(Boolean)}
              value={value}
              onChange={v => updateMutation.commitValue(props.context.environment, row.id, 'warehouseId', v)}
            />
          ),
        },
        productId: {
          title: 'Продукт',
          render: (value, row) => (
            <ProductSelect
              value={value}
              data={row.product}
              environment={props.context.environment}
              onChange={v => updateMutation.commitValue(props.context.environment, row.id, 'productId', v)}
            />
          ),
        },
        quantity: {
          title: 'Количество',
          width: 120,
          render: (value, row) => (
            <InlineInput
              transfarmValue={v => v.replace(',', '.')}
              isEqual={(a, b) => parseFloat(a) === parseFloat(b)}
              onUpdate={v => updateMutation.commitValue(props.context.environment, row.id, 'quantity', v)}
              value={value}
            />
          ),
        },
      }}
    />
  </div>
);

Items.propTypes = {
  context: PropTypes.object.isRequired,
  allWarehouses: PropTypes.object.isRequired,
  rows: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Items;
