import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'found';
import { renderQuantity } from 'components/helpers';
import { set } from 'unchanged';
import './Header.css';

const Header = ({ allWarehouses, product, match: { location } }) => (
  <div>
    <h3>Остатки</h3>
    <div styleName="warehouses">
      {product.quantities.nodes.map(n => {
        const warehouse = allWarehouses.nodes.find(w => w.rowId === n.warehouseId);

        if (!warehouse) {
          return null;
        }

        const isActive = location.query.warehouseId && parseInt(location.query.warehouseId, 10) === n.warehouseId;
        const styleName = isActive ? 'active-warehouse' : 'warehouse';
        const to = set('query.warehouseId', isActive ? undefined : n.warehouseId, location);

        return (
          <Link key={n.warehouseId} styleName={styleName} to={to}>
            <div styleName="warehouse-title">{warehouse.name}</div>
            <div styleName="warehouse-value">{renderQuantity(n.value, product)}</div>
          </Link>
        );
      })}
    </div>
  </div>
);

Header.propTypes = {
  match: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  allWarehouses: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
};

export default withRouter(Header);
