import React from 'react';

export default Component =>
  class WithRef extends React.Component {
    saveRef = ref => {
      this.savedRef = ref;
    };

    applyToRef = fn => {
      fn(this.savedRef);
    };

    render() {
      return <Component {...this.props} saveRef={this.saveRef} applyToRef={this.applyToRef} />;
    }
  };
