import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { Button } from 'antd';
import LinkButton from './LinkButton';
import ProgressButton from './ProgressButton';
import ConfirmButton from './ConfirmButton';
import './Page.css';

const A = ({ href, ...props }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    <Button {...props} />
  </a>
);

A.propTypes = {
  href: PropTypes.string.isRequired,
};

const actionComponents = {
  a: A,
  link: LinkButton,
  button: ProgressButton,
  confirmButton: ConfirmButton,
};

const Page = ({ title, children, actions, backUrl, className }) => (
  <div styleName="root" className={className}>
    <div styleName="header">
      <DocumentTitle title={`Склад – ${title}`} />
      <div styleName="title">
        {title}
        {backUrl && <LinkButton to={backUrl} icon="rollback" style={{ marginLeft: 20 }} />}
      </div>
      <div styleName="actions">
        {Object.keys(actions).map(key => {
          if (!actions[key]) {
            return null;
          }

          const { type, componentType, ...props } = actions[key];
          const Component = actionComponents[type] || type;

          return (
            <div key={key} styleName="action">
              <Component {...props} type={componentType} />
            </div>
          );
        })}
      </div>
    </div>
    <div styleName="content">{children}</div>
  </div>
);

Page.propTypes = {
  title: PropTypes.string.isRequired,
  backUrl: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.any,
  actions: PropTypes.object,
  className: PropTypes.string,
};

Page.defaultProps = {
  backUrl: null,
  className: null,
  actions: Object.create(null),
};

export default Page;
