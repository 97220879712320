// @flow

import { compose, withProps } from 'recompact';
import destroyMutation from 'mutations/DestroyScaleMutation';

export default compose(
  withProps({
    title: 'Весы',
    destroyMutation,
    columns: {
      name: {
        title: 'Название',
      },
      addr: {
        title: 'Адрес',
        width: 300,
      },
      syncedAt: {
        title: 'Последняя синхронизация',
        width: 300,
      },
      syncError: {
        title: 'Ошибка синхронизации',
        width: 300,
      },
      comment: {
        title: 'Коментарий',
      },
    },
  })
);
