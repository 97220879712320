/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MkbPaymentAction_order$ref: FragmentReference;
export type MkbPaymentAction_order = {|
  +totalSum: ?any,
  +owner: ?string,
  +confirmedAmount: any,
  +$refType: MkbPaymentAction_order$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MkbPaymentAction_order",
  "type": "ShopOrderDocument",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalSum",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "owner",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "confirmedAmount",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '404e38ba4e56e3fbd629e4b203829ab4';
module.exports = node;
