/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type ProductUnit = "KG" | "PCS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type DeficitPageShopOrderDocument_rows$ref: FragmentReference;
export type DeficitPageShopOrderDocument_rows = {|
  +nodes: $ReadOnlyArray<{|
    +id: string,
    +orderedQuantity: any,
    +order: ?{|
      +rowId: number,
      +deliveryAt: ?any,
      +owner: ?string,
      +label: ?string,
      +sourceUrl: ?string,
    |},
    +product: ?{|
      +name: string,
      +unit: ProductUnit,
    |},
  |}>,
  +$refType: DeficitPageShopOrderDocument_rows$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "DeficitPageShopOrderDocument_rows",
  "type": "ShopOrderItemsConnection",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nodes",
      "storageKey": null,
      "args": null,
      "concreteType": "ShopOrderItem",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "orderedQuantity",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": "order",
          "name": "shopOrderDocumentByDocumentId",
          "storageKey": null,
          "args": null,
          "concreteType": "ShopOrderDocument",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "rowId",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "deliveryAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "owner",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "label",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "sourceUrl",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "LinkedField",
          "alias": "product",
          "name": "productByProductId",
          "storageKey": null,
          "args": null,
          "concreteType": "Product",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "name",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "unit",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '8c52e9be32f29689a847da98bb0f40a2';
module.exports = node;
