/**
 * @flow
 * @relayHash a247962be385daced59a5da6c5e8b303
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateSupplyItemInput = {
  clientMutationId?: ?string,
  supplyItem: SupplyItemInput,
};
export type SupplyItemInput = {
  rowId?: ?number,
  documentId: number,
  productId: number,
  warehouseId: number,
  willBeSelledAt?: ?any,
  quantity: any,
  price: any,
};
export type CreateSupplyItemMutationVariables = {|
  input: CreateSupplyItemInput
|};
export type CreateSupplyItemMutationResponse = {|
  +createSupplyItem: ?{|
    +supplyItem: ?{|
      +rowId: number,
      +warehouseId: number,
    |}
  |}
|};
export type CreateSupplyItemMutation = {|
  variables: CreateSupplyItemMutationVariables,
  response: CreateSupplyItemMutationResponse,
|};
*/


/*
mutation CreateSupplyItemMutation(
  $input: CreateSupplyItemInput!
) {
  createSupplyItem(input: $input) {
    supplyItem {
      rowId
      warehouseId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateSupplyItemInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateSupplyItemInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "warehouseId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateSupplyItemMutation",
  "id": null,
  "text": "mutation CreateSupplyItemMutation(\n  $input: CreateSupplyItemInput!\n) {\n  createSupplyItem(input: $input) {\n    supplyItem {\n      rowId\n      warehouseId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateSupplyItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createSupplyItem",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateSupplyItemPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyItem",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyItem",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateSupplyItemMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createSupplyItem",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateSupplyItemPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyItem",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyItem",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ab0c493b0be346c4627eaabfa298b25e';
module.exports = node;
