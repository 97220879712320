/**
 * @flow
 * @relayHash 394f9ded8eb60a444baa9cfdb89ac3f7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateWriteOffDocumentInput = {
  clientMutationId?: ?string,
  writeOffDocument: WriteOffDocumentInput,
};
export type WriteOffDocumentInput = {
  rowId?: ?number,
  categoryId: number,
  eventedAt?: ?any,
  comment?: ?string,
  totalCost?: ?any,
};
export type CreateWriteOffDocumentMutationVariables = {|
  input: CreateWriteOffDocumentInput
|};
export type CreateWriteOffDocumentMutationResponse = {|
  +createWriteOffDocument: ?{|
    +writeOffDocument: ?{|
      +rowId: number
    |}
  |}
|};
export type CreateWriteOffDocumentMutation = {|
  variables: CreateWriteOffDocumentMutationVariables,
  response: CreateWriteOffDocumentMutationResponse,
|};
*/


/*
mutation CreateWriteOffDocumentMutation(
  $input: CreateWriteOffDocumentInput!
) {
  createWriteOffDocument(input: $input) {
    writeOffDocument {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateWriteOffDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateWriteOffDocumentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateWriteOffDocumentMutation",
  "id": null,
  "text": "mutation CreateWriteOffDocumentMutation(\n  $input: CreateWriteOffDocumentInput!\n) {\n  createWriteOffDocument(input: $input) {\n    writeOffDocument {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateWriteOffDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createWriteOffDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateWriteOffDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "writeOffDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "WriteOffDocument",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateWriteOffDocumentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createWriteOffDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateWriteOffDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "writeOffDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "WriteOffDocument",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c16eff8de01bf5d55e6401021c963a35';
module.exports = node;
