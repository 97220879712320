import React from 'react';
import { Button } from 'antd';
import { compose, omitProps, withProps } from 'recompact';
import InlineForm from './InlineForm';

const enhance = compose(
  withProps(props => ({
    buttons: (
      <Button.Group>
        {props.onClear && (
          <Button icon="rollback" onClick={props.onClear}>
            Очистить
          </Button>
        )}
        <Button type="primary" htmlType="submit" icon="search">
          Фильтровать
        </Button>
      </Button.Group>
    ),
  })),
  omitProps('onClear')
);

export default enhance(InlineForm);
