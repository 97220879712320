import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { Button, Input } from 'antd';
import DeliveryBorders from 'components/DeliveryBorders';
import qs from 'query-string';

const defaultPoint = { x: 55.76, y: 37.64 };
const toCoords = ({ x, y }) => [x, y];

const GeoPointInput = ({ value, onChange, size, address, prevPoint, ...props }) => {
  const [addressPoint, setAddressPoint] = useState(null);
  const [ymaps, setYmaps] = useState(null);
  const [geocodeResults, setGeocodeResults] = useState(null);

  const geocode = () => {
    if (!ymaps) {
      return;
    }

    const q = qs.stringify({
      address,
      key: 'AIzaSyBA0Yr9qsyt5mAVvOcTxVXo33TUUiZq4fU',
      language: 'ru',
    });

    fetch(`https://maps.googleapis.com/maps/api/geocode/json?${q}`)
      .then(r => r.json())
      .then(data => {
        setGeocodeResults(data.results);
        // if (data.results.length > 0) {
        //   const { location } = data.results[0].geometry;
        //   setAddressPoint([location.lat, location.lng]);
        // }
      });
    // ymaps.geocode(address).then(res => {
    //   const first = res.geoObjects.get(0);
    //   setAddressPoint(first.geometry.getCoordinates());
    // });
  };

  const toAddress = () => {
    onChange({ x: addressPoint[0], y: addressPoint[1] });
  };

  const toPrev = () => {
    onChange(prevPoint);
  };

  const handleRawData = e => {
    const m = e.target.value.match(/([\d.]+).*?([\d.]+)/);

    if (m && m.length > 2) {
      onChange({ x: m[1], y: m[2] });
    }
  };

  return (
    <React.Fragment>
      <div>
        {address && (
          <Button onClick={geocode} size="small">
            Автопоиск
          </Button>
        )}
        {addressPoint && (
          <Button onClick={toAddress} size="small">
            Переместить по адресу
          </Button>
        )}{' '}
        {prevPoint && (
          <Button onClick={toPrev} size="small">
            Переместить на прошлый
          </Button>
        )}
      </div>
      {geocodeResults && (
        <div>
          <string>Результаты поиска</string>
          {geocodeResults.length === 0 && <span>Ничего не найдено</span>}
          {geocodeResults.map(r => (
            <div>
              {r.formatted_address}{' '}
              <Button
                size="small"
                onClick={() => {
                  setAddressPoint([r.geometry.location.lat, r.geometry.location.lng]);
                }}
              >
                Показать
              </Button>
              <Button
                size="small"
                onClick={() => {
                  onChange({ x: r.geometry.location.lat, y: r.geometry.location.lng });
                }}
              >
                Установить
              </Button>
            </div>
          ))}
        </div>
      )}
      <div>Зеленая точка - найдена по адресу. Синяя точка - текущая. Красная - прошлый заказ.</div>
      <YMaps onApiAvaliable={setYmaps}>
        <Map state={{ center: toCoords(value || defaultPoint), zoom: 10 }} width="100%" height={`${size}px`} {...props}>
          <DeliveryBorders />
          {addressPoint && (
            <Placemark
              geometry={{
                coordinates: addressPoint,
              }}
              options={{
                preset: 'islands#greenStretchyIcon',
                draggable: true,
              }}
              properties={{
                balloonContentBody: address,
              }}
              onDragEnd={e => {
                const coords = e.originalEvent.target.geometry.getCoordinates();
                onChange({ x: coords[0], y: coords[1] });
              }}
            />
          )}
          {prevPoint && (
            <Placemark
              geometry={{
                coordinates: toCoords(prevPoint),
              }}
              options={{
                preset: 'islands#redStretchyIcon',
                draggable: true,
              }}
              properties={{
                balloonContentBody: address,
              }}
              onDragEnd={e => {
                const coords = e.originalEvent.target.geometry.getCoordinates();
                onChange({ x: coords[0], y: coords[1] });
              }}
            />
          )}
          <Placemark
            geometry={{
              coordinates: toCoords(value || defaultPoint),
            }}
            options={{
              preset: 'islands#blueStretchyIcon',
              draggable: true,
            }}
            onDragEnd={e => {
              const coords = e.originalEvent.target.geometry.getCoordinates();
              onChange({ x: coords[0], y: coords[1] });
            }}
          />
        </Map>
      </YMaps>
      <div style={{ lineHeight: '15px', paddingTop: 10 }}>Координаты текстом</div>
      <Input size="small" onChange={handleRawData} />
    </React.Fragment>
  );
};

GeoPointInput.propTypes = {
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  address: PropTypes.string,
  ymaps: PropTypes.object.isRequired,
  prevPoint: PropTypes.object,
  size: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
};

GeoPointInput.defaultProps = {
  size: 300,
  prevPoint: null,
};

export default GeoPointInput;
