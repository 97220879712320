import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateProductAutoWriteOffMutation($input: CreateProductAutoWriteOffInput!) {
    createProductAutoWriteOff(input: $input) {
      productAutoWriteOff {
        rowId
        sourceId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createProductAutoWriteOff', 'productAutoWriteOff');
