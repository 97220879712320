import { graphql } from 'relay-runtime';

export default graphql`
  query ShowWarehouseMovementDocumentQuery($rowId: Int!, $orderBy: [WarehouseMovementItemsOrderBy!]) {
    data: warehouseMovementDocumentByRowId(rowId: $rowId) {
      id
      rowId
      eventedAt
      comment

      items: warehouseMovementItemsByDocumentId(orderBy: $orderBy) {
        nodes {
          id
          rowId

          productId
          product: productByProductId {
            rowId
            name
          }

          sourceId
          targetId

          quantity
        }
      }
    }

    allWarehouses {
      nodes {
        rowId
        name
      }
    }
  }
`;
