/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type ShopOrderDocumentStatus = "CANCELED" | "COMPLETED" | "DELIVERED" | "PACKING" | "PENDING" | "SHIPPED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type GroupsMapShopOrderDocument$ref: FragmentReference;
export type GroupsMapShopOrderDocument = {|
  +nodes: $ReadOnlyArray<{|
    +id: string,
    +rowId: number,
    +deadlineAt: any,
    +courierId: ?number,
    +orders: {|
      +nodes: $ReadOnlyArray<{|
        +rowId: number,
        +status: ShopOrderDocumentStatus,
        +ready: boolean,
      |}>
    |},
  |}>,
  +$refType: GroupsMapShopOrderDocument$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "GroupsMapShopOrderDocument",
  "type": "ShopOrderGroupsConnection",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nodes",
      "storageKey": null,
      "args": null,
      "concreteType": "ShopOrderGroup",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "deadlineAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "courierId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": "orders",
          "name": "shopOrderDocumentsByGroupId",
          "storageKey": null,
          "args": null,
          "concreteType": "ShopOrderDocumentsConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "nodes",
              "storageKey": null,
              "args": null,
              "concreteType": "ShopOrderDocument",
              "plural": true,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "status",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "ready",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd2851ece2c3c4b007e425b8e385a9ece';
module.exports = node;
