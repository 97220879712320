/**
 * @flow
 * @relayHash c25f7415b27ea05d9f30ea43e7d34693
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SendCourierTelegramMessageInput = {
  clientMutationId?: ?string,
  courierId?: ?number,
  text?: ?string,
};
export type SendCourierTelegramMessageMutationVariables = {|
  input: SendCourierTelegramMessageInput
|};
export type SendCourierTelegramMessageMutationResponse = {|
  +sendCourierTelegramMessage: ?{|
    +clientMutationId: ?string
  |}
|};
export type SendCourierTelegramMessageMutation = {|
  variables: SendCourierTelegramMessageMutationVariables,
  response: SendCourierTelegramMessageMutationResponse,
|};
*/


/*
mutation SendCourierTelegramMessageMutation(
  $input: SendCourierTelegramMessageInput!
) {
  sendCourierTelegramMessage(input: $input) {
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SendCourierTelegramMessageInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "sendCourierTelegramMessage",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "SendCourierTelegramMessageInput!"
      }
    ],
    "concreteType": "SendCourierTelegramMessagePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SendCourierTelegramMessageMutation",
  "id": null,
  "text": "mutation SendCourierTelegramMessageMutation(\n  $input: SendCourierTelegramMessageInput!\n) {\n  sendCourierTelegramMessage(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SendCourierTelegramMessageMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SendCourierTelegramMessageMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '20215533a4b6716da892742cd6abcfe4';
module.exports = node;
