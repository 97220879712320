/**
 * @flow
 * @relayHash fefe427a9ebe694cd7c96b9d377c3888
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductGroupsOrderBy = "ID_ASC" | "ID_DESC" | "NAME_ASC" | "NAME_DESC" | "NATURAL" | "POSITION_ASC" | "POSITION_DESC" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "WAREHOUSE_ID_ASC" | "WAREHOUSE_ID_DESC" | "%future added value";
export type ProductGroupsQueryVariables = {|
  first: number,
  offset: number,
  orderBy?: ?$ReadOnlyArray<ProductGroupsOrderBy>,
|};
export type ProductGroupsQueryResponse = {|
  +rows: ?{|
    +totalCount: number,
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +warehouse: ?{|
        +rowId: number,
        +name: string,
      |},
      +name: string,
      +position: number,
    |}>,
  |}
|};
export type ProductGroupsQuery = {|
  variables: ProductGroupsQueryVariables,
  response: ProductGroupsQueryResponse,
|};
*/


/*
query ProductGroupsQuery(
  $first: Int!
  $offset: Int!
  $orderBy: [ProductGroupsOrderBy!]
) {
  rows: allProductGroups(first: $first, offset: $offset, orderBy: $orderBy) {
    totalCount
    nodes {
      id
      rowId
      warehouse: warehouseByWarehouseId {
        rowId
        name
        id
      }
      name
      position
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[ProductGroupsOrderBy!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy",
    "type": "[ProductGroupsOrderBy!]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "position",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ProductGroupsQuery",
  "id": null,
  "text": "query ProductGroupsQuery(\n  $first: Int!\n  $offset: Int!\n  $orderBy: [ProductGroupsOrderBy!]\n) {\n  rows: allProductGroups(first: $first, offset: $offset, orderBy: $orderBy) {\n    totalCount\n    nodes {\n      id\n      rowId\n      warehouse: warehouseByWarehouseId {\n        rowId\n        name\n        id\n      }\n      name\n      position\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ProductGroupsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "rows",
        "name": "allProductGroups",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProductGroupsConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductGroup",
            "plural": true,
            "selections": [
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": "warehouse",
                "name": "warehouseByWarehouseId",
                "storageKey": null,
                "args": null,
                "concreteType": "Warehouse",
                "plural": false,
                "selections": [
                  v4,
                  v5
                ]
              },
              v5,
              v6
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProductGroupsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "rows",
        "name": "allProductGroups",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProductGroupsConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductGroup",
            "plural": true,
            "selections": [
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": "warehouse",
                "name": "warehouseByWarehouseId",
                "storageKey": null,
                "args": null,
                "concreteType": "Warehouse",
                "plural": false,
                "selections": [
                  v4,
                  v5,
                  v3
                ]
              },
              v5,
              v6
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '75f98e657485b14cc6d2f7b22d54cae1';
module.exports = node;
