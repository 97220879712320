import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Icon = ({ className, size, ...props }) => (
  <i style={{ fontSize: size }} className={cx('material-icons', className)} {...props} />
);

Icon.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

Icon.defaultProps = {
  className: null,
  size: 20,
};

export default Icon;
