import { graphql } from 'relay-runtime';

export default graphql`
  query EditProductGroupQuery($rowId: Int!) {
    data: productGroupByRowId(rowId: $rowId) {
      id
      rowId
      warehouseId
      name
    }

    allWarehouses {
      nodes {
        rowId
        name
      }
    }
  }
`;
