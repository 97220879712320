/* eslint-disable import/prefer-default-export */

export const coords = {
  red: [
    [55.79614546245684, 37.97126885449218],
    [55.7791219874514, 37.936936579101555],
    [55.76131672933299, 37.89161797558593],
    [55.735755786509934, 37.883378229492195],
    [55.708627313889565, 37.88475152050782],
    [55.67604815915122, 37.906724176757805],
    [55.65664285838882, 37.89711113964842],
    [55.62868224515453, 37.866898737304695],
    [55.60692124076005, 37.827073297851555],
    [55.58981479779719, 37.79823418652343],
    [55.57036656436688, 37.74879570996094],
    [55.558692979675804, 37.70210381542969],
    [55.54779449048261, 37.65129204785156],
    [55.53209451704837, 37.59699371387053],
    [55.530536717666216, 37.54480865527677],
    [55.53365225446686, 37.49811676074552],
    [55.55156178157554, 37.452798157229914],
    [55.571019366901005, 37.42121246387051],
    [55.5896895450539, 37.389626770511136],
    [55.59902129062978, 37.342934875979886],
    [55.61301473043487, 37.31684234668303],
    [55.629334073898036, 37.296242981448636],
    [55.644869963079444, 37.2440579228549],
    [55.638656349876534, 37.19187286426116],
    [55.65574140438861, 37.14792755176115],
    [55.68601015297079, 37.102608948245525],
    [55.69454324104185, 37.039437561526775],
    [55.7154801652501, 36.985879211917414],
    [55.739504965612035, 36.9913723759799],
    [55.76408712412741, 37.00409695543227],
    [55.782665012891265, 37.041175812854135],
    [55.79813979472371, 37.08237454332289],
    [55.80974182984858, 37.12631985582287],
    [55.833708367811866, 37.14005276597912],
    [55.85302542312115, 37.14691922105726],
    [55.86538328028063, 37.16889187730725],
    [55.861521873952086, 37.23755642808852],
    [55.85997720348836, 37.27051541246352],
    [55.86847212780827, 37.2890548411744],
    [55.87889516710343, 37.30347439683846],
    [55.886228214321164, 37.32407376207285],
    [55.88391266548931, 37.36389920152596],
    [55.88005310915172, 37.38587185777598],
    [55.893174028500816, 37.40166470445567],
    [55.90127590057624, 37.414710969104085],
    [55.90783331453504, 37.43119046129158],
    [55.9132327018628, 37.45110318101816],
    [55.92904085052037, 37.48337551988535],
    [55.94021831508495, 37.48955532945565],
    [55.95463209431901, 37.527369470128335],
    [55.95134685862118, 37.5477975566391],
    [55.94402617084176, 37.57457673144379],
    [55.94402617084176, 37.615775461912555],
    [55.93747490362217, 37.64873444628755],
    [55.92783866555152, 37.66315400195162],
    [55.918971200732386, 37.671393748045354],
    [55.918971200732386, 37.71671235156098],
    [55.92398349640115, 37.73799836230316],
    [55.919356785046574, 37.766837473631284],
    [55.90701617650086, 37.77370392870942],
    [55.89660071975394, 37.778510447264104],
    [55.87383124277805, 37.80322968554537],
    [55.84988953902919, 37.83344208788912],
    [55.83945868122721, 37.84717499804535],
    [55.82168111573037, 37.89455353808441],
    [55.81433582668438, 37.95085846972504],
    [55.81046932577175, 37.972831125975055],
    [55.800027846384374, 37.97695099902192]
  ],
  blue
  : [
    [55.5680928026376, 38.288001414060915],
    [55.548633761348654, 38.194617624998415],
    [55.5680928026376, 38.091620798826526],
    [55.58831994028036, 38.0298227031234],
    [55.594541569686704, 37.98587739062339],
    [55.57820767960155, 37.95017182421715],
    [55.57665171498401, 37.88425385546716],
    [55.54629802597678, 37.83344208788902],
    [55.49643579144311, 37.818335886717136],
    [55.454315154398145, 37.83206879687339],
    [55.41918649949706, 37.83502034311337],
    [55.41594914118763, 37.77440128501321],
    [55.424541321159836, 37.744188882669455],
    [55.44093936133924, 37.71260318931008],
    [55.446403854633466, 37.67277774985695],
    [55.433131623946245, 37.61509952720069],
    [55.42141710928572, 37.57802066977882],
    [55.42141710928572, 37.53544864829444],
    [55.42688431716854, 37.47914371665383],
    [55.43547410803363, 37.450304605325705],
    [55.47059461933293, 37.48189029868508],
    [55.48619365689662, 37.47502384360696],
    [55.495550103202845, 37.417345620950705],
    [55.50100699952885, 37.373400308450705],
    [55.492431535761824, 37.33906803306008],
    [55.47215480214109, 37.31434879477882],
    [55.46903437450833, 37.24980411704445],
    [55.50490431768785, 37.22096500571632],
    [55.526722140471065, 37.20997867759134],
    [55.54463483721045, 37.177019693216316],
    [55.55942610245624, 37.15779361899757],
    [55.53061690204604, 37.04243717368503],
    [55.51581474752542, 36.99025211509129],
    [55.52594314167298, 36.957293130716295],
    [55.55164192290404, 36.966906167825655],
    [55.57421177760047, 36.98887882407567],
    [55.60532124757634, 36.99849186118503],
    [55.62786012983795, 36.97239933188817],
    [55.65038600163231, 36.96003971274754],
    [55.672122777835824, 36.933947183450655],
    [55.69229607373346, 36.89000187095065],
    [55.7109083038358, 36.84193668540378],
    [55.731836424001855, 36.833696939310045],
    [55.74810606504079, 36.87626896079443],
    [55.76901416743634, 36.89274845298192],
    [55.80151551174755, 36.91472110923191],
    [55.82162174859979, 36.92158756431002],
    [55.863347535131076, 36.93944034751317],
    [55.89191146695309, 36.947680093606905],
    [55.923648322985215, 37.02833276026135],
    [55.91130908636959, 37.079144527839496],
    [55.90822366092166, 37.15055566065198],
    [55.92519045025249, 37.19587426416761],
    [55.94831496565294, 37.23157983057385],
    [55.971425621228995, 37.26865868799574],
    [55.98451550892764, 37.30436425440198],
    [55.99914011948204, 37.38264184229261],
    [56.019143742437045, 37.42521386377699],
    [56.03144849504458, 37.4650393032301],
    [56.03144849504458, 37.55567651026136],
    [56.03067956337312, 37.60099511377698],
    [56.02222029977693, 37.713604977058225],
    [56.02068205187425, 37.7781496547926],
    [56.01991290484756, 37.863293697761364],
    [56.01298988931512, 37.89762597315198],
    [55.98528536378786, 37.993756344245725],
    [55.965264134662995, 38.04868798487074],
    [55.95216770394492, 38.063794186042614],
    [55.917479197690675, 38.07890038721449],
    [55.895107852178874, 38.080273678230114],
    [55.8588233169308, 38.059674312995725],
    [55.83409946313019, 38.040448238776975],
    [55.82250471964505, 38.040448238776975],
    [55.79930481841398, 38.062420895026996],
    [55.77996095753921, 38.04868798487074],
    [55.76138177415133, 38.03907494776136],
    [55.73969481889829, 38.00474267237074],
    [55.714894898926644, 37.98963647119886],
    [55.6862002675223, 37.99512963526135],
    [55.6621425276833, 38.01435570948012],
    [55.64273030086999, 38.06791405908948],
    [55.61942286818011, 38.13383202783948],
    [55.60076689634945, 38.22721581690198],
    [55.58054620066044, 38.269787838386364]
  ]
};

export const colors = {
  red: {
    strokeColor: 'ed4543e6'
  },
  blue
  : {
    strokeColor: '1e98ffe6'
  }
};

export const strokeWidth = 5;
