import { compose, withProps } from 'recompact';
import withCreateEnhance from 'hoc/withCreateEnhance';
import mutation from 'mutations/CreateEmployeeMutation';

export default compose(
  withProps({
    title: 'Создать нового сотрудника',
  }),
  withCreateEnhance({
    mutation,
    onCompleted: (_, { router, route }) => router.push(`${route.baseUrl}/`),
  })
);
