import { graphql } from 'relay-runtime';

export default graphql`
  query EditProductQuery($rowId: Int!) {
    data: productByRowId(rowId: $rowId) {
      id
      rowId
      name
      parentId
      categoryId
      unit
      status
      barcodes
      packingTolerance
      pcsWeight
      requiresPackaging
      ownPackaging
      ownProduction
      externalStatus
      availableQuantityThreshold
      vat

      parent: productByParentId {
        rowId
        name
      }

      shelfLife {
        hours
        days
        months
        years
      }
    }

    allProductCategories {
      nodes {
        rowId
        name
      }
    }
  }
`;
