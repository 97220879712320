/**
 * @flow
 * @relayHash ffa638492fd69d8d3761338ef9dcf7f0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateProductGroupInput = {
  clientMutationId?: ?string,
  productGroup: ProductGroupInput,
};
export type ProductGroupInput = {
  rowId?: ?number,
  name: string,
  warehouseId: number,
  position?: ?number,
};
export type CreateProductGroupMutationVariables = {|
  input: CreateProductGroupInput
|};
export type CreateProductGroupMutationResponse = {|
  +createProductGroup: ?{|
    +productGroup: ?{|
      +rowId: number
    |}
  |}
|};
export type CreateProductGroupMutation = {|
  variables: CreateProductGroupMutationVariables,
  response: CreateProductGroupMutationResponse,
|};
*/


/*
mutation CreateProductGroupMutation(
  $input: CreateProductGroupInput!
) {
  createProductGroup(input: $input) {
    productGroup {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateProductGroupInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateProductGroupInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateProductGroupMutation",
  "id": null,
  "text": "mutation CreateProductGroupMutation(\n  $input: CreateProductGroupInput!\n) {\n  createProductGroup(input: $input) {\n    productGroup {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateProductGroupMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createProductGroup",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateProductGroupPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productGroup",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductGroup",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateProductGroupMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createProductGroup",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateProductGroupPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productGroup",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductGroup",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '81f59bbf509dd7dffcd5008a2f557c2a';
module.exports = node;
