import { compose } from 'recompact';
import { connect } from 'react-redux';
import { changeDefaultWarehouseId, signout } from 'redux/actions';

const selector = (state, props) => ({
  defaultWarehouseId: state.defaultWarehouseId,
  currentWarehouse: props.allWarehouses.nodes.find(w => w.rowId === state.defaultWarehouseId),
});

export default compose(
  connect(
    selector,
    {
      changeDefaultWarehouseId,
      signout,
    }
  )
);
