import { getRoutes } from 'utils/crud';

import Index from './index';
import IndexQuery from './YandexOrdersQuery';

import Show, { ShowQuery } from './Show';

export default getRoutes({
  baseUrl: '/yandex/orders',
  Index,
  IndexQuery,
  indexPrepareVariables: () => ({
    orderBy: 'ID_DESC',
    offset: 0,
    first: 200,
    filter: {},
  }),
  Show,
  ShowQuery,
});
