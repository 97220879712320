import { graphql } from 'relay-runtime';

export default graphql`
  query EditSupplyDocumentQuery($rowId: Int!) {
    data: supplyDocumentByRowId(rowId: $rowId) {
      id
      rowId
      contractorId
      eventedAt
      comment
      ownerId
      organizationId
    }

    allSupplyContractors {
      nodes {
        ...SupplyContractorSelect_items
      }
    }

    allOrganizations {
      nodes {
        rowId
        name
      }
    }

    allEmployees {
      nodes {
        ...EmployeeSelect_items
      }
    }

    viewer {
      rowId
    }

    currentDate
  }
`;
