/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type GroupsMapShopOrderDocument$ref = any;
type OrderBalloonShopOrderDocumentMap_allGroups$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MapShopOrderDocument_allGroups$ref: FragmentReference;
export type MapShopOrderDocument_allGroups = {|
  +nodes: $ReadOnlyArray<{|
    +rowId: number,
    +deadlineAt: any,
    +courier: ?{|
      +hasDriversLicense: ?boolean
    |},
  |}>,
  +$fragmentRefs: OrderBalloonShopOrderDocumentMap_allGroups$ref & GroupsMapShopOrderDocument$ref,
  +$refType: MapShopOrderDocument_allGroups$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MapShopOrderDocument_allGroups",
  "type": "ShopOrderGroupsConnection",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nodes",
      "storageKey": null,
      "args": null,
      "concreteType": "ShopOrderGroup",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "rowId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "deadlineAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": "courier",
          "name": "courierByCourierId",
          "storageKey": null,
          "args": null,
          "concreteType": "Courier",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasDriversLicense",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    },
    {
      "kind": "FragmentSpread",
      "name": "OrderBalloonShopOrderDocumentMap_allGroups",
      "args": null
    },
    {
      "kind": "FragmentSpread",
      "name": "GroupsMapShopOrderDocument",
      "args": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'f1d91547ec571adbc95e2324b761e877';
module.exports = node;
