/**
 * @flow
 * @relayHash e6d69466ae7c27b38ad3abb91fdb0714
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateBalanceItemInput = {
  clientMutationId?: ?string,
  id: string,
  balanceItemPatch: BalanceItemPatch,
};
export type BalanceItemPatch = {
  rowId?: ?number,
  documentId?: ?number,
  warehouseId?: ?number,
  productId?: ?number,
  quantity?: ?any,
};
export type UpdateBalanceItemMutationVariables = {|
  input: UpdateBalanceItemInput
|};
export type UpdateBalanceItemMutationResponse = {|
  +updateBalanceItem: ?{|
    +balanceItem: ?{|
      +rowId: number,
      +warehouseId: number,
      +productId: number,
      +quantity: any,
    |}
  |}
|};
export type UpdateBalanceItemMutation = {|
  variables: UpdateBalanceItemMutationVariables,
  response: UpdateBalanceItemMutationResponse,
|};
*/


/*
mutation UpdateBalanceItemMutation(
  $input: UpdateBalanceItemInput!
) {
  updateBalanceItem(input: $input) {
    balanceItem {
      rowId
      warehouseId
      productId
      quantity
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateBalanceItemInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateBalanceItemInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "warehouseId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "productId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateBalanceItemMutation",
  "id": null,
  "text": "mutation UpdateBalanceItemMutation(\n  $input: UpdateBalanceItemInput!\n) {\n  updateBalanceItem(input: $input) {\n    balanceItem {\n      rowId\n      warehouseId\n      productId\n      quantity\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateBalanceItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateBalanceItem",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateBalanceItemPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "balanceItem",
            "storageKey": null,
            "args": null,
            "concreteType": "BalanceItem",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateBalanceItemMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateBalanceItem",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateBalanceItemPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "balanceItem",
            "storageKey": null,
            "args": null,
            "concreteType": "BalanceItem",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '701a875eac1bd9fcec8f331c06683c25';
module.exports = node;
