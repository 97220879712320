import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateProductGroupsProductMutation($input: CreateProductGroupsProductInput!) {
    createProductGroupsProduct(input: $input) {
      productGroupsProduct {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createProductGroupsProduct', 'productGroupsProduct');
