/**
 * @flow
 * @relayHash 46cf4ce9d0c60b685be1f747961088b3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteWriteOffDocumentInput = {
  clientMutationId?: ?string,
  id: string,
};
export type DestroyWriteOffDocumentMutationVariables = {|
  input: DeleteWriteOffDocumentInput
|};
export type DestroyWriteOffDocumentMutationResponse = {|
  +deleteWriteOffDocument: ?{|
    +deletedWriteOffDocumentId: ?string
  |}
|};
export type DestroyWriteOffDocumentMutation = {|
  variables: DestroyWriteOffDocumentMutationVariables,
  response: DestroyWriteOffDocumentMutationResponse,
|};
*/


/*
mutation DestroyWriteOffDocumentMutation(
  $input: DeleteWriteOffDocumentInput!
) {
  deleteWriteOffDocument(input: $input) {
    deletedWriteOffDocumentId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteWriteOffDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteWriteOffDocument",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteWriteOffDocumentInput!"
      }
    ],
    "concreteType": "DeleteWriteOffDocumentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedWriteOffDocumentId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DestroyWriteOffDocumentMutation",
  "id": null,
  "text": "mutation DestroyWriteOffDocumentMutation(\n  $input: DeleteWriteOffDocumentInput!\n) {\n  deleteWriteOffDocument(input: $input) {\n    deletedWriteOffDocumentId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DestroyWriteOffDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DestroyWriteOffDocumentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7631cec6bdc69bfa09b01c4a4866642b';
module.exports = node;
