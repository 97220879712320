import { graphql } from 'relay-runtime';

export default graphql`
  query ShowWriteOffDocumentQuery($rowId: Int!) {
    data: writeOffDocumentByRowId(rowId: $rowId) {
      id
      rowId
      eventedAt
      comment
      totalCost

      category: writeOffCategoryByCategoryId {
        name
      }

      items: writeOffItemsByDocumentId {
        nodes {
          id
          productId
          warehouseId
          quantity
        }
      }
    }

    allWarehouses {
      nodes {
        rowId
        name
      }
    }
  }
`;
