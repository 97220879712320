import React from 'react';
import { YMaps, Map, Placemark, Clusterer } from 'react-yandex-maps';
import moment from 'moment';
import AllOrdersMapQuery from './AllOrdersMapQuery';

const AllOrderMap = ({ documents }) => {
  const mapProps = {
    width: 'calc(100vw)',
    height: 'calc(100vh - 200px)',
    state: { zoom: 10, center: [55.76, 37.64] },
  };

  return (
    <div>
      <YMaps>
        <Map {...mapProps}>
          <Clusterer options={{ preset: 'islands#invertedVioletClusterIcons' }}>
            {documents.nodes.map(d => (
              <Placemark
                key={d.id}
                geometry={{
                  coordinates: [d.deliveryPoint.x, d.deliveryPoint.y],
                }}
                properties={{
                  balloonContent: `№${d.rowId}`,
                }}
                options={{
                  preset: 'islands#circleIcon'
                }}
              />
            ))}
          </Clusterer>
        </Map>
      </YMaps>
    </div>
  );
};

export default AllOrderMap;

export { AllOrdersMapQuery };

export const allOrdersMapPrepareVariables = () => ({
  filter: {
    deliveryAt: {
      greaterThan: moment().add(-9, 'days').startOf('day'),
    },
    owner: {
      in: ['myfresh', 'moscowfresh'],
    },
    status: {
      notEqualTo: 'CANCELED',
    },
  },
});
