/**
 * @flow
 * @relayHash 38477fd3f3e848c74b34f91fa12ccdfb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FullCopySupplyDocumentInput = {
  clientMutationId?: ?string,
  contractorId: number,
};
export type FullCopySupplyDocumentMutationVariables = {|
  input: FullCopySupplyDocumentInput
|};
export type FullCopySupplyDocumentMutationResponse = {|
  +fullCopySupplyDocument: ?{|
    +supplyDocument: ?{|
      +rowId: number
    |}
  |}
|};
export type FullCopySupplyDocumentMutation = {|
  variables: FullCopySupplyDocumentMutationVariables,
  response: FullCopySupplyDocumentMutationResponse,
|};
*/


/*
mutation FullCopySupplyDocumentMutation(
  $input: FullCopySupplyDocumentInput!
) {
  fullCopySupplyDocument(input: $input) {
    supplyDocument {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "FullCopySupplyDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "FullCopySupplyDocumentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "FullCopySupplyDocumentMutation",
  "id": null,
  "text": "mutation FullCopySupplyDocumentMutation(\n  $input: FullCopySupplyDocumentInput!\n) {\n  fullCopySupplyDocument(input: $input) {\n    supplyDocument {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "FullCopySupplyDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "fullCopySupplyDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "FullCopySupplyDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyDocument",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "FullCopySupplyDocumentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "fullCopySupplyDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "FullCopySupplyDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyDocument",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '173b6bcb3c72710bb24357a184edf740';
module.exports = node;
