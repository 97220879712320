import { graphql } from 'relay-runtime';

export default graphql`
  query EditWarehouseQuery($rowId: Int!) {
    data: warehouseByRowId(rowId: $rowId) {
      id
      name
    }
  }
`;
