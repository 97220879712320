/**
 * @flow
 * @relayHash 98564072a1e0480982046eee10cdddac
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateProductAutoWriteOffInput = {
  clientMutationId?: ?string,
  id: string,
  productAutoWriteOffPatch: ProductAutoWriteOffPatch,
};
export type ProductAutoWriteOffPatch = {
  rowId?: ?number,
  sourceId?: ?number,
  targetId?: ?number,
  quantity?: ?any,
};
export type UpdateProductAutoWriteOffMutationVariables = {|
  input: UpdateProductAutoWriteOffInput
|};
export type UpdateProductAutoWriteOffMutationResponse = {|
  +updateProductAutoWriteOff: ?{|
    +productAutoWriteOff: ?{|
      +rowId: number,
      +sourceId: number,
    |}
  |}
|};
export type UpdateProductAutoWriteOffMutation = {|
  variables: UpdateProductAutoWriteOffMutationVariables,
  response: UpdateProductAutoWriteOffMutationResponse,
|};
*/


/*
mutation UpdateProductAutoWriteOffMutation(
  $input: UpdateProductAutoWriteOffInput!
) {
  updateProductAutoWriteOff(input: $input) {
    productAutoWriteOff {
      rowId
      sourceId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProductAutoWriteOffInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateProductAutoWriteOffInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sourceId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateProductAutoWriteOffMutation",
  "id": null,
  "text": "mutation UpdateProductAutoWriteOffMutation(\n  $input: UpdateProductAutoWriteOffInput!\n) {\n  updateProductAutoWriteOff(input: $input) {\n    productAutoWriteOff {\n      rowId\n      sourceId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateProductAutoWriteOffMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateProductAutoWriteOff",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateProductAutoWriteOffPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productAutoWriteOff",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductAutoWriteOff",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateProductAutoWriteOffMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateProductAutoWriteOff",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateProductAutoWriteOffPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productAutoWriteOff",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductAutoWriteOff",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2b43a8492a4822df866dc8941cab35c6';
module.exports = node;
