import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateSupplyItemMutation($input: UpdateSupplyItemInput!) {
    updateSupplyItem(input: $input) {
      supplyItem {
        id
        quantity
        price

        warehouseId
        productId
        product: productByProductId {
          rowId
          name
          unit
        }

        document: supplyDocumentByDocumentId {
          id
          totalSum
        }
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateSupplyItem', 'supplyItem');
