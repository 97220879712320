// @flow

import { compose, withProps } from 'recompact';
import destroyMutation from 'mutations/DestroyWriteOffDocumentMutation';
import { getRelationLink, renderDate, renderPrice } from 'components/helpers';

export default compose(
  withProps({
    title: 'Списания',
    destroyMutation,
    pageActions: {
      warehouses: {
        type: 'link',
        to: '/write-off/categories',
        children: 'Категории',
        icon: 'bars',
      },
    },
    columns: {
      category: {
        title: 'Категория',
        render: getRelationLink({ path: '/write-off/categories' }),
        width: 150,
      },
      comment: {
        title: 'Коментарий',
      },
      eventedAt: {
        title: 'Дата',
        render: renderDate,
        width: 150,
      },
      totalCost: {
        title: 'Сумма',
        render: renderPrice,
        width: 150,
        align: 'right',
      },
    },
  })
);
