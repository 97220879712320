/**
 * @flow
 * @relayHash db80833fd66900b401a3f24ef5e3be85
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateProductAutoWriteOffInput = {
  clientMutationId?: ?string,
  productAutoWriteOff: ProductAutoWriteOffInput,
};
export type ProductAutoWriteOffInput = {
  rowId?: ?number,
  sourceId: number,
  targetId: number,
  quantity: any,
};
export type CreateProductAutoWriteOffMutationVariables = {|
  input: CreateProductAutoWriteOffInput
|};
export type CreateProductAutoWriteOffMutationResponse = {|
  +createProductAutoWriteOff: ?{|
    +productAutoWriteOff: ?{|
      +rowId: number,
      +sourceId: number,
    |}
  |}
|};
export type CreateProductAutoWriteOffMutation = {|
  variables: CreateProductAutoWriteOffMutationVariables,
  response: CreateProductAutoWriteOffMutationResponse,
|};
*/


/*
mutation CreateProductAutoWriteOffMutation(
  $input: CreateProductAutoWriteOffInput!
) {
  createProductAutoWriteOff(input: $input) {
    productAutoWriteOff {
      rowId
      sourceId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateProductAutoWriteOffInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateProductAutoWriteOffInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sourceId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateProductAutoWriteOffMutation",
  "id": null,
  "text": "mutation CreateProductAutoWriteOffMutation(\n  $input: CreateProductAutoWriteOffInput!\n) {\n  createProductAutoWriteOff(input: $input) {\n    productAutoWriteOff {\n      rowId\n      sourceId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateProductAutoWriteOffMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createProductAutoWriteOff",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateProductAutoWriteOffPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productAutoWriteOff",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductAutoWriteOff",
            "plural": false,
            "selections": [
              v2,
              v3
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateProductAutoWriteOffMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createProductAutoWriteOff",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateProductAutoWriteOffPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productAutoWriteOff",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductAutoWriteOff",
            "plural": false,
            "selections": [
              v2,
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8d0630bffb8130bf484d029fb00eabf2';
module.exports = node;
