import { graphql } from 'relay-runtime';

export default graphql`
  query NewSupplyDocumentQuery {
    currentDate

    allSupplyContractors {
      nodes {
        ...SupplyContractorSelect_items
      }
    }

    allEmployees {
      nodes {
        ...EmployeeSelect_items
      }
    }

    allOrganizations {
      nodes {
        rowId
        name
      }
    }

    viewer {
      rowId
    }
  }
`;
