import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateShopDepositReplenishmentMutation($input: CreateShopDepositReplenishmentInput!) {
    createShopDepositReplenishment(input: $input) {
      shopDepositReplenishment {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createShopDepositReplenishment', 'shopDepositReplenishment');
