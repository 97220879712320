/**
 * @flow
 * @relayHash 6898c1a563ae69a3d089158325a10722
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditWarehouseQueryVariables = {|
  rowId: number
|};
export type EditWarehouseQueryResponse = {|
  +data: ?{|
    +id: string,
    +name: string,
  |}
|};
export type EditWarehouseQuery = {|
  variables: EditWarehouseQueryVariables,
  response: EditWarehouseQueryResponse,
|};
*/


/*
query EditWarehouseQuery(
  $rowId: Int!
) {
  data: warehouseByRowId(rowId: $rowId) {
    id
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "data",
    "name": "warehouseByRowId",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "rowId",
        "variableName": "rowId",
        "type": "Int!"
      }
    ],
    "concreteType": "Warehouse",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditWarehouseQuery",
  "id": null,
  "text": "query EditWarehouseQuery(\n  $rowId: Int!\n) {\n  data: warehouseByRowId(rowId: $rowId) {\n    id\n    name\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditWarehouseQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "EditWarehouseQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4b2b1168cd3e100f07128a43b3d3f499';
module.exports = node;
