/**
 * @flow
 * @relayHash b177e5dd973bfdb61e377ee254b26a76
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateBalanceItemInput = {
  clientMutationId?: ?string,
  balanceItem: BalanceItemInput,
};
export type BalanceItemInput = {
  rowId?: ?number,
  documentId: number,
  warehouseId: number,
  productId: number,
  quantity: any,
};
export type CreateBalanceItemMutationVariables = {|
  input: CreateBalanceItemInput
|};
export type CreateBalanceItemMutationResponse = {|
  +createBalanceItem: ?{|
    +balanceItem: ?{|
      +rowId: number
    |}
  |}
|};
export type CreateBalanceItemMutation = {|
  variables: CreateBalanceItemMutationVariables,
  response: CreateBalanceItemMutationResponse,
|};
*/


/*
mutation CreateBalanceItemMutation(
  $input: CreateBalanceItemInput!
) {
  createBalanceItem(input: $input) {
    balanceItem {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateBalanceItemInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateBalanceItemInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateBalanceItemMutation",
  "id": null,
  "text": "mutation CreateBalanceItemMutation(\n  $input: CreateBalanceItemInput!\n) {\n  createBalanceItem(input: $input) {\n    balanceItem {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateBalanceItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createBalanceItem",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateBalanceItemPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "balanceItem",
            "storageKey": null,
            "args": null,
            "concreteType": "BalanceItem",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateBalanceItemMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createBalanceItem",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateBalanceItemPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "balanceItem",
            "storageKey": null,
            "args": null,
            "concreteType": "BalanceItem",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1127842e5d2c53737f7017aeb284291e';
module.exports = node;
