/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
type CourierSelectShopOrderDocumentMap_items$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type MapShopOrderDocument_allCouriers$ref: FragmentReference;
export type MapShopOrderDocument_allCouriers = {|
  +nodes: $ReadOnlyArray<{|
    +id: string,
    +lastPosition: ?{|
      +x: number,
      +y: number,
    |},
    +firstName: string,
    +lastName: ?string,
    +lastPositionedAt: ?any,
    +$fragmentRefs: CourierSelectShopOrderDocumentMap_items$ref,
  |}>,
  +$refType: MapShopOrderDocument_allCouriers$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "MapShopOrderDocument_allCouriers",
  "type": "CouriersConnection",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nodes",
      "storageKey": null,
      "args": null,
      "concreteType": "Courier",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "lastPosition",
          "storageKey": null,
          "args": null,
          "concreteType": "Point",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "x",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "y",
              "args": null,
              "storageKey": null
            }
          ]
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "firstName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lastName",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "lastPositionedAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "FragmentSpread",
          "name": "CourierSelectShopOrderDocumentMap_items",
          "args": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '1cd279825f77ab8b0510b78a06cd572c';
module.exports = node;
