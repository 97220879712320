/**
 * @flow
 * @relayHash 26e921d8902cc9449909f3301b07b0bb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WarehouseMovementItemsOrderBy = "DOCUMENT_ID_ASC" | "DOCUMENT_ID_DESC" | "ID_ASC" | "ID_DESC" | "NATURAL" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "PRODUCT_ID_ASC" | "PRODUCT_ID_DESC" | "QUANTITY_ASC" | "QUANTITY_DESC" | "SOURCE_ID_ASC" | "SOURCE_ID_DESC" | "TARGET_ID_ASC" | "TARGET_ID_DESC" | "%future added value";
export type ShowWarehouseMovementDocumentQueryVariables = {|
  rowId: number,
  orderBy?: ?$ReadOnlyArray<WarehouseMovementItemsOrderBy>,
|};
export type ShowWarehouseMovementDocumentQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +eventedAt: any,
    +comment: ?string,
    +items: {|
      +nodes: $ReadOnlyArray<{|
        +id: string,
        +rowId: number,
        +productId: number,
        +product: ?{|
          +rowId: number,
          +name: string,
        |},
        +sourceId: number,
        +targetId: number,
        +quantity: any,
      |}>
    |},
  |},
  +allWarehouses: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +name: string,
    |}>
  |},
|};
export type ShowWarehouseMovementDocumentQuery = {|
  variables: ShowWarehouseMovementDocumentQueryVariables,
  response: ShowWarehouseMovementDocumentQueryResponse,
|};
*/


/*
query ShowWarehouseMovementDocumentQuery(
  $rowId: Int!
  $orderBy: [WarehouseMovementItemsOrderBy!]
) {
  data: warehouseMovementDocumentByRowId(rowId: $rowId) {
    id
    rowId
    eventedAt
    comment
    items: warehouseMovementItemsByDocumentId(orderBy: $orderBy) {
      nodes {
        id
        rowId
        productId
        product: productByProductId {
          rowId
          name
          id
        }
        sourceId
        targetId
        quantity
      }
    }
  }
  allWarehouses {
    nodes {
      rowId
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[WarehouseMovementItemsOrderBy!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "eventedAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "comment",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy",
    "type": "[WarehouseMovementItemsOrderBy!]"
  }
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "productId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v9 = [
  v3,
  v8
],
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "sourceId",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "targetId",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v13 = [
  v3,
  v8,
  v2
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ShowWarehouseMovementDocumentQuery",
  "id": null,
  "text": "query ShowWarehouseMovementDocumentQuery(\n  $rowId: Int!\n  $orderBy: [WarehouseMovementItemsOrderBy!]\n) {\n  data: warehouseMovementDocumentByRowId(rowId: $rowId) {\n    id\n    rowId\n    eventedAt\n    comment\n    items: warehouseMovementItemsByDocumentId(orderBy: $orderBy) {\n      nodes {\n        id\n        rowId\n        productId\n        product: productByProductId {\n          rowId\n          name\n          id\n        }\n        sourceId\n        targetId\n        quantity\n      }\n    }\n  }\n  allWarehouses {\n    nodes {\n      rowId\n      name\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ShowWarehouseMovementDocumentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "warehouseMovementDocumentByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "WarehouseMovementDocument",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": "items",
            "name": "warehouseMovementItemsByDocumentId",
            "storageKey": null,
            "args": v6,
            "concreteType": "WarehouseMovementItemsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "WarehouseMovementItem",
                "plural": true,
                "selections": [
                  v2,
                  v3,
                  v7,
                  {
                    "kind": "LinkedField",
                    "alias": "product",
                    "name": "productByProductId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": v9
                  },
                  v10,
                  v11,
                  v12
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allWarehouses",
        "storageKey": null,
        "args": null,
        "concreteType": "WarehousesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": true,
            "selections": v9
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ShowWarehouseMovementDocumentQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "warehouseMovementDocumentByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "WarehouseMovementDocument",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "LinkedField",
            "alias": "items",
            "name": "warehouseMovementItemsByDocumentId",
            "storageKey": null,
            "args": v6,
            "concreteType": "WarehouseMovementItemsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "WarehouseMovementItem",
                "plural": true,
                "selections": [
                  v2,
                  v3,
                  v7,
                  {
                    "kind": "LinkedField",
                    "alias": "product",
                    "name": "productByProductId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": v13
                  },
                  v10,
                  v11,
                  v12
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allWarehouses",
        "storageKey": null,
        "args": null,
        "concreteType": "WarehousesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": true,
            "selections": v13
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3e8b378b698e00babc3281f267222cb1';
module.exports = node;
