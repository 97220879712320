// @flow
import type { ShopOrderDocumentStatus } from './__generated__/Documents.graphql';

export type StatusEnum = { [ShopOrderDocumentStatus]: string };

const statutes: StatusEnum = {
  PENDING: 'Ожидает',
  PACKING: 'Сборка',
  SHIPPED: 'Отправлен',
  CANCELED: 'Отменён',
};

export default statutes;
