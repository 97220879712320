import { graphql } from 'relay-runtime';

export default graphql`
  query CourierPrintShopOrderDocumentQuery($filter: ShopOrderDocumentFilter!) {
    orders: allShopOrderDocuments(filter: $filter, orderBy: [GROUP_POSITION_ASC]) {
      nodes {
        id
        rowId
        owner
        deliveryAt
        totalSum
        groupPosition
        groupId
        label
        asap
        comment
        paymentMethod
        confirmedAmount
        depositBalance
        meta
        deliveryMaxDelay {
          minutes
          hours
        }

        yandexOrders: yandexOrdersByDocumentId(first: 1) {
          nodes {
            clientName
            address
            phone
            comment
            change
          }
        }

        group: shopOrderGroupByGroupId {
          courier: courierByCourierId {
            firstName
            lastName
          }
        }
      }
    }
  }
`;
