import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroyShopOrderGroupMutation($input: DeleteShopOrderGroupInput!) {
    deleteShopOrderGroup(input: $input) {
      deletedShopOrderGroupId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteShopOrderGroup', 'deletedShopOrderGroupId');
