import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateSupplyDocumentMutation($input: UpdateSupplyDocumentInput!) {
    updateSupplyDocument(input: $input) {
      supplyDocument {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateSupplyDocument', 'supplyDocument');
