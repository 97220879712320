import React from 'react';
import { QueryRenderer } from 'react-relay';
import PropTypes from 'prop-types';
import CurrentQuantityQuery from './CurrentQuantityQuery'
import render from './render'

const CurrentQuantity = props => {
    if(props.variables.productId && props.variables.warehouseId) {
     return <QueryRenderer environment={props.environment} variables={props.variables} query={CurrentQuantityQuery} render={render} />
    }
    return false
}
CurrentQuantity.proppTypes = {
    props: PropTypes.shape({
        environment: PropTypes.object.isRequired,
        variables: PropTypes.shape({
            productId: PropTypes.number,
            warehouseId: PropTypes.number
        })
    })
}

export default CurrentQuantity