import { graphql } from 'relay-runtime';
import commitMutation from 'relay-commit-mutation-promise';

const mutation = graphql`
  mutation FullCopySupplyDocumentMutation($input: FullCopySupplyDocumentInput!) {
    fullCopySupplyDocument(input: $input) {
      supplyDocument {
        rowId
      }
    }
  }
`;

const commit = (environment, contractorId, options) => {
  const config = {
    mutation,
    variables: {
      input: { contractorId },
    },
    ...options,
  };

  return commitMutation(environment, config).then(data => data.fullCopySupplyDocument.supplyDocument);
};

export default { commit };
