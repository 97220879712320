/**
 * @flow
 * @relayHash 535a499a2a7b742d937929d702b7315b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductExternalStatus = "ALWAYS_AVAILABLE" | "AVAILABLE" | "BY_REQUEST" | "FROM_PARENT" | "HIDDEN" | "%future added value";
export type ProductStatus = "ACTIVE" | "ARCHIVE" | "DEPRECATED" | "%future added value";
export type ProductUnit = "KG" | "PCS" | "%future added value";
export type ProductsOrderBy = "AVAILABLE_QUANTITY_THRESHOLD_ASC" | "AVAILABLE_QUANTITY_THRESHOLD_DESC" | "BARCODES_ASC" | "BARCODES_DESC" | "CATEGORY_ID_ASC" | "CATEGORY_ID_DESC" | "COUNTRY_ASC" | "COUNTRY_DESC" | "EXTERNAL_STATUS_ASC" | "EXTERNAL_STATUS_DESC" | "ID_ASC" | "ID_DESC" | "META_ASC" | "META_DESC" | "NAME_ASC" | "NAME_DESC" | "NATURAL" | "OWN_PACKAGING_ASC" | "OWN_PACKAGING_DESC" | "OWN_PRODUCTION_ASC" | "OWN_PRODUCTION_DESC" | "PACKING_TOLERANCE_ASC" | "PACKING_TOLERANCE_DESC" | "PARENT_ID_ASC" | "PARENT_ID_DESC" | "PCS_WEIGHT_ASC" | "PCS_WEIGHT_DESC" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "REQUIRES_PACKAGING_ASC" | "REQUIRES_PACKAGING_DESC" | "SHELF_LIFE_ASC" | "SHELF_LIFE_DESC" | "STATUS_ASC" | "STATUS_DESC" | "SYNC_ID_ASC" | "SYNC_ID_DESC" | "TAGS_ASC" | "TAGS_DESC" | "UNIT_ASC" | "UNIT_DESC" | "VAT_ASC" | "VAT_DESC" | "%future added value";
export type ProductFilter = {
  rowId?: ?IntFilter,
  parentId?: ?IntFilter,
  name?: ?StringFilter,
  barcodes?: ?StringListFilter,
  unit?: ?ProductUnitFilter,
  status?: ?ProductStatusFilter,
  pcsWeight?: ?BigFloatFilter,
  shelfLife?: ?IntervalFilter,
  meta?: ?JSONFilter,
  packingTolerance?: ?BigFloatFilter,
  requiresPackaging?: ?BooleanFilter,
  ownPackaging?: ?BooleanFilter,
  tags?: ?StringListFilter,
  categoryId?: ?IntFilter,
  ownProduction?: ?BooleanFilter,
  externalStatus?: ?ProductExternalStatusFilter,
  availableQuantityThreshold?: ?BigFloatFilter,
  country?: ?StringFilter,
  syncId?: ?UUIDFilter,
  vat?: ?IntFilter,
  shopOrderPrices?: ?BigFloatListFilter,
  and?: ?$ReadOnlyArray<ProductFilter>,
  or?: ?$ReadOnlyArray<ProductFilter>,
  not?: ?ProductFilter,
};
export type IntFilter = {
  isNull?: ?boolean,
  equalTo?: ?number,
  notEqualTo?: ?number,
  distinctFrom?: ?number,
  notDistinctFrom?: ?number,
  in?: ?$ReadOnlyArray<number>,
  notIn?: ?$ReadOnlyArray<number>,
  lessThan?: ?number,
  lessThanOrEqualTo?: ?number,
  greaterThan?: ?number,
  greaterThanOrEqualTo?: ?number,
};
export type StringFilter = {
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  equalToInsensitive?: ?string,
  notEqualToInsensitive?: ?string,
  distinctFromInsensitive?: ?string,
  notDistinctFromInsensitive?: ?string,
  inInsensitive?: ?$ReadOnlyArray<string>,
  notInInsensitive?: ?$ReadOnlyArray<string>,
  lessThanInsensitive?: ?string,
  lessThanOrEqualToInsensitive?: ?string,
  greaterThanInsensitive?: ?string,
  greaterThanOrEqualToInsensitive?: ?string,
};
export type StringListFilter = {
  isNull?: ?boolean,
  equalTo?: ?$ReadOnlyArray<?string>,
  notEqualTo?: ?$ReadOnlyArray<?string>,
  distinctFrom?: ?$ReadOnlyArray<?string>,
  notDistinctFrom?: ?$ReadOnlyArray<?string>,
  lessThan?: ?$ReadOnlyArray<?string>,
  lessThanOrEqualTo?: ?$ReadOnlyArray<?string>,
  greaterThan?: ?$ReadOnlyArray<?string>,
  greaterThanOrEqualTo?: ?$ReadOnlyArray<?string>,
  contains?: ?$ReadOnlyArray<?string>,
  containedBy?: ?$ReadOnlyArray<?string>,
  overlaps?: ?$ReadOnlyArray<?string>,
  anyEqualTo?: ?string,
  anyNotEqualTo?: ?string,
  anyLessThan?: ?string,
  anyLessThanOrEqualTo?: ?string,
  anyGreaterThan?: ?string,
  anyGreaterThanOrEqualTo?: ?string,
};
export type ProductUnitFilter = {
  isNull?: ?boolean,
  equalTo?: ?ProductUnit,
  notEqualTo?: ?ProductUnit,
  distinctFrom?: ?ProductUnit,
  notDistinctFrom?: ?ProductUnit,
  in?: ?$ReadOnlyArray<ProductUnit>,
  notIn?: ?$ReadOnlyArray<ProductUnit>,
  lessThan?: ?ProductUnit,
  lessThanOrEqualTo?: ?ProductUnit,
  greaterThan?: ?ProductUnit,
  greaterThanOrEqualTo?: ?ProductUnit,
};
export type ProductStatusFilter = {
  isNull?: ?boolean,
  equalTo?: ?ProductStatus,
  notEqualTo?: ?ProductStatus,
  distinctFrom?: ?ProductStatus,
  notDistinctFrom?: ?ProductStatus,
  in?: ?$ReadOnlyArray<ProductStatus>,
  notIn?: ?$ReadOnlyArray<ProductStatus>,
  lessThan?: ?ProductStatus,
  lessThanOrEqualTo?: ?ProductStatus,
  greaterThan?: ?ProductStatus,
  greaterThanOrEqualTo?: ?ProductStatus,
};
export type BigFloatFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type IntervalFilter = {
  isNull?: ?boolean,
  equalTo?: ?IntervalInput,
  notEqualTo?: ?IntervalInput,
  distinctFrom?: ?IntervalInput,
  notDistinctFrom?: ?IntervalInput,
  in?: ?$ReadOnlyArray<IntervalInput>,
  notIn?: ?$ReadOnlyArray<IntervalInput>,
  lessThan?: ?IntervalInput,
  lessThanOrEqualTo?: ?IntervalInput,
  greaterThan?: ?IntervalInput,
  greaterThanOrEqualTo?: ?IntervalInput,
};
export type IntervalInput = {
  seconds?: ?number,
  minutes?: ?number,
  hours?: ?number,
  days?: ?number,
  months?: ?number,
  years?: ?number,
};
export type JSONFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
  contains?: ?any,
  containsKey?: ?string,
  containsAllKeys?: ?$ReadOnlyArray<string>,
  containsAnyKeys?: ?$ReadOnlyArray<string>,
  containedBy?: ?any,
};
export type BooleanFilter = {
  isNull?: ?boolean,
  equalTo?: ?boolean,
  notEqualTo?: ?boolean,
  distinctFrom?: ?boolean,
  notDistinctFrom?: ?boolean,
  in?: ?$ReadOnlyArray<boolean>,
  notIn?: ?$ReadOnlyArray<boolean>,
  lessThan?: ?boolean,
  lessThanOrEqualTo?: ?boolean,
  greaterThan?: ?boolean,
  greaterThanOrEqualTo?: ?boolean,
};
export type ProductExternalStatusFilter = {
  isNull?: ?boolean,
  equalTo?: ?ProductExternalStatus,
  notEqualTo?: ?ProductExternalStatus,
  distinctFrom?: ?ProductExternalStatus,
  notDistinctFrom?: ?ProductExternalStatus,
  in?: ?$ReadOnlyArray<ProductExternalStatus>,
  notIn?: ?$ReadOnlyArray<ProductExternalStatus>,
  lessThan?: ?ProductExternalStatus,
  lessThanOrEqualTo?: ?ProductExternalStatus,
  greaterThan?: ?ProductExternalStatus,
  greaterThanOrEqualTo?: ?ProductExternalStatus,
};
export type UUIDFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type BigFloatListFilter = {
  isNull?: ?boolean,
  equalTo?: ?$ReadOnlyArray<?any>,
  notEqualTo?: ?$ReadOnlyArray<?any>,
  distinctFrom?: ?$ReadOnlyArray<?any>,
  notDistinctFrom?: ?$ReadOnlyArray<?any>,
  lessThan?: ?$ReadOnlyArray<?any>,
  lessThanOrEqualTo?: ?$ReadOnlyArray<?any>,
  greaterThan?: ?$ReadOnlyArray<?any>,
  greaterThanOrEqualTo?: ?$ReadOnlyArray<?any>,
  contains?: ?$ReadOnlyArray<?any>,
  containedBy?: ?$ReadOnlyArray<?any>,
  overlaps?: ?$ReadOnlyArray<?any>,
  anyEqualTo?: ?any,
  anyNotEqualTo?: ?any,
  anyLessThan?: ?any,
  anyLessThanOrEqualTo?: ?any,
  anyGreaterThan?: ?any,
  anyGreaterThanOrEqualTo?: ?any,
};
export type SustainProductsQueryVariables = {|
  first: number,
  offset: number,
  orderBy?: ?$ReadOnlyArray<ProductsOrderBy>,
  filter?: ?ProductFilter,
|};
export type SustainProductsQueryResponse = {|
  +rows: ?{|
    +totalCount: number,
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +name: string,
      +quantities: {|
        +nodes: $ReadOnlyArray<{|
          +id: string,
          +warehouseId: number,
          +value: any,
          +sustainableValue: any,
        |}>
      |},
    |}>,
  |},
  +allWarehouses: ?{|
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +name: string,
    |}>
  |},
  +allProductCategories: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +name: string,
    |}>
  |},
|};
export type SustainProductsQuery = {|
  variables: SustainProductsQueryVariables,
  response: SustainProductsQueryResponse,
|};
*/


/*
query SustainProductsQuery(
  $first: Int!
  $offset: Int!
  $orderBy: [ProductsOrderBy!]
  $filter: ProductFilter
) {
  rows: allProducts(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
    totalCount
    nodes {
      id
      rowId
      name
      quantities: productWarehouseQuantitiesByProductId {
        nodes {
          id
          warehouseId
          value
          sustainableValue
        }
      }
    }
  }
  allWarehouses {
    nodes {
      id
      rowId
      name
    }
  }
  allProductCategories {
    nodes {
      rowId
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[ProductsOrderBy!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "ProductFilter",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "LinkedField",
  "alias": "rows",
  "name": "allProducts",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "filter",
      "variableName": "filter",
      "type": "ProductFilter"
    },
    {
      "kind": "Variable",
      "name": "first",
      "variableName": "first",
      "type": "Int"
    },
    {
      "kind": "Variable",
      "name": "offset",
      "variableName": "offset",
      "type": "Int"
    },
    {
      "kind": "Variable",
      "name": "orderBy",
      "variableName": "orderBy",
      "type": "[ProductsOrderBy!]"
    }
  ],
  "concreteType": "ProductsConnection",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalCount",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nodes",
      "storageKey": null,
      "args": null,
      "concreteType": "Product",
      "plural": true,
      "selections": [
        v1,
        v2,
        v3,
        {
          "kind": "LinkedField",
          "alias": "quantities",
          "name": "productWarehouseQuantitiesByProductId",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductWarehouseQuantitiesConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "nodes",
              "storageKey": null,
              "args": null,
              "concreteType": "ProductWarehouseQuantity",
              "plural": true,
              "selections": [
                v1,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "warehouseId",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "value",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "sustainableValue",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
},
v5 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "allWarehouses",
  "storageKey": null,
  "args": null,
  "concreteType": "WarehousesConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nodes",
      "storageKey": null,
      "args": null,
      "concreteType": "Warehouse",
      "plural": true,
      "selections": [
        v1,
        v2,
        v3
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SustainProductsQuery",
  "id": null,
  "text": "query SustainProductsQuery(\n  $first: Int!\n  $offset: Int!\n  $orderBy: [ProductsOrderBy!]\n  $filter: ProductFilter\n) {\n  rows: allProducts(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {\n    totalCount\n    nodes {\n      id\n      rowId\n      name\n      quantities: productWarehouseQuantitiesByProductId {\n        nodes {\n          id\n          warehouseId\n          value\n          sustainableValue\n        }\n      }\n    }\n  }\n  allWarehouses {\n    nodes {\n      id\n      rowId\n      name\n    }\n  }\n  allProductCategories {\n    nodes {\n      rowId\n      name\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SustainProductsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v4,
      v5,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allProductCategories",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductCategoriesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductCategory",
            "plural": true,
            "selections": [
              v2,
              v3
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SustainProductsQuery",
    "argumentDefinitions": v0,
    "selections": [
      v4,
      v5,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allProductCategories",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductCategoriesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductCategory",
            "plural": true,
            "selections": [
              v2,
              v3,
              v1
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '21d7a0731a2d7dbda8e01e0e2f6689d4';
module.exports = node;
