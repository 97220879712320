import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Select, Button, Row, Col } from 'antd';
import PageForm from 'components/PageForm';

const EmployeeForm = ({ title, form, handleSubmit, data, submitting }) => (
  <PageForm onSubmit={handleSubmit} title={title}>
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item label="Имя">
          {form.getFieldDecorator('firstName', {
            rules: [
              {
                required: true,
                message: 'Введите имя',
              },
            ],
          })(<Input />)}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Фамилия">
          {form.getFieldDecorator('lastName', {
            rules: [
              {
                required: true,
                message: 'Введите фамилию',
              },
            ],
          })(<Input />)}
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item label="E-mail">
          {form.getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: 'Введите e-mail',
              },
            ],
          })(<Input />)}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Пароль">
          {form.getFieldDecorator('encryptedPassword', {
            rules: [
              {
                required: !data,
                message: 'Введите пароль',
              },
            ],
          })(<Input />)}
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={24}>
      <Col span={12}>
        {form.getFieldDecorator('role', { initialValue: 'stock_employee_full' })(
          <Form.Item label="Роль">
            <Select>
              <Select.Option value="stock_employee_full">Полные права (кроме добавления пользователей)</Select.Option>
              <Select.Option value="stock_employee_senior">Старший руководитель</Select.Option>
            </Select>
          </Form.Item>
        )}
      </Col>
    </Row>
    <Form.Item>
      <Button disabled={submitting} type="primary" htmlType="submit">
        Сохранить
      </Button>
    </Form.Item>
  </PageForm>
);

EmployeeForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default EmployeeForm;
