import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { omit, debounce } from 'lodash';

class DebouncedInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };

    this.save = debounce(() => {
      if (this.props.onSave) {
        this.props.onSave(this.state.value);
      }
    }, 3000);
  }

  render() {
    const onChange = e => {
      if (this.props.onChange) {
        this.props.onChange(e);
      }

      this.setState({ value: e.target.value });
      this.save(e);
    };

    return <Input {...omit(this.props, 'onSave')} onChange={onChange} value={this.state.value} />;
  }
}

DebouncedInput.propTypes = {
  onSave: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

DebouncedInput.defaultProps = {
  onSave: null,
  onChange: null,
  value: '',
};

export default DebouncedInput;
