exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-Products-Print-___Print__table___11jE9 {\n  border-collapse: collapse;\n  width: 100%;\n  font-size: 14px;\n}\n\n.src-pages-Products-Print-___Print__table___11jE9 td {\n  border: 1px solid black;\n  padding: 3px 5px;\n}\n\n.src-pages-Products-Print-___Print__number___3UQcv {\n  text-align: right;\n}\n", ""]);

// exports
exports.locals = {
	"table": "src-pages-Products-Print-___Print__table___11jE9",
	"number": "src-pages-Products-Print-___Print__number___3UQcv"
};