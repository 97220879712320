import { graphql } from 'relay-runtime';

export default graphql`
  query MovesQuery($rowId: Int!, $condition: ExtendMoveCondition!, $limit: Int) {
    product: productByRowId(rowId: $rowId) {
      id
      name
      unit

      parent: productByParentId {
        rowId
        name
      }

      quantities: productWarehouseQuantitiesByProductId {
        nodes {
          warehouseId
          value
        }
      }
    }

    allWarehouses {
      nodes {
        rowId
        name
      }
    }

    rows: allExtendMoves(first: $limit, orderBy: EVENTED_AT_DESC, condition: $condition) {
      nodes {
        rowId
        warehouseId
        actualProductId
        eventedAt
        quantity
        remain
        cost

        supplyDocumentId
        shopOrderDocumentId
        writeOffDocumentId
        sourceWarehouseMovementDocumentId
        targetWarehouseMovementDocumentId
      }
      totalCount
    }
  }
`;
