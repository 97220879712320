import { graphql } from 'relay-runtime';

export default graphql`
  query SustainProductsQuery($first: Int!, $offset: Int!, $orderBy: [ProductsOrderBy!], $filter: ProductFilter) {
    rows: allProducts(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
      totalCount

      nodes {
        id
        rowId
        name

        quantities: productWarehouseQuantitiesByProductId {
          nodes {
            id
            warehouseId
            value
            sustainableValue
          }
        }
      }
    }

    allWarehouses {
      nodes {
        id
        rowId
        name
      }
    }

    allProductCategories {
      nodes {
        rowId
        name
      }
    }
  }
`;
