import { graphql } from 'relay-runtime';

export default graphql`
  query NewSupplyContractorQuery {
    currentDate

    allEmployees {
      nodes {
        ...EmployeeSelect_items
      }
    }
  }
`;
