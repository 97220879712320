/**
 * @flow
 * @relayHash 9af57dbaf3752e325a4aa34298a93590
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CourierPaymentDetailCondition = {
  courierId?: ?number,
  day?: ?any,
  value?: ?any,
  employee?: ?string,
  sum?: ?any,
  comment?: ?string,
};
export type queryCourierTransactionsQueryVariables = {|
  rowId: number,
  condition: CourierPaymentDetailCondition,
|};
export type queryCourierTransactionsQueryResponse = {|
  +transactions: ?{|
    +nodes: $ReadOnlyArray<{|
      +day: ?any,
      +comment: ?string,
      +value: ?any,
      +employee: ?string,
      +sum: ?any,
    |}>
  |},
  +data: ?{|
    +rowId: number,
    +firstName: string,
    +lastName: ?string,
  |},
|};
export type queryCourierTransactionsQuery = {|
  variables: queryCourierTransactionsQueryVariables,
  response: queryCourierTransactionsQueryResponse,
|};
*/


/*
query queryCourierTransactionsQuery(
  $rowId: Int!
  $condition: CourierPaymentDetailCondition!
) {
  transactions: allCourierPaymentDetails(condition: $condition) {
    nodes {
      day
      comment
      value
      employee
      sum
    }
  }
  data: courierByRowId(rowId: $rowId) {
    rowId
    firstName
    lastName
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "condition",
    "type": "CourierPaymentDetailCondition!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "LinkedField",
  "alias": "transactions",
  "name": "allCourierPaymentDetails",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "condition",
      "variableName": "condition",
      "type": "CourierPaymentDetailCondition"
    }
  ],
  "concreteType": "CourierPaymentDetailsConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nodes",
      "storageKey": null,
      "args": null,
      "concreteType": "CourierPaymentDetail",
      "plural": true,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "day",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "comment",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "value",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "employee",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "sum",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
},
v2 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId",
    "type": "Int!"
  }
],
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "queryCourierTransactionsQuery",
  "id": null,
  "text": "query queryCourierTransactionsQuery(\n  $rowId: Int!\n  $condition: CourierPaymentDetailCondition!\n) {\n  transactions: allCourierPaymentDetails(condition: $condition) {\n    nodes {\n      day\n      comment\n      value\n      employee\n      sum\n    }\n  }\n  data: courierByRowId(rowId: $rowId) {\n    rowId\n    firstName\n    lastName\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "queryCourierTransactionsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v1,
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "courierByRowId",
        "storageKey": null,
        "args": v2,
        "concreteType": "Courier",
        "plural": false,
        "selections": [
          v3,
          v4,
          v5
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "queryCourierTransactionsQuery",
    "argumentDefinitions": v0,
    "selections": [
      v1,
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "courierByRowId",
        "storageKey": null,
        "args": v2,
        "concreteType": "Courier",
        "plural": false,
        "selections": [
          v3,
          v4,
          v5,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b237ed76b39a9a53183f74a435b42f3d';
module.exports = node;
