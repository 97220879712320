import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroyWriteOffCategoryMutation($input: DeleteWriteOffCategoryInput!) {
    deleteWriteOffCategory(input: $input) {
      deletedWriteOffCategoryId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteWriteOffCategory', 'deletedWriteOffCategoryId');
