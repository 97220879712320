/**
 * @flow
 * @relayHash a3a43839393813cebc29dec2e7f5eba6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductUnit = "KG" | "PCS" | "%future added value";
export type UpdateSupplyItemInput = {
  clientMutationId?: ?string,
  id: string,
  supplyItemPatch: SupplyItemPatch,
};
export type SupplyItemPatch = {
  rowId?: ?number,
  documentId?: ?number,
  productId?: ?number,
  warehouseId?: ?number,
  willBeSelledAt?: ?any,
  quantity?: ?any,
  price?: ?any,
};
export type UpdateSupplyItemMutationVariables = {|
  input: UpdateSupplyItemInput
|};
export type UpdateSupplyItemMutationResponse = {|
  +updateSupplyItem: ?{|
    +supplyItem: ?{|
      +id: string,
      +quantity: any,
      +price: any,
      +warehouseId: number,
      +productId: number,
      +product: ?{|
        +rowId: number,
        +name: string,
        +unit: ProductUnit,
      |},
      +document: ?{|
        +id: string,
        +totalSum: any,
      |},
    |}
  |}
|};
export type UpdateSupplyItemMutation = {|
  variables: UpdateSupplyItemMutationVariables,
  response: UpdateSupplyItemMutationResponse,
|};
*/


/*
mutation UpdateSupplyItemMutation(
  $input: UpdateSupplyItemInput!
) {
  updateSupplyItem(input: $input) {
    supplyItem {
      id
      quantity
      price
      warehouseId
      productId
      product: productByProductId {
        rowId
        name
        unit
        id
      }
      document: supplyDocumentByDocumentId {
        id
        totalSum
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateSupplyItemInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateSupplyItemInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "warehouseId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "productId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "unit",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "LinkedField",
  "alias": "document",
  "name": "supplyDocumentByDocumentId",
  "storageKey": null,
  "args": null,
  "concreteType": "SupplyDocument",
  "plural": false,
  "selections": [
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "totalSum",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateSupplyItemMutation",
  "id": null,
  "text": "mutation UpdateSupplyItemMutation(\n  $input: UpdateSupplyItemInput!\n) {\n  updateSupplyItem(input: $input) {\n    supplyItem {\n      id\n      quantity\n      price\n      warehouseId\n      productId\n      product: productByProductId {\n        rowId\n        name\n        unit\n        id\n      }\n      document: supplyDocumentByDocumentId {\n        id\n        totalSum\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateSupplyItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSupplyItem",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateSupplyItemPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyItem",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyItem",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              {
                "kind": "LinkedField",
                "alias": "product",
                "name": "productByProductId",
                "storageKey": null,
                "args": null,
                "concreteType": "Product",
                "plural": false,
                "selections": [
                  v7,
                  v8,
                  v9
                ]
              },
              v10
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateSupplyItemMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSupplyItem",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateSupplyItemPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyItem",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyItem",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              {
                "kind": "LinkedField",
                "alias": "product",
                "name": "productByProductId",
                "storageKey": null,
                "args": null,
                "concreteType": "Product",
                "plural": false,
                "selections": [
                  v7,
                  v8,
                  v9,
                  v2
                ]
              },
              v10
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'db89d26109e0556d1bb127253e0dec00';
module.exports = node;
