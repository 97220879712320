import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroyWarehouseMovementItemMutation($input: DeleteWarehouseMovementItemInput!) {
    deleteWarehouseMovementItem(input: $input) {
      deletedWarehouseMovementItemId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteWarehouseMovementItem', 'deletedWarehouseMovementItemId');
