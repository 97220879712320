export default (nextVariables, match) => {
  let ids = match.location.query.ids || [];

  if (!Array.isArray(ids)) {
    ids = [ids];
  }

  return {
    ...nextVariables,
    filter: {
      rowId: {
        in: ids,
      },
    },
  };
};
