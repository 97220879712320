import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroyWriteOffDocumentMutation($input: DeleteWriteOffDocumentInput!) {
    deleteWriteOffDocument(input: $input) {
      deletedWriteOffDocumentId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteWriteOffDocument', 'deletedWriteOffDocumentId');
