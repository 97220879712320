import { graphql } from 'relay-runtime';

export default graphql`
  query ShowProductQuery($rowId: Int!) {
    data: productByRowId(rowId: $rowId) {
      id
      rowId
      unit
      name
      status
      externalStatus

      parent: productByParentId {
        rowId
        name
      }

      shelfLife {
        hours
        days
        months
        years
      }

      children: productsByParentId {
        nodes {
          rowId
          name
        }
      }

      groups: productGroupsProductsByProductId {
        nodes {
          id
          productGroup: productGroupByGroupId {
            name
          }
        }
      }

      productAutoWriteOffsBySourceId {
        nodes {
          id
          rowId
          quantity
          target: productByTargetId {
            name
            unit
          }
        }
      }

      supplyItems: supplyItemsByProductId(orderBy: ID_DESC, first: 20) {
        nodes {
          id
          rowId
          quantity
          price

          warehouse: warehouseByWarehouseId {
            rowId
            name
          }

          document: supplyDocumentByDocumentId {
            rowId
            eventedAt
            comment

            contractor: supplyContractorByContractorId {
              rowId
              name
            }
          }
        }
      }
    }

    allProductGroups {
      nodes {
        id
        rowId
        name
      }
    }
  }
`;
