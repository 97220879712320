import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateShopOrderGroupMutation($input: CreateShopOrderGroupInput!) {
    createShopOrderGroup(input: $input) {
      shopOrderGroup {
        id
        rowId
        courierId
        deadlineAt
        comment

        orders: shopOrderDocumentsByGroupId {
          nodes {
            id
            groupId
          }
        }
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createShopOrderGroup', 'shopOrderGroup');
