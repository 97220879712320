import { graphql } from 'relay-runtime';

export default graphql`
  query WarehousesQuery($first: Int!, $offset: Int!, $orderBy: [WarehousesOrderBy!]) {
    rows: allWarehouses(first: $first, offset: $offset, orderBy: $orderBy) {
      totalCount

      nodes {
        id
        rowId
        name
      }
    }
  }
`;
