/**
 * @flow
 * @relayHash 4495bf9b3716d9cc925d985ea6fc9c2c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WarehouseMovementDocumentsOrderBy = "COMMENT_ASC" | "COMMENT_DESC" | "EVENTED_AT_ASC" | "EVENTED_AT_DESC" | "ID_ASC" | "ID_DESC" | "NATURAL" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "%future added value";
export type WarehouseMovementDocumentsQueryVariables = {|
  first: number,
  offset: number,
  orderBy?: ?$ReadOnlyArray<WarehouseMovementDocumentsOrderBy>,
|};
export type WarehouseMovementDocumentsQueryResponse = {|
  +rows: ?{|
    +totalCount: number,
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +eventedAt: any,
      +comment: ?string,
    |}>,
  |}
|};
export type WarehouseMovementDocumentsQuery = {|
  variables: WarehouseMovementDocumentsQueryVariables,
  response: WarehouseMovementDocumentsQueryResponse,
|};
*/


/*
query WarehouseMovementDocumentsQuery(
  $first: Int!
  $offset: Int!
  $orderBy: [WarehouseMovementDocumentsOrderBy!]
) {
  rows: allWarehouseMovementDocuments(first: $first, offset: $offset, orderBy: $orderBy) {
    totalCount
    nodes {
      id
      rowId
      eventedAt
      comment
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[WarehouseMovementDocumentsOrderBy!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "rows",
    "name": "allWarehouseMovementDocuments",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "offset",
        "variableName": "offset",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy",
        "type": "[WarehouseMovementDocumentsOrderBy!]"
      }
    ],
    "concreteType": "WarehouseMovementDocumentsConnection",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": null,
        "concreteType": "WarehouseMovementDocument",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rowId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "eventedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "comment",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "WarehouseMovementDocumentsQuery",
  "id": null,
  "text": "query WarehouseMovementDocumentsQuery(\n  $first: Int!\n  $offset: Int!\n  $orderBy: [WarehouseMovementDocumentsOrderBy!]\n) {\n  rows: allWarehouseMovementDocuments(first: $first, offset: $offset, orderBy: $orderBy) {\n    totalCount\n    nodes {\n      id\n      rowId\n      eventedAt\n      comment\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "WarehouseMovementDocumentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "WarehouseMovementDocumentsQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f834d9f3278f2432bd83915aa5dbdea2';
module.exports = node;
