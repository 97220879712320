// @flow
/* global $ElementType */

import React from 'react';
import { createRefetchContainer, graphql } from 'react-relay';
import type { RelayProp } from 'react-relay';
import { Button, Table, Icon } from 'antd';
import { get } from 'unchanged';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import differenceInHours from 'date-fns/differenceInHours';
import type { Status, NewState, OrderDir, OrderKey } from './Types';
import type { Documents as DocumentsData } from './__generated__/Documents.graphql';
import MarkMutation from './MarkPackingDocumentMutation';
import statuses from './statuses';
import s from './Documents.css';

type DataRow = $ElementType<DocumentsData, number>;
type RowClassNames = 'green-row' | 'red-row' | 'yellow-row' | 'orange-row';

const statusPositions = {
  PENDING: 1,
  PACKING: 2,
};

const getRowClassName = (mode: OrderKey) => (row: DataRow): ?RowClassNames => {
  if (row.ready) {
    return s['green-row'];
  }

  const deadlineAt = (mode === 'deadlineAt' && row.group && row.group.deadlineAt) || row.deliveryAt || new Date();
  const dist = differenceInHours(parseISO(deadlineAt), new Date());

  if (dist < 1) {
    return s['red-row'];
  }

  if (dist < 2) {
    return s['yellow-row'];
  }

  if (row.status === 'PACKING') {
    return s['orange-row'];
  }

  return undefined;
};

const groupSorter = (a: DataRow, b: DataRow) => {
  const sa = +new Date(get('group.deadlineAt', a) || 9999999999999);
  const sb = +new Date(get('group.deadlineAt', b) || 9999999999999);

  if (sa === sb) {
    const diff = (a.groupId || 0) - (b.groupId || 0);

    if (diff === 0) {
      return (a.groupPosition || 0) - (b.groupPosition || 0);
    }

    return diff;
  }

  return sa - sb;
};

type Props = {
  relay: RelayProp,
  orderDir: OrderDir,
  orderKey: OrderKey,
  statuses: Status[],
  data: DocumentsData,
  day: ?string,
  updateState: NewState => void,
  router: Object,
};

type State = {
  selectedIds: number[],
  index: number,
};

class Documents extends React.Component<Props, State> {
  interval: any = null;

  state = {
    selectedIds: [],
    index: 0,
  };

  componentDidMount() {
    this.interval = setInterval(this.reload, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  reload = () => {
    this.setState(ss => ({ ...ss, index: ss.index + 1 }));

    this.props.relay.refetch({
      filter: {
        deliveryOn: {
          equalTo: format(this.props.day ? parseISO(this.props.day) : new Date(), 'yyyy-MM-dd'),
        },
        status: {
          in: this.props.statuses,
        },
        owner: {
          in: ['myfresh', 'moscowfresh', 'myfresh-yandex', 'moscowfresh-yandex', 'familyfriend', 'myfresh-deliveryclub', 'edoque'],
        },
      },
    });
  };

  handleSelectChange = selectedIds => {
    console.log('update selected', selectedIds);
    this.setState({ selectedIds });
  };

  handleChange = (pagination, filters, sorter) => {
    if (filters && filters.status) {
      this.props.updateState({
        statuses: filters.status,
      });
    }

    if (sorter) {
      this.props.updateState({
        orderKey: sorter.columnKey,
        orderDir: sorter.order,
      });
    }
  };

  toPacking = () => {
    const selectedIds =
      this.state.selectedIds.length > 0 ? this.state.selectedIds : this.props.data.map(o => o && o.rowId);

    if (selectedIds.length === 0) {
      return;
    }

    this.setState({ selectedIds: [] });

    const requests = selectedIds.map(id => {
      const order = this.props.data.find(o => o.rowId === id);

      if (order && order.status === 'PENDING') {
        return MarkMutation.commit(this.props.relay.environment, id);
      }

      return Promise.resolve();
    });

    Promise.all(requests)
      .then(() => {
        this.props.router.push({
          pathname: '/shop/order/documents/print',
          query: {
            listen: 1,
            ids: selectedIds,
          },
        });
      });
  };

  render() {
    const { index } = this.state;

    const dataSource = this.props.data.filter(o => o && this.props.statuses.indexOf(o.status) > -1);
    const rowSelection = {
      selectedRowKeys: this.state.selectedIds,
      onChange: this.handleSelectChange,
    };

    return (
      <div>
        <div styleName="header">
          <Button onClick={this.toPacking}>Отправить на сборку</Button>
        </div>
        <Table
          key={index}
          rowKey="rowId"
          pagination={false}
          dataSource={dataSource}
          onChange={this.handleChange}
          rowSelection={rowSelection}
          rowClassName={getRowClassName(this.props.orderKey)}
          columns={[
            {
              key: 'rowId',
              dataIndex: 'rowId',
              title: '#',
            },
            {
              key: 'label',
              dataIndex: 'label',
              title: 'Метка',
            },
            {
              key: 'status',
              dataIndex: 'status',
              title: 'Статус',
              sorter: (a, b) => statusPositions[a.status] - statusPositions[b.status],
              sortOrder: this.props.orderKey === 'status' && this.props.orderDir,
              filteredValue: this.props.statuses,
              filters: [
                {
                  value: 'PENDING',
                  text: statuses.PENDING,
                },
                {
                  value: 'PACKING',
                  text: statuses.PACKING,
                },
              ],
              render: v => statuses[v],
            },
            {
              key: 'deliveryAt',
              dataIndex: 'deliveryAt',
              title: 'Время доставки',
              sorter: (a, b) => {
                if (a.asap === b.asap) {
                  return +new Date(a.deliveryAt) - +new Date(b.deliveryAt);
                }

                return a.asap ? -1 : 1;
              },
              sortOrder: this.props.orderKey === 'deliveryAt' && this.props.orderDir,
              render: (v, row) => (
                <div>
                  {format(parseISO(v), 'HH:mm')} {row.asap && <Icon type="clock-circle" />}
                </div>
              ),
            },
            {
              key: 'group',
              dataIndex: 'group',
              title: 'Маршрут',
              sorter: groupSorter,
              sortOrder: this.props.orderKey === 'group' && this.props.orderDir,
              render: group => {
                if (!group) {
                  return null;
                }

                return `#${group.rowId} ${format(parseISO(group.deadlineAt), 'HH:mm')}`;
              },
            },
            {
              key: 'comment',
              dataIndex: 'comment',
              title: '',
              width: 100,
              render: (comment, row) => {
                if (comment.includes('Заказ доставляет курьер Яндекс.Еда')) {
                  return <strong>ЯНДЕКС</strong>;
                }

                if (comment.includes('Яндекс.Еда доставляет наша доставка')) {
                  return <strong>ЯНДЕКС НД</strong>;
                }

                if (row.owner.includes('-deliveryclub')) {
                  return <strong>DeliveryClub</strong>;
                }

                return null;
              },
            }
          ]}
        />
      </div>
    );
  }
}

export default createRefetchContainer(
  Documents,
  graphql`
    fragment Documents on ShopOrderDocument @relay(plural: true) {
      id
      rowId
      status
      label
      deliveryAt
      ready
      asap
      comment
      owner

      groupId
      groupPosition

      group: shopOrderGroupByGroupId {
        id
        rowId
        deadlineAt
      }
    }
  `,
  graphql`
    query DocumentsQuery($filter: ShopOrderDocumentFilter!) {
      allShopOrderDocuments(filter: $filter) {
        nodes {
          ...Documents
        }
      }
    }
  `
);
