exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-___JSONDetailModal__table___d-yRy {\n  width: 100%;\n}\n\n.src-components-___JSONDetailModal__table___d-yRy td {\n  padding: 3px;\n  border-bottom: 1px solid #ccc;\n}\n", ""]);

// exports
exports.locals = {
	"table": "src-components-___JSONDetailModal__table___d-yRy"
};