/**
 * @flow
 * @relayHash 025f3e3284736e2e132f3ee1a3e65cb9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateScaleProductInput = {
  clientMutationId?: ?string,
  id: string,
  scaleProductPatch: ScaleProductPatch,
};
export type ScaleProductPatch = {
  rowId?: ?number,
  scaleId?: ?number,
  productId?: ?number,
  firstLine?: ?string,
  lastLine?: ?string,
  position?: ?number,
};
export type UpdateScaleProductMutationVariables = {|
  input: UpdateScaleProductInput
|};
export type UpdateScaleProductMutationResponse = {|
  +updateScaleProduct: ?{|
    +scaleProduct: ?{|
      +id: string,
      +rowId: number,
      +firstLine: ?string,
      +lastLine: ?string,
      +position: number,
    |}
  |}
|};
export type UpdateScaleProductMutation = {|
  variables: UpdateScaleProductMutationVariables,
  response: UpdateScaleProductMutationResponse,
|};
*/


/*
mutation UpdateScaleProductMutation(
  $input: UpdateScaleProductInput!
) {
  updateScaleProduct(input: $input) {
    scaleProduct {
      id
      rowId
      firstLine
      lastLine
      position
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateScaleProductInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateScaleProduct",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateScaleProductInput!"
      }
    ],
    "concreteType": "UpdateScaleProductPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "scaleProduct",
        "storageKey": null,
        "args": null,
        "concreteType": "ScaleProduct",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rowId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstLine",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastLine",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "position",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateScaleProductMutation",
  "id": null,
  "text": "mutation UpdateScaleProductMutation(\n  $input: UpdateScaleProductInput!\n) {\n  updateScaleProduct(input: $input) {\n    scaleProduct {\n      id\n      rowId\n      firstLine\n      lastLine\n      position\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateScaleProductMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateScaleProductMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '29b004c7531f3f6e0c61a823b2983382';
module.exports = node;
