/**
 * @flow
 * @relayHash 061b8f8b7d54411c7546ff3532d12953
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CouriersOrderBy = "ACTIVE_GROUP_ID_ASC" | "ACTIVE_GROUP_ID_DESC" | "BASE_PAYOFF_ASC" | "BASE_PAYOFF_DESC" | "EXTRA_PAYOFF_ASC" | "EXTRA_PAYOFF_DESC" | "FIRED_ASC" | "FIRED_DESC" | "FIRST_NAME_ASC" | "FIRST_NAME_DESC" | "HAS_DRIVERS_LICENSE_ASC" | "HAS_DRIVERS_LICENSE_DESC" | "HIDE_CONTACTS_FROM_CLIENTS_ASC" | "HIDE_CONTACTS_FROM_CLIENTS_DESC" | "ID_ASC" | "ID_DESC" | "LAST_NAME_ASC" | "LAST_NAME_DESC" | "LAST_POSITIONED_AT_ASC" | "LAST_POSITIONED_AT_DESC" | "LAST_POSITION_ASC" | "LAST_POSITION_DESC" | "MIDDLE_NAME_ASC" | "MIDDLE_NAME_DESC" | "MOBILE_CASHIER_ID_ASC" | "MOBILE_CASHIER_ID_DESC" | "NATURAL" | "PAYMENT_TERMINAL_ID_ASC" | "PAYMENT_TERMINAL_ID_DESC" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "PRIVATE_CAR_ASC" | "PRIVATE_CAR_DESC" | "TELEGRAM_CHAT_ID_ASC" | "TELEGRAM_CHAT_ID_DESC" | "TELEGRAM_TOKEN_ASC" | "TELEGRAM_TOKEN_DESC" | "WORK_PHONE_ASC" | "WORK_PHONE_DESC" | "%future added value";
export type CourierFilter = {
  rowId?: ?IntFilter,
  firstName?: ?StringFilter,
  middleName?: ?StringFilter,
  lastName?: ?StringFilter,
  hasDriversLicense?: ?BooleanFilter,
  workPhone?: ?StringFilter,
  privateCar?: ?StringFilter,
  telegramToken?: ?StringFilter,
  telegramChatId?: ?BigIntFilter,
  lastPositionedAt?: ?DatetimeFilter,
  hideContactsFromClients?: ?BooleanFilter,
  paymentTerminalId?: ?StringFilter,
  activeGroupId?: ?IntFilter,
  fired?: ?BooleanFilter,
  basePayoff?: ?BigFloatFilter,
  extraPayoff?: ?BigFloatFilter,
  mobileCashierId?: ?StringFilter,
  and?: ?$ReadOnlyArray<CourierFilter>,
  or?: ?$ReadOnlyArray<CourierFilter>,
  not?: ?CourierFilter,
};
export type IntFilter = {
  isNull?: ?boolean,
  equalTo?: ?number,
  notEqualTo?: ?number,
  distinctFrom?: ?number,
  notDistinctFrom?: ?number,
  in?: ?$ReadOnlyArray<number>,
  notIn?: ?$ReadOnlyArray<number>,
  lessThan?: ?number,
  lessThanOrEqualTo?: ?number,
  greaterThan?: ?number,
  greaterThanOrEqualTo?: ?number,
};
export type StringFilter = {
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  equalToInsensitive?: ?string,
  notEqualToInsensitive?: ?string,
  distinctFromInsensitive?: ?string,
  notDistinctFromInsensitive?: ?string,
  inInsensitive?: ?$ReadOnlyArray<string>,
  notInInsensitive?: ?$ReadOnlyArray<string>,
  lessThanInsensitive?: ?string,
  lessThanOrEqualToInsensitive?: ?string,
  greaterThanInsensitive?: ?string,
  greaterThanOrEqualToInsensitive?: ?string,
};
export type BooleanFilter = {
  isNull?: ?boolean,
  equalTo?: ?boolean,
  notEqualTo?: ?boolean,
  distinctFrom?: ?boolean,
  notDistinctFrom?: ?boolean,
  in?: ?$ReadOnlyArray<boolean>,
  notIn?: ?$ReadOnlyArray<boolean>,
  lessThan?: ?boolean,
  lessThanOrEqualTo?: ?boolean,
  greaterThan?: ?boolean,
  greaterThanOrEqualTo?: ?boolean,
};
export type BigIntFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type DatetimeFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type BigFloatFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type CouriersQueryVariables = {|
  first: number,
  offset: number,
  orderBy?: ?$ReadOnlyArray<CouriersOrderBy>,
  filter?: ?CourierFilter,
|};
export type CouriersQueryResponse = {|
  +rows: ?{|
    +totalCount: number,
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +firstName: string,
      +lastName: ?string,
      +middleName: ?string,
      +workPhone: ?string,
      +hasDriversLicense: ?boolean,
      +telegramToken: ?string,
      +telegramChatId: ?any,
      +privateCar: ?string,
    |}>,
  |}
|};
export type CouriersQuery = {|
  variables: CouriersQueryVariables,
  response: CouriersQueryResponse,
|};
*/


/*
query CouriersQuery(
  $first: Int!
  $offset: Int!
  $orderBy: [CouriersOrderBy!]
  $filter: CourierFilter
) {
  rows: allCouriers(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
    totalCount
    nodes {
      id
      rowId
      firstName
      lastName
      middleName
      workPhone
      hasDriversLicense
      telegramToken
      telegramChatId
      privateCar
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[CouriersOrderBy!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "CourierFilter",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "rows",
    "name": "allCouriers",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter",
        "type": "CourierFilter"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "offset",
        "variableName": "offset",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy",
        "type": "[CouriersOrderBy!]"
      }
    ],
    "concreteType": "CouriersConnection",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": null,
        "concreteType": "Courier",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rowId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "middleName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "workPhone",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "hasDriversLicense",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "telegramToken",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "telegramChatId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "privateCar",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "CouriersQuery",
  "id": null,
  "text": "query CouriersQuery(\n  $first: Int!\n  $offset: Int!\n  $orderBy: [CouriersOrderBy!]\n  $filter: CourierFilter\n) {\n  rows: allCouriers(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {\n    totalCount\n    nodes {\n      id\n      rowId\n      firstName\n      lastName\n      middleName\n      workPhone\n      hasDriversLicense\n      telegramToken\n      telegramChatId\n      privateCar\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CouriersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "CouriersQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6c9de8ccaa40e1d3e13f69637ec896ec';
module.exports = node;
