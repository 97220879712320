import React from 'react';
import PropTypes from 'prop-types';
import { Select, DatePicker, Form, Input, Button } from 'antd';
import PageForm from 'components/PageForm';
import SupplyContractorSelect from 'components/SupplyContractorSelect';
import EmployeeSelect from 'components/EmployeeSelect';

const SupplyDocumentForm = ({
  title,
  form,
  handleSubmit,
  allSupplyContractors,
  allOrganizations,
  viewer,
  allEmployees,
  submitting,
  data,
}) => (
  <PageForm onSubmit={handleSubmit} title={title}>
    <Form.Item label="Поставщик">
      {form.getFieldDecorator('contractorId', {
        rules: [
          {
            required: true,
            message: 'Укажите поставщика',
          },
        ],
      })(
        <SupplyContractorSelect
          items={allSupplyContractors.nodes}
          showSearch
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        />
      )}
    </Form.Item>
    <Form.Item label="Дата поставки">
      {form.getFieldDecorator('eventedAt')(<DatePicker showTime={{ format: 'HH:mm' }} format="DD.MM.YYYY HH:mm" />)}
    </Form.Item>
    <Form.Item label="Принял">
      {form.getFieldDecorator('ownerId', { initialValue: !data && viewer ? viewer.rowId : null })(
        <EmployeeSelect
          items={allEmployees.nodes}
          showSearch
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        />
      )}
    </Form.Item>
    <Form.Item label="Организация">
      {form.getFieldDecorator('organizationId')(
        <Select>
          {allOrganizations.nodes.map(o => (
            <Select.Option value={o.rowId} key={o.rowId}>
              {o.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </Form.Item>
    <Form.Item label="Комментарий">{form.getFieldDecorator('comment')(<Input.TextArea rows={4} />)}</Form.Item>
    <Form.Item>
      <Button disabled={submitting} type="primary" htmlType="submit">
        Сохранить
      </Button>
    </Form.Item>
  </PageForm>
);

SupplyDocumentForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  allSupplyContractors: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
  allOrganizations: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
  allEmployees: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
  data: PropTypes.object,
  viewer: PropTypes.shape({
    rowId: PropTypes.number.isRequired,
  }),
  submitting: PropTypes.bool.isRequired,
};

SupplyDocumentForm.defaultProps = {
  viewer: null,
  data: null,
};

export default SupplyDocumentForm;
