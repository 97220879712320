/**
 * @flow
 * @relayHash c78b51ce6e11d3864262784ab50e128d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateCourierInput = {
  clientMutationId?: ?string,
  id: string,
  courierPatch: CourierPatch,
};
export type CourierPatch = {
  rowId?: ?number,
  firstName?: ?string,
  middleName?: ?string,
  lastName?: ?string,
  hasDriversLicense?: ?boolean,
  workPhone?: ?string,
  privateCar?: ?string,
  telegramToken?: ?string,
  telegramChatId?: ?any,
  lastPosition?: ?PointInput,
  lastPositionedAt?: ?any,
  hideContactsFromClients?: ?boolean,
  paymentTerminalId?: ?string,
  activeGroupId?: ?number,
  fired?: ?boolean,
  basePayoff?: ?any,
  extraPayoff?: ?any,
  mobileCashierId?: ?string,
};
export type PointInput = {
  x: number,
  y: number,
};
export type UpdateCourierMutationVariables = {|
  input: UpdateCourierInput
|};
export type UpdateCourierMutationResponse = {|
  +updateCourier: ?{|
    +courier: ?{|
      +rowId: number
    |}
  |}
|};
export type UpdateCourierMutation = {|
  variables: UpdateCourierMutationVariables,
  response: UpdateCourierMutationResponse,
|};
*/


/*
mutation UpdateCourierMutation(
  $input: UpdateCourierInput!
) {
  updateCourier(input: $input) {
    courier {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateCourierInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateCourierInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateCourierMutation",
  "id": null,
  "text": "mutation UpdateCourierMutation(\n  $input: UpdateCourierInput!\n) {\n  updateCourier(input: $input) {\n    courier {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateCourierMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateCourier",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateCourierPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "courier",
            "storageKey": null,
            "args": null,
            "concreteType": "Courier",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateCourierMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateCourier",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateCourierPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "courier",
            "storageKey": null,
            "args": null,
            "concreteType": "Courier",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '53d20389d6bda9a481f238215f891a69';
module.exports = node;
