/**
 * @flow
 * @relayHash fc57b6fbe584b3f5baac665b76484a60
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateWarehouseMovementDocumentInput = {
  clientMutationId?: ?string,
  warehouseMovementDocument: WarehouseMovementDocumentInput,
};
export type WarehouseMovementDocumentInput = {
  rowId?: ?number,
  eventedAt?: ?any,
  comment?: ?string,
};
export type CreateWarehouseMovementDocumentMutationVariables = {|
  input: CreateWarehouseMovementDocumentInput
|};
export type CreateWarehouseMovementDocumentMutationResponse = {|
  +createWarehouseMovementDocument: ?{|
    +warehouseMovementDocument: ?{|
      +rowId: number
    |}
  |}
|};
export type CreateWarehouseMovementDocumentMutation = {|
  variables: CreateWarehouseMovementDocumentMutationVariables,
  response: CreateWarehouseMovementDocumentMutationResponse,
|};
*/


/*
mutation CreateWarehouseMovementDocumentMutation(
  $input: CreateWarehouseMovementDocumentInput!
) {
  createWarehouseMovementDocument(input: $input) {
    warehouseMovementDocument {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateWarehouseMovementDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateWarehouseMovementDocumentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateWarehouseMovementDocumentMutation",
  "id": null,
  "text": "mutation CreateWarehouseMovementDocumentMutation(\n  $input: CreateWarehouseMovementDocumentInput!\n) {\n  createWarehouseMovementDocument(input: $input) {\n    warehouseMovementDocument {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateWarehouseMovementDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createWarehouseMovementDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateWarehouseMovementDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "warehouseMovementDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "WarehouseMovementDocument",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateWarehouseMovementDocumentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createWarehouseMovementDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateWarehouseMovementDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "warehouseMovementDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "WarehouseMovementDocument",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8630769950ba8f9a7b98243f3cf5f372';
module.exports = node;
