import { getRoutes } from 'utils/crud';

import Index from './index';

import IndexQuery from './ShopOrderDocumentsQuery';
import prepareVariables from './prepareVariables';

import Edit from './Edit';
import EditQuery from './Edit/EditShopOrderDocumentQuery';

import Show from './Show';
import ShowQuery from './Show/ShowShopOrderDocumentQuery';
import showPrepareVariables from './Show/prepareVariables';

import Print from './Print';
import PrintQuery from './Print/PrintShopOrderDocumentsQuery';
import printPrepareVariables from './Print/prepareVariables';

import MapPage from './Map';
import MapQuery from './Map/MapShopOrderDocumentsQuery';
import mapPrepareVariables from './Map/prepareVariables';

import Deficit from './Deficit';
import DeficitQuery from './Deficit/DeficitShopOrderDocumentQuery';
import deficitPrepareVariables from './Deficit/prepareVariables';

import CourierPrint from './CourierPrint';
import CourierPrintShopOrderDocumentQuery from './CourierPrint/CourierPrintShopOrderDocumentQuery';
import courierPrintPrepareVariables from './CourierPrint/prepareVariables';

import CourierMap from './CourierMap/CourierMap';
import CourierMapShopOrderDocumentQuery from './CourierMap/CourierMapQuery';
import courierMapPrepareVariables from './CourierMap/prepareVariables';

export default [
  {
    Component: Deficit,
    path: '/shop/order/documents/deficit',
    query: DeficitQuery,
    prepareVariables: deficitPrepareVariables,
  },
  {
    Component: CourierMap,
    path: '/shop/order/documents/courierMap',
    query: CourierMapShopOrderDocumentQuery,
    prepareVariables: courierMapPrepareVariables,
  },
  {
    Component: CourierPrint,
    path: '/shop/order/documents/courier',
    query: CourierPrintShopOrderDocumentQuery,
    prepareVariables: courierPrintPrepareVariables,
  },
  {
    Component: Print,
    path: '/shop/order/documents/print',
    query: PrintQuery,
    prepareVariables: printPrepareVariables,
  },
  {
    Component: MapPage,
    query: MapQuery,
    prepareVariables: mapPrepareVariables,
    path: '/shop/order/documents/map',
  },
  {
    Component: Show,
    query: ShowQuery,
    prepareVariables: showPrepareVariables,
    path: '/shop/order/documents/:rowId',
  },
  ...getRoutes({
    baseUrl: '/shop/order/documents',
    Index,
    IndexQuery,
    Edit,
    EditQuery,
    indexPrepareVariables: prepareVariables,
  }),
];
