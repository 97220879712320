exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-ShopOrderDocuments-Map-___Map__root___3lRRQ {\n  display: flex;\n}\n\n.src-pages-ShopOrderDocuments-Map-___Map__sidebar___1zuZU {\n  width: 650px;\n}\n\n.src-pages-ShopOrderDocuments-Map-___Map__map___2XgrV {\n  flex: 1;\n  height: calc(100vh - 52px);\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-pages-ShopOrderDocuments-Map-___Map__root___3lRRQ",
	"sidebar": "src-pages-ShopOrderDocuments-Map-___Map__sidebar___1zuZU",
	"map": "src-pages-ShopOrderDocuments-Map-___Map__map___2XgrV"
};