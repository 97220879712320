import { compose, withProps } from 'recompact';
import withCreateEnhance from 'hoc/withCreateEnhance';
import mutation from 'mutations/CreateCourierMutation';

export default compose(
  withProps({
    title: 'Создать нового курьера',
  }),
  withCreateEnhance({
    normalize: values => ({
      ...values,
      telegramChatId: parseInt(values.telegramChatId, 10),
      basePayoff: parseFloat(values.basePayoff || '2500'),
      extraPayoff: parseFloat(values.extraPayoff || '150'),
    }),
    mutation,
  })
);
