/**
 * @flow
 * @relayHash 8df1ca34dd45e0179a9b4d39fe9f8dda
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupplyContractorStatus = "ARCHIVE" | "DANGEROUS" | "FRIENDS" | "UNSET" | "%future added value";
export type UpdateSupplyContractorInput = {
  clientMutationId?: ?string,
  id: string,
  supplyContractorPatch: SupplyContractorPatch,
};
export type SupplyContractorPatch = {
  rowId?: ?number,
  name?: ?string,
  status?: ?SupplyContractorStatus,
  description?: ?string,
  supplyTotalSum?: ?any,
  ownerId?: ?number,
};
export type UpdateSupplyContractorMutationVariables = {|
  input: UpdateSupplyContractorInput
|};
export type UpdateSupplyContractorMutationResponse = {|
  +updateSupplyContractor: ?{|
    +supplyContractor: ?{|
      +rowId: number
    |}
  |}
|};
export type UpdateSupplyContractorMutation = {|
  variables: UpdateSupplyContractorMutationVariables,
  response: UpdateSupplyContractorMutationResponse,
|};
*/


/*
mutation UpdateSupplyContractorMutation(
  $input: UpdateSupplyContractorInput!
) {
  updateSupplyContractor(input: $input) {
    supplyContractor {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateSupplyContractorInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateSupplyContractorInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateSupplyContractorMutation",
  "id": null,
  "text": "mutation UpdateSupplyContractorMutation(\n  $input: UpdateSupplyContractorInput!\n) {\n  updateSupplyContractor(input: $input) {\n    supplyContractor {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateSupplyContractorMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSupplyContractor",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateSupplyContractorPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyContractor",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyContractor",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateSupplyContractorMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSupplyContractor",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateSupplyContractorPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyContractor",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyContractor",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4ee0b0c5e1082a76e423827a8f2eab74';
module.exports = node;
