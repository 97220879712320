import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroySupplyDocumentMutation($input: DeleteSupplyDocumentInput!) {
    deleteSupplyDocument(input: $input) {
      deletedSupplyDocumentId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteSupplyDocument', 'deletedSupplyDocumentId');
