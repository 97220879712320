/**
 * @flow
 * @relayHash db2cc24bac9c68871ce69c483d69ada2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditShopDepositReplenishmentQueryVariables = {|
  rowId: number
|};
export type EditShopDepositReplenishmentQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +createdAt: any,
    +userOwner: string,
    +userId: string,
    +sellerId: ?number,
    +value: any,
    +comment: ?string,
  |},
  +allEmployees: ?{|
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +firstName: string,
      +lastName: string,
    |}>
  |},
|};
export type EditShopDepositReplenishmentQuery = {|
  variables: EditShopDepositReplenishmentQueryVariables,
  response: EditShopDepositReplenishmentQueryResponse,
|};
*/


/*
query EditShopDepositReplenishmentQuery(
  $rowId: Int!
) {
  data: shopDepositReplenishmentByRowId(rowId: $rowId) {
    id
    rowId
    createdAt
    userOwner
    userId
    sellerId
    value
    comment
  }
  allEmployees {
    nodes {
      id
      rowId
      firstName
      lastName
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": "data",
    "name": "shopDepositReplenishmentByRowId",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "rowId",
        "variableName": "rowId",
        "type": "Int!"
      }
    ],
    "concreteType": "ShopDepositReplenishment",
    "plural": false,
    "selections": [
      v1,
      v2,
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "createdAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "userOwner",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "userId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "sellerId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "value",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "comment",
        "args": null,
        "storageKey": null
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "allEmployees",
    "storageKey": null,
    "args": null,
    "concreteType": "EmployeesConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": null,
        "concreteType": "Employee",
        "plural": true,
        "selections": [
          v1,
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditShopDepositReplenishmentQuery",
  "id": null,
  "text": "query EditShopDepositReplenishmentQuery(\n  $rowId: Int!\n) {\n  data: shopDepositReplenishmentByRowId(rowId: $rowId) {\n    id\n    rowId\n    createdAt\n    userOwner\n    userId\n    sellerId\n    value\n    comment\n  }\n  allEmployees {\n    nodes {\n      id\n      rowId\n      firstName\n      lastName\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditShopDepositReplenishmentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v3
  },
  "operation": {
    "kind": "Operation",
    "name": "EditShopDepositReplenishmentQuery",
    "argumentDefinitions": v0,
    "selections": v3
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bdcc28c161422ff22d8262c4e4e311f7';
module.exports = node;
