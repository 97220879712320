import { graphql } from 'relay-runtime';

export default graphql`
  query SupplyContractorsQuery($first: Int!, $offset: Int!, $orderBy: [SupplyContractorsOrderBy!]) {
    rows: allSupplyContractors(first: $first, offset: $offset, orderBy: $orderBy) {
      totalCount

      nodes {
        id
        rowId
        name
        description
        status
        supplyTotalSum

        owner: employeeByOwnerId {
          firstName
          lastName
        }
      }
    }
  }
`;
