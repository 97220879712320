import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import CollectionSelect from 'components/CollectionSelect';
import InlineForm from 'components/InlineForm';
import ProductSelect from 'containers/ProductSelect';
import CurrentQuantity from 'containers/CurrentQuantity';
import QuantityInput from 'components/QuantityInput';
import './NewItem.css';

const NewItem = ({ form, context, allWarehouses, handleSubmit, defaultWarehouseId, saveRef }) => (
  <div styleName="root">
    <h3 style={{ marginTop: 50 }}>Добавить позицию</h3>
    <InlineForm onSubmit={handleSubmit}>
      <Form.Item label="Склад">
        {form.getFieldDecorator('warehouseId', { initialValue: defaultWarehouseId })(
          <CollectionSelect items={allWarehouses} />
        )}
      </Form.Item>
      <Form.Item label="Позиция">
        {form.getFieldDecorator('productId')(
          <ProductSelect environment={context.environment} showAction={['click', 'focus']} autoFocus ref={saveRef} />
        )}
      </Form.Item>
      <Form.Item label="Количество">
        {form.getFieldDecorator('quantity')(<QuantityInput />)}
        <CurrentQuantity
          environment={context.environment}
          variables={{ productId: form.getFieldValue('productId'), warehouseId: form.getFieldValue('warehouseId') }}
        />
      </Form.Item>
    </InlineForm>
  </div>
);

NewItem.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
  context: PropTypes.shape({
    environment: PropTypes.object.isRequired,
  }).isRequired,
  allWarehouses: PropTypes.array.isRequired,
  defaultWarehouseId: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
  saveRef: PropTypes.func.isRequired,
};

NewItem.defaultProps = {
  defaultWarehouseId: null,
};

export default NewItem;
