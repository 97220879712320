import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import withFiltersEnhance from 'hoc/withFiltersEnhance';
import FilterForm from 'components/FilterForm';
import { FilterCheckbox, FilterSelect } from 'components/filterControls';
import { getSelectItems } from 'components/helpers';
import statuses from '../statuses';

const Filters = ({ form, handleSubmit, handleClear }) => (
  <FilterForm onSubmit={handleSubmit} onClear={handleClear}>
    <Form.Item label="Статус">
      {form.getFieldDecorator('status')(
        <FilterSelect filterType="in" mode="multiple">
          {getSelectItems(statuses)}
        </FilterSelect>
      )}
    </Form.Item>
    <Form.Item label="Пробит">
      {form.getFieldDecorator('ready', { valuePropName: 'checked' })(
        <FilterCheckbox filterType="equalTo" valuePropName="checked" />
      )}
    </Form.Item>
  </FilterForm>
);

Filters.propTypes = {
  form: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClear: PropTypes.func,
};

Filters.defaultProps = {
  handleClear: null,
};

export default withFiltersEnhance([])(Filters);
