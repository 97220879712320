import { graphql } from 'relay-runtime';
import { makeCopyMutation } from './helpers';

const mutation = graphql`
  mutation CopySupplyDocumentMutation($input: CopySupplyDocumentInput!) {
    copySupplyDocument(input: $input) {
      supplyDocument {
        rowId
      }
    }
  }
`;

export default makeCopyMutation(mutation, 'copySupplyDocument', 'supplyDocument');
