import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateCourierMutation($input: UpdateCourierInput!) {
    updateCourier(input: $input) {
      courier {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateCourier', 'courier');
