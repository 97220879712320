import React from 'react';
import PropTypes from 'prop-types';
import { graphql, createFragmentContainer } from 'react-relay';
import { Table } from 'antd';
import { Link } from 'found';
import moment from 'moment';
import { getRelationLink } from 'components/helpers';
import { formatPrice, formatQuantity } from 'utils/format';
import Quantities from './Quantities';

const LastSupplyItems = ({ data, relay }) => (
  <div>
    <Table
      dataSource={data}
      rowKey="id"
      pagination={false}
      columns={[
        {
          key: 'product',
          dataIndex: 'product',
          title: 'Позиция',
          render: getRelationLink({ path: '/products' }),
        },
        {
          key: 'document',
          dataIndex: 'document',
          title: 'Поставка',
          render: doc => <Link to={`/supply/documents/${doc.rowId}`}>{moment(doc.eventedAt).format('DD MMM YYYY')}</Link>,
        },
        {
          key: 'quantity',
          dataIndex: 'quantity',
          title: 'Количество',
          render: (q, row) => formatQuantity(q, row.product.unit),
        },
        {
          key: 'price',
          dataIndex: 'price',
          title: 'Цена',
          render: v => formatPrice(v),
        },
        {
          key: 'warehouseId',
          title: 'Текущий остаток',
          render: row => {
            const quantity = row.product.quantities.nodes.find(q => q.warehouseId === row.warehouseId);

            if (!quantity) {
              return '-';
            }

            return `${row.warehouse.name}: ${formatQuantity(quantity.value, row.product.unit)}`;
          },
        },
      ]}
    />
    <h3 style={{ marginTop: 50, pageBreakBefore: 'always' }}>Остатки по позициям этого поставщика</h3>
    <Quantities ids={data.map(i => i.product.rowId)} environment={relay.environment} />
  </div>
);

LastSupplyItems.propTypes = {
  data: PropTypes.array.isRequired,
};

export default createFragmentContainer(
  LastSupplyItems,
  graphql`
    fragment LastSupplyItemsSupplyContractorsShow on SupplyItem @relay(plural: true) {
      id
      quantity
      price
      warehouseId

      warehouse: warehouseByWarehouseId {
        name
      }

      document: supplyDocumentByDocumentId {
        rowId
        eventedAt
      }

      product: productByProductId {
        rowId
        name
        unit

        quantities: productWarehouseQuantitiesByProductId {
          nodes {
            warehouseId
            value
          }
        }
      }
    }
  `
);
