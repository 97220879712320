import { graphql } from 'relay-runtime';

export default graphql`
  query ProductCategoriesQuery($first: Int!, $offset: Int!, $orderBy: [ProductCategoriesOrderBy!]) {
    rows: allProductCategories(first: $first, offset: $offset, orderBy: $orderBy) {
      totalCount

      nodes {
        id
        rowId
        name
        position
      }
    }
  }
`;
