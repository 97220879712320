// @flow
/* global $NonMaybeType, $ElementType */

import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import AttributeTable from 'components/AttributeTable';
import { graphql, commitMutation } from 'relay-runtime';
import { getShowActions } from 'utils/crud';
import { Select, Row, Col, notification } from 'antd';
import DataTable from 'components/DataTable';
import StatusTag from 'components/StatusTag';
import moment from 'moment';

import {
  renderPercentDiscount,
  getRelationLink,
  renderQuantity,
  renderPrice,
  renderEnum,
  renderIcon,
} from 'components/helpers';

import updateItemMutation from 'mutations/UpdateShopOrderItemMutation';

import type { PageProps } from 'types';
import type { ShowShopOrderDocumentQueryResponse } from './__generated__/ShowShopOrderDocumentQuery.graphql';

import paymentMethods from '../paymentMethods';
import returnStatuses from './returnStatuses';
import renderStatus from '../renderStatus';
import MkbPayments from './MkbPayments';
import DepositTransactions from './DepositTransactions';
import ItemCommentInput from './ItemCommentInput';
import EditQuantity from './EditQuantity';

type Props = PageProps & {
  data: $NonMaybeType<$ElementType<ShowShopOrderDocumentQueryResponse, 'data'>>,
};

const attributeRows = [
  {
    dataKey: 'warehouseByMainWarehouseId',
    label: 'Склад',
    render: w => w ? w.name : 'Бережковская',
  },
  {
    dataKey: 'deliveryAt',
    label: 'Время доставки',
    render: v =>
      moment(v)
        .tz('Europe/Moscow')
        .format('DD MMM YYYY HH:mm'),
  },
  {
    dataKey: 'status',
    label: 'Статус',
    render: renderStatus,
  },
  {
    dataKey: 'paymentMethod',
    label: 'Метод оплаты',
    render: renderEnum(paymentMethods),
  },
  {
    dataKey: 'itemSum',
    label: 'Сумма позиций',
    render: renderPrice,
  },
  {
    dataKey: 'discount',
    label: 'Скидка',
    render: renderPrice,
  },
  {
    dataKey: 'totalSumMultiplier',
    label: '% скидка',
    render: renderPercentDiscount,
  },
  {
    dataKey: 'deliveryCost',
    label: 'Доставка',
    render: renderPrice,
  },
  {
    dataKey: 'totalSum',
    label: 'Итого',
    render: renderPrice,
  },
  {
    dataKey: 'confirmedAmount',
    label: 'Подтвержденная оплата',
    render: (v, row) => {
      const totalSum = row.itemSum === 0 ? row.expectedTotalSum : row.totalSum;

      if (v > totalSum) {
        return <StatusTag value="success">{renderPrice(v)}</StatusTag>;
      }

      return renderPrice(v);
    },
  },
  {
    dataKey: 'expectedTotalSum',
    label: 'Предварительная сумма',
    render: renderPrice,
  },
  {
    dataKey: 'totalWeight',
    label: 'Вес',
    render: v => (v ? `${parseFloat(v).toFixed(2)} кг` : '–'),
  },
  {
    dataKey: 'comment',
    label: 'Коментарий',
  },
  {
    dataKey: 'label',
    label: 'Метка',
  },
  {
    dataKey: 'group.courier',
    label: 'Курьер',
    render: courier => courier && (
      <span>
        <a href={`/couriers/${courier.rowId}`}>{courier.lastName} {courier.firstName}</a>{' '}{courier.privateCar}
      </span>
    ),
  },
  {
    dataKey: 'deliveryPoint',
    label: 'Координаты',
    render: p => {
      if (!p) {
        return '???';
      }

      return `${p.x}, ${p.y}`;
    },
  },
];

const rollbackProductionOrderMutation = graphql`
  mutation ShowShopOrderDocumentsRollbackProductionOrderMutation($input: RollbackProductionOrderInput!) {
    rollbackProductionOrder(input: $input) {
      clientMutationId
    }
  }
`;

const getActions = props => ({
  torg12: props.data.owner !== 'production' && {
    type: 'button',
    icon: 'file-text',
    children: 'Экспортировать в MС',
    onClick: () => {
      const options = {
        method: 'POST',
        body: JSON.stringify(props.data),
      };

      fetch('https://functions.yandexcloud.net/d4e8ng35lqj1497oi0h3', options)
        .then(res => res.text())
        .then(text => {
          if (text === 'ok') {
            notification.error({
              message: 'Что-то пошло не так',
              description: 'Скорее всего отгрузка уже экспортирована',
            });
          } else {
            const data = JSON.parse(text);
            // eslint-disable-next-line no-restricted-globals
            location.href = data.meta.uuidHref;
          }
        });
    },
  },
  torg12sd: props.data.owner !== 'production' && {
    type: 'button',
    icon: 'file-text',
    children: 'Экспортировать в MС (СД)',
    onClick: () => {
      const options = {
        method: 'POST',
        body: JSON.stringify(props.data),
      };

      fetch('https://functions.yandexcloud.net/d4epc22shkeg4gq25se6', options)
        .then(res => res.text())
        .then(text => {
          if (text === 'ok') {
            notification.error({
              message: 'Что-то пошло не так',
              description: 'Скорее всего отгрузка уже экспортирована',
            });
          } else {
            const data = JSON.parse(text);
            // eslint-disable-next-line no-restricted-globals
            location.href = data.meta.uuidHref;
          }
        });
    },
  },
  rollbackProductionOrder: props.data.owner === 'production' && {
    type: 'button',
    icon: 'rollback',
    children: 'Откатить',
    onClick: () => {
      commitMutation(props.context.environment, {
        mutation: rollbackProductionOrderMutation,
        variables: {
          input: { documentId: props.data.rowId },
        },
        onCompleted: () => {
          notification.success({
            message: 'Выполнено',
            description: 'Заказ откачен',
          });
        },
      });
    },
  },
  printCourier: {
    type: 'a',
    href: `/shop/order/documents/courier?ids=${props.data.rowId}`,
    target: '_blank',
    icon: 'printer',
    children: 'Лист водителя',
  },
  print: {
    type: 'a',
    href: `/shop/order/documents/print?ids=${props.data.rowId}`,
    target: '_blank',
    icon: 'printer',
    children: 'Лист сборки',
  },
  sourceUrl: props.data.sourceUrl && {
    type: 'a',
    href: props.data.sourceUrl.replace('adm.', ''),
    target: '_blank',
    icon: 'rocket',
    children: 'Открыть на витрине',
  },
  ...getShowActions(props),
});

const Show = (props: Props) => (
  <Page title={`Заказ с витрины #${props.data.rowId}`} actions={getActions(props)}>
    <Row gutter={32}>
      <Col span={8}>
        <AttributeTable data={props.data} rows={attributeRows} />
      </Col>
      <Col span={16}>
        <MkbPayments {...props} order={props.data} />
        <DepositTransactions {...props} order={props.data} />
      </Col>
    </Row>
    <h3 style={{ marginTop: 50 }}>Позиции</h3>
    <DataTable
      {...props}
      rowKey="id"
      onlyTable
      rows={props.data.items}
      actions={[]}
      columns={{
        rowId: {
          title: '№',
          width: 60,
          render: (_, row, index) => {
            if (props.location.query.sortColumn === 'rowId' && props.location.query.sortOrder === 'descend') {
              return `${props.data.items.nodes.length - index}.`;
            }

            return `${index + 1}.`;
          },
          sorter: false,
        },
        product: {
          title: 'Продукт',
          render: getRelationLink({ path: '/products' }),
          sorter: false,
        },
        printed: {
          title: 'Чек',
          render: renderIcon({ type: 'check-circle', theme: 'outlined' }),
          align: 'center',
          width: 70,
          sorter: false,
        },
        deficit: {
          title: 'Дефицит',
          render: renderIcon({ type: 'stop', theme: 'twoTone', twoToneColor: 'red' }),
          align: 'center',
          width: 120,
          sorter: false,
        },
        orderedQuantity: {
          title: 'Заказано',
          render: (v, row) => renderQuantity(v, row.product),
          sorter: false,
        },
        quantity: {
          title: 'Пробито',
          sorter: false,
          width: 100,
          render: (v, row) => {
            if (props.location.query.fix !== undefined) {
              return <EditQuantity data={row} context={props.context} />
            }

            return renderQuantity(v, row.product);
          },
        },
        price: {
          title: 'Цена',
          render: renderPrice,
          sorter: false,
        },
        diff: {
          title: 'Разница',
          sorter: false,
          render: (_, row) =>
            renderPrice(
              row.quantity &&
                row.price * row.priceMultiplier * row.quantity - row.price * row.priceMultiplier * row.orderedQuantity
            ),
        },
        priceMultiplier: {
          sorter: false,
          title: 'Скидка',
          render: v => (parseFloat(v) === 1 ? '–' : `${(1 - v) * 100}%`),
        },
        totalSum: {
          sorter: false,
          title: 'Итого',
          render: (_, row) => renderPrice(row.quantity && row.quantity * row.price * row.priceMultiplier),
        },
        comment: {
          sorter: false,
          title: 'Комментария',
          render: (_, row) => <ItemCommentInput data={row} />,
        },
        returnStatus: {
          sorter: false,
          title: 'Возврат',
          width: 100,
          render: (value, row) => {
            const v = props.context.viewer;
            const allowRoles = ['stock_employee_senior', 'stock_employee_god'];

            if (v && allowRoles.indexOf(v.role) === -1) {
              return returnStatuses[value];
            }

            const change = val => {
              updateItemMutation.commitValue(props.context.environment, row.id, 'returnStatus', val);
            };

            return (
              <Select size="small" value={value} onChange={change} style={{ width: '100%' }}>
                <Select.Option value={null}>-</Select.Option>
                {Object.keys(returnStatuses).map(k => (
                  <Select.Option key={k} value={k}>
                    {returnStatuses[k]}
                  </Select.Option>
                ))}
              </Select>
            );
          },
        },
      }}
    />
  </Page>
);

Show.propTypes = {
  data: PropTypes.shape({
    rowId: PropTypes.number.isRequired,
    items: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  context: PropTypes.shape({
    environment: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    query: PropTypes.object.isRequired,
  }).isRequired,
};

export default Show;
