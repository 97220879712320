/**
 * @flow
 * @relayHash cfea05b7708416f76235835b2a807248
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditWriteOffDocumentQueryVariables = {|
  rowId: number
|};
export type EditWriteOffDocumentQueryResponse = {|
  +data: ?{|
    +id: string
  |},
  +allCategories: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +name: string,
    |}>
  |},
|};
export type EditWriteOffDocumentQuery = {|
  variables: EditWriteOffDocumentQueryVariables,
  response: EditWriteOffDocumentQueryResponse,
|};
*/


/*
query EditWriteOffDocumentQuery(
  $rowId: Int!
) {
  data: writeOffDocumentByRowId(rowId: $rowId) {
    id
  }
  allCategories: allWriteOffCategories {
    nodes {
      rowId
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": "data",
  "name": "writeOffDocumentByRowId",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "rowId",
      "variableName": "rowId",
      "type": "Int!"
    }
  ],
  "concreteType": "WriteOffDocument",
  "plural": false,
  "selections": [
    v1
  ]
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditWriteOffDocumentQuery",
  "id": null,
  "text": "query EditWriteOffDocumentQuery(\n  $rowId: Int!\n) {\n  data: writeOffDocumentByRowId(rowId: $rowId) {\n    id\n  }\n  allCategories: allWriteOffCategories {\n    nodes {\n      rowId\n      name\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditWriteOffDocumentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v2,
      {
        "kind": "LinkedField",
        "alias": "allCategories",
        "name": "allWriteOffCategories",
        "storageKey": null,
        "args": null,
        "concreteType": "WriteOffCategoriesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "WriteOffCategory",
            "plural": true,
            "selections": [
              v3,
              v4
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditWriteOffDocumentQuery",
    "argumentDefinitions": v0,
    "selections": [
      v2,
      {
        "kind": "LinkedField",
        "alias": "allCategories",
        "name": "allWriteOffCategories",
        "storageKey": null,
        "args": null,
        "concreteType": "WriteOffCategoriesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "WriteOffCategory",
            "plural": true,
            "selections": [
              v3,
              v4,
              v1
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a1a0c3be0fe73392dc1150b40a896917';
module.exports = node;
