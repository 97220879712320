/**
 * @flow
 * @relayHash 21683224f23f679a06a0a9f9c084b24e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateShopOrderMkbTransactionInput = {
  clientMutationId?: ?string,
  id: string,
  shopOrderMkbTransactionPatch: ShopOrderMkbTransactionPatch,
};
export type ShopOrderMkbTransactionPatch = {
  rowId?: ?number,
  orderId?: ?number,
  createdAt?: ?any,
  amount?: ?any,
  confirmed?: ?boolean,
  confirmData?: ?any,
  documentId?: ?number,
  orderOwner?: ?string,
};
export type UpdateShopOrderMkbTransactionMutationVariables = {|
  input: UpdateShopOrderMkbTransactionInput
|};
export type UpdateShopOrderMkbTransactionMutationResponse = {|
  +updateShopOrderMkbTransaction: ?{|
    +shopOrderMkbTransaction: ?{|
      +rowId: number
    |}
  |}
|};
export type UpdateShopOrderMkbTransactionMutation = {|
  variables: UpdateShopOrderMkbTransactionMutationVariables,
  response: UpdateShopOrderMkbTransactionMutationResponse,
|};
*/


/*
mutation UpdateShopOrderMkbTransactionMutation(
  $input: UpdateShopOrderMkbTransactionInput!
) {
  updateShopOrderMkbTransaction(input: $input) {
    shopOrderMkbTransaction {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateShopOrderMkbTransactionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateShopOrderMkbTransactionInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateShopOrderMkbTransactionMutation",
  "id": null,
  "text": "mutation UpdateShopOrderMkbTransactionMutation(\n  $input: UpdateShopOrderMkbTransactionInput!\n) {\n  updateShopOrderMkbTransaction(input: $input) {\n    shopOrderMkbTransaction {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateShopOrderMkbTransactionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateShopOrderMkbTransaction",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateShopOrderMkbTransactionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shopOrderMkbTransaction",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderMkbTransaction",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateShopOrderMkbTransactionMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateShopOrderMkbTransaction",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateShopOrderMkbTransactionPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shopOrderMkbTransaction",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderMkbTransaction",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '67118d5ab70f7afea05c232cb90c9874';
module.exports = node;
