/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderBalloonShopOrderDocumentMap_allGroups$ref: FragmentReference;
export type OrderBalloonShopOrderDocumentMap_allGroups = {|
  +nodes: $ReadOnlyArray<{|
    +id: string,
    +rowId: number,
    +deadlineAt: any,
    +courierId: ?number,
    +comment: string,
    +courier: ?{|
      +id: string,
      +firstName: string,
      +lastName: ?string,
      +hasDriversLicense: ?boolean,
    |},
  |}>,
  +$refType: OrderBalloonShopOrderDocumentMap_allGroups$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "OrderBalloonShopOrderDocumentMap_allGroups",
  "type": "ShopOrderGroupsConnection",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nodes",
      "storageKey": null,
      "args": null,
      "concreteType": "ShopOrderGroup",
      "plural": true,
      "selections": [
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "rowId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "deadlineAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "courierId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "comment",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": "courier",
          "name": "courierByCourierId",
          "storageKey": null,
          "args": null,
          "concreteType": "Courier",
          "plural": false,
          "selections": [
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "firstName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "lastName",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "hasDriversLicense",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '110e572291a72c479adfbeeb7a6a4432';
module.exports = node;
