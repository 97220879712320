import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateWarehouseMovementDocumentMutation($input: CreateWarehouseMovementDocumentInput!) {
    createWarehouseMovementDocument(input: $input) {
      warehouseMovementDocument {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createWarehouseMovementDocument', 'warehouseMovementDocument');
