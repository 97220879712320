import { decamelize } from 'humps';

const DEFAULT_PAGE_SIZE = 20;

export const getPaginationOptions = location => ({
  current: parseInt(location.query.page || 1, 10),
  pageSize: parseInt(location.query.pageSize || DEFAULT_PAGE_SIZE, 10),
});

export const preparePaginationVariables = (nextVariables, routeMatch) => {
  const page = (routeMatch.location.query.page || 1) - 1;
  const size = routeMatch.location.query.pageSize || DEFAULT_PAGE_SIZE;
  const { sortColumn, sortOrder } = routeMatch.location.query;
  const filter = (routeMatch.location.query.filter && JSON.parse(routeMatch.location.query.filter)) || {};

  // TODO: rename this module
  const orderBy = sortColumn
    ? [
        `${decamelize(sortColumn === 'rowId' ? 'id' : sortColumn).toUpperCase()}_${
          sortOrder === 'descend' ? 'DESC' : 'ASC'
        }`,
      ]
    : ['ID_DESC'];

  return {
    ...nextVariables,
    first: size,
    offset: page * size,
    orderBy,
    ...routeMatch.location.query,
    filter,
  };
};
