import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateWriteOffCategoryMutation($input: CreateWriteOffCategoryInput!) {
    createWriteOffCategory(input: $input) {
      writeOffCategory {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createWriteOffCategory', 'writeOffCategory');
