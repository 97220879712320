import React from 'react';
import PropTypes from 'prop-types';
import { Spin, Select } from 'antd';
import { fetchQuery, graphql } from 'react-relay';
import { Link } from 'found';
import Icon from 'components/Icon';
import './ProductSelect.css';

const query = graphql`
  query ProductSelectQuery($withTerm: Boolean!, $filter: ProductFilter) {
    allProducts(filter: $filter) @include(if: $withTerm) {
      nodes {
        rowId
        name
      }
    }
  }
`;

const getLoadingState = (term, nodes) => {
  if (nodes) {
    return <span>Ничего не найдено.</span>;
  }

  if (term.length <= 2) {
    return <span>Введите ещё пару символов.</span>;
  }

  return <Spin size="small" />;
};

class ProductSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nodes: [],
      term: '',
    };

    this.onSearch = term => {
      this.setState({ term }, () => {
        this.loadItems();
      });
    };
  }

  componentDidMount() {
    if (!this.props.data || this.props.data.rowId !== this.props.value) {
      this.loadItems();
    }
  }

  focus = () => {
    this.ref.focus();
  };

  saveRef = ref => {
    this.ref = ref;
  };

  loadItems() {
    const variables = {
      withTerm: this.state.term.length > 2 || !!this.props.value,
      filter: {
        rowId: {
          notEqualTo: this.props.skipId,
        },
        or: [
          this.props.value && {
            rowId: {
              equalTo: this.props.value,
            },
          },
          this.state.term.length > 2 && {
            name: {
              includesInsensitive: this.state.term,
            },
          },
        ].filter(Boolean),
      },
    };

    fetchQuery(this.props.environment, query, variables).then(data => {
      if (!data.allProducts) {
        return;
      }

      this.setState({
        nodes: data.allProducts.nodes,
      });
    });
  }

  render() {
    const selectProps = {
      showSearch: true,
      defaultActiveFirstOption: true,
      showArrow: false,
      filterOption: false,
      onSearch: this.onSearch,
      notFoundContent: getLoadingState(this.state.term, this.state.nodes),
    };

    const { environment, skipId, ...restProps } = this.props;
    const nodes =
      this.state.nodes.length === 0 && this.props.data && this.props.value === this.props.data.rowId
        ? [this.props.data]
        : this.state.nodes;

    return (
      <div styleName="root">
        <Select {...selectProps} {...this.props} styleName="select" ref={this.saveRef}>
          {nodes.map(n => (
            <Select.Option key={n.rowId} value={n.rowId}>
              {n.rowId} {n.name}
            </Select.Option>
          ))}
        </Select>
        <div styleName="link">
          {restProps.value && (
            <Link to={`/products/${restProps.value}`} target="_blank" tabIndex="-1">
              <Icon size={16}>launch</Icon>
            </Link>
          )}
        </div>
      </div>
    );
  }
}

ProductSelect.propTypes = {
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.number,
  environment: PropTypes.object.isRequired,
  skipId: PropTypes.number,
  data: PropTypes.shape({
    rowId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

ProductSelect.defaultProps = {
  data: null,
  skipId: null,
};

export default ProductSelect;
