import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import DataTable from 'components/DataTable';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'antd';
import { renderPrice } from 'components/helpers';
import InlineInput from 'components/InlineInput';
import moment from 'moment';
import JSONDetailModal from 'components/JSONDetailModal';
import StatusTag from 'components/StatusTag';
import updateMutation from 'mutations/UpdateShopOrderMkbTransactionMutation';
import MkbPaymentAction from './MkbPaymentAction';

const humanStatuses = {
  '': 'Ожидает',
  failed: 'Ошибка',
  success: 'Подтвержден',
};

const getRefundStatus = r => {
  if (r.confirmed) {
    return 'success';
  }

  if (r.confirmData) {
    return 'failed';
  }

  return '';
};

const MkbPayments = ({ order, relay, token, ...props }) => (
  <div style={{ marginBottom: 20 }}>
    <Row gutter={16}>
      <Col span={14}>
        <h3>MKB платежи</h3>
      </Col>
      {order.userId &&
        order.owner === 'moscowfresh' && (
          <Col span={4} style={{ textAlign: 'right' }}>
            <Button
              size="small"
              onClick={() => {
                const text = `Ссылка для оплаты вашего заказа №${order.shopId} - https://${order.owner}.ru/pay/${
                  order.shopId
                }`;

                // eslint-disable-next-line no-restricted-globals, no-alert
                if (confirm(text)) {
                  fetch('/services/send-sms', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      jwt: token,
                      text,
                      phone: order.userId,
                      owner: order.owner,
                    }),
                  });
                }
              }}
            >
              Отправить с ссылкой
            </Button>
          </Col>
        )}
      {order.userId &&
        order.owner === 'moscowfresh' &&
        parseFloat(order.totalSum) > order.confirmedAmount && (
          <Col span={6} style={{ textAlign: 'right' }}>
            <Button
              size="small"
              onClick={() => {
                const diff = (order.totalSum - order.confirmedAmount).toFixed(2).replace('.', ',');
                const text = `По вашему заказу №${
                  order.shopId
                } осталась задолженность ${diff} руб., поскольку в вашем заказе были весовые товары. Ссылка для оплаты:
https://${order.owner}.ru/mkb/pay/${order.rowId}
Спасибо!`;

                // eslint-disable-next-line no-restricted-globals, no-alert
                if (confirm(text)) {
                  fetch('/services/send-sms', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      jwt: token,
                      text,
                      phone: order.userId,
                      owner: order.owner,
                    }),
                  });
                }
              }}
            >
              Отправить sms по задолженности
            </Button>
          </Col>
        )}
    </Row>
    <DataTable
      {...props}
      rowKey="id"
      onlyTable
      rows={order.mkbTransactions}
      actions={[]}
      columns={{
        rowId: {
          hidden: true,
        },
        createdAt: {
          title: 'Время',
          sorter: false,
          render: v =>
            moment(v)
              .tz('Europe/Moscow')
              .format('DD MMM YYYY HH:mm'),
        },
        amount: {
          title: 'Сумма',
          sorter: false,
          render: renderPrice,
        },
        confirmed: {
          title: 'Статус',
          sorter: false,
          render: (_, t) => <StatusTag value={getRefundStatus(t)}>{humanStatuses[getRefundStatus(t)]}</StatusTag>,
        },
        refund: {
          title: '',
          sorter: false,
          width: 200,
          render: (_, row) => (
            <React.Fragment>
              <div>
                <MkbPaymentAction data={row} order={order} />
              </div>
              {row.refunds.nodes.map(r => (
                <JSONDetailModal data={r.confirmData} key={r.id}>
                  <StatusTag value={getRefundStatus(r)} style={{ margin: 2 }}>
                    {r.amount}
                  </StatusTag>
                </JSONDetailModal>
              ))}
            </React.Fragment>
          ),
        },
        confirmData: {
          title: '',
          sorter: false,
          render: data => data && <JSONDetailModal data={data} />,
        },
        documentId: {
          title: 'ID заказа',
          sorter: false,
          render: (v, row) => location.search.indexOf('mkb') === 1 && (
            <InlineInput
              transfarmValue={val => val.replace(',', '.')}
              onUpdate={val => updateMutation.commitValue(relay.environment, row.id, 'documentId', val)}
              value={v}
              style={{ width: 100 }}
            />
          ),
        },
      }}
    />
  </div>
);

const selector = state => ({
  token: state.token,
});

export default createFragmentContainer(
  connect(selector)(MkbPayments),
  graphql`
    fragment MkbPayments_order on ShopOrderDocument {
      ...MkbPaymentAction_order

      rowId
      userId
      shopId
      totalSum
      confirmedAmount
      owner

      mkbTransactions: shopOrderMkbTransactionsByDocumentId {
        nodes {
          id
          createdAt
          amount
          confirmed
          confirmData
          documentId

          ...MkbPaymentAction

          refunds: shopOrderMkbRefundsByTransactionId(orderBy: [CREATED_AT_ASC]) {
            nodes {
              id
              amount
              confirmed
              confirmData
            }
          }
        }
      }
    }
  `
);
