exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-Couriers-Show-___Groups__canceled___3fgfd {\n  background: red;\n  color: white;\n}\n\n.src-pages-Couriers-Show-___Groups__order___45w1D {\n  display: inline-block;\n  padding: 0 3px;\n  border-bottom: 2px solid #ccc;\n  text-decoration: none;\n}\n\n.src-pages-Couriers-Show-___Groups__timeSuccess___2IY1p {\n  border-color: green;\n}\n\n.src-pages-Couriers-Show-___Groups__timeError___1zih0 {\n  border-color: red;\n}\n\n.src-pages-Couriers-Show-___Groups__timeWarning___2f03I {\n  border-color: yellow;\n}\n", ""]);

// exports
exports.locals = {
	"canceled": "src-pages-Couriers-Show-___Groups__canceled___3fgfd",
	"order": "src-pages-Couriers-Show-___Groups__order___45w1D",
	"timeSuccess": "src-pages-Couriers-Show-___Groups__timeSuccess___2IY1p",
	"timeError": "src-pages-Couriers-Show-___Groups__timeError___1zih0",
	"timeWarning": "src-pages-Couriers-Show-___Groups__timeWarning___2f03I"
};