/**
 * @flow
 * @relayHash dbc2a3ea475e2eecab5dcf05062f7917
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ShowYandexOrderDocument$ref = any;
export type ShopOrderItemReturnStatus = "REUSE" | "THROW" | "%future added value";
export type CreateShopOrderItemInput = {
  clientMutationId?: ?string,
  shopOrderItem: ShopOrderItemInput,
};
export type ShopOrderItemInput = {
  rowId?: ?number,
  documentId: number,
  warehouseId: number,
  productId: number,
  orderedQuantity: any,
  prepareQuantity?: ?any,
  quantity?: ?any,
  price: any,
  priceMultiplier?: ?any,
  meta?: ?any,
  printed?: ?boolean,
  comment?: ?string,
  deficit?: ?boolean,
  weightMultiplier?: ?any,
  supplyProductionItemId?: ?number,
  returnStatus?: ?ShopOrderItemReturnStatus,
  supplyOrderedQuantity?: ?any,
  supplyDocumentId?: ?number,
  replacedByProductId?: ?number,
};
export type CreateShopOrderItemMutationVariables = {|
  input: CreateShopOrderItemInput
|};
export type CreateShopOrderItemMutationResponse = {|
  +createShopOrderItem: ?{|
    +shopOrderItem: ?{|
      +shopOrderDocumentByDocumentId: ?{|
        +$fragmentRefs: ShowYandexOrderDocument$ref
      |}
    |}
  |}
|};
export type CreateShopOrderItemMutation = {|
  variables: CreateShopOrderItemMutationVariables,
  response: CreateShopOrderItemMutationResponse,
|};
*/


/*
mutation CreateShopOrderItemMutation(
  $input: CreateShopOrderItemInput!
) {
  createShopOrderItem(input: $input) {
    shopOrderItem {
      shopOrderDocumentByDocumentId {
        ...ShowYandexOrderDocument
        id
      }
      id
    }
  }
}

fragment ShowYandexOrderDocument on ShopOrderDocument {
  rowId
  owner
  mainWarehouseId
  items: shopOrderItemsByDocumentId(orderBy: [DEFICIT_DESC]) {
    nodes {
      rowId
      id
      printed
      deficit
      product: productByProductId {
        rowId
        name
        unit
        id
      }
      orderedQuantity
      quantity
      price
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateShopOrderItemInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateShopOrderItemInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateShopOrderItemMutation",
  "id": null,
  "text": "mutation CreateShopOrderItemMutation(\n  $input: CreateShopOrderItemInput!\n) {\n  createShopOrderItem(input: $input) {\n    shopOrderItem {\n      shopOrderDocumentByDocumentId {\n        ...ShowYandexOrderDocument\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment ShowYandexOrderDocument on ShopOrderDocument {\n  rowId\n  owner\n  mainWarehouseId\n  items: shopOrderItemsByDocumentId(orderBy: [DEFICIT_DESC]) {\n    nodes {\n      rowId\n      id\n      printed\n      deficit\n      product: productByProductId {\n        rowId\n        name\n        unit\n        id\n      }\n      orderedQuantity\n      quantity\n      price\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateShopOrderItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createShopOrderItem",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateShopOrderItemPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shopOrderItem",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderItem",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "shopOrderDocumentByDocumentId",
                "storageKey": null,
                "args": null,
                "concreteType": "ShopOrderDocument",
                "plural": false,
                "selections": [
                  {
                    "kind": "FragmentSpread",
                    "name": "ShowYandexOrderDocument",
                    "args": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateShopOrderItemMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createShopOrderItem",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateShopOrderItemPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shopOrderItem",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderItem",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "shopOrderDocumentByDocumentId",
                "storageKey": null,
                "args": null,
                "concreteType": "ShopOrderDocument",
                "plural": false,
                "selections": [
                  v2,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "owner",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "mainWarehouseId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": "items",
                    "name": "shopOrderItemsByDocumentId",
                    "storageKey": "shopOrderItemsByDocumentId(orderBy:[\"DEFICIT_DESC\"])",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "orderBy",
                        "value": [
                          "DEFICIT_DESC"
                        ],
                        "type": "[ShopOrderItemsOrderBy!]"
                      }
                    ],
                    "concreteType": "ShopOrderItemsConnection",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "LinkedField",
                        "alias": null,
                        "name": "nodes",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "ShopOrderItem",
                        "plural": true,
                        "selections": [
                          v2,
                          v3,
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "printed",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "deficit",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "LinkedField",
                            "alias": "product",
                            "name": "productByProductId",
                            "storageKey": null,
                            "args": null,
                            "concreteType": "Product",
                            "plural": false,
                            "selections": [
                              v2,
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "name",
                                "args": null,
                                "storageKey": null
                              },
                              {
                                "kind": "ScalarField",
                                "alias": null,
                                "name": "unit",
                                "args": null,
                                "storageKey": null
                              },
                              v3
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "orderedQuantity",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "quantity",
                            "args": null,
                            "storageKey": null
                          },
                          {
                            "kind": "ScalarField",
                            "alias": null,
                            "name": "price",
                            "args": null,
                            "storageKey": null
                          }
                        ]
                      }
                    ]
                  },
                  v3
                ]
              },
              v3
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '48409d0047b6c978b4e1ee881e0f6b0f';
module.exports = node;
