exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-ShopOrderDocuments-Map-___Orders__header___32Awp {\n  padding: 0 20px;\n}\n\n.src-pages-ShopOrderDocuments-Map-___Orders__ready___24Fi4 {\n  background: #53a753;\n}\n", ""]);

// exports
exports.locals = {
	"header": "src-pages-ShopOrderDocuments-Map-___Orders__header___32Awp",
	"ready": "src-pages-ShopOrderDocuments-Map-___Orders__ready___24Fi4"
};