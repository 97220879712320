import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateScaleProductMutation($input: CreateScaleProductInput!) {
    createScaleProduct(input: $input) {
      scaleProduct {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createScaleProduct', 'scaleProduct');
