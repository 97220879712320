// @flow
/* global $ReadOnlyArray */

import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

type Props = {
  items: $ReadOnlyArray<{|
    +rowId: number,
    +name: string,
  |}>,
};

// antd wants ref
// eslint-disable-next-line react/prefer-stateless-function
class CollectionSelect extends React.Component<Props> {
  render() {
    const { items, getLabel, ...props } = this.props;

    return (
      <Select {...props}>
        {items.map(n => (
          <Select.Option key={n.rowId} value={n.rowId}>
            {getLabel ? getLabel(n, this.props) : n.name}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

CollectionSelect.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      rowId: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

export default CollectionSelect;
