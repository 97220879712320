exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-ShopOrderDocuments-Map-___Groups__ready___UjZaP {\n  background: #53a753;\n}\n\n.src-pages-ShopOrderDocuments-Map-___Groups__shipped___21oXG {\n  opacity: 0.4;\n}\n\n.src-pages-ShopOrderDocuments-Map-___Groups__delivery-time___28g2E {\n  height: 24px;\n  line-height: 24px;\n  margin-top: 10px;\n  margin-bottom: 0;\n}\n", ""]);

// exports
exports.locals = {
	"ready": "src-pages-ShopOrderDocuments-Map-___Groups__ready___UjZaP",
	"shipped": "src-pages-ShopOrderDocuments-Map-___Groups__shipped___21oXG",
	"delivery-time": "src-pages-ShopOrderDocuments-Map-___Groups__delivery-time___28g2E"
};