/**
 * @flow
 * @relayHash 21056fa4d8ec04f87a74c939a7fdfefa
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NewShopDepositReplenishmentQueryVariables = {||};
export type NewShopDepositReplenishmentQueryResponse = {|
  +allEmployees: ?{|
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +firstName: string,
      +lastName: string,
    |}>
  |}
|};
export type NewShopDepositReplenishmentQuery = {|
  variables: NewShopDepositReplenishmentQueryVariables,
  response: NewShopDepositReplenishmentQueryResponse,
|};
*/


/*
query NewShopDepositReplenishmentQuery {
  allEmployees {
    nodes {
      id
      rowId
      firstName
      lastName
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "allEmployees",
    "storageKey": null,
    "args": null,
    "concreteType": "EmployeesConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": null,
        "concreteType": "Employee",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rowId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "NewShopDepositReplenishmentQuery",
  "id": null,
  "text": "query NewShopDepositReplenishmentQuery {\n  allEmployees {\n    nodes {\n      id\n      rowId\n      firstName\n      lastName\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NewShopDepositReplenishmentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": v0
  },
  "operation": {
    "kind": "Operation",
    "name": "NewShopDepositReplenishmentQuery",
    "argumentDefinitions": [],
    "selections": v0
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '42dc4bffa6c52c86238c1bfa4189cbfa';
module.exports = node;
