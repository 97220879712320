/**
 * @flow
 * @relayHash 2ba1fffe2e8ef6eb20d605fa5be7741a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShopOrderGroupStatus = "COMPLETED" | "PENDING" | "SENDED" | "%future added value";
export type CreateShopOrderGroupInput = {
  clientMutationId?: ?string,
  shopOrderGroup: ShopOrderGroupInput,
};
export type ShopOrderGroupInput = {
  rowId?: ?number,
  deadlineAt: any,
  owner?: ?string,
  comment?: ?string,
  courierId?: ?number,
  status?: ?ShopOrderGroupStatus,
  courierToken?: ?any,
  senderId?: ?number,
  completerId?: ?number,
  totalChange?: ?any,
  totalCashSum?: ?any,
  activeDocumentId?: ?number,
  courierBasePayoff?: ?any,
  courierExtraPayoff?: ?any,
};
export type CreateShopOrderGroupMutationVariables = {|
  input: CreateShopOrderGroupInput
|};
export type CreateShopOrderGroupMutationResponse = {|
  +createShopOrderGroup: ?{|
    +shopOrderGroup: ?{|
      +id: string,
      +rowId: number,
      +courierId: ?number,
      +deadlineAt: any,
      +comment: string,
      +orders: {|
        +nodes: $ReadOnlyArray<{|
          +id: string,
          +groupId: ?number,
        |}>
      |},
    |}
  |}
|};
export type CreateShopOrderGroupMutation = {|
  variables: CreateShopOrderGroupMutationVariables,
  response: CreateShopOrderGroupMutationResponse,
|};
*/


/*
mutation CreateShopOrderGroupMutation(
  $input: CreateShopOrderGroupInput!
) {
  createShopOrderGroup(input: $input) {
    shopOrderGroup {
      id
      rowId
      courierId
      deadlineAt
      comment
      orders: shopOrderDocumentsByGroupId {
        nodes {
          id
          groupId
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateShopOrderGroupInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createShopOrderGroup",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "CreateShopOrderGroupInput!"
      }
    ],
    "concreteType": "CreateShopOrderGroupPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "shopOrderGroup",
        "storageKey": null,
        "args": null,
        "concreteType": "ShopOrderGroup",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rowId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "courierId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "deadlineAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "comment",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": "orders",
            "name": "shopOrderDocumentsByGroupId",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderDocumentsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "ShopOrderDocument",
                "plural": true,
                "selections": [
                  v1,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "groupId",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateShopOrderGroupMutation",
  "id": null,
  "text": "mutation CreateShopOrderGroupMutation(\n  $input: CreateShopOrderGroupInput!\n) {\n  createShopOrderGroup(input: $input) {\n    shopOrderGroup {\n      id\n      rowId\n      courierId\n      deadlineAt\n      comment\n      orders: shopOrderDocumentsByGroupId {\n        nodes {\n          id\n          groupId\n        }\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateShopOrderGroupMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateShopOrderGroupMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '305d554ae5994317e0e325204a6135da';
module.exports = node;
