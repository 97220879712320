import React from 'react';
import { Icon, Button } from 'antd';
import { compose, omitProps, withState, withHandlers } from 'recompact';
import PropTypes from 'prop-types';

const enhance = compose(
  withState('loading', 'setLoading', false),
  withState('status', 'setStatus', 'pending'),
  withHandlers({
    onClick: props => e => {
      if (!props.onClick) {
        return;
      }

      const result = props.onClick(e);

      if (result && result.then) {
        const timeout = setTimeout(() => {
          props.setLoading(true);
        }, 200);

        const finish = () => {
          props.setLoading(false);
          clearInterval(timeout);
        };

        result
          .then(() => {
            finish();
            props.setStatus('finish');
          })
          .catch(err => {
            finish();
            props.setStatus('error');
            // eslint-disable-next-line no-console
            console.error(err);
          });
      }
    },
  }),
  omitProps(['setLoading', 'setStatus'])
);

const ProgressButton = ({ status, children, ...props }) => (
  <Button {...props}>
    {status === 'finish' && <Icon type="check-circle" theme="outlined" />}
    {status === 'error' && <Icon type="exclamation-circle" theme="outlined" />}
    {children}
  </Button>
);

ProgressButton.propTypes = {
  status: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default enhance(ProgressButton);
