import { compose, withProps } from 'recompact';
import { connect } from 'react-redux';
import withCreateEnhance from 'hoc/withCreateEnhance';
import mutation from 'mutations/CreateProductGroupMutation';

const selector = state => ({
  defaultWarehouseId: state.defaultWarehouseId,
});

export default compose(
  connect(selector),
  withProps({
    title: 'Создать новое место хранения',
  }),
  withCreateEnhance({
    mutation,
  })
);
