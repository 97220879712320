import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import ProductSelect from 'containers/ProductSelect';

const Home = ({ context: { environment }, router: { push } }) => {
  const handleChangeProduct = id => push(`/products/${id}`);

  return (
    <Page title="Главная">
      <div>Быстрый поиск продукта:</div>
      <ProductSelect environment={environment} onChange={handleChangeProduct} style={{ width: 500 }} />
    </Page>
  );
};

Home.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  context: PropTypes.shape({
    environment: PropTypes.object.isRequired,
  }).isRequired,
};

export default Home;
