import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateBalanceItemMutation($input: CreateBalanceItemInput!) {
    createBalanceItem(input: $input) {
      balanceItem {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createBalanceItem', 'balanceItem');
