exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@media print {\n  .src-pages-WarehouseMovementDocuments-Show-NewItem-___NewItem__root___1EoSq {\n    display: none;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-pages-WarehouseMovementDocuments-Show-NewItem-___NewItem__root___1EoSq"
};