// @flow

import { compose, withProps } from 'recompact';
import destroyMutation from 'mutations/DestroyEmployeeMutation';

export default compose(
  withProps({
    title: 'Сотрудники',
    destroyMutation,
    actions: ['edit', 'destroy'],
    columns: {
      email: {
        title: 'E-mail',
      },
      firstName: {
        title: 'Имя',
      },
      lastName: {
        title: 'Фамилия',
      },
      role: {
        title: 'Роль',
      },
    },
  })
);
