/**
 * @flow
 * @relayHash 25a6631b561557b09099b0a9cb903e41
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type Documents$ref = any;
export type ShopOrderDocumentPaymentMethod = "BY_CARD" | "BY_CASH" | "DEPOSIT" | "EMPLOYEE" | "ONLINE" | "%future added value";
export type ShopOrderDocumentStatus = "CANCELED" | "COMPLETED" | "DELIVERED" | "PACKING" | "PENDING" | "SHIPPED" | "%future added value";
export type ShopOrderDocumentFilter = {
  rowId?: ?IntFilter,
  paymentMethod?: ?ShopOrderDocumentPaymentMethodFilter,
  status?: ?ShopOrderDocumentStatusFilter,
  eventedAt?: ?DatetimeFilter,
  totalSumMultiplier?: ?BigFloatFilter,
  discount?: ?BigFloatFilter,
  itemSum?: ?BigFloatFilter,
  deliveryCost?: ?BigFloatFilter,
  totalWeight?: ?BigFloatFilter,
  meta?: ?JSONFilter,
  comment?: ?StringFilter,
  groupId?: ?IntFilter,
  owner?: ?StringFilter,
  deliveryAt?: ?DatetimeFilter,
  deliveryMaxDelay?: ?IntervalFilter,
  sourceUrl?: ?StringFilter,
  printed?: ?BooleanFilter,
  ready?: ?BooleanFilter,
  deliveryPointBlocked?: ?BooleanFilter,
  groupPosition?: ?IntFilter,
  change?: ?BigFloatFilter,
  confirmedAmount?: ?BigFloatFilter,
  userId?: ?StringFilter,
  shopId?: ?IntFilter,
  asap?: ?BooleanFilter,
  deliveryNoticeAt?: ?DatetimeFilter,
  ecoPack?: ?BooleanFilter,
  withoutOverweight?: ?BooleanFilter,
  withGlassCoupon?: ?BooleanFilter,
  deliveredAt?: ?DatetimeFilter,
  byCardPaymentConfirmed?: ?BooleanFilter,
  userRating?: ?IntFilter,
  userRatingAt?: ?DatetimeFilter,
  userReview?: ?StringFilter,
  userReviewAt?: ?DatetimeFilter,
  userReviewImages?: ?StringListFilter,
  userReviewSolvedEmployeeId?: ?IntFilter,
  userReviewSolveComment?: ?StringFilter,
  readyAt?: ?DatetimeFilter,
  shippedAt?: ?DatetimeFilter,
  mainWarehouseId?: ?IntFilter,
  manualConfirmedAmount?: ?BigFloatFilter,
  packageCount?: ?IntFilter,
  checkEmployeeId?: ?IntFilter,
  clientPhone?: ?StringFilter,
  clientEmail?: ?StringFilter,
  lastSentEvent?: ?StringFilter,
  deadlineAt?: ?StringFilter,
  deliveryOn?: ?DateFilter,
  depositBalance?: ?BigFloatFilter,
  expectedTotalSum?: ?BigFloatFilter,
  heavy?: ?BooleanFilter,
  label?: ?StringFilter,
  owedSum?: ?BigFloatFilter,
  totalSum?: ?BigFloatFilter,
  and?: ?$ReadOnlyArray<ShopOrderDocumentFilter>,
  or?: ?$ReadOnlyArray<ShopOrderDocumentFilter>,
  not?: ?ShopOrderDocumentFilter,
};
export type IntFilter = {
  isNull?: ?boolean,
  equalTo?: ?number,
  notEqualTo?: ?number,
  distinctFrom?: ?number,
  notDistinctFrom?: ?number,
  in?: ?$ReadOnlyArray<number>,
  notIn?: ?$ReadOnlyArray<number>,
  lessThan?: ?number,
  lessThanOrEqualTo?: ?number,
  greaterThan?: ?number,
  greaterThanOrEqualTo?: ?number,
};
export type ShopOrderDocumentPaymentMethodFilter = {
  isNull?: ?boolean,
  equalTo?: ?ShopOrderDocumentPaymentMethod,
  notEqualTo?: ?ShopOrderDocumentPaymentMethod,
  distinctFrom?: ?ShopOrderDocumentPaymentMethod,
  notDistinctFrom?: ?ShopOrderDocumentPaymentMethod,
  in?: ?$ReadOnlyArray<ShopOrderDocumentPaymentMethod>,
  notIn?: ?$ReadOnlyArray<ShopOrderDocumentPaymentMethod>,
  lessThan?: ?ShopOrderDocumentPaymentMethod,
  lessThanOrEqualTo?: ?ShopOrderDocumentPaymentMethod,
  greaterThan?: ?ShopOrderDocumentPaymentMethod,
  greaterThanOrEqualTo?: ?ShopOrderDocumentPaymentMethod,
};
export type ShopOrderDocumentStatusFilter = {
  isNull?: ?boolean,
  equalTo?: ?ShopOrderDocumentStatus,
  notEqualTo?: ?ShopOrderDocumentStatus,
  distinctFrom?: ?ShopOrderDocumentStatus,
  notDistinctFrom?: ?ShopOrderDocumentStatus,
  in?: ?$ReadOnlyArray<ShopOrderDocumentStatus>,
  notIn?: ?$ReadOnlyArray<ShopOrderDocumentStatus>,
  lessThan?: ?ShopOrderDocumentStatus,
  lessThanOrEqualTo?: ?ShopOrderDocumentStatus,
  greaterThan?: ?ShopOrderDocumentStatus,
  greaterThanOrEqualTo?: ?ShopOrderDocumentStatus,
};
export type DatetimeFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type BigFloatFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type JSONFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
  contains?: ?any,
  containsKey?: ?string,
  containsAllKeys?: ?$ReadOnlyArray<string>,
  containsAnyKeys?: ?$ReadOnlyArray<string>,
  containedBy?: ?any,
};
export type StringFilter = {
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  equalToInsensitive?: ?string,
  notEqualToInsensitive?: ?string,
  distinctFromInsensitive?: ?string,
  notDistinctFromInsensitive?: ?string,
  inInsensitive?: ?$ReadOnlyArray<string>,
  notInInsensitive?: ?$ReadOnlyArray<string>,
  lessThanInsensitive?: ?string,
  lessThanOrEqualToInsensitive?: ?string,
  greaterThanInsensitive?: ?string,
  greaterThanOrEqualToInsensitive?: ?string,
};
export type IntervalFilter = {
  isNull?: ?boolean,
  equalTo?: ?IntervalInput,
  notEqualTo?: ?IntervalInput,
  distinctFrom?: ?IntervalInput,
  notDistinctFrom?: ?IntervalInput,
  in?: ?$ReadOnlyArray<IntervalInput>,
  notIn?: ?$ReadOnlyArray<IntervalInput>,
  lessThan?: ?IntervalInput,
  lessThanOrEqualTo?: ?IntervalInput,
  greaterThan?: ?IntervalInput,
  greaterThanOrEqualTo?: ?IntervalInput,
};
export type IntervalInput = {
  seconds?: ?number,
  minutes?: ?number,
  hours?: ?number,
  days?: ?number,
  months?: ?number,
  years?: ?number,
};
export type BooleanFilter = {
  isNull?: ?boolean,
  equalTo?: ?boolean,
  notEqualTo?: ?boolean,
  distinctFrom?: ?boolean,
  notDistinctFrom?: ?boolean,
  in?: ?$ReadOnlyArray<boolean>,
  notIn?: ?$ReadOnlyArray<boolean>,
  lessThan?: ?boolean,
  lessThanOrEqualTo?: ?boolean,
  greaterThan?: ?boolean,
  greaterThanOrEqualTo?: ?boolean,
};
export type StringListFilter = {
  isNull?: ?boolean,
  equalTo?: ?$ReadOnlyArray<?string>,
  notEqualTo?: ?$ReadOnlyArray<?string>,
  distinctFrom?: ?$ReadOnlyArray<?string>,
  notDistinctFrom?: ?$ReadOnlyArray<?string>,
  lessThan?: ?$ReadOnlyArray<?string>,
  lessThanOrEqualTo?: ?$ReadOnlyArray<?string>,
  greaterThan?: ?$ReadOnlyArray<?string>,
  greaterThanOrEqualTo?: ?$ReadOnlyArray<?string>,
  contains?: ?$ReadOnlyArray<?string>,
  containedBy?: ?$ReadOnlyArray<?string>,
  overlaps?: ?$ReadOnlyArray<?string>,
  anyEqualTo?: ?string,
  anyNotEqualTo?: ?string,
  anyLessThan?: ?string,
  anyLessThanOrEqualTo?: ?string,
  anyGreaterThan?: ?string,
  anyGreaterThanOrEqualTo?: ?string,
};
export type DateFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type DocumentsQueryVariables = {|
  filter: ShopOrderDocumentFilter
|};
export type DocumentsQueryResponse = {|
  +allShopOrderDocuments: ?{|
    +nodes: $ReadOnlyArray<{|
      +$fragmentRefs: Documents$ref
    |}>
  |}
|};
export type DocumentsQuery = {|
  variables: DocumentsQueryVariables,
  response: DocumentsQueryResponse,
|};
*/


/*
query DocumentsQuery(
  $filter: ShopOrderDocumentFilter!
) {
  allShopOrderDocuments(filter: $filter) {
    nodes {
      ...Documents
      id
    }
  }
}

fragment Documents on ShopOrderDocument {
  id
  rowId
  status
  label
  deliveryAt
  ready
  asap
  comment
  owner
  groupId
  groupPosition
  group: shopOrderGroupByGroupId {
    id
    rowId
    deadlineAt
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "ShopOrderDocumentFilter!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter",
    "type": "ShopOrderDocumentFilter"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DocumentsQuery",
  "id": null,
  "text": "query DocumentsQuery(\n  $filter: ShopOrderDocumentFilter!\n) {\n  allShopOrderDocuments(filter: $filter) {\n    nodes {\n      ...Documents\n      id\n    }\n  }\n}\n\nfragment Documents on ShopOrderDocument {\n  id\n  rowId\n  status\n  label\n  deliveryAt\n  ready\n  asap\n  comment\n  owner\n  groupId\n  groupPosition\n  group: shopOrderGroupByGroupId {\n    id\n    rowId\n    deadlineAt\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DocumentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allShopOrderDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "ShopOrderDocumentsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderDocument",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "Documents",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DocumentsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allShopOrderDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "ShopOrderDocumentsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderDocument",
            "plural": true,
            "selections": [
              v2,
              v3,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "status",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "label",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "deliveryAt",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "ready",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "asap",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "comment",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "owner",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "groupId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "groupPosition",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": "group",
                "name": "shopOrderGroupByGroupId",
                "storageKey": null,
                "args": null,
                "concreteType": "ShopOrderGroup",
                "plural": false,
                "selections": [
                  v2,
                  v3,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "deadlineAt",
                    "args": null,
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd35c9d6e62a6a0a86a9e7619eae4a316';
module.exports = node;
