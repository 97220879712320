/**
 * @flow
 * @relayHash b5406b5e1a2d364cf071b6c7a694a1dc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateSupplyProductionItemInput = {
  clientMutationId?: ?string,
  id: string,
  supplyProductionItemPatch: SupplyProductionItemPatch,
};
export type SupplyProductionItemPatch = {
  rowId?: ?number,
  documentId?: ?number,
  productId?: ?number,
  warehouseId?: ?number,
  quantity?: ?any,
  fixed?: ?boolean,
  overrideQuantity?: ?any,
};
export type UpdateSupplyProductionItemMutationVariables = {|
  input: UpdateSupplyProductionItemInput
|};
export type UpdateSupplyProductionItemMutationResponse = {|
  +updateSupplyProductionItem: ?{|
    +supplyProductionItem: ?{|
      +rowId: number,
      +fixed: boolean,
      +quantity: ?any,
      +document: ?{|
        +id: string,
        +supplyItemsByDocumentId: {|
          +nodes: $ReadOnlyArray<{|
            +id: string,
            +quantity: any,
          |}>
        |},
      |},
    |}
  |}
|};
export type UpdateSupplyProductionItemMutation = {|
  variables: UpdateSupplyProductionItemMutationVariables,
  response: UpdateSupplyProductionItemMutationResponse,
|};
*/


/*
mutation UpdateSupplyProductionItemMutation(
  $input: UpdateSupplyProductionItemInput!
) {
  updateSupplyProductionItem(input: $input) {
    supplyProductionItem {
      rowId
      fixed
      quantity
      document: supplyDocumentByDocumentId {
        id
        supplyItemsByDocumentId {
          nodes {
            id
            quantity
          }
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateSupplyProductionItemInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateSupplyProductionItemInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fixed",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "LinkedField",
  "alias": "document",
  "name": "supplyDocumentByDocumentId",
  "storageKey": null,
  "args": null,
  "concreteType": "SupplyDocument",
  "plural": false,
  "selections": [
    v5,
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "supplyItemsByDocumentId",
      "storageKey": null,
      "args": null,
      "concreteType": "SupplyItemsConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "nodes",
          "storageKey": null,
          "args": null,
          "concreteType": "SupplyItem",
          "plural": true,
          "selections": [
            v5,
            v4
          ]
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateSupplyProductionItemMutation",
  "id": null,
  "text": "mutation UpdateSupplyProductionItemMutation(\n  $input: UpdateSupplyProductionItemInput!\n) {\n  updateSupplyProductionItem(input: $input) {\n    supplyProductionItem {\n      rowId\n      fixed\n      quantity\n      document: supplyDocumentByDocumentId {\n        id\n        supplyItemsByDocumentId {\n          nodes {\n            id\n            quantity\n          }\n        }\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateSupplyProductionItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSupplyProductionItem",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateSupplyProductionItemPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyProductionItem",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyProductionItem",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v6
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateSupplyProductionItemMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateSupplyProductionItem",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateSupplyProductionItemPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyProductionItem",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyProductionItem",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v6,
              v5
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '016419a1be13f1d3c8ef86fd0e8aba6b';
module.exports = node;
