import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Form, Checkbox, Input, Button, Select } from 'antd';
import { getSelectItems } from 'components/helpers';
import PageForm from 'components/PageForm';
import ProductSelect from 'containers/ProductSelect';
import CollectionSelect from 'components/CollectionSelect';
import IntervalInput from 'components/IntervalInput';
import TextInput from 'components/TextInput';
import statuses from './statuses';
import externalStatuses from './externalStatuses';
import units from './units';

const ProductForm = ({
  title,
  form,
  data,
  handleSubmit,
  allProductCategories,
  context: { environment },
  submitting,
}) => (
  <PageForm onSubmit={handleSubmit} title={title}>
    <Form.Item label="Название">
      {form.getFieldDecorator('name', {
        rules: [
          {
            required: true,
            message: 'Введите название',
          },
        ],
      })(<Input />)}
    </Form.Item>
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item label="Категория">
          {form.getFieldDecorator('categoryId')(
            <CollectionSelect
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              showSearch
              items={allProductCategories.nodes}
            />
          )}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Родительский продукт">
          {form.getFieldDecorator('parentId')(
            <ProductSelect environment={environment} skipId={data && data.rowId} data={data && data.parent} />
          )}
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={24}>
      <Col span={6}>
        <Form.Item label="НДС">{form.getFieldDecorator('vat')(<Input />)}</Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Собственное произвдство">
          {form.getFieldDecorator('ownProduction', { valuePropName: 'checked' })(<Checkbox />)}
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Требуется упаковкa">
          {form.getFieldDecorator('requiresPackaging', { valuePropName: 'checked' })(<Checkbox />)}
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Брендированная упаковка">
          {form.getFieldDecorator('ownPackaging', { valuePropName: 'checked' })(<Checkbox />)}
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={24}>
      <Col span={6}>
        <Form.Item label="Статус">
          {form.getFieldDecorator('status', { initialValue: 'ACTIVE' })(<Select>{getSelectItems(statuses)}</Select>)}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Срок годности">{form.getFieldDecorator('shelfLife')(<IntervalInput />)}</Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="Единица измерения">
          {form.getFieldDecorator('unit', { initialValue: 'KG' })(<Select>{getSelectItems(units)}</Select>)}
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={24}>
      <Col span={6}>
        <Form.Item label="Внешний статус">
          {form.getFieldDecorator('externalStatus', { initialValue: 'AVAILABLE' })(
            <Select>{getSelectItems(externalStatuses)}</Select>
          )}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Порог доступности">
          {form.getFieldDecorator('availableQuantityThreshold')(<TextInput />)}
        </Form.Item>
      </Col>
    </Row>
    <Form.Item label="Вес штуки">{form.getFieldDecorator('pcsWeight')(<Input />)}</Form.Item>
    <Form.Item label="Разрешенное отклонение">{form.getFieldDecorator('packingTolerance')(<Input />)}</Form.Item>
    <Form.Item label="Штрихкоды">{form.getFieldDecorator('barcodes')(<Select mode="tags" />)}</Form.Item>
    <Form.Item>
      <Button disabled={submitting} type="primary" htmlType="submit">
        Сохранить
      </Button>
    </Form.Item>
  </PageForm>
);

ProductForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  context: PropTypes.shape({
    environment: PropTypes.object.isRequired,
  }).isRequired,
  allProductCategories: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
  data: PropTypes.object,
  submitting: PropTypes.bool.isRequired,
};

ProductForm.defaultProps = {
  data: null,
};

export default ProductForm;
