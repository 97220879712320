import React from 'react';
import PropTypes from 'prop-types';
import CollectionSelect from 'components/CollectionSelect';
import { Button } from 'antd';
import { Link } from 'found';

const Actions = ({
  allProductCategories,
  categoryId,
  setCategoryId,
  onToCategory,
  scaleId,
  setScaleId,
  allScales,
  onToScale,
  selectedRowKeys,
  rows,
}) => (
  <div style={{ paddingBottom: 20 }}>
    <h3>Действия</h3>
    <CollectionSelect
      value={categoryId}
      onChange={id => setCategoryId(id)}
      items={allProductCategories.nodes}
      style={{ width: '200px', marginRight: 10 }}
    />
    <Button onClick={onToCategory}>Перенести в категорию</Button>
    <div style={{ height: 5 }} />
    <CollectionSelect
      value={scaleId}
      onChange={id => setScaleId(id)}
      items={allScales.nodes}
      style={{ width: '200px', marginRight: 10 }}
    />
    <Button onClick={onToScale}>Перенести в весы</Button>
    <div>
      <Link
        to={{
          pathname: '/products/print',
          query: { ids: selectedRowKeys.map(id => rows.nodes.find(p => p && p.id === id).rowId) },
        }}
      >
        <Button>Печать</Button>
      </Link>
    </div>
  </div>
);

Actions.propTypes = {
  rows: PropTypes.array.isRequired,
  selectedRowKeys: PropTypes.array.isRequired,
  allProductCategories: PropTypes.object.isRequired,
  categoryId: PropTypes.number,
  setCategoryId: PropTypes.func.isRequired,
  onToCategory: PropTypes.func.isRequired,
  allScales: PropTypes.object.isRequired,
  scaleId: PropTypes.number,
  setScaleId: PropTypes.func.isRequired,
  onToScale: PropTypes.func.isRequired,
};

Actions.defaultProps = {
  categoryId: null,
  scaleId: null,
};

export default Actions;
