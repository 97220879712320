import commitMutation from 'relay-commit-mutation-promise';
import { get } from 'unchanged';

export const makeDestroyMutation = (mutation, method, deletedIDFieldName) => {
  const extractId = get(`${method}.${deletedIDFieldName}`);
  const commit = (environment, id, options) => {
    const config = {
      mutation,
      variables: {
        input: { id },
      },
      configs: [
        {
          type: 'NODE_DELETE',
          deletedIDFieldName,
        },
      ],
      ...options,
    };

    return commitMutation(environment, config).then(extractId);
  };

  return { commit };
};

export const makeCreateMutation = (mutation, method, entityName) => {
  const extractEntity = get(`${method}.${entityName}`);
  const commit = (environment, values, options) => {
    const config = {
      mutation,
      variables: {
        input: {
          [entityName]: values,
        },
      },
      ...options,
    };

    return commitMutation(environment, config).then(extractEntity);
  };

  return { commit };
};

export const makeCopyMutation = (mutation, method, entityName) => {
  const extractEntity = get(`${method}.${entityName}`);
  const commit = (environment, sourceDocumentId, options) => {
    const config = {
      mutation,
      variables: {
        input: { sourceDocumentId },
      },
      ...options,
    };

    return commitMutation(environment, config).then(extractEntity);
  };

  return { commit };
};

export const makeUpdateMutation = (mutation, method, entityName, idKey = 'id') => {
  const extractEntity = get(`${method}.${entityName}`);
  const commit = (environment, id, values, options) => {
    const config = {
      mutation,
      variables: {
        input: {
          [idKey]: id,
          [`${entityName}Patch`]: values,
        },
      },
      ...options,
    };

    return commitMutation(environment, config).then(extractEntity);
  };

  const commitValue = (environment, id, key, value, options) => {
    commit(
      environment,
      id,
      { [key]: value },
      {
        optimisticResponse: {
          [method]: {
            [entityName]: { id, [key]: value },
          },
        },
        ...options,
      }
    );
  };

  return { commit, commitValue };
};
