import { getRoutes } from 'utils/crud';

import Index from './index';
import New from './New';
import Edit from './Edit';

import IndexQuery from './EmployeesQuery';
import EditQuery from './Edit/EditEmployeeQuery';

export default getRoutes({
  baseUrl: '/employees',
  Index,
  IndexQuery,
  Edit,
  EditQuery,
  New,
});
