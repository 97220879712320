/**
 * @flow
 * @relayHash 424d1198a44e177b1b269b18d032807c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CurrentQuantityQueryVariables = {|
  productId: number,
  warehouseId: number,
|};
export type CurrentQuantityQueryResponse = {|
  +quantity: ?{|
    +value: any
  |}
|};
export type CurrentQuantityQuery = {|
  variables: CurrentQuantityQueryVariables,
  response: CurrentQuantityQueryResponse,
|};
*/


/*
query CurrentQuantityQuery(
  $productId: Int!
  $warehouseId: Int!
) {
  quantity: productWarehouseQuantityByProductIdAndWarehouseId(productId: $productId, warehouseId: $warehouseId) {
    value
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "productId",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "warehouseId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "productId",
    "variableName": "productId",
    "type": "Int!"
  },
  {
    "kind": "Variable",
    "name": "warehouseId",
    "variableName": "warehouseId",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "CurrentQuantityQuery",
  "id": null,
  "text": "query CurrentQuantityQuery(\n  $productId: Int!\n  $warehouseId: Int!\n) {\n  quantity: productWarehouseQuantityByProductIdAndWarehouseId(productId: $productId, warehouseId: $warehouseId) {\n    value\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CurrentQuantityQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "quantity",
        "name": "productWarehouseQuantityByProductIdAndWarehouseId",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProductWarehouseQuantity",
        "plural": false,
        "selections": [
          v2
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CurrentQuantityQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "quantity",
        "name": "productWarehouseQuantityByProductIdAndWarehouseId",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProductWarehouseQuantity",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '37bde4bbc84782813e80251fe736a3ad';
module.exports = node;
