import React from 'react';
import { Button, Modal } from 'antd';
import './JSONDetailModal.css';

class JSONDetailModal extends React.Component {
  state = {
    visible: false,
  };

  show = () => this.setState({ visible: true });
  close = () => this.setState({ visible: false });

  renderData = () => {
    const data = JSON.parse(this.props.data);

    return (
      <table styleName="table">
        <tbody>
          {Object.keys(data).map(k => (
            <tr key={k}>
              <td>{k}</td>
              <td>{data[k]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.props.children ? (
          <span onClick={this.show}>{this.props.children}</span>
        ) : (
          <Button size="small" onClick={this.show}>
            Детали
          </Button>
        )}
        <Modal title="Детали" visible={this.state.visible} footer={null} onCancel={this.close}>
          {this.state.visible && this.renderData()}
        </Modal>
      </React.Fragment>
    );
  }
}

export default JSONDetailModal;
