exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-ShopOrderDocuments-Map-___OrderBalloon__title___2SyV9 {\n  font-size: 12px;\n  font-weight: bold;\n  margin: 10px 0 0 0;\n}\n", ""]);

// exports
exports.locals = {
	"title": "src-pages-ShopOrderDocuments-Map-___OrderBalloon__title___2SyV9"
};