/**
 * @flow
 * @relayHash c7f8b9a3f35dd3a4d756b05c2e1f0bf8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditProductCategoryQueryVariables = {|
  rowId: number
|};
export type EditProductCategoryQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +name: string,
  |}
|};
export type EditProductCategoryQuery = {|
  variables: EditProductCategoryQueryVariables,
  response: EditProductCategoryQueryResponse,
|};
*/


/*
query EditProductCategoryQuery(
  $rowId: Int!
) {
  data: productCategoryByRowId(rowId: $rowId) {
    id
    rowId
    name
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "data",
    "name": "productCategoryByRowId",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "rowId",
        "variableName": "rowId",
        "type": "Int!"
      }
    ],
    "concreteType": "ProductCategory",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rowId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "name",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditProductCategoryQuery",
  "id": null,
  "text": "query EditProductCategoryQuery(\n  $rowId: Int!\n) {\n  data: productCategoryByRowId(rowId: $rowId) {\n    id\n    rowId\n    name\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditProductCategoryQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "EditProductCategoryQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4f1baf478bf756c521663485f7b980b1';
module.exports = node;
