/**
 * @flow
 * @relayHash 4f9a4cf6ea702ea2c5d209a2b9979771
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateWarehouseInput = {
  clientMutationId?: ?string,
  warehouse: WarehouseInput,
};
export type WarehouseInput = {
  rowId?: ?number,
  name: string,
};
export type CreateWarehouseMutationVariables = {|
  input: CreateWarehouseInput
|};
export type CreateWarehouseMutationResponse = {|
  +createWarehouse: ?{|
    +warehouse: ?{|
      +rowId: number
    |}
  |}
|};
export type CreateWarehouseMutation = {|
  variables: CreateWarehouseMutationVariables,
  response: CreateWarehouseMutationResponse,
|};
*/


/*
mutation CreateWarehouseMutation(
  $input: CreateWarehouseInput!
) {
  createWarehouse(input: $input) {
    warehouse {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateWarehouseInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateWarehouseInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateWarehouseMutation",
  "id": null,
  "text": "mutation CreateWarehouseMutation(\n  $input: CreateWarehouseInput!\n) {\n  createWarehouse(input: $input) {\n    warehouse {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateWarehouseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createWarehouse",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateWarehousePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "warehouse",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateWarehouseMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createWarehouse",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateWarehousePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "warehouse",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '731d21be99ef7848a3e65865c9edb0f2';
module.exports = node;
