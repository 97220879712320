/**
 * @flow
 * @relayHash ae07cf59210008f0b98d649dfccb64f1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WriteOffDocumentsOrderBy = "CATEGORY_ID_ASC" | "CATEGORY_ID_DESC" | "COMMENT_ASC" | "COMMENT_DESC" | "EVENTED_AT_ASC" | "EVENTED_AT_DESC" | "ID_ASC" | "ID_DESC" | "NATURAL" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "TOTAL_COST_ASC" | "TOTAL_COST_DESC" | "%future added value";
export type WriteOffDocumentsQueryVariables = {|
  first: number,
  offset: number,
  orderBy?: ?$ReadOnlyArray<WriteOffDocumentsOrderBy>,
|};
export type WriteOffDocumentsQueryResponse = {|
  +rows: ?{|
    +totalCount: number,
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +eventedAt: any,
      +comment: string,
      +totalCost: ?any,
      +category: ?{|
        +rowId: number,
        +name: string,
      |},
    |}>,
  |}
|};
export type WriteOffDocumentsQuery = {|
  variables: WriteOffDocumentsQueryVariables,
  response: WriteOffDocumentsQueryResponse,
|};
*/


/*
query WriteOffDocumentsQuery(
  $first: Int!
  $offset: Int!
  $orderBy: [WriteOffDocumentsOrderBy!]
) {
  rows: allWriteOffDocuments(first: $first, offset: $offset, orderBy: $orderBy) {
    totalCount
    nodes {
      id
      rowId
      eventedAt
      comment
      totalCost
      category: writeOffCategoryByCategoryId {
        rowId
        name
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[WriteOffDocumentsOrderBy!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy",
    "type": "[WriteOffDocumentsOrderBy!]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "eventedAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "comment",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCost",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "WriteOffDocumentsQuery",
  "id": null,
  "text": "query WriteOffDocumentsQuery(\n  $first: Int!\n  $offset: Int!\n  $orderBy: [WriteOffDocumentsOrderBy!]\n) {\n  rows: allWriteOffDocuments(first: $first, offset: $offset, orderBy: $orderBy) {\n    totalCount\n    nodes {\n      id\n      rowId\n      eventedAt\n      comment\n      totalCost\n      category: writeOffCategoryByCategoryId {\n        rowId\n        name\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "WriteOffDocumentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "rows",
        "name": "allWriteOffDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "WriteOffDocumentsConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "WriteOffDocument",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              v7,
              {
                "kind": "LinkedField",
                "alias": "category",
                "name": "writeOffCategoryByCategoryId",
                "storageKey": null,
                "args": null,
                "concreteType": "WriteOffCategory",
                "plural": false,
                "selections": [
                  v4,
                  v8
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "WriteOffDocumentsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "rows",
        "name": "allWriteOffDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "WriteOffDocumentsConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "WriteOffDocument",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              v7,
              {
                "kind": "LinkedField",
                "alias": "category",
                "name": "writeOffCategoryByCategoryId",
                "storageKey": null,
                "args": null,
                "concreteType": "WriteOffCategory",
                "plural": false,
                "selections": [
                  v4,
                  v8,
                  v3
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b80c77d237c6f167316dff4a66dbb941';
module.exports = node;
