import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateProductCategoryMutation($input: CreateProductCategoryInput!) {
    createProductCategory(input: $input) {
      productCategory {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createProductCategory', 'productCategory');
