/**
 * @flow
 * @relayHash 8285f969878c7469f0fee7d60916c25d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ResetSupplyItemQuantitiesInput = {
  clientMutationId?: ?string,
  targetDocumentId: number,
};
export type ResetSupplyItemQuantitiesMutationVariables = {|
  input: ResetSupplyItemQuantitiesInput
|};
export type ResetSupplyItemQuantitiesMutationResponse = {|
  +resetSupplyItemQuantities: ?{|
    +supplyItems: ?$ReadOnlyArray<{|
      +id: string,
      +quantity: any,
    |}>
  |}
|};
export type ResetSupplyItemQuantitiesMutation = {|
  variables: ResetSupplyItemQuantitiesMutationVariables,
  response: ResetSupplyItemQuantitiesMutationResponse,
|};
*/


/*
mutation ResetSupplyItemQuantitiesMutation(
  $input: ResetSupplyItemQuantitiesInput!
) {
  resetSupplyItemQuantities(input: $input) {
    supplyItems {
      id
      quantity
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ResetSupplyItemQuantitiesInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "resetSupplyItemQuantities",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "ResetSupplyItemQuantitiesInput!"
      }
    ],
    "concreteType": "ResetSupplyItemQuantitiesPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "supplyItems",
        "storageKey": null,
        "args": null,
        "concreteType": "SupplyItem",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "quantity",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "ResetSupplyItemQuantitiesMutation",
  "id": null,
  "text": "mutation ResetSupplyItemQuantitiesMutation(\n  $input: ResetSupplyItemQuantitiesInput!\n) {\n  resetSupplyItemQuantities(input: $input) {\n    supplyItems {\n      id\n      quantity\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ResetSupplyItemQuantitiesMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ResetSupplyItemQuantitiesMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '07ca69b45e2cc150833760aaf6603d9f';
module.exports = node;
