/**
 * @flow
 * @relayHash 39ae66fcdefc1470dc92dcd4e737d8a6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ScalesOrderBy = "ADDR_ASC" | "ADDR_DESC" | "COMMENT_ASC" | "COMMENT_DESC" | "ID_ASC" | "ID_DESC" | "NAME_ASC" | "NAME_DESC" | "NATURAL" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "SYNCED_AT_ASC" | "SYNCED_AT_DESC" | "SYNC_ERROR_ASC" | "SYNC_ERROR_DESC" | "%future added value";
export type ScalesQueryVariables = {|
  first: number,
  offset: number,
  orderBy?: ?$ReadOnlyArray<ScalesOrderBy>,
|};
export type ScalesQueryResponse = {|
  +rows: ?{|
    +totalCount: number,
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +syncedAt: ?any,
      +addr: ?string,
      +name: string,
    |}>,
  |}
|};
export type ScalesQuery = {|
  variables: ScalesQueryVariables,
  response: ScalesQueryResponse,
|};
*/


/*
query ScalesQuery(
  $first: Int!
  $offset: Int!
  $orderBy: [ScalesOrderBy!]
) {
  rows: allScales(first: $first, offset: $offset, orderBy: $orderBy) {
    totalCount
    nodes {
      id
      rowId
      syncedAt
      addr
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[ScalesOrderBy!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "rows",
    "name": "allScales",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "offset",
        "variableName": "offset",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy",
        "type": "[ScalesOrderBy!]"
      }
    ],
    "concreteType": "ScalesConnection",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": null,
        "concreteType": "Scale",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rowId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "syncedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "addr",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ScalesQuery",
  "id": null,
  "text": "query ScalesQuery(\n  $first: Int!\n  $offset: Int!\n  $orderBy: [ScalesOrderBy!]\n) {\n  rows: allScales(first: $first, offset: $offset, orderBy: $orderBy) {\n    totalCount\n    nodes {\n      id\n      rowId\n      syncedAt\n      addr\n      name\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ScalesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "ScalesQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '65eec9679204636cbac076d35385fcc5';
module.exports = node;
