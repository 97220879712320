import moment from 'moment';

export default (nextVariables, match) => {
  if (match.location.query.ids) {
    return {
      filter: {
        rowId: {
          in: (Array.isArray(match.location.query.ids) ? match.location.query.ids : [match.location.query.ids]).map(n => parseInt(n, 10)),
        },
      },
    };
  }

  return {
    ...nextVariables,
    filter: {
      deliveryAt: {
        greaterThanOrEqualTo: `${match.location.query.deliverOn || moment().format('YYYY-MM-DD')} ${
          match.location.query.from
        }:00:00`,
      },
      or: match.location.query.labels.split('-').map(label => ({
        label: {
          equalTo: label,
        },
      })),
    },
  };
};
