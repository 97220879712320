// @flow
/* global $NonMaybeType, $ElementType */

import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import AttributeTable from 'components/AttributeTable';
import { getShowActions } from 'utils/crud';
import { getRelationLink, renderIcon, renderDate, renderPrice, renderText } from 'components/helpers';
import { Button, Row, Col } from 'antd';
import { Link } from 'found';
import DataTable from 'components/DataTable';
import ProductSelect from 'containers/ProductSelect';
import InlineInput from 'components/InlineInput';
import CollectionSelect from 'components/CollectionSelect';

import updateItemMutation from 'mutations/UpdateSupplyItemMutation';
import updateMutation from 'mutations/UpdateSupplyDocumentMutation';
import updateItemProductionMutation from 'mutations/UpdateSupplyProductionItemMutation';
import destoyItemMutation from 'mutations/DestroySupplyItemMutation';

import copyMutation from 'mutations/CopySupplyDocumentMutation';
import destroyMutation from 'mutations/DestroySupplyDocumentMutation';
import resetMutation from 'mutations/ResetSupplyItemQuantitiesMutation';

import type { PageProps } from 'types';
import type { ShowSupplyDocumentQueryResponse } from './__generated__/ShowSupplyDocumentQuery.graphql';

import NewItem from './NewItem';

type Props = PageProps & {
  data: $NonMaybeType<$ElementType<ShowSupplyDocumentQueryResponse, 'data'>>,
  allWarehouses: $NonMaybeType<$ElementType<ShowSupplyDocumentQueryResponse, 'allWarehouses'>>,
};

const attributeRows = [
  {
    dataKey: 'eventedAt',
    label: 'Дата',
    render: renderDate,
  },
  {
    dataKey: 'totalSum',
    label: 'Сумма',
    render: renderPrice,
  },
  {
    dataKey: 'comment',
    label: 'Комментарий',
    render: renderText,
  },
  {
    dataKey: 'contractor',
    label: 'Поставщик',
    render: getRelationLink({ path: '/supply/contractors' }),
  },
  {
    dataKey: 'owner',
    label: 'Принял',
    render: o => o && `${o.lastName} ${o.firstName}`,
  },
  {
    dataKey: 'organization.name',
    label: 'Огранизация',
  },
];

const actionMutations = {
  destroyMutation,
  copyMutation,
  resetMutation,
};

const Show = (props: Props) => (
  <Page title={`Поставка #${props.data.rowId}`} actions={getShowActions(props, actionMutations)}>
    <AttributeTable data={props.data} rows={attributeRows} />
    <NewItem
      context={props.context}
      allWarehouses={props.allWarehouses.nodes}
      additionalValues={{ documentId: props.data.rowId }}
    />
    {props.data.productionItems.nodes.length > 0 && (
      <div>
        <h3 style={{ marginTop: 50 }}>Позиции производства</h3>
        <DataTable
          {...props}
          rowKey="id"
          onlyTable
          skipReloadAfterDestroy
          rows={props.data.productionItems}
          actions={[]}
          columns={{
            rowId: {
              hidden: true,
            },
            product: {
              title: 'Продукт',
              sorter: false,
              render: getRelationLink({ path: '/products' }),
            },
            quantity: {
              title: 'Количество',
              width: 200,
              sorter: false,
              render: (value, row) => {
                if (row.overrideQuantity && row.overrideQuantity !== value) {
                  return <div style={{ color: 'red' }}>{value}</div>;
                }

                return value;
              },
            },
            overrideQuantity: {
              title: 'Проверка',
              width: 120,
              sorter: false,
              render: (value, row) => (
                <InlineInput
                  isEqual={(a, b) => parseFloat(a) === parseFloat(b)}
                  transfarmValue={v => v.replace(',', '.')}
                  onUpdate={v =>
                    updateItemProductionMutation.commitValue(props.context.environment, row.id, 'overrideQuantity', v)
                  }
                  value={value}
                />
              ),
            },
            fixed: {
              title: 'Зафиксировано',
              sorter: false,
              width: 200,
              render: (v, row) => {
                if (v) {
                  return renderIcon({ type: 'check-circle', theme: 'outlined' })();
                }

                const fix = () => {
                  updateItemProductionMutation.commit(props.context.environment, row.id, {
                    fixed: true,
                  });
                };

                return (
                  <Button size="small" onClick={fix}>
                    Фиксировать
                  </Button>
                );
              },
            },
          }}
        />
        {props.data.productionItems.nodes.some(n => n && !n.fixed) && (
          <Button
            style={{ marginTop: 20 }}
            onClick={() => {
              props.data.productionItems.nodes.forEach(n => {
                if (n && !n.fixed) {
                  updateItemProductionMutation.commit(props.context.environment, n.id, {
                    fixed: true,
                  });
                }
              });

              updateMutation.commit(props.context.environment, props.data.id, {
                contractorId: 1, // !!! hard way
              });
            }}
          >
            Зафиксировать все
          </Button>
        )}
      </div>
    )}
    <Row gutter={24} style={{ marginTop: 50 }}>
      <Col span={10}>
        <h3>Позиции</h3>
      </Col>
      <Col span={14} style={{ textAlign: 'right' }}>
        <Link to={{ pathname: '/products/print', query: { ids: props.data.items.nodes.map(i => i && i.productId) } }}>
          <Button size="small">Печать</Button>
        </Link>
      </Col>
    </Row>
    <DataTable
      {...props}
      rowKey="id"
      onlyTable
      skipReloadAfterDestroy
      rows={props.data.items}
      destroyMutation={destoyItemMutation}
      actions={['destroy']}
      columns={{
        rowId: {
          title: '№',
          width: 60,
          render: (_, row, index) => {
            if (props.location.query.sortColumn === 'rowId' && props.location.query.sortOrder === 'descend') {
              return `${props.data.items.nodes.length - index}.`;
            }

            return `${index + 1}.`;
          },
        },
        warehouseId: {
          title: 'Склад',
          width: 200,
          render: (value, row) => (
            <CollectionSelect
              style={{ width: '100%' }}
              items={props.allWarehouses.nodes.filter(Boolean)}
              value={value}
              onChange={v => updateItemMutation.commitValue(props.context.environment, row.id, 'warehouseId', v)}
            />
          ),
        },
        productId: {
          title: 'Продукт',
          render: (value, row) => (
            <ProductSelect
              value={value}
              data={row.product}
              environment={props.context.environment}
              onChange={v => updateItemMutation.commitValue(props.context.environment, row.id, 'productId', v)}
            />
          ),
        },
        quantity: {
          title: 'Количество',
          width: 120,
          render: (value, row) => (
            <InlineInput
              transfarmValue={v => v.replace(',', '.')}
              onUpdate={v => updateItemMutation.commitValue(props.context.environment, row.id, 'quantity', v)}
              value={value}
            />
          ),
        },
        price: {
          title: 'Цена',
          width: 120,
          render: (value, row) => (
            <InlineInput
              transfarmValue={v => v.replace(',', '.')}
              onUpdate={v => updateItemMutation.commitValue(props.context.environment, row.id, 'price', v)}
              value={value}
            />
          ),
        },
        totalSum: {
          title: 'Сумма',
          sorter: false,
          width: 120,
          render: (_, row) => renderPrice(row.quantity * row.price),
        },
      }}
    />
  </Page>
);

Show.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    rowId: PropTypes.number.isRequired,
    items: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
    productionItems: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  allWarehouses: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    query: PropTypes.object.isRequired,
  }).isRequired,
  context: PropTypes.shape({
    environment: PropTypes.object.isRequired,
  }).isRequired,
};

export default Show;
