import { compose, withState } from 'recompact';
import { withRouter } from 'found';
import { connect } from 'react-redux';
import { merge } from 'unchanged';
import { showSuccess } from 'utils/notifications';
import { fetchQuery } from 'relay-runtime';
import withRef from 'hoc/withRef';
import withCreateEnhance from 'hoc/withCreateEnhance';
import mutation from 'mutations/CreateSupplyItemMutation';
import quantityQuery from './ProductWarehouseQuantityQuery';

const selector = (state, props) => ({
  defaultWarehouseId: parseInt(props.match.location.query.warehouseId || state.defaultWarehouseId, 10),
});

export default compose(
  withRouter,
  connect(selector),
  withRef,
  withState('quantity', 'setQuantity', null),
  withCreateEnhance({
    mutation,
    formOptions: {
      onFieldsChange: (props, fields, allValues) => {
        if (!allValues.productId.value || !allValues.warehouseId.value) {
          return;
        }

        // skip unnecessary fetch, data already loaded
        if (
          props.quantity &&
          props.quantity.productId === allValues.productId.value &&
          props.quantity.warehouseId === allValues.warehouseId.value
        ) {
          return;
        }

        const variables = {
          productId: allValues.productId.value,
          warehouseId: allValues.warehouseId.value,
        };

        fetchQuery(props.context.environment, quantityQuery, variables).then(data => props.setQuantity(data.quantity));
      },
    },
    onCompleted: ({ rowId, warehouseId }, { router, match, applyToRef, form }) => {
      router.push(merge('query', { addedItemId: rowId, warehouseId }, match.location));
      applyToRef(ref => ref.focus());
      showSuccess();
      form.resetFields();
    },
  })
);
