import { graphql } from 'relay-runtime';

export default graphql`
  query YandexOrdersQuery($first: Int!, $offset: Int!, $orderBy: [YandexOrdersOrderBy!], $filter: YandexOrderFilter) {
    rows: allYandexOrders(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
      totalCount

      nodes {
        id
        rowId
        externalId
        phone
        clientName
        comment
        discriminator
        document: shopOrderDocumentByDocumentId {
          rowId
          owner
          totalSum
          label
          owner
          deliveryAt
          status
          ready
          warehouseByMainWarehouseId {
            name
          }
        }
      }
    }
  }
`;
