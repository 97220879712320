import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'found';
import { set } from 'unchanged';
import LinkButton from 'components/LinkButton';

const Footer = ({ match, rows }) => (
  <div style={{ paddingTop: 20 }}>
    {!match.location.query.showAll &&
      rows.totalCount > 20 && <LinkButton to={set('query.showAll', 1, match.location)}>Загрузить все</LinkButton>}
  </div>
);

Footer.propTypes = {
  match: PropTypes.object.isRequired,
  rows: PropTypes.object,
};

Footer.defaultProps = {
  rows: { totalCount: 0 },
};

export default withRouter(Footer);
