// @flow
import moment from 'moment';

const units = {
  KG: 'кг',
  PCS: 'шт',
};

export const formatQuantity = (value: ?string, unit: string) => {
  let formatValue = value;
  if (value !== null) {
    formatValue = value % 1 !== 0 ? parseFloat(value) : parseInt(value, 10);
  }
  if (unit === 'PCS' && value !== null) {
    return `${formatValue} ${units[unit]}`;
  }

  return value ? `${formatValue.toFixed(3)} ${units[unit]}` : '–';
};

export const formatPrice = (value: ?string) => (value || value === 0 ? `${Math.round(parseFloat(value) * 100) / 100} ₽` : '–');

export const formatOnlyHours = v =>
  moment(v)
    .tz('Europe/Moscow')
    .format('HH:mm');
