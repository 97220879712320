/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type OrderBalloonShopOrderDocumentMap$ref: FragmentReference;
export type OrderBalloonShopOrderDocumentMap = {|
  +rowId: number,
  +sourceUrl: ?string,
  +groupId: ?number,
  +groupPosition: number,
  +group: ?{|
    +id: string,
    +rowId: number,
    +deadlineAt: any,
    +courierId: ?number,
    +comment: string,
  |},
  +$refType: OrderBalloonShopOrderDocumentMap$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "OrderBalloonShopOrderDocumentMap",
  "type": "ShopOrderDocument",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "sourceUrl",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "groupId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "groupPosition",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "group",
      "name": "shopOrderGroupByGroupId",
      "storageKey": null,
      "args": null,
      "concreteType": "ShopOrderGroup",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        v0,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "deadlineAt",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "courierId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "comment",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '5ca161a2d790d6fe599050cf1eca5044';
module.exports = node;
