import React from 'react';
import PropTypes from 'prop-types';
import { Icon as AntIcon } from 'antd';
import { Link } from 'found';
import Icon from 'components/Icon';
import s from './Header.css';

const menuItems = [
  {
    label: 'Позиции',
    icon: 'dashboard',
    to: '/products',
  },
  {
    label: 'Поставки',
    icon: 'local_shipping',
    to: '/supply/documents',
  },
  {
    label: 'Заказы',
    icon: 'shopping_cart',
    to: '/shop/order/documents',
  },
  {
    label: 'Списания',
    icon: 'broken_image',
    to: '/write-off/documents',
  },
  {
    label: 'Перемещения',
    icon: 'loop',
    to: '/warehouse/movement/documents',
  },
  {
    label: 'Выравнивания',
    icon: 'alarm_on',
    to: '/balance/documents',
  },
  {
    label: 'Сотрудники',
    icon: 'verified_user',
    to: '/employees',
  },
];

const canView = (viewer, i) => {
  if (viewer.role !== 'stock_employee_god' && viewer.role !== 'stock_employee_senior' && i.to === '/employees') {
    return false;
  }

  return true;
};

const Header = ({ children, logoText, viewer, onSignout }) => (
  <div styleName="root">
    <Link styleName="logo" to="/" exact>
      {logoText} <AntIcon type="home" /> СКЛАД
    </Link>
    {menuItems.map(
      i =>
        canView(viewer, i) && (
          <Link to={i.to} styleName="menu-item" activeClassName={s.activeMenuItem} key={i.to}>
            <Icon styleName="menu-icon">{i.icon}</Icon>
            <span styleName="menu-text">{i.label}</span>
          </Link>
        )
    )}
    <div styleName="right">
      {children}
      <AntIcon type="logout" styleName="signout" onClick={onSignout} />
    </div>
  </div>
);

Header.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.any,
  logoText: PropTypes.string,
  onSignout: PropTypes.func.isRequired,
  viewer: PropTypes.object.isRequired,
};

Header.defaultProps = {
  logoText: null,
};

export default Header;
