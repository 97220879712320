import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Table, Form, Input } from 'antd';
import { merge } from 'unchanged';
import { renderPrice } from 'components/helpers';
import Page from 'components/Page';
import InlineForm from 'components/InlineForm';
import QuantityInput from 'components/QuantityInput';
import CreateCourierTransactionMutation from 'mutations/CreateCourierTransactionMutation';
import { showErrors } from 'utils/notifications';

const CourierTransactions = ({ data, transactions, form, context, router, match }) => {
  if (!data) {
    return <div>Курьер не найден</div>;
  }

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return;
      }

      try {
        const variables = { ...values, courierId: data.rowId, employeeId: context.viewer.rowId };
        const res = await CreateCourierTransactionMutation.commit(context.environment, variables);

        form.resetFields();

        router.push(merge('query', { addedItemId: res.rowId }, match.location));
      } catch (err) {
        showErrors(err);
      }
    });
  };

  return (
    <Page title={`${data.firstName} ${data.lastName}`} backUrl={`/couriers/${data.rowId}`}>
      <p>Выплаты вводить со знаком минус.</p>
      <InlineForm onSubmit={handleSubmit}>
        <Form.Item label="Комментарий">
          {form.getFieldDecorator('comment', { required: true })(
            <Input autoFocus />
          )}
        </Form.Item>
        <Form.Item label="Сумма">
          {form.getFieldDecorator('value', { required: true })(
            <QuantityInput />
          )}
        </Form.Item>
      </InlineForm>
      <Table
        rowKey="day"
        dataSource={transactions.nodes.filter(Boolean)}
        pagination={false}
        columns={[
          {
            title: 'Добавил',
            dataIndex: 'employee',
            key: 'employee',
            width: 200,
          },
          {
            title: 'Комментарий',
            dataIndex: 'comment',
            key: 'comment',
          },
          {
            title: 'Дата',
            dataIndex: 'day',
            key: 'day',
            render: v => moment(v).tz('Europe/Moscow').format('DD MMM YYYY HH:mm'),
            width: 200,
          },
          {
            title: 'Значение',
            dataIndex: 'value',
            key: 'value',
            render: renderPrice,
            width: 200,
          },
          {
            title: 'Общее',
            dataIndex: 'sum',
            key: 'sum',
            render: renderPrice,
            width: 200,
          },
        ]}
      />
    </Page>
  );
};

CourierTransactions.propTypes = {
  data: PropTypes.any,
  transactions: PropTypes.any,
  form: PropTypes.any.isRequired,
  router: PropTypes.any.isRequired,
  match: PropTypes.any.isRequired,
  context: PropTypes.any.isRequired,
};

export default Form.create()(CourierTransactions);
