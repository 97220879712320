import { graphql } from 'relay-runtime';
import commitMutation from 'relay-commit-mutation-promise';

const mutation = graphql`
  mutation ResetSupplyItemQuantitiesMutation($input: ResetSupplyItemQuantitiesInput!) {
    resetSupplyItemQuantities(input: $input) {
      supplyItems {
        id
        quantity
      }
    }
  }
`;

const commit = (environment, targetDocumentId) =>
  commitMutation(environment, {
    mutation,
    variables: {
      input: { targetDocumentId },
    },
  });

export default { commit };
