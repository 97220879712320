/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type ProductUnit = "KG" | "PCS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type LastSupplyItemsSupplyContractorsShow$ref: FragmentReference;
export type LastSupplyItemsSupplyContractorsShow = $ReadOnlyArray<{|
  +id: string,
  +quantity: any,
  +price: any,
  +warehouseId: number,
  +warehouse: ?{|
    +name: string
  |},
  +document: ?{|
    +rowId: number,
    +eventedAt: any,
  |},
  +product: ?{|
    +rowId: number,
    +name: string,
    +unit: ProductUnit,
    +quantities: {|
      +nodes: $ReadOnlyArray<{|
        +warehouseId: number,
        +value: any,
      |}>
    |},
  |},
  +$refType: LastSupplyItemsSupplyContractorsShow$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "warehouseId",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "LastSupplyItemsSupplyContractorsShow",
  "type": "SupplyItem",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "id",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "quantity",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "price",
      "args": null,
      "storageKey": null
    },
    v0,
    {
      "kind": "LinkedField",
      "alias": "warehouse",
      "name": "warehouseByWarehouseId",
      "storageKey": null,
      "args": null,
      "concreteType": "Warehouse",
      "plural": false,
      "selections": [
        v1
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "document",
      "name": "supplyDocumentByDocumentId",
      "storageKey": null,
      "args": null,
      "concreteType": "SupplyDocument",
      "plural": false,
      "selections": [
        v2,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "eventedAt",
          "args": null,
          "storageKey": null
        }
      ]
    },
    {
      "kind": "LinkedField",
      "alias": "product",
      "name": "productByProductId",
      "storageKey": null,
      "args": null,
      "concreteType": "Product",
      "plural": false,
      "selections": [
        v2,
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "unit",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": "quantities",
          "name": "productWarehouseQuantitiesByProductId",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductWarehouseQuantitiesConnection",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "nodes",
              "storageKey": null,
              "args": null,
              "concreteType": "ProductWarehouseQuantity",
              "plural": true,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "value",
                  "args": null,
                  "storageKey": null
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '671fba3f14c9ac884ae0b323b75c7442';
module.exports = node;
