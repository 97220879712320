import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'found';
import { Button } from 'antd';

const LinkButton = ({ to, buttonType, target, rel, ...props }) => (
  <Link to={to} target={target} rel={rel}>
    <Button type={buttonType} {...props} />
  </Link>
);

LinkButton.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]).isRequired,
  buttonType: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
};

LinkButton.defaultProps = {
  buttonType: null,
  target: null,
  rel: null,
};

export default LinkButton;
