import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import { Table } from 'antd';
import { getRelationLink } from 'components/helpers';
import { formatQuantity } from 'utils/format';

const renderQuery = ({ error, props }) => {
  if (error) {
    return <div>{error.message}</div>;
  }

  if (!props) {
    return <div>Loading</div>;
  }

  return (
    <Table
      dataSource={props.allProductWarehouseQuantities.nodes}
      rowKey="id"
      pagination={false}
      columns={[
        {
          key: 'product',
          dataIndex: 'product',
          title: 'Позиция',
          render: getRelationLink({ path: '/products' }),
        },
        {
          key: 'warehouse',
          dataIndex: 'warehouse.name',
          title: 'Склад',
        },
        {
          key: 'quantity',
          dataIndex: 'value',
          title: 'Количество',
          render: (q, row) => formatQuantity(q, row.product.unit),
        },
      ]}
    />
  );
};

const Quantities = ({ ids, environment }) => (
  <QueryRenderer
    environment={environment}
    query={graphql`
      query QuantitiesOfSupplyContractorQuery($filter: ProductWarehouseQuantityFilter!) {
        allProductWarehouseQuantities(filter: $filter) {
          nodes {
            id
            value
            product: productByProductId {
              rowId
              unit
              name
            }
            warehouse: warehouseByWarehouseId {
              name
            }
          }
        }
      }
    `}
    variables={{
      filter: {
        productId: { in: ids },
      },
    }}
    render={renderQuery}
  />
);

export default Quantities;
