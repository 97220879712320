/**
 * @flow
 * @relayHash 8367b021d73b87477f1831329cfd76e3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShopOrderDocumentStatus = "CANCELED" | "COMPLETED" | "DELIVERED" | "PACKING" | "PENDING" | "SHIPPED" | "%future added value";
export type YandexOrdersOrderBy = "ADDRESS_ASC" | "ADDRESS_DESC" | "CANCELED_AT_ASC" | "CANCELED_AT_DESC" | "CHANGE_ASC" | "CHANGE_DESC" | "CLIENT_NAME_ASC" | "CLIENT_NAME_DESC" | "COMMENT_ASC" | "COMMENT_DESC" | "CREATED_AT_ASC" | "CREATED_AT_DESC" | "DISCRIMINATOR_ASC" | "DISCRIMINATOR_DESC" | "DOCUMENT_ID_ASC" | "DOCUMENT_ID_DESC" | "EXTERNAL_ID_ASC" | "EXTERNAL_ID_DESC" | "ID_ASC" | "ID_DESC" | "INITIAL_PRODUCT_IDS_ASC" | "INITIAL_PRODUCT_IDS_DESC" | "INITIAL_TOTAL_SUM_ASC" | "INITIAL_TOTAL_SUM_DESC" | "NATURAL" | "PAYMENT_TYPE_ASC" | "PAYMENT_TYPE_DESC" | "PHONE_ASC" | "PHONE_DESC" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "%future added value";
export type YandexOrderFilter = {
  rowId?: ?IntFilter,
  documentId?: ?IntFilter,
  externalId?: ?StringFilter,
  discriminator?: ?StringFilter,
  clientName?: ?StringFilter,
  paymentType?: ?StringFilter,
  phone?: ?StringFilter,
  address?: ?StringFilter,
  comment?: ?StringFilter,
  change?: ?BigFloatFilter,
  initialProductIds?: ?IntListFilter,
  initialTotalSum?: ?BigFloatFilter,
  canceledAt?: ?DatetimeFilter,
  createdAt?: ?DatetimeFilter,
  and?: ?$ReadOnlyArray<YandexOrderFilter>,
  or?: ?$ReadOnlyArray<YandexOrderFilter>,
  not?: ?YandexOrderFilter,
};
export type IntFilter = {
  isNull?: ?boolean,
  equalTo?: ?number,
  notEqualTo?: ?number,
  distinctFrom?: ?number,
  notDistinctFrom?: ?number,
  in?: ?$ReadOnlyArray<number>,
  notIn?: ?$ReadOnlyArray<number>,
  lessThan?: ?number,
  lessThanOrEqualTo?: ?number,
  greaterThan?: ?number,
  greaterThanOrEqualTo?: ?number,
};
export type StringFilter = {
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  equalToInsensitive?: ?string,
  notEqualToInsensitive?: ?string,
  distinctFromInsensitive?: ?string,
  notDistinctFromInsensitive?: ?string,
  inInsensitive?: ?$ReadOnlyArray<string>,
  notInInsensitive?: ?$ReadOnlyArray<string>,
  lessThanInsensitive?: ?string,
  lessThanOrEqualToInsensitive?: ?string,
  greaterThanInsensitive?: ?string,
  greaterThanOrEqualToInsensitive?: ?string,
};
export type BigFloatFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type IntListFilter = {
  isNull?: ?boolean,
  equalTo?: ?$ReadOnlyArray<?number>,
  notEqualTo?: ?$ReadOnlyArray<?number>,
  distinctFrom?: ?$ReadOnlyArray<?number>,
  notDistinctFrom?: ?$ReadOnlyArray<?number>,
  lessThan?: ?$ReadOnlyArray<?number>,
  lessThanOrEqualTo?: ?$ReadOnlyArray<?number>,
  greaterThan?: ?$ReadOnlyArray<?number>,
  greaterThanOrEqualTo?: ?$ReadOnlyArray<?number>,
  contains?: ?$ReadOnlyArray<?number>,
  containedBy?: ?$ReadOnlyArray<?number>,
  overlaps?: ?$ReadOnlyArray<?number>,
  anyEqualTo?: ?number,
  anyNotEqualTo?: ?number,
  anyLessThan?: ?number,
  anyLessThanOrEqualTo?: ?number,
  anyGreaterThan?: ?number,
  anyGreaterThanOrEqualTo?: ?number,
};
export type DatetimeFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type YandexOrdersQueryVariables = {|
  first: number,
  offset: number,
  orderBy?: ?$ReadOnlyArray<YandexOrdersOrderBy>,
  filter?: ?YandexOrderFilter,
|};
export type YandexOrdersQueryResponse = {|
  +rows: ?{|
    +totalCount: number,
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +externalId: string,
      +phone: string,
      +clientName: string,
      +comment: string,
      +discriminator: string,
      +document: ?{|
        +rowId: number,
        +owner: ?string,
        +totalSum: ?any,
        +label: ?string,
        +deliveryAt: ?any,
        +status: ShopOrderDocumentStatus,
        +ready: boolean,
        +warehouseByMainWarehouseId: ?{|
          +name: string
        |},
      |},
    |}>,
  |}
|};
export type YandexOrdersQuery = {|
  variables: YandexOrdersQueryVariables,
  response: YandexOrdersQueryResponse,
|};
*/


/*
query YandexOrdersQuery(
  $first: Int!
  $offset: Int!
  $orderBy: [YandexOrdersOrderBy!]
  $filter: YandexOrderFilter
) {
  rows: allYandexOrders(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
    totalCount
    nodes {
      id
      rowId
      externalId
      phone
      clientName
      comment
      discriminator
      document: shopOrderDocumentByDocumentId {
        rowId
        owner
        totalSum
        label
        deliveryAt
        status
        ready
        warehouseByMainWarehouseId {
          name
          id
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[YandexOrdersOrderBy!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "YandexOrderFilter",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter",
    "type": "YandexOrderFilter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy",
    "type": "[YandexOrdersOrderBy!]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "externalId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientName",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "comment",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "discriminator",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "owner",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalSum",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deliveryAt",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ready",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "YandexOrdersQuery",
  "id": null,
  "text": "query YandexOrdersQuery(\n  $first: Int!\n  $offset: Int!\n  $orderBy: [YandexOrdersOrderBy!]\n  $filter: YandexOrderFilter\n) {\n  rows: allYandexOrders(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {\n    totalCount\n    nodes {\n      id\n      rowId\n      externalId\n      phone\n      clientName\n      comment\n      discriminator\n      document: shopOrderDocumentByDocumentId {\n        rowId\n        owner\n        totalSum\n        label\n        deliveryAt\n        status\n        ready\n        warehouseByMainWarehouseId {\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "YandexOrdersQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "rows",
        "name": "allYandexOrders",
        "storageKey": null,
        "args": v1,
        "concreteType": "YandexOrdersConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "YandexOrder",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              {
                "kind": "LinkedField",
                "alias": "document",
                "name": "shopOrderDocumentByDocumentId",
                "storageKey": null,
                "args": null,
                "concreteType": "ShopOrderDocument",
                "plural": false,
                "selections": [
                  v4,
                  v10,
                  v11,
                  v12,
                  v13,
                  v14,
                  v15,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "warehouseByMainWarehouseId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Warehouse",
                    "plural": false,
                    "selections": [
                      v16
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "YandexOrdersQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "rows",
        "name": "allYandexOrders",
        "storageKey": null,
        "args": v1,
        "concreteType": "YandexOrdersConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "YandexOrder",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              {
                "kind": "LinkedField",
                "alias": "document",
                "name": "shopOrderDocumentByDocumentId",
                "storageKey": null,
                "args": null,
                "concreteType": "ShopOrderDocument",
                "plural": false,
                "selections": [
                  v4,
                  v10,
                  v11,
                  v12,
                  v13,
                  v14,
                  v15,
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "warehouseByMainWarehouseId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Warehouse",
                    "plural": false,
                    "selections": [
                      v16,
                      v3
                    ]
                  },
                  v3
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7cc82737124cff900cfeaebf9c00e7d7';
module.exports = node;
