import React from 'react';
import { Select } from 'antd';

class MobileCashierSelect extends React.Component {
  state = { items: [] }

  componentDidMount() {
    fetch('/evator/staff')
      .then(r => r.json())
      .then(items => this.setState({ items }));
  }

  render() {
    return (
      <Select {...this.props}>
        <Select.Option value={null}>---</Select.Option>
        {this.state.items.map(i => (
          <Select.Option key={i.id} value={i.id}>{i.name} {i.lastName}</Select.Option>
        ))}
      </Select>
    );
  }
}

export default MobileCashierSelect;
