exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-___InlineForm__root___2opui {\n  display: flex;\n  margin-bottom: 30px;\n}\n\n@media (max-width: 700px) {\n  .src-components-___InlineForm__root___2opui {\n    flex-wrap: wrap;\n  }\n}\n\n.src-components-___InlineForm__root___2opui > div {\n  flex: 1;\n  margin-right: 10px;\n  margin-bottom: 0;\n}\n\n.src-components-___InlineForm__root___2opui > div:last-child {\n  margin-right: 0;\n}\n\n.src-components-___InlineForm__root___2opui > .src-components-___InlineForm__button-item___2odfV {\n  flex-grow: 0;\n  display: flex;\n  align-items: flex-end;\n  white-space: nowrap;\n}\n\n@media print {\n  .src-components-___InlineForm__root___2opui {\n    display: none;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-components-___InlineForm__root___2opui",
	"button-item": "src-components-___InlineForm__button-item___2odfV"
};