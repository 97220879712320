import { graphql } from 'relay-runtime';
import { makeCopyMutation } from './helpers';

const mutation = graphql`
  mutation CopyBalanceDocumentMutation($input: CopyBalanceDocumentInput!) {
    copyBalanceDocument(input: $input) {
      balanceDocument {
        rowId
      }
    }
  }
`;

export default makeCopyMutation(mutation, 'copyBalanceDocument', 'balanceDocument');
