import { compose, withProps } from 'recompact';
import withCreateEnhance from 'hoc/withCreateEnhance';
import mutation from 'mutations/CreateSupplyDocumentMutation';

export default compose(
  withProps({
    title: 'Создать новую поставку',
  }),
  withCreateEnhance({
    mutation,
  })
);
