/**
 * @flow
 * @relayHash c3811ee5fac62d633011141311e700c7
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductUnit = "KG" | "PCS" | "%future added value";
export type SupplyItemsOrderBy = "DOCUMENT_ID_ASC" | "DOCUMENT_ID_DESC" | "ID_ASC" | "ID_DESC" | "NATURAL" | "PRICE_ASC" | "PRICE_DESC" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "PRODUCT_ID_ASC" | "PRODUCT_ID_DESC" | "QUANTITY_ASC" | "QUANTITY_DESC" | "WAREHOUSE_ID_ASC" | "WAREHOUSE_ID_DESC" | "WILL_BE_SELLED_AT_ASC" | "WILL_BE_SELLED_AT_DESC" | "%future added value";
export type ShowSupplyDocumentQueryVariables = {|
  rowId: number,
  orderBy?: ?$ReadOnlyArray<SupplyItemsOrderBy>,
|};
export type ShowSupplyDocumentQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +eventedAt: any,
    +totalSum: any,
    +comment: string,
    +contractor: ?{|
      +rowId: number,
      +name: string,
    |},
    +organization: ?{|
      +rowId: number,
      +name: string,
    |},
    +owner: ?{|
      +firstName: string,
      +lastName: string,
    |},
    +productionItems: {|
      +nodes: $ReadOnlyArray<{|
        +id: string,
        +quantity: ?any,
        +overrideQuantity: ?any,
        +fixed: boolean,
        +product: ?{|
          +rowId: number,
          +name: string,
          +unit: ProductUnit,
        |},
      |}>
    |},
    +items: {|
      +nodes: $ReadOnlyArray<{|
        +id: string,
        +productId: number,
        +warehouseId: number,
        +product: ?{|
          +rowId: number,
          +name: string,
        |},
        +willBeSelledAt: ?any,
        +quantity: any,
        +price: any,
      |}>
    |},
  |},
  +allWarehouses: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +name: string,
    |}>
  |},
|};
export type ShowSupplyDocumentQuery = {|
  variables: ShowSupplyDocumentQueryVariables,
  response: ShowSupplyDocumentQueryResponse,
|};
*/


/*
query ShowSupplyDocumentQuery(
  $rowId: Int!
  $orderBy: [SupplyItemsOrderBy!]
) {
  data: supplyDocumentByRowId(rowId: $rowId) {
    id
    rowId
    eventedAt
    totalSum
    comment
    contractor: supplyContractorByContractorId {
      rowId
      name
      id
    }
    organization: organizationByOrganizationId {
      rowId
      name
      id
    }
    owner: employeeByOwnerId {
      firstName
      lastName
      id
    }
    productionItems: supplyProductionItemsByDocumentId {
      nodes {
        id
        quantity
        overrideQuantity
        fixed
        product: productByProductId {
          rowId
          name
          unit
          id
        }
      }
    }
    items: supplyItemsByDocumentId(orderBy: $orderBy) {
      nodes {
        id
        productId
        warehouseId
        product: productByProductId {
          rowId
          name
          id
        }
        willBeSelledAt
        quantity
        price
      }
    }
  }
  allWarehouses {
    nodes {
      rowId
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[SupplyItemsOrderBy!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "eventedAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalSum",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "comment",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = [
  v3,
  v7
],
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "overrideQuantity",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "fixed",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "unit",
  "args": null,
  "storageKey": null
},
v15 = [
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy",
    "type": "[SupplyItemsOrderBy!]"
  }
],
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "productId",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "warehouseId",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "willBeSelledAt",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v20 = [
  v3,
  v7,
  v2
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ShowSupplyDocumentQuery",
  "id": null,
  "text": "query ShowSupplyDocumentQuery(\n  $rowId: Int!\n  $orderBy: [SupplyItemsOrderBy!]\n) {\n  data: supplyDocumentByRowId(rowId: $rowId) {\n    id\n    rowId\n    eventedAt\n    totalSum\n    comment\n    contractor: supplyContractorByContractorId {\n      rowId\n      name\n      id\n    }\n    organization: organizationByOrganizationId {\n      rowId\n      name\n      id\n    }\n    owner: employeeByOwnerId {\n      firstName\n      lastName\n      id\n    }\n    productionItems: supplyProductionItemsByDocumentId {\n      nodes {\n        id\n        quantity\n        overrideQuantity\n        fixed\n        product: productByProductId {\n          rowId\n          name\n          unit\n          id\n        }\n      }\n    }\n    items: supplyItemsByDocumentId(orderBy: $orderBy) {\n      nodes {\n        id\n        productId\n        warehouseId\n        product: productByProductId {\n          rowId\n          name\n          id\n        }\n        willBeSelledAt\n        quantity\n        price\n      }\n    }\n  }\n  allWarehouses {\n    nodes {\n      rowId\n      name\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ShowSupplyDocumentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "supplyDocumentByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "SupplyDocument",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "LinkedField",
            "alias": "contractor",
            "name": "supplyContractorByContractorId",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyContractor",
            "plural": false,
            "selections": v8
          },
          {
            "kind": "LinkedField",
            "alias": "organization",
            "name": "organizationByOrganizationId",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": false,
            "selections": v8
          },
          {
            "kind": "LinkedField",
            "alias": "owner",
            "name": "employeeByOwnerId",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": false,
            "selections": [
              v9,
              v10
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "productionItems",
            "name": "supplyProductionItemsByDocumentId",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyProductionItemsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "SupplyProductionItem",
                "plural": true,
                "selections": [
                  v2,
                  v11,
                  v12,
                  v13,
                  {
                    "kind": "LinkedField",
                    "alias": "product",
                    "name": "productByProductId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      v3,
                      v7,
                      v14
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "items",
            "name": "supplyItemsByDocumentId",
            "storageKey": null,
            "args": v15,
            "concreteType": "SupplyItemsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "SupplyItem",
                "plural": true,
                "selections": [
                  v2,
                  v16,
                  v17,
                  {
                    "kind": "LinkedField",
                    "alias": "product",
                    "name": "productByProductId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": v8
                  },
                  v18,
                  v11,
                  v19
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allWarehouses",
        "storageKey": null,
        "args": null,
        "concreteType": "WarehousesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": true,
            "selections": v8
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ShowSupplyDocumentQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "supplyDocumentByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "SupplyDocument",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "LinkedField",
            "alias": "contractor",
            "name": "supplyContractorByContractorId",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyContractor",
            "plural": false,
            "selections": v20
          },
          {
            "kind": "LinkedField",
            "alias": "organization",
            "name": "organizationByOrganizationId",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": false,
            "selections": v20
          },
          {
            "kind": "LinkedField",
            "alias": "owner",
            "name": "employeeByOwnerId",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": false,
            "selections": [
              v9,
              v10,
              v2
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "productionItems",
            "name": "supplyProductionItemsByDocumentId",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyProductionItemsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "SupplyProductionItem",
                "plural": true,
                "selections": [
                  v2,
                  v11,
                  v12,
                  v13,
                  {
                    "kind": "LinkedField",
                    "alias": "product",
                    "name": "productByProductId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      v3,
                      v7,
                      v14,
                      v2
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "items",
            "name": "supplyItemsByDocumentId",
            "storageKey": null,
            "args": v15,
            "concreteType": "SupplyItemsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "SupplyItem",
                "plural": true,
                "selections": [
                  v2,
                  v16,
                  v17,
                  {
                    "kind": "LinkedField",
                    "alias": "product",
                    "name": "productByProductId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": v20
                  },
                  v18,
                  v11,
                  v19
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allWarehouses",
        "storageKey": null,
        "args": null,
        "concreteType": "WarehousesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": true,
            "selections": v20
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a0ab7085396a54581177c57caade452c';
module.exports = node;
