import { graphql } from 'relay-runtime';
import commitMutation from 'relay-commit-mutation-promise';

const mutation = graphql`
  mutation SigninMutation($input: SigninInput!) {
    signin(input: $input) {
      jwtToken
    }
  }
`;

const commit = (environment, input) =>
  commitMutation(environment, {
    mutation,
    variables: { input },
  });

export default { commit };
