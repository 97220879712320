import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroyBalanceItemMutation($input: DeleteBalanceItemInput!) {
    deleteBalanceItem(input: $input) {
      deletedBalanceItemId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteBalanceItem', 'deletedBalanceItemId');
