/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type ShopOrderDocumentStatus = "CANCELED" | "COMPLETED" | "DELIVERED" | "PACKING" | "PENDING" | "SHIPPED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type Documents$ref: FragmentReference;
export type Documents = $ReadOnlyArray<{|
  +id: string,
  +rowId: number,
  +status: ShopOrderDocumentStatus,
  +label: ?string,
  +deliveryAt: ?any,
  +ready: boolean,
  +asap: boolean,
  +comment: string,
  +owner: ?string,
  +groupId: ?number,
  +groupPosition: number,
  +group: ?{|
    +id: string,
    +rowId: number,
    +deadlineAt: any,
  |},
  +$refType: Documents$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "Documents",
  "type": "ShopOrderDocument",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    v0,
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "label",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "deliveryAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ready",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "asap",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "comment",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "owner",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "groupId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "groupPosition",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "group",
      "name": "shopOrderGroupByGroupId",
      "storageKey": null,
      "args": null,
      "concreteType": "ShopOrderGroup",
      "plural": false,
      "selections": [
        v0,
        v1,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "deadlineAt",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = '9ddce7e8a75f50c6ff2bcaa6836486c8';
module.exports = node;
