import React from 'react';
import PropTypes from 'prop-types';
import DataTable from 'components/DataTable';
import { createRefetchContainer, graphql } from 'react-relay';
import DocumentTitle from 'react-document-title';
import cx from 'classnames';
import prepareVariables from './prepareVariables';
import s from './Deficit.css';

class Deficit extends React.Component {
  componentDidMount() {
    setInterval(() => {
      this.props.relay.refetch(prepareVariables({}, this.props.match));
    }, 5000);
  }

  render() {
    const rows = {
      nodes: this.props.rows.nodes.slice().sort((a, b) => a.order.rowId - b.order.rowId),
    };

    const tableProps = {
      pagination: false,
      rowClassName: row => {
        const cid = parseInt(this.props.location.query.cid, 10);

        return cx({
          [s.selected]: row.order.rowId === cid,
          // [s.begin]: index > 0 && this.props.rows.nodes[index - 1].order.rowId !== row.order.rowId,
        });
      },
    };

    return (
      <div>
        <DataTable {...this.props} rows={rows} tableProps={tableProps} />
        <DocumentTitle title={`${this.props.rows.nodes.length} Недостача`} />
      </div>
    );
  }
}

Deficit.propTypes = {
  relay: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  rows: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default createRefetchContainer(
  Deficit,
  graphql`
    fragment DeficitPageShopOrderDocument_rows on ShopOrderItemsConnection {
      nodes {
        id
        orderedQuantity

        order: shopOrderDocumentByDocumentId {
          rowId
          deliveryAt
          owner
          label
          sourceUrl
        }

        product: productByProductId {
          name
          unit
        }
      }
    }
  `,
  graphql`
    query DeficitPageShopOrderDocumentQuery($day: Date!) {
      rows: allDeficitShopOrderItems(day: $day) {
        ...DeficitPageShopOrderDocument_rows
      }
    }
  `
);
