/**
 * @flow
 * @relayHash b71b19c4985848683bae8b5083d6640b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SyncScaleInput = {
  clientMutationId?: ?string,
  id?: ?number,
};
export type SyncScaleMutationVariables = {|
  input: SyncScaleInput
|};
export type SyncScaleMutationResponse = {|
  +syncScale: ?{|
    +clientMutationId: ?string
  |}
|};
export type SyncScaleMutation = {|
  variables: SyncScaleMutationVariables,
  response: SyncScaleMutationResponse,
|};
*/


/*
mutation SyncScaleMutation(
  $input: SyncScaleInput!
) {
  syncScale(input: $input) {
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "SyncScaleInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "syncScale",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "SyncScaleInput!"
      }
    ],
    "concreteType": "SyncScalePayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "clientMutationId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "SyncScaleMutation",
  "id": null,
  "text": "mutation SyncScaleMutation(\n  $input: SyncScaleInput!\n) {\n  syncScale(input: $input) {\n    clientMutationId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SyncScaleMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "SyncScaleMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '842f70f3f6c19308b1ab9d6cfded5876';
module.exports = node;
