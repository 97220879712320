import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateWriteOffDocumentMutation($input: UpdateWriteOffDocumentInput!) {
    updateWriteOffDocument(input: $input) {
      writeOffDocument {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateWriteOffDocument', 'writeOffDocument');
