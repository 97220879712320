/**
 * @flow
 * @relayHash 53cb3ce8425359fa3caf538d352c1204
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShopOrderDocumentPaymentMethod = "BY_CARD" | "BY_CASH" | "DEPOSIT" | "EMPLOYEE" | "ONLINE" | "%future added value";
export type ShopOrderDocumentStatus = "CANCELED" | "COMPLETED" | "DELIVERED" | "PACKING" | "PENDING" | "SHIPPED" | "%future added value";
export type EditShopOrderDocumentQueryVariables = {|
  rowId: number
|};
export type EditShopOrderDocumentQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +deliveryAt: ?any,
    +status: ShopOrderDocumentStatus,
    +groupPosition: number,
    +groupId: ?number,
    +deliveryPointBlocked: boolean,
    +confirmedAmount: any,
    +totalSumMultiplier: any,
    +paymentMethod: ShopOrderDocumentPaymentMethod,
    +discount: any,
    +userId: ?string,
    +prevDeliveryPoint: ?{|
      +x: number,
      +y: number,
    |},
    +deliveryPoint: ?{|
      +x: number,
      +y: number,
    |},
  |}
|};
export type EditShopOrderDocumentQuery = {|
  variables: EditShopOrderDocumentQueryVariables,
  response: EditShopOrderDocumentQueryResponse,
|};
*/


/*
query EditShopOrderDocumentQuery(
  $rowId: Int!
) {
  data: shopOrderDocumentByRowId(rowId: $rowId) {
    id
    rowId
    deliveryAt
    status
    groupPosition
    groupId
    deliveryPointBlocked
    confirmedAmount
    totalSumMultiplier
    paymentMethod
    discount
    userId
    prevDeliveryPoint {
      x
      y
    }
    deliveryPoint {
      x
      y
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "x",
    "args": null,
    "storageKey": null
  },
  {
    "kind": "ScalarField",
    "alias": null,
    "name": "y",
    "args": null,
    "storageKey": null
  }
],
v2 = [
  {
    "kind": "LinkedField",
    "alias": "data",
    "name": "shopOrderDocumentByRowId",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "rowId",
        "variableName": "rowId",
        "type": "Int!"
      }
    ],
    "concreteType": "ShopOrderDocument",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rowId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deliveryAt",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "status",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "groupPosition",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "groupId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deliveryPointBlocked",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "confirmedAmount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalSumMultiplier",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "paymentMethod",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "discount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "userId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "prevDeliveryPoint",
        "storageKey": null,
        "args": null,
        "concreteType": "Point",
        "plural": false,
        "selections": v1
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "deliveryPoint",
        "storageKey": null,
        "args": null,
        "concreteType": "Point",
        "plural": false,
        "selections": v1
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditShopOrderDocumentQuery",
  "id": null,
  "text": "query EditShopOrderDocumentQuery(\n  $rowId: Int!\n) {\n  data: shopOrderDocumentByRowId(rowId: $rowId) {\n    id\n    rowId\n    deliveryAt\n    status\n    groupPosition\n    groupId\n    deliveryPointBlocked\n    confirmedAmount\n    totalSumMultiplier\n    paymentMethod\n    discount\n    userId\n    prevDeliveryPoint {\n      x\n      y\n    }\n    deliveryPoint {\n      x\n      y\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditShopOrderDocumentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "EditShopOrderDocumentQuery",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '78869fb65140cc58035e4b0201d5c7b3';
module.exports = node;
