/**
 * @flow
 * @relayHash 1e4170897aa6ac945576b1df0e66f7f5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShowWriteOffDocumentQueryVariables = {|
  rowId: number
|};
export type ShowWriteOffDocumentQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +eventedAt: any,
    +comment: string,
    +totalCost: ?any,
    +category: ?{|
      +name: string
    |},
    +items: {|
      +nodes: $ReadOnlyArray<{|
        +id: string,
        +productId: number,
        +warehouseId: number,
        +quantity: any,
      |}>
    |},
  |},
  +allWarehouses: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +name: string,
    |}>
  |},
|};
export type ShowWriteOffDocumentQuery = {|
  variables: ShowWriteOffDocumentQueryVariables,
  response: ShowWriteOffDocumentQueryResponse,
|};
*/


/*
query ShowWriteOffDocumentQuery(
  $rowId: Int!
) {
  data: writeOffDocumentByRowId(rowId: $rowId) {
    id
    rowId
    eventedAt
    comment
    totalCost
    category: writeOffCategoryByCategoryId {
      name
      id
    }
    items: writeOffItemsByDocumentId {
      nodes {
        id
        productId
        warehouseId
        quantity
      }
    }
  }
  allWarehouses {
    nodes {
      rowId
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "eventedAt",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "comment",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCost",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "LinkedField",
  "alias": "items",
  "name": "writeOffItemsByDocumentId",
  "storageKey": null,
  "args": null,
  "concreteType": "WriteOffItemsConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nodes",
      "storageKey": null,
      "args": null,
      "concreteType": "WriteOffItem",
      "plural": true,
      "selections": [
        v2,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "productId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "warehouseId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "quantity",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ShowWriteOffDocumentQuery",
  "id": null,
  "text": "query ShowWriteOffDocumentQuery(\n  $rowId: Int!\n) {\n  data: writeOffDocumentByRowId(rowId: $rowId) {\n    id\n    rowId\n    eventedAt\n    comment\n    totalCost\n    category: writeOffCategoryByCategoryId {\n      name\n      id\n    }\n    items: writeOffItemsByDocumentId {\n      nodes {\n        id\n        productId\n        warehouseId\n        quantity\n      }\n    }\n  }\n  allWarehouses {\n    nodes {\n      rowId\n      name\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ShowWriteOffDocumentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "writeOffDocumentByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "WriteOffDocument",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "LinkedField",
            "alias": "category",
            "name": "writeOffCategoryByCategoryId",
            "storageKey": null,
            "args": null,
            "concreteType": "WriteOffCategory",
            "plural": false,
            "selections": [
              v7
            ]
          },
          v8
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allWarehouses",
        "storageKey": null,
        "args": null,
        "concreteType": "WarehousesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": true,
            "selections": [
              v3,
              v7
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ShowWriteOffDocumentQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "writeOffDocumentByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "WriteOffDocument",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "LinkedField",
            "alias": "category",
            "name": "writeOffCategoryByCategoryId",
            "storageKey": null,
            "args": null,
            "concreteType": "WriteOffCategory",
            "plural": false,
            "selections": [
              v7,
              v2
            ]
          },
          v8
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allWarehouses",
        "storageKey": null,
        "args": null,
        "concreteType": "WarehousesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": true,
            "selections": [
              v3,
              v7,
              v2
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '899aaba9e175832c0eb72b8cc19e3735';
module.exports = node;
