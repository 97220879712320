import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateShopOrderItemMutation($input: CreateShopOrderItemInput!) {
    createShopOrderItem(input: $input) {
      shopOrderItem {
        shopOrderDocumentByDocumentId {
          ...ShowYandexOrderDocument
        }
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createShopOrderItem', 'shopOrderItem');
