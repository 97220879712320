import { compose, withProps } from 'recompact';
import withCreateEnhance from 'hoc/withCreateEnhance';
import mutation from 'mutations/CreateSupplyContractorMutation';

export default compose(
  withProps({
    title: 'Создать нового поставщика',
  }),
  withCreateEnhance({
    mutation,
  })
);
