import { graphql } from 'relay-runtime';

export default graphql`
  query EditWarehouseMovementDocumentQuery($rowId: Int!) {
    data: warehouseMovementDocumentByRowId(rowId: $rowId) {
      id
      rowId
      eventedAt
      comment
    }
  }
`;
