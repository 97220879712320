import React from 'react';
import PropTypes from 'prop-types';
import { Popconfirm, Button } from 'antd';

const defaultConfirmProps = {
  okText: 'Да',
  cancelText: 'Нет',
};

const ConfirmButton = ({ confirm, ...props }) => (
  <Popconfirm {...defaultConfirmProps} {...confirm}>
    <Button {...props} />
  </Popconfirm>
);

ConfirmButton.propTypes = {
  confirm: PropTypes.shape(Popconfirm.propTypes).isRequired,
};

export default ConfirmButton;
