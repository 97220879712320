/**
 * @flow
 * @relayHash 902b3c049811d617f2d25b04f34c323f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateProductCategoryInput = {
  clientMutationId?: ?string,
  productCategory: ProductCategoryInput,
};
export type ProductCategoryInput = {
  rowId?: ?number,
  name: string,
  position?: ?number,
};
export type CreateProductCategoryMutationVariables = {|
  input: CreateProductCategoryInput
|};
export type CreateProductCategoryMutationResponse = {|
  +createProductCategory: ?{|
    +productCategory: ?{|
      +rowId: number
    |}
  |}
|};
export type CreateProductCategoryMutation = {|
  variables: CreateProductCategoryMutationVariables,
  response: CreateProductCategoryMutationResponse,
|};
*/


/*
mutation CreateProductCategoryMutation(
  $input: CreateProductCategoryInput!
) {
  createProductCategory(input: $input) {
    productCategory {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateProductCategoryInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateProductCategoryInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateProductCategoryMutation",
  "id": null,
  "text": "mutation CreateProductCategoryMutation(\n  $input: CreateProductCategoryInput!\n) {\n  createProductCategory(input: $input) {\n    productCategory {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateProductCategoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createProductCategory",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateProductCategoryPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productCategory",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductCategory",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateProductCategoryMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createProductCategory",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateProductCategoryPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productCategory",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductCategory",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c97819a0987f1c750b32b263b28fc3db';
module.exports = node;
