/**
 * @flow
 * @relayHash cd8ab8b66baf673564a3568e513a7854
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteWarehouseMovementDocumentInput = {
  clientMutationId?: ?string,
  id: string,
};
export type DestroyWarehouseMovementDocumentMutationVariables = {|
  input: DeleteWarehouseMovementDocumentInput
|};
export type DestroyWarehouseMovementDocumentMutationResponse = {|
  +deleteWarehouseMovementDocument: ?{|
    +deletedWarehouseMovementDocumentId: ?string
  |}
|};
export type DestroyWarehouseMovementDocumentMutation = {|
  variables: DestroyWarehouseMovementDocumentMutationVariables,
  response: DestroyWarehouseMovementDocumentMutationResponse,
|};
*/


/*
mutation DestroyWarehouseMovementDocumentMutation(
  $input: DeleteWarehouseMovementDocumentInput!
) {
  deleteWarehouseMovementDocument(input: $input) {
    deletedWarehouseMovementDocumentId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteWarehouseMovementDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteWarehouseMovementDocument",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteWarehouseMovementDocumentInput!"
      }
    ],
    "concreteType": "DeleteWarehouseMovementDocumentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedWarehouseMovementDocumentId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DestroyWarehouseMovementDocumentMutation",
  "id": null,
  "text": "mutation DestroyWarehouseMovementDocumentMutation(\n  $input: DeleteWarehouseMovementDocumentInput!\n) {\n  deleteWarehouseMovementDocument(input: $input) {\n    deletedWarehouseMovementDocumentId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DestroyWarehouseMovementDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DestroyWarehouseMovementDocumentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0d5b276befe0323b6db792173ec6af62';
module.exports = node;
