import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, DatePicker, Button } from 'antd';
import PageForm from 'components/PageForm';

const BalanceDocumentForm = ({ title, form, handleSubmit, submitting }) => (
  <PageForm onSubmit={handleSubmit} title={title}>
    <Form.Item label="Дата поставки">
      {form.getFieldDecorator('eventedAt')(<DatePicker showTime={{ format: 'HH:mm' }} format="DD.MM.YYYY HH:mm" />)}
    </Form.Item>
    <Form.Item label="Комментарий">{form.getFieldDecorator('comment')(<Input.TextArea rows={4} />)}</Form.Item>
    <Form.Item>
      <Button disabled={submitting} type="primary" htmlType="submit">
        Сохранить
      </Button>
    </Form.Item>
  </PageForm>
);

BalanceDocumentForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default BalanceDocumentForm;
