import { graphql } from 'relay-runtime';

export default graphql`
  query ShowBalanceDocumentQuery($rowId: Int!) {
    data: balanceDocumentByRowId(rowId: $rowId) {
      id
      rowId
      eventedAt
      comment

      items: balanceItemsByDocumentId {
        nodes {
          id
          productId
          warehouseId
          quantity
        }
      }
    }

    allWarehouses {
      nodes {
        rowId
        name
      }
    }
  }
`;
