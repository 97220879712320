/**
 * @flow
 * @relayHash 27a3dee356511df3cfd024d8fdcc8b5e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateShopDepositReplenishmentInput = {
  clientMutationId?: ?string,
  shopDepositReplenishment: ShopDepositReplenishmentInput,
};
export type ShopDepositReplenishmentInput = {
  rowId?: ?number,
  createdAt?: ?any,
  userId: string,
  userOwner: string,
  value: any,
  comment?: ?string,
  sellerId?: ?number,
};
export type CreateShopDepositReplenishmentMutationVariables = {|
  input: CreateShopDepositReplenishmentInput
|};
export type CreateShopDepositReplenishmentMutationResponse = {|
  +createShopDepositReplenishment: ?{|
    +shopDepositReplenishment: ?{|
      +rowId: number
    |}
  |}
|};
export type CreateShopDepositReplenishmentMutation = {|
  variables: CreateShopDepositReplenishmentMutationVariables,
  response: CreateShopDepositReplenishmentMutationResponse,
|};
*/


/*
mutation CreateShopDepositReplenishmentMutation(
  $input: CreateShopDepositReplenishmentInput!
) {
  createShopDepositReplenishment(input: $input) {
    shopDepositReplenishment {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateShopDepositReplenishmentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateShopDepositReplenishmentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateShopDepositReplenishmentMutation",
  "id": null,
  "text": "mutation CreateShopDepositReplenishmentMutation(\n  $input: CreateShopDepositReplenishmentInput!\n) {\n  createShopDepositReplenishment(input: $input) {\n    shopDepositReplenishment {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateShopDepositReplenishmentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createShopDepositReplenishment",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateShopDepositReplenishmentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shopDepositReplenishment",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopDepositReplenishment",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateShopDepositReplenishmentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createShopDepositReplenishment",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateShopDepositReplenishmentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shopDepositReplenishment",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopDepositReplenishment",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6bd16f089f75c26c1e610cfd95a91af8';
module.exports = node;
