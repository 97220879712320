import { graphql } from 'relay-runtime';

export default graphql`
  query ShopDepositReplenishmentsQuery(
    $first: Int!
    $offset: Int!
    $orderBy: [ShopDepositReplenishmentsOrderBy!]
    $filter: ShopDepositReplenishmentFilter
  ) {
    rows: allShopDepositReplenishments(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
      totalCount

      nodes {
        id
        rowId
        userId
        createdAt
        userOwner
        value
      }
    }

    allEmployees {
      nodes {
        id
        rowId
        firstName
        lastName
      }
    }
  }
`;
