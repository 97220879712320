import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroyShopDepositReplenishmentMutation($input: DeleteShopDepositReplenishmentInput!) {
    deleteShopDepositReplenishment(input: $input) {
      deletedShopDepositReplenishmentId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteShopDepositReplenishment', 'deletedShopDepositReplenishmentId');
