/**
 * @flow
 * @relayHash 0c7a33afee4ac2c3ca7be4b8236acaa9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type WarehousesOrderBy = "ID_ASC" | "ID_DESC" | "NAME_ASC" | "NAME_DESC" | "NATURAL" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "%future added value";
export type WarehousesQueryVariables = {|
  first: number,
  offset: number,
  orderBy?: ?$ReadOnlyArray<WarehousesOrderBy>,
|};
export type WarehousesQueryResponse = {|
  +rows: ?{|
    +totalCount: number,
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +name: string,
    |}>,
  |}
|};
export type WarehousesQuery = {|
  variables: WarehousesQueryVariables,
  response: WarehousesQueryResponse,
|};
*/


/*
query WarehousesQuery(
  $first: Int!
  $offset: Int!
  $orderBy: [WarehousesOrderBy!]
) {
  rows: allWarehouses(first: $first, offset: $offset, orderBy: $orderBy) {
    totalCount
    nodes {
      id
      rowId
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[WarehousesOrderBy!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "rows",
    "name": "allWarehouses",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "offset",
        "variableName": "offset",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy",
        "type": "[WarehousesOrderBy!]"
      }
    ],
    "concreteType": "WarehousesConnection",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": null,
        "concreteType": "Warehouse",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rowId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "name",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "WarehousesQuery",
  "id": null,
  "text": "query WarehousesQuery(\n  $first: Int!\n  $offset: Int!\n  $orderBy: [WarehousesOrderBy!]\n) {\n  rows: allWarehouses(first: $first, offset: $offset, orderBy: $orderBy) {\n    totalCount\n    nodes {\n      id\n      rowId\n      name\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "WarehousesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "WarehousesQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2094d14a67d4c291a3f2c533218a0fb8';
module.exports = node;
