const F7 = 118; // F7

let sequence = '';
let insideCode = false;

const keyDownHandler = e => {
  if (e.which === F7) {
    if (!insideCode) {
      insideCode = true;
      e.preventDefault();
    } else {
      document.dispatchEvent(new CustomEvent('readBarcode', { detail: sequence.toLowerCase() }));
      insideCode = false;
      sequence = '';
      e.preventDefault();
    }
  } else if (insideCode) {
    sequence += String.fromCharCode(e.which);
    e.preventDefault();
  }
};

document.addEventListener('keydown', keyDownHandler, true);
