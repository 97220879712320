import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateWarehouseMutation($input: CreateWarehouseInput!) {
    createWarehouse(input: $input) {
      warehouse {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createWarehouse', 'warehouse');
