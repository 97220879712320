import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateProductGroupMutation($input: CreateProductGroupInput!) {
    createProductGroup(input: $input) {
      productGroup {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createProductGroup', 'productGroup');
