import { withProps, lifecycle, compose } from 'recompact';
import { fromPairs } from 'lodash';

export default compose(
  withProps(props => ({
    categories: fromPairs(props.allProductCategories.nodes.map(c => [c.rowId, c])),
    groups: fromPairs(props.allProductGroups.nodes.map(g => [g.rowId, g])),
  })),
  lifecycle({
    componentDidMount() {
      if (this.props.location.query.listen) {
        this.listenMessage = e => {
          if (e.data === 'print') {
            if (document.readyState === 'complete') {
              window.print();
              e.source.postMessage('printed', '*');
            } else {
              window.addEventListener('load', () => {
                window.print();
                e.source.postMessage('printed', '*');
              });
            }
          }
        };

        window.addEventListener('message', this.listenMessage);
      }
    },
    componentWillUnmount() {
      window.removeEventListener('message', this.listenMessage);
    },
  })
);
