import query from './MovesQuery';
import Index from './index';

export default [
  {
    path: '/moves/:productId',
    Component: Index,
    query,
    prepareVariables: (nextVariables, { location, params }) => ({
      ...nextVariables,
      rowId: parseInt(params.productId, 10),
      limit: location.query.showAll ? null : 30,
      condition: {
        ...params,
        productId: parseInt(params.productId, 10),
        warehouseId: parseInt(location.query.warehouseId, 10),
      },
    }),
  },
];
