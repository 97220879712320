exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-ShopOrderDocuments-___styles__normal___d3zHi {\n  background: #d2ffd0;\n}\n\n.src-pages-ShopOrderDocuments-___styles__canceled___1foYc {\n  display: inline-block;\n  padding: 3px;\n  margin: -3px;\n  background: #ff6c6c;\n  color: white;\n}\n\n.src-pages-ShopOrderDocuments-___styles__printed___F7QGI {\n}\n", ""]);

// exports
exports.locals = {
	"normal": "src-pages-ShopOrderDocuments-___styles__normal___d3zHi",
	"canceled": "src-pages-ShopOrderDocuments-___styles__canceled___1foYc",
	"printed": "src-pages-ShopOrderDocuments-___styles__printed___F7QGI"
};