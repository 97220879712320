// @flow
/* global $NonMaybeType, $ElementType */

import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import AttributeTable from 'components/AttributeTable';
import DataTable from 'components/DataTable';
import ProductSelect from 'containers/ProductSelect';
import CollectionSelect from 'components/CollectionSelect';
import InlineInput from 'components/InlineInput';
import { getShowActions } from 'utils/crud';
import { renderDate, getRelationLink } from 'components/helpers';
import transform from 'utils/transform';

import updateItemMutation from 'mutations/UpdateWriteOffItemMutation';
import destoyItemMutation from 'mutations/DestroyWriteOffItemMutation';
import destroyMutation from 'mutations/DestroyWriteOffDocumentMutation';

import type { PageProps } from 'types';
import type { ShowWriteOffDocumentQueryResponse } from './__generated__/ShowWriteOffDocumentQuery.graphql';

import NewItem from './NewItem';

type Props = PageProps & {
  data: $NonMaybeType<$ElementType<ShowWriteOffDocumentQueryResponse, 'data'>>,
};

const attributeRows = [
  {
    dataKey: 'category',
    label: 'Категория',
    render: getRelationLink({ path: '/write-off/documents' }),
  },
  {
    dataKey: 'eventedAt',
    label: 'Дата',
    render: renderDate,
  },
  {
    dataKey: 'comment',
    label: 'Коментарий',
  },
];

const actionMutations = {
  destroyMutation,
};

const getPageActions = (props, mutations) => ({
  groupActions: {
    type: 'button',
    children: 'Режим со сканером',
    onClick: () => {
      props.router.push(transform('query.barcode', v => (v === 'yes' ? 'no' : 'yes'), props.location));
    },
    icon: 'barcode',
  },
  ...getShowActions(props, mutations),
});

const Show = (props: Props) => (
  <Page
    title={`Списание #${props.data.rowId}`}
    actions={getPageActions(props, actionMutations)}
    backUrl={props.route.baseUrl}
  >
    <AttributeTable data={props.data} rows={attributeRows} />
    <NewItem
      context={props.context}
      allWarehouses={props.allWarehouses.nodes}
      additionalValues={{ documentId: props.data.rowId }}
      withBarcode={props.location.query.barcode === 'yes'}
    />
    <h3 style={{ marginTop: 50 }}>Позиции</h3>
    <DataTable
      {...props}
      rowKey="id"
      onlyTable
      skipReloadAfterDestroy
      rows={props.data.items}
      destroyMutation={destoyItemMutation}
      actions={['destroy']}
      columns={{
        rowId: {
          title: '№',
          width: 60,
          render: (_, row, index) => {
            if (props.location.query.sortColumn === 'rowId' && props.location.query.sortOrder === 'descend') {
              return `${props.data.items.nodes.length - index}.`;
            }

            return `${index + 1}.`;
          },
        },
        warehouseId: {
          title: 'Склад',
          width: 200,
          render: (value, row) => (
            <CollectionSelect
              style={{ width: '100%' }}
              items={props.allWarehouses.nodes.filter(Boolean)}
              value={value}
              onChange={v => updateItemMutation.commitValue(props.context.environment, row.id, 'warehouseId', v)}
            />
          ),
        },
        productId: {
          title: 'Продукт',
          render: (value, row) => (
            <ProductSelect
              value={value}
              data={row.product}
              environment={props.context.environment}
              onChange={v => updateItemMutation.commitValue(props.context.environment, row.id, 'productId', v)}
            />
          ),
        },
        quantity: {
          title: 'Количество',
          width: 120,
          render: (value, row) => (
            <InlineInput
              transfarmValue={v => v.replace(',', '.')}
              isEqual={(a, b) => parseFloat(a) === parseFloat(b)}
              onUpdate={v => updateItemMutation.commitValue(props.context.environment, row.id, 'quantity', v)}
              value={value}
            />
          ),
        },
      }}
    />
  </Page>
);

Show.propTypes = {
  route: PropTypes.object.isRequired,
  data: PropTypes.shape({
    rowId: PropTypes.number.isRequired,
    items: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  allWarehouses: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    query: PropTypes.object.isRequired,
  }).isRequired,
  context: PropTypes.shape({
    environment: PropTypes.object.isRequired,
  }).isRequired,
};

export default Show;
