import React from 'react';
import PropTypes from 'prop-types';
import { graphql, createFragmentContainer } from 'react-relay';
import { Tag, Select, notification } from 'antd';
import AttributeTable from 'components/AttributeTable';
import DataTable from 'components/DataTable';
import Page from 'components/Page';
import CreateShopOrderItemMutation from 'mutations/CreateShopOrderItemMutation';
import EditQuantity from 'pages/ShopOrderDocuments/Show/EditQuantity';
import { getRelationLink, renderPrice, renderIcon } from 'components/helpers';
import { withState } from 'recompact';
import updateItemMutation from 'mutations/UpdateShopOrderItemMutation';

const getActions = () => ({});

const owners = {
  'myfresh-yandex': 'myfresh',
  'moscowfresh-yandex': 'moscowfresh',
  'familyfriend-yandex': 'familyfriend',
  myfresh: 'myfresh',
  moscowfresh: 'moscowfresh',
  familyfriend: 'familyfriend',
  'myfresh-deliveryclub': 'myfresh',
  'moscowfresh-deliveryclub': 'moscowfresh',
  'familyfriend-deliveryclub': 'familyfriend',
  'myfresh-piter': 'myfresh',
  'moscowfresh-piter': 'moscowfresh',
  'familyfriend-piter': 'familyfriend',
  'sbermarket-myfresh': 'myfresh',
  'edoque-yandex': 'edoque',
};

const DocumentInner = props => (
  <div>
    <DataTable
      {...props}
      rowKey="id"
      onlyTable
      rows={props.data.items}
      actions={[]}
      columns={{
        rowId: {
          title: '№',
          width: 60,
          hidden: true,
        },
        product: {
          title: 'Продукт',
          render: (product, row) => (
            <div>
              <input type="radio" name="replacedBy" value={row.id} onChange={e => props.setReplacedId(e.target.value)} />{' '}
              {getRelationLink({ path: '/products' })(product, row)}
            </div>
          ),
          sorter: false,
        },
        printed: {
          title: 'Чек',
          render: renderIcon({ type: 'check-circle', theme: 'outlined' }),
          align: 'center',
          width: 70,
          sorter: false,
        },
        deficit: {
          title: 'Дефицит',
          render: renderIcon({ type: 'stop', theme: 'twoTone', twoToneColor: 'red' }),
          align: 'center',
          width: 120,
          sorter: false,
        },
        orderedQuantity: {
          title: 'Заказано',
          width: 100,
          // eslint-disable-next-line
          render: (v, row) => <EditQuantity data={row} context={props.context} field="orderedQuantity" />,
          sorter: false,
        },
        quantity: {
          title: 'Пробито',
          sorter: false,
          width: 100,
          // eslint-disable-next-line
          render: (v, row) => <EditQuantity data={row} context={props.context} />,
        },
        price: {
          title: 'Цена',
          render: renderPrice,
          sorter: false,
        },
        diff: {
          title: 'Разница',
          sorter: false,
          render: (_, row) =>
            renderPrice(
              row.quantity &&
                row.price * row.priceMultiplier * row.quantity - row.price * row.priceMultiplier * row.orderedQuantity
            ),
        },
        totalSum: {
          sorter: false,
          title: 'Итого',
          render: (_, row) => renderPrice(row.quantity && row.quantity * row.price * row.priceMultiplier),
        },
      }}
    />
  </div>
);

const Document = createFragmentContainer(
  DocumentInner,
  graphql`
    fragment ShowYandexOrderDocument on ShopOrderDocument {
      rowId
      owner
      mainWarehouseId

      items: shopOrderItemsByDocumentId(orderBy: [DEFICIT_DESC]) {
        nodes {
          rowId
          id

          printed
          deficit

          product: productByProductId {
            rowId
            name
            unit
          }

          orderedQuantity
          quantity

          price
        }
      }
    }
  `
);

const Show = props => {
  const owner = owners[props.data.owner];
  if (!owner) {
    return <div>unknown owner {props.data.owner}</div>;
  }

  // get new shop id
  let nomenclatures = props.allYandexNomenclatures.nodes.filter(
    n => n.shop === `${owner}-${props.data.mainWarehouseId}`
  );

  // old data
  if (nomenclatures.length === 0) {
    nomenclatures = props.allYandexNomenclatures.nodes.filter(n => n.shop === owner);
  }

  if (nomenclatures.length === 0) {
    return <div>Номенклатура для {props.data.owner} не найдена</div>;
  }

  const yandexOrder = props.data.yandexOrdersByDocumentId.nodes[0];
  if (!yandexOrder) {
    return <div>Заказ яндекса не найден, это странно, обратитесь к разработчику.</div>;
  }

  const filterOption = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const createItem = async productId => {
    const item = nomenclatures.find(n => n.productId === productId);
    if (!item) {
      return;
    }

    await CreateShopOrderItemMutation.commit(props.context.environment, {
      warehouseId: 2,
      documentId: props.data.rowId,
      productId,
      orderedQuantity: parseFloat(props.data.grammar) || 1,
      price: item.price / (parseFloat(item.grammar) || 1),
    });

    if (props.replacedId) {
      await updateItemMutation.commitValue(props.context.environment, props.replacedId, 'replacedByProductId', productId);
    }

    notification.success({
      message: 'Успешно',
      description: 'Новая позиция добавлена',
    });
  };

  return (
    <Page title={`Заказ #${props.data.rowId}`} actions={getActions(props)}>
      <AttributeTable
        data={yandexOrder}
        rows={[
          {
            dataKey: 'owner',
            label: 'Витрина',
            render: () => props.data.owner,
          },
          {
            dataKey: 'externalId',
            label: 'ЯндексID',
          },
          {
            dataKey: 'clientName',
            label: 'Имя клиента',
          },
          {
            dataKey: 'phone',
            label: 'Контактный номер',
          },
          {
            dataKey: 'address',
            label: 'Адрес',
          },
          {
            dataKey: 'comment',
            label: 'Комментарий клиента',
          },
          {
            dataKey: 'initialTotalSum',
            label: 'Начальная сумма',
            render: val => {
              const sum = props.data.totalSum || props.data.expectedTotalSum;

              return (
                <div>
                  {renderPrice(val)}
                  {val * 1.1 < sum && (
                    <Tag color="red" style={{ marginLeft: 10 }}>
                      Сумма превышает лимит на {renderPrice(sum - val * 1.1)}
                    </Tag>
                  )}
                </div>
              );
            },
          },
          {
            dataKey: 'expectedTotalSum',
            label: 'Заказано на',
            render: () => renderPrice(props.data.expectedTotalSum),
          },
          {
            dataKey: 'totalSum',
            label: 'Итого',
            render: () => renderPrice(props.data.totalSum),
          },
        ]}
      />
      <br />
      <br />
      Добавить позицию:
      <br />
      <Select
        style={{ width: 300 }}
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        onChange={createItem}
      >
        {nomenclatures.map(n => (
          <Select.Option key={n.productId} value={n.productId}>
            {n.product.name}
          </Select.Option>
        ))}
      </Select>{' '}
      {props.replacedId && <span>Замена {props.replacedId}</span>}
      <br />
      <br />
      <Document {...props} />
    </Page>
  );
};

Show.propTypes = {
  data: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
  allYandexNomenclatures: PropTypes.object.isRequired,
};

const showEnhance = withState('replacedId', 'setReplacedId');

export default showEnhance(Show);

export const ShowQuery = graphql`
  query ShowYandexOrderQuery($rowId: Int!) {
    allYandexNomenclatures {
      nodes {
        shop
        price
        grammar
        productId
        product: productByProductId {
          name
        }
      }
    }

    data: shopOrderDocumentByRowId(rowId: $rowId) {
      rowId
      owner
      totalSum
      expectedTotalSum
      ...ShowYandexOrderDocument

      yandexOrdersByDocumentId {
        nodes {
          externalId
          phone
          clientName
          address
          comment
          initialTotalSum
        }
      }
    }
  }
`;
