/**
 * @flow
 * @relayHash 5fc0d797e4958daee2fac22798837525
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductUnit = "KG" | "PCS" | "%future added value";
export type ProductWarehouseQuantityFilter = {
  productId?: ?IntFilter,
  warehouseId?: ?IntFilter,
  value?: ?BigFloatFilter,
  sustainableValue?: ?BigFloatFilter,
  lastSupplyPrice?: ?BigFloatFilter,
  and?: ?$ReadOnlyArray<ProductWarehouseQuantityFilter>,
  or?: ?$ReadOnlyArray<ProductWarehouseQuantityFilter>,
  not?: ?ProductWarehouseQuantityFilter,
};
export type IntFilter = {
  isNull?: ?boolean,
  equalTo?: ?number,
  notEqualTo?: ?number,
  distinctFrom?: ?number,
  notDistinctFrom?: ?number,
  in?: ?$ReadOnlyArray<number>,
  notIn?: ?$ReadOnlyArray<number>,
  lessThan?: ?number,
  lessThanOrEqualTo?: ?number,
  greaterThan?: ?number,
  greaterThanOrEqualTo?: ?number,
};
export type BigFloatFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type QuantitiesOfSupplyContractorQueryVariables = {|
  filter: ProductWarehouseQuantityFilter
|};
export type QuantitiesOfSupplyContractorQueryResponse = {|
  +allProductWarehouseQuantities: ?{|
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +value: any,
      +product: ?{|
        +rowId: number,
        +unit: ProductUnit,
        +name: string,
      |},
      +warehouse: ?{|
        +name: string
      |},
    |}>
  |}
|};
export type QuantitiesOfSupplyContractorQuery = {|
  variables: QuantitiesOfSupplyContractorQueryVariables,
  response: QuantitiesOfSupplyContractorQueryResponse,
|};
*/


/*
query QuantitiesOfSupplyContractorQuery(
  $filter: ProductWarehouseQuantityFilter!
) {
  allProductWarehouseQuantities(filter: $filter) {
    nodes {
      id
      value
      product: productByProductId {
        rowId
        unit
        name
        id
      }
      warehouse: warehouseByWarehouseId {
        name
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "ProductWarehouseQuantityFilter!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter",
    "type": "ProductWarehouseQuantityFilter"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "unit",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "QuantitiesOfSupplyContractorQuery",
  "id": null,
  "text": "query QuantitiesOfSupplyContractorQuery(\n  $filter: ProductWarehouseQuantityFilter!\n) {\n  allProductWarehouseQuantities(filter: $filter) {\n    nodes {\n      id\n      value\n      product: productByProductId {\n        rowId\n        unit\n        name\n        id\n      }\n      warehouse: warehouseByWarehouseId {\n        name\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "QuantitiesOfSupplyContractorQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allProductWarehouseQuantities",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProductWarehouseQuantitiesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductWarehouseQuantity",
            "plural": true,
            "selections": [
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": "product",
                "name": "productByProductId",
                "storageKey": null,
                "args": null,
                "concreteType": "Product",
                "plural": false,
                "selections": [
                  v4,
                  v5,
                  v6
                ]
              },
              {
                "kind": "LinkedField",
                "alias": "warehouse",
                "name": "warehouseByWarehouseId",
                "storageKey": null,
                "args": null,
                "concreteType": "Warehouse",
                "plural": false,
                "selections": [
                  v6
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "QuantitiesOfSupplyContractorQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allProductWarehouseQuantities",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProductWarehouseQuantitiesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductWarehouseQuantity",
            "plural": true,
            "selections": [
              v2,
              v3,
              {
                "kind": "LinkedField",
                "alias": "product",
                "name": "productByProductId",
                "storageKey": null,
                "args": null,
                "concreteType": "Product",
                "plural": false,
                "selections": [
                  v4,
                  v5,
                  v6,
                  v2
                ]
              },
              {
                "kind": "LinkedField",
                "alias": "warehouse",
                "name": "warehouseByWarehouseId",
                "storageKey": null,
                "args": null,
                "concreteType": "Warehouse",
                "plural": false,
                "selections": [
                  v6,
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9d09e5f547fae3dc69d0b43001f15ff2';
module.exports = node;
