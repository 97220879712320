import { getRoutes } from 'utils/crud';

import Index from './index';
import New from './New';
import Edit from './Edit';
import Show from './Show';

import IndexQuery from './SupplyDocumentsQuery';
import NewQuery from './New/NewSupplyDocumentQuery';
import EditQuery from './Edit/EditSupplyDocumentQuery';
import ShowQuery from './Show/ShowSupplyDocumentQuery';

export default getRoutes({
  baseUrl: '/supply/documents',
  Index,
  IndexQuery,
  Edit,
  EditQuery,
  Show,
  ShowQuery,
  New,
  NewQuery,
});
