import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-refetch';

const User = ({ userFetch }) => {
  if (!userFetch) {
    return null;
  }

  if (userFetch.pending) {
    return <div>loading</div>;
  }

  if (userFetch.rejected) {
    return <div>Error: {userFetch.reason.toString()}</div>;
  }

  if (userFetch.fulfilled) {
    if (!userFetch.value) {
      return <div>Нет данных о клиенте</div>;
    }

    return (
      <div>
        <div>{userFetch.value.name}</div>
        <div>Адрес: {userFetch.value.address}</div>
        <div>Телефон: {userFetch.value.phone}</div>
        <div>
          Комментарий клиента:
          {userFetch.value.comment}
        </div>
      </div>
    );
  }

  return <div>...</div>;
};

User.propTypes = {
  userFetch: PropTypes.object.isRequired,
};

const enhance = connect(props => {
  if (props.owner === 'myfresh') {
    return {
      userFetch: `/secret?stock_id=${props.stockId}`,
    };
  }

  if (props.owner === 'moscowfresh' && props.stockId >= 297767) {
    return {
      userFetch: `https://moscowfresh.ru/stock2?token=freshalliance&id=${props.stockId}`,
    };
  }

  if (props.owner === 'moscowfresh') {
    return {
      userFetch: `https://moscowfresh.ru/stock?token=freshalliance&id=${props.stockId}`,
    };
  }

  return {};
});

export default enhance(User);
