import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateProductAutoWriteOffMutation($input: UpdateProductAutoWriteOffInput!) {
    updateProductAutoWriteOff(input: $input) {
      productAutoWriteOff {
        rowId
        sourceId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateProductAutoWriteOff', 'productAutoWriteOff');
