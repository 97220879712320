import { preparePaginationVariables } from 'utils/pagination';
import moment from 'moment';
import { merge } from 'unchanged';
import transform from 'utils/transform';

export default (nextVariables, match) => {
  const day = match.location.query.deliveryOn || moment().format('YYYY-MM-DD');
  const deliveryAt = {};

  if (match.location.query.from) {
    deliveryAt.greaterThanOrEqualTo = `${day} ${match.location.query.from}:00:00`;
  }

  if (match.location.query.to) {
    deliveryAt.lessThanOrEqualTo = `${day} ${match.location.query.to}:59:00`;
  }

  const variables = preparePaginationVariables(nextVariables, match);

  return transform(
    'filter.status.in',
    v => (v && v.length > 0 ? v : undefined),
    merge(
      'groupFilter',
      {
        deadlineOn: {
          equalTo: day,
        },
      },
      merge(
        'filter',
        {
          deliveryOn: {
            equalTo: day,
          },
          deliveryAt,
          owner: {
            in: ['myfresh', 'myfresh-yandex', 'moscowfresh', 'moscowfresh-yandex', 'familyfriend', 'familyfriend-yandex', 'edoque', 'edoque-yandex', 'sbermarket-myfresh'],
          },
        },
        variables
      )
    )
  );
};
