import { graphql } from 'relay-runtime';

export default graphql`
  query MapShopOrderDocumentsQuery(
    $filter: ShopOrderDocumentFilter
    $groupFilter: ShopOrderGroupFilter
    $orderBy: [ShopOrderDocumentsOrderBy!]
  ) {
    orders: allShopOrderDocuments(filter: $filter, orderBy: $orderBy) {
      ...MapShopOrderDocument_orders
    }

    allCouriers(filter: { fired: { equalTo: false } }) {
      ...GroupFormShopOrderDocumentMap_allCouriers
      ...GroupsMapShopOrderDocument_allCouriers
      ...MapShopOrderDocument_allCouriers
    }

    allGroups: allShopOrderGroups(filter: $groupFilter, orderBy: [DEADLINE_AT_ASC]) {
      ...MapShopOrderDocument_allGroups
    }
  }
`;
