/* global Raven */
import 'babel-polyfill';

import 'moment/locale/ru';
import { LocaleProvider } from 'antd';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import 'moment-timezone';

import { Provider } from 'react-redux';
import { Resolver } from 'found-relay';
import { render } from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Actions as FarceActions } from 'farce';
import React from 'react';
import moment from 'moment';
import createStore from './redux/createStore';
import createRelayEnvironment from './createRelayEnvironment';
import Auth from './containers/Auth';
import Router from './Router';
import routes from './routes';

import './utils/readBarcodeDocumentEvent';
import './global.css';

if (process.env.NODE_ENV === 'production') {
  Raven.config('https://bae21fa977bf452c990d492a461d48fd@sentry.io/1278653').install();
}

moment.locale('ru');

const { store, persistor } = createStore({ routes });
const environment = createRelayEnvironment(store);

const mountNode = document.getElementById('app');
const resolver = new Resolver(environment);
const context = { environment, store };

const tree = (
  <LocaleProvider locale={ruRU}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Auth privateComponent={Router} resolver={resolver} matchContext={context} />
      </PersistGate>
    </Provider>
  </LocaleProvider>
);

render(tree, mountNode);

if (module.hot) {
  module.hot.accept('./routes', () => {
    // eslint-disable-next-line global-require
    store.found.replaceRouteConfig(require('./routes').default);
  });
}

document.addEventListener('readBarcode', e => {
  if (e.detail.indexOf('order') > -1) {
    store.dispatch(FarceActions.push(`/shop/order/documents/${e.detail.substr(5)}`));
  }
});
