import React from 'react';
import PropTypes from 'prop-types';
import { Table, Popconfirm } from 'antd';
import { Link } from 'found';
import { set, merge } from 'unchanged';
import { getPaginationOptions } from 'utils/pagination';
import { showErrors, showSuccess } from 'utils/notifications';
import Icon from './Icon';
import Page from './Page';
import s from './DataTable.css';

const defaultColumns = {
  rowId: {
    width: 80,
    title: '#',
    align: 'right',
  },
};

const getDefaultColumns = (row, columns, location) => {
  if (!row) {
    return [];
  }

  const allColumns = { ...defaultColumns, ...columns };

  return Object.keys(allColumns)
    .map(key => {
      const { hidden, ...other } = allColumns[key] || {};

      if (hidden) {
        return null;
      }

      return {
        key,
        dataIndex: key,
        title: key,
        sorter: true,
        sortOrder: location.query.sortColumn === key && location.query.sortOrder,
        ...other,
      };
    })
    .filter(Boolean);
};

const getPagination = (location, total) => {
  const options = getPaginationOptions(location);

  if (!total || options.pageSize > total) {
    return false;
  }

  return {
    ...options,
    total,
    showSizeChanger: true,
  };
};

const actionIcons = {
  destroy: 'delete',
  edit: 'edit',
  show: 'description',
};

const DataTable = ({
  actions,
  rows,
  columns,
  transformColumns,
  router,
  location,
  title,
  children,
  destroyMutation,
  context,
  pageActions,
  onlyTable,
  skipReloadAfterDestroy,
  route,
  Header,
  Footer,
  tableProps,
  ...props
}) => {
  const handleChange = (pagination, filters, sorter) => {
    const values = {
      page: pagination.current,
      pageSize: pagination.pageSize,
      sortColumn: sorter.field,
      sortOrder: sorter.order,
    };

    router.push(merge('query', values, location));
  };

  const initialColumns = getDefaultColumns(rows.nodes[0], columns, location);
  const finalColumns = transformColumns ? transformColumns({ ...props, context }, initialColumns) : initialColumns;

  const actionHandlers = {
    show: (_, rowId) => `${route.baseUrl}/${rowId}`,
    edit: (_, rowId) => `${route.baseUrl}/${rowId}/edit`,
    destroy:
      destroyMutation &&
      (id => () =>
        destroyMutation
          .commit(context.environment, id)
          .then(showSuccess)
          .then(deletedId => {
            if (!skipReloadAfterDestroy) {
              router.push(set('query.deletedId', deletedId, location));
            }
          })
          .catch(showErrors)),
  };

  const actionsColumn = {
    key: 'actions',
    title: '',
    width: actions.length * 25 + 22,
    dataIndex: 'id',
    className: s.actionColumn,
    render: (id, { rowId }) =>
      actions.map(name => {
        if (name === 'destroy') {
          const popconfirmProps = {
            key: name,
            placement: 'topRight',
            title: 'Вы уверены, что хотите это удалить?',
            onConfirm: actionHandlers[name] && actionHandlers[name](id, rowId),
            okText: 'Да, удалить',
            cancelText: 'Нет',
          };

          return (
            <Popconfirm {...popconfirmProps}>
              <Icon styleName="action">{actionIcons[name]}</Icon>
            </Popconfirm>
          );
        }

        const handler = actionHandlers[name] && actionHandlers[name](id, rowId);

        if (typeof handler === 'string') {
          return (
            <Link to={handler} key={name}>
              <Icon styleName="action" key={name}>
                {actionIcons[name]}
              </Icon>
            </Link>
          );
        }

        return (
          <Icon styleName="action" key={name} onClick={handler}>
            {actionIcons[name]}
          </Icon>
        );
      }),
  };

  const allPageActions = {
    ...pageActions,
    create:
      pageActions.create === null
        ? null
        : {
            type: 'link',
            to: `${location.pathname}/new`,
            children: 'Создать',
            icon: 'file-add',
            buttonType: 'primary',
          },
  };

  const table = (
    <Table
      styleName="table"
      rowKey="id"
      onChange={handleChange}
      dataSource={rows.nodes.filter(Boolean)}
      columns={[...finalColumns, actionsColumn]}
      pagination={getPagination(location, rows.totalCount)}
      {...tableProps}
    />
  );

  if (onlyTable) {
    return table;
  }

  return (
    <Page title={title} actions={allPageActions}>
      {Header && <Header {...props} location={location} context={context} router={router} rows={rows} />}
      {table}
      {Footer && <Footer {...props} rows={rows} />}
    </Page>
  );
};

DataTable.propTypes = {
  Header: PropTypes.any,
  Footer: PropTypes.any,
  rows: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
    totalCount: PropTypes.number,
  }).isRequired,
  columns: PropTypes.object,
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  title: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.string.isRequired),
  baseUrl: PropTypes.string,
  transformColumns: PropTypes.func,
  destroyMutation: PropTypes.object,
  pageActions: PropTypes.object,
  onlyTable: PropTypes.bool,
  skipReloadAfterDestroy: PropTypes.bool,
  route: PropTypes.object.isRequired,
  tableProps: PropTypes.object,
  context: PropTypes.shape({
    // eslint-disable-next-line react/require-default-props
    environment: PropTypes.object.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.any,
};

DataTable.defaultProps = {
  title: '',
  tableProps: {},
  baseUrl: '/todo/change-base-url',
  columns: {},
  onlyTable: false,
  transformColumns: null,
  destroyMutation: null,
  pageActions: {},
  actions: ['show', 'edit', 'destroy'],
  Header: null,
  Footer: null,
  skipReloadAfterDestroy: false,
};

export default DataTable;
