import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroyWarehouseMutation($input: DeleteWarehouseInput!) {
    deleteWarehouse(input: $input) {
      deletedWarehouseId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteWarehouse', 'deletedWarehouseId');
