import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateWarehouseMovementItemMutation($input: CreateWarehouseMovementItemInput!) {
    createWarehouseMovementItem(input: $input) {
      warehouseMovementItem {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createWarehouseMovementItem', 'warehouseMovementItem');
