import React, { Component } from 'react';
import PropTypes from 'prop-types';
import timer from 'battery-friendly-timer';

function hash(str) {
  const len = str.length;
  let v = 0;
  if (len === 0) return hash;
  let i;
  for (i = 0; i < len; i += 1) {
    // eslint-disable-next-line no-bitwise
    v = (v << 5) - v + str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    v |= 0; // Convert to 32bit integer
  }
  return v;
}

function reloadApp(e) {
  window.location.reload(true);
  e.preventDefault();
}

class AutoReload extends Component {
  constructor(props) {
    super(props);
    this.previousHash = null;
    this.state = {
      codeHasChanged: false,
    };
    this.fetchSource = this.fetchSource.bind(this);
  }

  componentDidMount() {
    const { tryDelay, forceDelay } = this.props;
    this.fetchSource();
    this.interval = timer.setInterval(this.fetchSource, tryDelay, forceDelay);
  }

  componentWillUnmount() {
    timer.clearInterval(this.interval);
  }

  fetchSource() {
    return fetch(this.props.url)
      .then(response => {
        if (response.status !== 200) {
          throw new Error('offline');
        }
        return response.text();
      })
      .then(html => {
        const h = hash(html);

        if (!this.previousHash) {
          this.previousHash = h;
          return;
        }

        if (this.previousHash !== h) {
          this.previousHash = h;
          this.setState({ codeHasChanged: true });
        }
      })
      .catch(() => {
        /* do nothing */
      });
  }

  render() {
    if (!this.state.codeHasChanged) return null;
    const style = {
      position: 'absolute',
      top: 5,
      right: 5,
      padding: '1em',
      zIndex: 1050,
      backgroundColor: 'bisque',
      borderRadius: 5,
      textAlign: 'center',
    };

    return (
      <div style={style}>
        <div>Доступна новая версия.</div>
        <div>
          <button type="button" onClick={reloadApp}>
            Перезагрузить
          </button>
        </div>
      </div>
    );
  }
}

AutoReload.propTypes = {
  url: PropTypes.string,
  tryDelay: PropTypes.number,
  forceDelay: PropTypes.number,
};

AutoReload.defaultProps = {
  url: '/',
  tryDelay: 5 * 60 * 1000, // 5 minutes
  forceDelay: 24 * 60 * 60 * 1000, // 1 day
};

export default AutoReload;
