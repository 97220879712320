import React from 'react';
import PropTypes from 'prop-types';
import { Select, DatePicker, Form, Input, Button } from 'antd';
import { getSelectItems } from 'components/helpers';
import PageForm from 'components/PageForm';
import CollectionSelect from 'components/CollectionSelect';
import QuantityInput from 'components/QuantityInput';
import owners from './owners';

const ShopDepositReplenishmentForm = ({ title, form, handleSubmit, allEmployees }) => (
  <PageForm onSubmit={handleSubmit} title={title}>
    <Form.Item label="Дата покупки">
      {form.getFieldDecorator('createdAt')(<DatePicker showTime={{ format: 'HH:mm' }} format="DD.MM.YYYY HH:mm" />)}
    </Form.Item>
    <Form.Item label="Витрина">
      {form.getFieldDecorator('userOwner', {
        rules: [
          {
            required: true,
            message: 'Укажите витрину',
          },
        ],
      })(<Select>{getSelectItems(owners)}</Select>)}
    </Form.Item>
    <Form.Item label="ID пользователя">
      {form.getFieldDecorator('userId', {
        rules: [
          {
            required: true,
            message: 'Введите ID пользователя',
          },
        ],
      })(<Input />)}
    </Form.Item>
    <Form.Item label="Сумма">
      {form.getFieldDecorator('value', {
        rules: [
          {
            required: true,
            message: 'Введите значение',
          },
        ],
      })(<QuantityInput />)}
    </Form.Item>
    <Form.Item label="Продал">
      {form.getFieldDecorator('sellerId')(
        <CollectionSelect
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          showSearch
          getLabel={i => `${i.lastName} ${i.firstName}`}
          items={allEmployees.nodes}
        />
      )}
    </Form.Item>
    <Form.Item label="Комментарий">{form.getFieldDecorator('comment')(<Input.TextArea rows={4} />)}</Form.Item>
    <Form.Item>
      <Button type="primary" htmlType="submit">
        Сохранить
      </Button>
    </Form.Item>
  </PageForm>
);

ShopDepositReplenishmentForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  allEmployees: PropTypes.object.isRequired,
};

export default ShopDepositReplenishmentForm;
