import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Alert, Select, DatePicker, Form, Button } from 'antd';
import { YMaps } from 'react-yandex-maps';
import PageForm from 'components/PageForm';
import TextInput from 'components/TextInput';
import GeoPointInput from 'components/GeoPointInput';
import QuantityInput from 'components/QuantityInput';
import { getSelectItems } from 'components/helpers';
import statuses from './statuses';
import paymentMethods from './paymentMethods';

const ShopOrderDocumentForm = ({ title, form, handleSubmit, data, location }) => (
  <PageForm onSubmit={handleSubmit} title={title}>
    {location.query.address ? (
      <Form.Item label="Точка доставки">
        {form.getFieldDecorator('deliveryPoint', { initialValue: data.deliveryPoint })(
          <GeoPointInput size={800} address={location.query.address} prevPoint={data.prevDeliveryPoint} />
        )}
      </Form.Item>
    ) : (
      <React.Fragment>
        <Form.Item label="Время доставки">
          {form.getFieldDecorator('deliveryAt')(<DatePicker showTime format="DD-MM-YYYY HH:mm" />)}
        </Form.Item>
        <Form.Item label="Статус">
          {form.getFieldDecorator('status')(<Select>{getSelectItems(statuses)}</Select>)}
        </Form.Item>
        <Form.Item label="Метод оплаты">
          {form.getFieldDecorator('paymentMethod')(<Select>{getSelectItems(paymentMethods)}</Select>)}
        </Form.Item>
        {!data.deliveryPoint && <Alert message="Координаты не выставлены!" type="error" />}
        <Form.Item label="Точка доставки">
          {form.getFieldDecorator('deliveryPoint', { initialValue: data.deliveryPoint })(<GeoPointInput />)}
        </Form.Item>
        <Form.Item label="Адрес заблокирован">
          {form.getFieldDecorator('deliveryPointBlocked', { valuePropName: 'checked' })(<Checkbox />)}
        </Form.Item>
        <Form.Item label="ID маршрута">{form.getFieldDecorator('groupId')(<TextInput />)}</Form.Item>
        <Form.Item label="Номер поездки">{form.getFieldDecorator('groupPosition')(<TextInput />)}</Form.Item>
        <Form.Item label="Множитель суммы позиций">
          {form.getFieldDecorator('totalSumMultiplier')(<QuantityInput />)}
        </Form.Item>
        <Form.Item label="Скидка">{form.getFieldDecorator('discount')(<QuantityInput />)}</Form.Item>
        <Form.Item label="Потдверждённая оплата">
          {form.getFieldDecorator('confirmedAmount')(<QuantityInput />)}
        </Form.Item>
        <Form.Item label="ID пользователя">{form.getFieldDecorator('userId')(<TextInput />)}</Form.Item>
      </React.Fragment>
    )}
    <Form.Item>
      <Button type="primary" htmlType="submit">
        Сохранить
      </Button>
    </Form.Item>
  </PageForm>
);

ShopOrderDocumentForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
};

export default ShopOrderDocumentForm;
