/**
 * @flow
 * @relayHash 102b0bdd2cc72e24360fbead962c7074
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductExternalStatus = "ALWAYS_AVAILABLE" | "AVAILABLE" | "BY_REQUEST" | "FROM_PARENT" | "HIDDEN" | "%future added value";
export type ProductStatus = "ACTIVE" | "ARCHIVE" | "DEPRECATED" | "%future added value";
export type ProductUnit = "KG" | "PCS" | "%future added value";
export type ShowProductQueryVariables = {|
  rowId: number
|};
export type ShowProductQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +unit: ProductUnit,
    +name: string,
    +status: ProductStatus,
    +externalStatus: ProductExternalStatus,
    +parent: ?{|
      +rowId: number,
      +name: string,
    |},
    +shelfLife: ?{|
      +hours: ?number,
      +days: ?number,
      +months: ?number,
      +years: ?number,
    |},
    +children: {|
      +nodes: $ReadOnlyArray<{|
        +rowId: number,
        +name: string,
      |}>
    |},
    +groups: {|
      +nodes: $ReadOnlyArray<{|
        +id: string,
        +productGroup: ?{|
          +name: string
        |},
      |}>
    |},
    +productAutoWriteOffsBySourceId: {|
      +nodes: $ReadOnlyArray<{|
        +id: string,
        +rowId: number,
        +quantity: any,
        +target: ?{|
          +name: string,
          +unit: ProductUnit,
        |},
      |}>
    |},
    +supplyItems: {|
      +nodes: $ReadOnlyArray<{|
        +id: string,
        +rowId: number,
        +quantity: any,
        +price: any,
        +warehouse: ?{|
          +rowId: number,
          +name: string,
        |},
        +document: ?{|
          +rowId: number,
          +eventedAt: any,
          +comment: string,
          +contractor: ?{|
            +rowId: number,
            +name: string,
          |},
        |},
      |}>
    |},
  |},
  +allProductGroups: ?{|
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +name: string,
    |}>
  |},
|};
export type ShowProductQuery = {|
  variables: ShowProductQueryVariables,
  response: ShowProductQueryResponse,
|};
*/


/*
query ShowProductQuery(
  $rowId: Int!
) {
  data: productByRowId(rowId: $rowId) {
    id
    rowId
    unit
    name
    status
    externalStatus
    parent: productByParentId {
      rowId
      name
      id
    }
    shelfLife {
      hours
      days
      months
      years
    }
    children: productsByParentId {
      nodes {
        rowId
        name
        id
      }
    }
    groups: productGroupsProductsByProductId {
      nodes {
        id
        productGroup: productGroupByGroupId {
          name
          id
        }
      }
    }
    productAutoWriteOffsBySourceId {
      nodes {
        id
        rowId
        quantity
        target: productByTargetId {
          name
          unit
          id
        }
      }
    }
    supplyItems: supplyItemsByProductId(orderBy: ID_DESC, first: 20) {
      nodes {
        id
        rowId
        quantity
        price
        warehouse: warehouseByWarehouseId {
          rowId
          name
          id
        }
        document: supplyDocumentByDocumentId {
          rowId
          eventedAt
          comment
          contractor: supplyContractorByContractorId {
            rowId
            name
            id
          }
          id
        }
      }
    }
  }
  allProductGroups {
    nodes {
      id
      rowId
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "unit",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "externalStatus",
  "args": null,
  "storageKey": null
},
v8 = [
  v3,
  v5
],
v9 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "shelfLife",
  "storageKey": null,
  "args": null,
  "concreteType": "Interval",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hours",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "days",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "months",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "years",
      "args": null,
      "storageKey": null
    }
  ]
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "quantity",
  "args": null,
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20,
    "type": "Int"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "ID_DESC",
    "type": "[SupplyItemsOrderBy!]"
  }
],
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "eventedAt",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "comment",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "allProductGroups",
  "storageKey": null,
  "args": null,
  "concreteType": "ProductGroupsConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nodes",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductGroup",
      "plural": true,
      "selections": [
        v2,
        v3,
        v5
      ]
    }
  ]
},
v16 = [
  v3,
  v5,
  v2
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ShowProductQuery",
  "id": null,
  "text": "query ShowProductQuery(\n  $rowId: Int!\n) {\n  data: productByRowId(rowId: $rowId) {\n    id\n    rowId\n    unit\n    name\n    status\n    externalStatus\n    parent: productByParentId {\n      rowId\n      name\n      id\n    }\n    shelfLife {\n      hours\n      days\n      months\n      years\n    }\n    children: productsByParentId {\n      nodes {\n        rowId\n        name\n        id\n      }\n    }\n    groups: productGroupsProductsByProductId {\n      nodes {\n        id\n        productGroup: productGroupByGroupId {\n          name\n          id\n        }\n      }\n    }\n    productAutoWriteOffsBySourceId {\n      nodes {\n        id\n        rowId\n        quantity\n        target: productByTargetId {\n          name\n          unit\n          id\n        }\n      }\n    }\n    supplyItems: supplyItemsByProductId(orderBy: ID_DESC, first: 20) {\n      nodes {\n        id\n        rowId\n        quantity\n        price\n        warehouse: warehouseByWarehouseId {\n          rowId\n          name\n          id\n        }\n        document: supplyDocumentByDocumentId {\n          rowId\n          eventedAt\n          comment\n          contractor: supplyContractorByContractorId {\n            rowId\n            name\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n  allProductGroups {\n    nodes {\n      id\n      rowId\n      name\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ShowProductQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "productByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "Product",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          v7,
          {
            "kind": "LinkedField",
            "alias": "parent",
            "name": "productByParentId",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": false,
            "selections": v8
          },
          v9,
          {
            "kind": "LinkedField",
            "alias": "children",
            "name": "productsByParentId",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "Product",
                "plural": true,
                "selections": v8
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "groups",
            "name": "productGroupsProductsByProductId",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductGroupsProductsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductGroupsProduct",
                "plural": true,
                "selections": [
                  v2,
                  {
                    "kind": "LinkedField",
                    "alias": "productGroup",
                    "name": "productGroupByGroupId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProductGroup",
                    "plural": false,
                    "selections": [
                      v5
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productAutoWriteOffsBySourceId",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductAutoWriteOffsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductAutoWriteOff",
                "plural": true,
                "selections": [
                  v2,
                  v3,
                  v10,
                  {
                    "kind": "LinkedField",
                    "alias": "target",
                    "name": "productByTargetId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      v5,
                      v4
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "supplyItems",
            "name": "supplyItemsByProductId",
            "storageKey": "supplyItemsByProductId(first:20,orderBy:\"ID_DESC\")",
            "args": v11,
            "concreteType": "SupplyItemsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "SupplyItem",
                "plural": true,
                "selections": [
                  v2,
                  v3,
                  v10,
                  v12,
                  {
                    "kind": "LinkedField",
                    "alias": "warehouse",
                    "name": "warehouseByWarehouseId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Warehouse",
                    "plural": false,
                    "selections": v8
                  },
                  {
                    "kind": "LinkedField",
                    "alias": "document",
                    "name": "supplyDocumentByDocumentId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SupplyDocument",
                    "plural": false,
                    "selections": [
                      v3,
                      v13,
                      v14,
                      {
                        "kind": "LinkedField",
                        "alias": "contractor",
                        "name": "supplyContractorByContractorId",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SupplyContractor",
                        "plural": false,
                        "selections": v8
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      v15
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ShowProductQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "productByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "Product",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          v7,
          {
            "kind": "LinkedField",
            "alias": "parent",
            "name": "productByParentId",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": false,
            "selections": v16
          },
          v9,
          {
            "kind": "LinkedField",
            "alias": "children",
            "name": "productsByParentId",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "Product",
                "plural": true,
                "selections": v16
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "groups",
            "name": "productGroupsProductsByProductId",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductGroupsProductsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductGroupsProduct",
                "plural": true,
                "selections": [
                  v2,
                  {
                    "kind": "LinkedField",
                    "alias": "productGroup",
                    "name": "productGroupByGroupId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ProductGroup",
                    "plural": false,
                    "selections": [
                      v5,
                      v2
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "productAutoWriteOffsBySourceId",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductAutoWriteOffsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductAutoWriteOff",
                "plural": true,
                "selections": [
                  v2,
                  v3,
                  v10,
                  {
                    "kind": "LinkedField",
                    "alias": "target",
                    "name": "productByTargetId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      v5,
                      v4,
                      v2
                    ]
                  }
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": "supplyItems",
            "name": "supplyItemsByProductId",
            "storageKey": "supplyItemsByProductId(first:20,orderBy:\"ID_DESC\")",
            "args": v11,
            "concreteType": "SupplyItemsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "SupplyItem",
                "plural": true,
                "selections": [
                  v2,
                  v3,
                  v10,
                  v12,
                  {
                    "kind": "LinkedField",
                    "alias": "warehouse",
                    "name": "warehouseByWarehouseId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Warehouse",
                    "plural": false,
                    "selections": v16
                  },
                  {
                    "kind": "LinkedField",
                    "alias": "document",
                    "name": "supplyDocumentByDocumentId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "SupplyDocument",
                    "plural": false,
                    "selections": [
                      v3,
                      v13,
                      v14,
                      {
                        "kind": "LinkedField",
                        "alias": "contractor",
                        "name": "supplyContractorByContractorId",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "SupplyContractor",
                        "plural": false,
                        "selections": v16
                      },
                      v2
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      v15
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '649f5c50164371993a3a571d2851e79b';
module.exports = node;
