import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { getSelectItems } from 'components/helpers';
import withFiltersEnhance from 'hoc/withFiltersEnhance';
import FilterForm from 'components/FilterForm';
import { FilterSelect, FilterCollectionSelect, FilterInput } from 'components/filterControls';
import owners from './owners';

const Filters = ({ form, handleSubmit, handleClear, allEmployees }) => (
  <FilterForm onSubmit={handleSubmit} onClear={handleClear}>
    <Form.Item label="Продал">
      {form.getFieldDecorator('sellerId')(<FilterCollectionSelect items={allEmployees.nodes} filterType="equalTo" />)}
    </Form.Item>
    <Form.Item label="Витрина">
      {form.getFieldDecorator('userOwner')(
        <FilterSelect filterType="equalTo" placeholder="Фильтр по статусу">
          {getSelectItems(owners)}
        </FilterSelect>
      )}
    </Form.Item>
    <Form.Item label="ID пользователя">
      {form.getFieldDecorator('userId')(<FilterInput filterType="equalTo" />)}
    </Form.Item>
  </FilterForm>
);

Filters.propTypes = {
  form: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClear: PropTypes.func,
  allEmployees: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
};

Filters.defaultProps = {
  handleClear: null,
};

export default withFiltersEnhance(['totalSum'])(Filters);
