import { graphql } from 'relay-runtime';

export default graphql`
  query CourierMapQueryShopOrderDocumentsQuery(
    $filter: CourierFilter
  ) {
    allCouriers(filter: $filter) {
      ...CourierMap_allCouriers
    }
  }
`;
