import { getRoutes } from 'utils/crud';

import Index from './index';
import New from './New';
import Edit from './Edit';
import Show from './Show';
import Print from './Print';

import IndexQuery from './ProductCategoriesQuery';
import EditQuery from './Edit/EditProductCategoryQuery';
import ShowQuery from './Show/ShowProductCategoryQuery';
import PrintQuery from './Print/PrintProductCategoriesQuery';

import printPrepareVariables from './Print/prepareVariables';

export default [
  {
    path: '/product/categories/print',
    Component: Print,
    query: PrintQuery,
    prepareVariables: printPrepareVariables,
  },
  ...getRoutes({
    baseUrl: '/product/categories',
    Index,
    IndexQuery,
    Edit,
    EditQuery,
    Show,
    ShowQuery,
    New,
  }),
];
