import { compose, withProps } from 'recompact';
import transform from 'utils/transform';
import moment from 'moment';
import mutation from 'mutations/UpdateShopOrderDocument2Mutation';
import withUpdateEnhance from 'hoc/withUpdateEnhance';
import withNotFound from 'hoc/withNotFound';

export default compose(
  withNotFound(),
  withProps({
    title: 'Изменить',
  }),
  withProps(transform('data.deliveryAt', v => v && moment(v).tz('Europe/Moscow'))),
  withUpdateEnhance({
    mutation,
  })
);
