import { graphql } from 'relay-runtime';

export default graphql`
  query EditProductCategoryQuery($rowId: Int!) {
    data: productCategoryByRowId(rowId: $rowId) {
      id
      rowId
      name
    }
  }
`;
