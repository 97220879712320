/**
 * @flow
 * @relayHash 639adada64878b4746104b7dfd875351
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShopOrderGroupStatus = "COMPLETED" | "PENDING" | "SENDED" | "%future added value";
export type UpdateShopOrderGroupInput = {
  clientMutationId?: ?string,
  id: string,
  shopOrderGroupPatch: ShopOrderGroupPatch,
};
export type ShopOrderGroupPatch = {
  rowId?: ?number,
  deadlineAt?: ?any,
  owner?: ?string,
  comment?: ?string,
  courierId?: ?number,
  status?: ?ShopOrderGroupStatus,
  courierToken?: ?any,
  senderId?: ?number,
  completerId?: ?number,
  totalChange?: ?any,
  totalCashSum?: ?any,
  activeDocumentId?: ?number,
  courierBasePayoff?: ?any,
  courierExtraPayoff?: ?any,
};
export type UpdateShopOrderGroupMutationVariables = {|
  input: UpdateShopOrderGroupInput
|};
export type UpdateShopOrderGroupMutationResponse = {|
  +updateShopOrderGroup: ?{|
    +shopOrderGroup: ?{|
      +rowId: number
    |}
  |}
|};
export type UpdateShopOrderGroupMutation = {|
  variables: UpdateShopOrderGroupMutationVariables,
  response: UpdateShopOrderGroupMutationResponse,
|};
*/


/*
mutation UpdateShopOrderGroupMutation(
  $input: UpdateShopOrderGroupInput!
) {
  updateShopOrderGroup(input: $input) {
    shopOrderGroup {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateShopOrderGroupInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateShopOrderGroupInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateShopOrderGroupMutation",
  "id": null,
  "text": "mutation UpdateShopOrderGroupMutation(\n  $input: UpdateShopOrderGroupInput!\n) {\n  updateShopOrderGroup(input: $input) {\n    shopOrderGroup {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateShopOrderGroupMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateShopOrderGroup",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateShopOrderGroupPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shopOrderGroup",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderGroup",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateShopOrderGroupMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateShopOrderGroup",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateShopOrderGroupPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shopOrderGroup",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderGroup",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5904d14dee889f9609081295427092e5';
module.exports = node;
