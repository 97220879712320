import React from 'react';
import { createFragmentContainer } from 'react-relay';
import { notification, Icon, Button, Dropdown, Menu } from 'antd';
import { connect } from 'react-redux';

const refund = (data, { owner }) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return fetch(`/services/${owner}/mkb/refund`, options);
};

const testJWT =
  process.env.NODE_ENV !== 'production'
    ? 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwicm9sZSI6InN0b2NrX2VtcGxveWVlX21vbmV5X3NlbmlvciIsImlhdCI6MTUxNjIzOTAyMn0.PSdht9BZwzANv7UW-bqbswaaGlsv_T2f3UZaWpNUoko'
    : null;

const MkbPaymentAction = ({ data, order, token }) => {
  if (location.search.indexOf('mkb') === -1) {
    return null;
  }

  if (data.amount === 0 || !data.confirmed) {
    return null;
  }

  const diff = (parseFloat(order.confirmedAmount) - order.totalSum).toFixed(2);

  const handleMenuClick = ({ key }) => {
    const options = {
      action: key,
      transaction_id: data.rowId,
      amount: key === 'refund' ? Math.round(diff * 100) : undefined,
      jwt: token || testJWT,
    };

    if (key === 'reverse') {
      if (!confirm('Уверены, что хотите вернуть все?')) {
        return;
      }

      options.action = 'refund';
      options.amount = Math.round(data.amount * 100);
    }

    refund(options, order)
      .then(r => r.text())
      .then(t => {
        notification.success({
          message: 'Возврат прошел',
          description: t,
        });
      });
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="capture">
        <Icon type="check" /> Подтвердить
      </Menu.Item>
      {diff > 0 && (
        <Menu.Item key="refund">
          <Icon type="undo" /> Вернуть разницу {diff} руб.
        </Menu.Item>
      )}
      <Menu.Item key="reverse">
        <Icon type="warning" /> Вернуть все
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button size="small">
        Действие <Icon type="down" />
      </Button>
    </Dropdown>
  );
};

const selector = state => ({
  token: state.token,
});

export default createFragmentContainer(
  connect(selector)(MkbPaymentAction),
  graphql`
    fragment MkbPaymentAction on ShopOrderMkbTransaction {
      rowId
      amount
      confirmed
      confirmData
    }

    fragment MkbPaymentAction_order on ShopOrderDocument {
      totalSum
      owner
      confirmedAmount
    }
  `
);
