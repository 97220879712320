import React from 'react';
import PropTypes from 'prop-types';
import { formatQuantity } from 'utils/format';
import './Print.css';

const unsetCategory = {
  rowId: null,
  name: 'Без категории',
};

const ProductionPrint = ({ allProductCategories, allProducts }) => (
  <div styleName="root">
    {[...allProductCategories.nodes, unsetCategory].map(category => {
      const products = allProducts.nodes.filter(n => n.categoryId === category.rowId);

      if (products.length === 0) {
        return null;
      }

      return (
        <div>
          <h4 styleName="category-title">{category.name}</h4>
          <table styleName="table">
            <tbody>
              <tr>
                <th>Название</th>
                <th width="100">Остаток</th>
                <th width="100">F</th>
                <th width="100">MF</th>
              </tr>
              {products.map(product => (
                <tr key={product.id}>
                  <td>{product.name}</td>
                  <td>
                    {formatQuantity(
                      product.quantities.nodes.length > 0 ? product.quantities.nodes[0].value : null,
                      product.unit
                    )}
                  </td>
                  <td />
                  <td />
                </tr>
              ))}
            </tbody>
          </table>
          <div styleName="footer" />
        </div>
      );
    })}
  </div>
);

ProductionPrint.propTypes = {
  allProductCategories: PropTypes.object.isRequired,
  allProducts: PropTypes.object.isRequired,
};

export default ProductionPrint;
