/**
 * @flow
 * @relayHash 9ccb9cc7f6d7f8a4aeda46a20e337f65
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupplyContractorStatus = "ARCHIVE" | "DANGEROUS" | "FRIENDS" | "UNSET" | "%future added value";
export type CreateSupplyContractorInput = {
  clientMutationId?: ?string,
  supplyContractor: SupplyContractorInput,
};
export type SupplyContractorInput = {
  rowId?: ?number,
  name: string,
  status?: ?SupplyContractorStatus,
  description?: ?string,
  supplyTotalSum?: ?any,
  ownerId?: ?number,
};
export type CreateSupplyContractorMutationVariables = {|
  input: CreateSupplyContractorInput
|};
export type CreateSupplyContractorMutationResponse = {|
  +createSupplyContractor: ?{|
    +supplyContractor: ?{|
      +rowId: number
    |}
  |}
|};
export type CreateSupplyContractorMutation = {|
  variables: CreateSupplyContractorMutationVariables,
  response: CreateSupplyContractorMutationResponse,
|};
*/


/*
mutation CreateSupplyContractorMutation(
  $input: CreateSupplyContractorInput!
) {
  createSupplyContractor(input: $input) {
    supplyContractor {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateSupplyContractorInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateSupplyContractorInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateSupplyContractorMutation",
  "id": null,
  "text": "mutation CreateSupplyContractorMutation(\n  $input: CreateSupplyContractorInput!\n) {\n  createSupplyContractor(input: $input) {\n    supplyContractor {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateSupplyContractorMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createSupplyContractor",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateSupplyContractorPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyContractor",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyContractor",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateSupplyContractorMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createSupplyContractor",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateSupplyContractorPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyContractor",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyContractor",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fa1f58bcf0c2399d9943370f73a9746b';
module.exports = node;
