import React from 'react';
import PropTypes from 'prop-types';
import s from './StatusTag.css';

const StatusTag = ({ value, children, ...props }) => (
  <div styleName="root" className={s[value]} {...props}>
    {children || value}
  </div>
);

StatusTag.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.any,
};

StatusTag.defaultProps = {
  children: null,
};

export default StatusTag;
