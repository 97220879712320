import { combineReducers, compose, createStore } from 'redux';
import { foundReducer, Matcher, createMatchEnhancer } from 'found';
import { createHistoryEnhancer, BrowserProtocol, queryMiddleware, Actions as FarceActions } from 'farce';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { persistReducer, persistStore } from 'redux-persist';
import crosstabSync from 'utils/persistCrosstab';
import storage from 'redux-persist/lib/storage';
import * as reducers from './reducers';

const persistConfig = {
  key: 'stock',
  storage,
  blacklist: ['found', 'loadingBar'],
};

export default ({ routes }) => {
  // eslint-disable-next-line no-underscore-dangle
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    persistReducer(
      persistConfig,
      combineReducers({
        found: foundReducer,
        loadingBar: loadingBarReducer,
        ...reducers,
      })
    ),
    composeEnhancers(
      createHistoryEnhancer({
        protocol: new BrowserProtocol(),
        middlewares: [queryMiddleware],
      }),
      createMatchEnhancer(new Matcher(routes))
    )
  );

  store.dispatch(FarceActions.init());

  const persistor = persistStore(store);

  crosstabSync(persistor, {
    blacklist: ['found', 'loadingBar'],
  });

  return { store, persistor };
};
