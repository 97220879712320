import { graphql } from 'relay-runtime';

export default graphql`
  query PaymasterQuery($first: Int!, $offset: Int!, $orderBy: [ShopOrderDocumentsOrderBy!], $filter: ShopOrderDocumentFilter) {
    rows: allShopOrderDocuments(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
      totalCount

      nodes {
        id
        rowId
        paymentMethod
        totalSum
        status
        groupId
        ...PaymasterAmount
        group: shopOrderGroupByGroupId {
          courier: courierByCourierId {
            firstName
            lastName
          }
        }
      }
    }
  }
`;
