exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-PackingMonitor-___Documents__header___1Smwd {\n  padding: 20px;\n  text-align: right;\n}\n\n.src-pages-PackingMonitor-___Documents__red-row___22gRg td {\n  background: red !important;\n  color: white;\n}\n\n.src-pages-PackingMonitor-___Documents__yellow-row___ZCv6H td {\n  background: yellow !important;\n}\n\n.src-pages-PackingMonitor-___Documents__orange-row___1o8Y2 td {\n  background: orange !important;\n}\n\n.src-pages-PackingMonitor-___Documents__green-row___2mB6O td {\n  background: green !important;\n  color: white;\n}\n", ""]);

// exports
exports.locals = {
	"header": "src-pages-PackingMonitor-___Documents__header___1Smwd",
	"red-row": "src-pages-PackingMonitor-___Documents__red-row___22gRg",
	"yellow-row": "src-pages-PackingMonitor-___Documents__yellow-row___ZCv6H",
	"orange-row": "src-pages-PackingMonitor-___Documents__orange-row___1o8Y2",
	"green-row": "src-pages-PackingMonitor-___Documents__green-row___2mB6O"
};