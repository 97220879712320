import { graphql } from 'relay-runtime';

export default graphql`
  query WriteOffDocumentsQuery($first: Int!, $offset: Int!, $orderBy: [WriteOffDocumentsOrderBy!]) {
    rows: allWriteOffDocuments(first: $first, offset: $offset, orderBy: $orderBy) {
      totalCount

      nodes {
        id
        rowId
        eventedAt
        comment
        totalCost

        category: writeOffCategoryByCategoryId {
          rowId
          name
        }
      }
    }
  }
`;
