exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-containers-ProductSelect-___ProductSelect__root___3c-uS {\n  position: relative;\n}\n\n.src-containers-ProductSelect-___ProductSelect__select___1h8Im {\n  width: 100%;\n}\n\n.src-containers-ProductSelect-___ProductSelect__link___3ZK55 {\n  background: white;\n  border-radius: 3px;\n  position: absolute;\n  right: 15px;\n  top: 50%;\n  transform: translateY(-50%);\n}\n\n@media print {\n  .src-containers-ProductSelect-___ProductSelect__link___3ZK55 {\n    display: none;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-containers-ProductSelect-___ProductSelect__root___3c-uS",
	"select": "src-containers-ProductSelect-___ProductSelect__select___1h8Im",
	"link": "src-containers-ProductSelect-___ProductSelect__link___3ZK55"
};