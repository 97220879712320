import { compose } from 'recompact';
import { withRouter } from 'found';
import { merge } from 'unchanged';
import { showSuccess } from 'utils/notifications';
import withRef from 'hoc/withRef';
import withCreateEnhance from 'hoc/withCreateEnhance';
import mutation from 'mutations/CreateProductGroupsProductMutation';

export default compose(
  withRouter,
  withRef,
  withCreateEnhance({
    mutation,
    onCompleted: ({ rowId }, { router, match, applyToRef }) => {
      router.push(merge('query', { addedItemId: rowId }, match.location));
      applyToRef(ref => ref.focus());
      showSuccess();
    },
  })
);
