import { graphql } from 'relay-runtime';

export default graphql`
  query ScalesQuery($first: Int!, $offset: Int!, $orderBy: [ScalesOrderBy!]) {
    rows: allScales(first: $first, offset: $offset, orderBy: $orderBy) {
      totalCount

      nodes {
        id
        rowId
        syncedAt
        addr
        name
      }
    }
  }
`;
