import React from 'react';
import PropTypes from 'prop-types';
import { Input, Row, Col } from 'antd';
import { set } from 'unchanged';

export const intervalFields = {
  hours: 'Ч',
  days: 'Д',
  months: 'М',
  years: 'Л',
};

export const intervalFieldKeys = Object.keys(intervalFields);

const defaultValue = {};

// antd wants ref
// eslint-disable-next-line react/prefer-stateless-function
class IntervalInput extends React.Component {
  render() {
    const value = this.props.value || defaultValue;
    const { onChange } = this.props;
    const makeHandler = key => e => onChange && onChange(set(key, e.target.value, value));

    return (
      <Row gutter={10}>
        {intervalFieldKeys.map(key => (
          <Col key={key} span={6}>
            <Input key={key} value={value[key]} onChange={makeHandler(key)} addonBefore={intervalFields[key]} />
          </Col>
        ))}
      </Row>
    );
  }
}

IntervalInput.propTypes = {
  // message from antd about defaultProps.value:
  // Warning: `getFieldDecorator` will override `value`, so please don't set `value` directly and use `setFieldsValue` to set it.
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.object,
  onChange: PropTypes.func,
};

IntervalInput.defaultProps = {
  onChange: undefined,
};

export default IntervalInput;
