// @flow

import type { SupplyContractorStatus } from './__generated__/SupplyContractorsQuery.graphql';

const statuses: { [SupplyContractorStatus]: string } = {
  FRIENDS: 'Друзья',
  DANGEROUS: 'Опасный',
  ARCHIVE: 'Не сотрудничаем',
  UNSET: '—',
};

export default statuses;
