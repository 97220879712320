exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-Scales-Show-___Show__sizes___1emsE {\n}\n\n.src-pages-Scales-Show-___Show__keyboard1___2zIe2 {\n}\n\n.src-pages-Scales-Show-___Show__keyboard2___uQlsa {\n}\n\n.src-pages-Scales-Show-___Show__product___1z4rQ {\n  border: 0.5mm solid #000;\n  border-radius: 3;\n  width: 1.62cm;\n  height: 1.52cm;\n  margin: 0.2mm;\n  float: left;\n  display: flex;\n  justify-content: center;\n  text-align: center;\n  text-transform: uppercase;\n  font: 15px 'Ubuntu Mono', monospace;\n}\n\n.src-pages-Scales-Show-___Show__keyboard2___uQlsa .src-pages-Scales-Show-___Show__product___1z4rQ {\n  width: 1.45cm;\n  height: 1.6cm;\n}\n\n.src-pages-Scales-Show-___Show__row___3OhWN {\n  overflow: hidden;\n}\n\n.src-pages-Scales-Show-___Show__type___2Md7C {\n  font-weight: 800;\n  text-decoration: underline;\n  height: 0.7cm;\n  padding-top: 0.25cm;\n}\n\n@media print {\n  .src-pages-Scales-Show-___Show__keyboard___1frsO {\n    display: block;\n  }\n\n  .src-pages-Scales-Show-___Show__page___1l-0f {\n    display: none;\n  }\n\n  .src-pages-Scales-Show-___Show__sizes___1emsE {\n    display: none;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"sizes": "src-pages-Scales-Show-___Show__sizes___1emsE",
	"keyboard1": "src-pages-Scales-Show-___Show__keyboard1___2zIe2",
	"keyboard2": "src-pages-Scales-Show-___Show__keyboard2___uQlsa",
	"product": "src-pages-Scales-Show-___Show__product___1z4rQ",
	"row": "src-pages-Scales-Show-___Show__row___3OhWN",
	"type": "src-pages-Scales-Show-___Show__type___2Md7C",
	"keyboard": "src-pages-Scales-Show-___Show__keyboard___1frsO",
	"page": "src-pages-Scales-Show-___Show__page___1l-0f"
};