import { notification } from 'antd';

export const messages = {
  'update or delete on table "products" violates foreign key constraint "moves_product_id_fkey" on table "moves"':
    'По данному продукту существуют записи движения. Удаление невозможно.',
  'update or delete on table "supply_contractors" violates foreign key constraint "supply_documents_contractor_id_fkey" on table "supply_documents"':
    'Невозможно удалить поставщика, потому что по нему существую поставки.',
  'update or delete on table "shop_order_groups" violates foreign key constraint "shop_order_documents_group_id_fkey" on table "shop_order_documents"':
    'Невозможно удалить маршрут с привязанными заказами.',
};

export const showErrors = error => {
  if (error.res && error.res.errors) {
    error.res.errors.forEach(e => {
      notification.error({
        message: 'Ошибка',
        description: messages[e.message] || e.message,
      });
    });
  }

  if (error.message.toLowerCase().indexOf('failed to fetch') > -1) {
    notification.error({
      message: 'Ошибка',
      description: 'Не удалось отправить данные. Проверьте интернет подключение.',
    });
  }

  console.error(error);
};

export const showSuccess = response => {
  notification.success({
    description: 'Действие успешно выполнено',
  });

  return response;
};
