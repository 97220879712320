import { graphql } from 'relay-runtime';

export default graphql`
  query ShowSupplyContractorQuery($rowId: Int!) {
    data: supplyContractorByRowId(rowId: $rowId) {
      id
      rowId
      name
      status
      description

      owner: employeeByOwnerId { firstName, lastName }

      documents: supplyDocumentsByContractorId {
        nodes {
          id
          rowId
          eventedAt
          totalSum
          comment
        }
      }
    }

    allLastContractorSupplyItems(id: $rowId) {
      nodes {
        ...LastSupplyItemsSupplyContractorsShow
      }
    }
  }
`;
