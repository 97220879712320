// @flow
/* global $NonMaybeType, $ElementType */
/* eslint-disable jsx-a11y/label-has-for */

import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import AttributeTable from 'components/AttributeTable';
import DataTable from 'components/DataTable';
import ProductSelect from 'containers/ProductSelect';
import { getRelationLink } from 'components/helpers';
import { getShowActions } from 'utils/crud';
import destoyItemMutation from 'mutations/DestroyProductGroupsProductMutation';
import updateItemMutation from 'mutations/UpdateProductGroupsProductMutation';

import type { PageProps } from 'types';
import type { ShowProductGroupQueryResponse } from './__generated__/ShowProductGroupQuery.graphql';

import NewItem from './NewItem';

type Props = PageProps & {
  data: $NonMaybeType<$ElementType<ShowProductGroupQueryResponse, 'data'>>,
};

const attributeRows = [
  {
    dataKey: 'name',
    label: 'Название',
  },
  {
    dataKey: 'warehouse',
    label: 'Склад',
    render: getRelationLink({ path: '/warehouses' }),
  },
];

const Show = (props: Props) => (
  <Page title={`Группа продуктов "${props.data.name}"`} actions={getShowActions(props)} backUrl={props.route.baseUrl}>
    <AttributeTable data={props.data} rows={attributeRows} />
    <NewItem context={props.context} additionalValues={{ groupId: props.data.rowId }} />
    <h3 style={{ marginTop: 50 }}>Позиции</h3>
    <DataTable
      {...props}
      rowKey="id"
      onlyTable
      skipReloadAfterDestroy
      rows={props.data.items}
      destroyMutation={destoyItemMutation}
      actions={['destroy']}
      columns={{
        rowId: {
          title: '№',
          width: 60,
          render: (_, row, index) => {
            if (props.location.query.sortColumn === 'rowId' && props.location.query.sortOrder === 'descend') {
              return `${props.data.items.nodes.length - index}.`;
            }

            return `${index + 1}.`;
          },
        },
        productId: {
          title: 'Продукт',
          render: (value, row) => (
            <ProductSelect
              value={value}
              data={row.product}
              environment={props.context.environment}
              onChange={v => updateItemMutation.commitValue(props.context.environment, row.id, 'productId', v)}
            />
          ),
        },
      }}
    />
  </Page>
);

Show.propTypes = {
  data: PropTypes.shape({
    rowId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  context: PropTypes.shape({
    environment: PropTypes.object.isRequired,
  }).isRequired,
};

export default Show;
