/**
 * @flow
 * @relayHash 663655526c5c445cf3e2064133ecf8a2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShopOrderDocumentPaymentMethod = "BY_CARD" | "BY_CASH" | "DEPOSIT" | "EMPLOYEE" | "ONLINE" | "%future added value";
export type ShopOrderDocumentStatus = "CANCELED" | "COMPLETED" | "DELIVERED" | "PACKING" | "PENDING" | "SHIPPED" | "%future added value";
export type UpdateShopOrderDocumentByRowIdInput = {
  clientMutationId?: ?string,
  shopOrderDocumentPatch: ShopOrderDocumentPatch,
  rowId: number,
};
export type ShopOrderDocumentPatch = {
  rowId?: ?number,
  paymentMethod?: ?ShopOrderDocumentPaymentMethod,
  status?: ?ShopOrderDocumentStatus,
  eventedAt?: ?any,
  totalSumMultiplier?: ?any,
  discount?: ?any,
  itemSum?: ?any,
  deliveryCost?: ?any,
  totalWeight?: ?any,
  meta?: ?any,
  comment?: ?string,
  groupId?: ?number,
  owner?: ?string,
  deliveryAt?: ?any,
  deliveryMaxDelay?: ?IntervalInput,
  deliveryPoint?: ?PointInput,
  sourceUrl?: ?string,
  printed?: ?boolean,
  ready?: ?boolean,
  deliveryPointBlocked?: ?boolean,
  groupPosition?: ?number,
  change?: ?any,
  confirmedAmount?: ?any,
  userId?: ?string,
  shopId?: ?number,
  asap?: ?boolean,
  deliveryNoticeAt?: ?any,
  ecoPack?: ?boolean,
  withoutOverweight?: ?boolean,
  withGlassCoupon?: ?boolean,
  deliveredAt?: ?any,
  byCardPaymentConfirmed?: ?boolean,
  userRating?: ?number,
  userRatingAt?: ?any,
  userReview?: ?string,
  userReviewAt?: ?any,
  userReviewImages?: ?$ReadOnlyArray<?string>,
  userReviewSolvedEmployeeId?: ?number,
  userReviewSolveComment?: ?string,
  readyAt?: ?any,
  shippedAt?: ?any,
  mainWarehouseId?: ?number,
  manualConfirmedAmount?: ?any,
  packageCount?: ?number,
  checkEmployeeId?: ?number,
  clientPhone?: ?string,
  clientEmail?: ?string,
  lastSentEvent?: ?string,
};
export type IntervalInput = {
  seconds?: ?number,
  minutes?: ?number,
  hours?: ?number,
  days?: ?number,
  months?: ?number,
  years?: ?number,
};
export type PointInput = {
  x: number,
  y: number,
};
export type UpdateShopOrderDocumentMutationVariables = {|
  input: UpdateShopOrderDocumentByRowIdInput
|};
export type UpdateShopOrderDocumentMutationResponse = {|
  +updateShopOrderDocumentByRowId: ?{|
    +shopOrderDocument: ?{|
      +rowId: number,
      +status: ShopOrderDocumentStatus,
      +groupId: ?number,
      +groupPosition: number,
      +group: ?{|
        +id: string,
        +rowId: number,
        +deadlineAt: any,
        +courierId: ?number,
        +comment: string,
      |},
    |}
  |}
|};
export type UpdateShopOrderDocumentMutation = {|
  variables: UpdateShopOrderDocumentMutationVariables,
  response: UpdateShopOrderDocumentMutationResponse,
|};
*/


/*
mutation UpdateShopOrderDocumentMutation(
  $input: UpdateShopOrderDocumentByRowIdInput!
) {
  updateShopOrderDocumentByRowId(input: $input) {
    shopOrderDocument {
      rowId
      status
      groupId
      groupPosition
      group: shopOrderGroupByGroupId {
        id
        rowId
        deadlineAt
        courierId
        comment
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateShopOrderDocumentByRowIdInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateShopOrderDocumentByRowIdInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "groupId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "groupPosition",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "LinkedField",
  "alias": "group",
  "name": "shopOrderGroupByGroupId",
  "storageKey": null,
  "args": null,
  "concreteType": "ShopOrderGroup",
  "plural": false,
  "selections": [
    v6,
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "deadlineAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "courierId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "comment",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateShopOrderDocumentMutation",
  "id": null,
  "text": "mutation UpdateShopOrderDocumentMutation(\n  $input: UpdateShopOrderDocumentByRowIdInput!\n) {\n  updateShopOrderDocumentByRowId(input: $input) {\n    shopOrderDocument {\n      rowId\n      status\n      groupId\n      groupPosition\n      group: shopOrderGroupByGroupId {\n        id\n        rowId\n        deadlineAt\n        courierId\n        comment\n      }\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateShopOrderDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateShopOrderDocumentByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateShopOrderDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shopOrderDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderDocument",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v7
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateShopOrderDocumentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateShopOrderDocumentByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateShopOrderDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shopOrderDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderDocument",
            "plural": false,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v7,
              v6
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f27448227a6fbbe9720bb9c5b47bf38c';
module.exports = node;
