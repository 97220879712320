/**
 * @flow
 * @relayHash cdb8ecb1d0021a23799e15a613e628b1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShowWarehouseQueryVariables = {|
  rowId: number
|};
export type ShowWarehouseQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +name: string,
    +quantities: {|
      +nodes: $ReadOnlyArray<{|
        +value: any,
        +product: ?{|
          +categoryId: ?number,
          +rowId: number,
          +name: string,
        |},
      |}>
    |},
  |}
|};
export type ShowWarehouseQuery = {|
  variables: ShowWarehouseQueryVariables,
  response: ShowWarehouseQueryResponse,
|};
*/


/*
query ShowWarehouseQuery(
  $rowId: Int!
) {
  data: warehouseByRowId(rowId: $rowId) {
    id
    rowId
    name
    quantities: productWarehouseQuantitiesByWarehouseId {
      nodes {
        value
        product: productByProductId {
          categoryId
          rowId
          name
          id
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "categoryId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ShowWarehouseQuery",
  "id": null,
  "text": "query ShowWarehouseQuery(\n  $rowId: Int!\n) {\n  data: warehouseByRowId(rowId: $rowId) {\n    id\n    rowId\n    name\n    quantities: productWarehouseQuantitiesByWarehouseId {\n      nodes {\n        value\n        product: productByProductId {\n          categoryId\n          rowId\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ShowWarehouseQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "warehouseByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "Warehouse",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": "quantities",
            "name": "productWarehouseQuantitiesByWarehouseId",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductWarehouseQuantitiesConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductWarehouseQuantity",
                "plural": true,
                "selections": [
                  v5,
                  {
                    "kind": "LinkedField",
                    "alias": "product",
                    "name": "productByProductId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      v6,
                      v3,
                      v4
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ShowWarehouseQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "warehouseByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "Warehouse",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          {
            "kind": "LinkedField",
            "alias": "quantities",
            "name": "productWarehouseQuantitiesByWarehouseId",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductWarehouseQuantitiesConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "ProductWarehouseQuantity",
                "plural": true,
                "selections": [
                  v5,
                  {
                    "kind": "LinkedField",
                    "alias": "product",
                    "name": "productByProductId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      v6,
                      v3,
                      v4,
                      v2
                    ]
                  },
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bc8cf954cedff7deabae1269688282ef';
module.exports = node;
