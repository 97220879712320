import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateCourierTransactionMutation($input: CreateCourierTransactionInput!) {
    createCourierTransaction(input: $input) {
      courierTransaction {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createCourierTransaction', 'courierTransaction');
