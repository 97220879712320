// @flow
/* global $NonMaybeType, $ElementType */

import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import AttributeTable from 'components/AttributeTable';
import DataTable from 'components/DataTable';
import CollectionSelect from 'components/CollectionSelect';
import ProductSelect from 'containers/ProductSelect';
import InlineInput from 'components/InlineInput';
import { getShowActions } from 'utils/crud';
import { renderDate } from 'components/helpers';

import updateItemMutation from 'mutations/UpdateWarehouseMovementItemMutation';
import destroyItemMutation from 'mutations/DestroyWarehouseMovementItemMutation';

import copyMutation from 'mutations/CopyWarehouseMovementDocumentMutation';
import destroyMutation from 'mutations/DestroyWarehouseMovementDocumentMutation';
import resetMutation from 'mutations/ResetWarehouseMovementItemQuantitiesMutation';

import type { PageProps } from 'types';
import type { ShowWarehouseMovementDocumentQueryResponse } from './__generated__/ShowWarehouseMovementDocumentQuery.graphql';

import NewItem from './NewItem';

type Props = PageProps & {
  data: $NonMaybeType<$ElementType<ShowWarehouseMovementDocumentQueryResponse, 'data'>>,
  allWarehouses: $NonMaybeType<$ElementType<ShowWarehouseMovementDocumentQueryResponse, 'allWarehouses'>>,
};

const attributeRows = [
  {
    dataKey: 'eventedAt',
    label: 'Дата',
    render: renderDate,
  },
  {
    dataKey: 'comment',
    label: 'Коментарий',
  },
];

const actionMutations = {
  destroyMutation,
  copyMutation,
  resetMutation,
};

const Show = (props: Props) => (
  <Page title={`Перемещение между складами #${props.data.rowId}`} actions={getShowActions(props, actionMutations)}>
    <AttributeTable data={props.data} rows={attributeRows} />
    <NewItem
      context={props.context}
      allWarehouses={props.allWarehouses.nodes}
      additionalValues={{ documentId: props.data.rowId }}
    />
    <h3 style={{ marginTop: 50 }}>Позиции</h3>
    <DataTable
      {...props}
      rowKey="id"
      onlyTable
      rows={props.data.items}
      actions={['destroy']}
      destroyMutation={destroyItemMutation}
      skipReloadAfterDestroy
      columns={{
        rowId: {
          title: '№',
          width: 60,
          render: (_, row, index) => {
            if (props.location.query.sortColumn === 'rowId' && props.location.query.sortOrder === 'descend') {
              return `${props.data.items.nodes.length - index}.`;
            }

            return `${index + 1}.`;
          },
        },
        sourceId: {
          title: 'Склад-источник',
          width: 200,
          render: (value, row) => (
            <CollectionSelect
              style={{ width: '100%' }}
              items={props.allWarehouses.nodes.filter(Boolean)}
              value={value}
              onChange={v => updateItemMutation.commitValue(props.context.environment, row.id, 'sourceId', v)}
            />
          ),
        },
        targetId: {
          title: 'Склад-приёмник',
          width: 200,
          render: (value, row) => (
            <CollectionSelect
              style={{ width: '100%' }}
              items={props.allWarehouses.nodes.filter(Boolean)}
              value={value}
              onChange={v => updateItemMutation.commitValue(props.context.environment, row.id, 'targetId', v)}
            />
          ),
        },
        productId: {
          title: 'Продукт',
          render: (value, row) => (
            <ProductSelect
              value={value}
              data={row.product}
              environment={props.context.environment}
              onChange={v => updateItemMutation.commitValue(props.context.environment, row.id, 'productId', v)}
            />
          ),
        },
        quantity: {
          title: 'Количество',
          width: 120,
          render: (value, row) => (
            <InlineInput
              transfarmValue={v => v.replace(',', '.')}
              isEqual={(a, b) => parseFloat(a) === parseFloat(b)}
              onUpdate={v => updateItemMutation.commitValue(props.context.environment, row.id, 'quantity', v)}
              value={value}
            />
          ),
        },
      }}
    />
  </Page>
);

Show.propTypes = {
  data: PropTypes.shape({
    rowId: PropTypes.number.isRequired,
    items: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
  }).isRequired,
  allWarehouses: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    query: PropTypes.object.isRequired,
  }).isRequired,
  context: PropTypes.shape({
    environment: PropTypes.object.isRequired,
  }).isRequired,
};

export default Show;
