import React from 'react';
import { Link } from 'found';
import { compose, withProps } from 'recompact';
import { getRelationLink, renderDate, renderPrice } from 'components/helpers';
import Header from './Header';
import Footer from './Footer';
import s from './styles.css';

const documents = [
  {
    key: 'supplyDocumentId',
    label: 'Поставка',
    path: '/supply/documents',
  },
  {
    key: 'shopOrderDocumentId',
    label: 'Заказ',
    path: '/shop/order/documents',
  },
  {
    key: 'writeOffDocumentId',
    label: 'Списание',
    path: '/write-off/documents',
  },
  {
    key: 'sourceWarehouseMovementDocumentId',
    label: 'Перемещение',
    path: '/warehouse/movement/documents',
  },
  {
    key: 'targetWarehouseMovementDocumentId',
    label: 'Перемещение',
    path: '/warehouse/movement/documents',
  },
];

const formatQuantity = (value, unit) => {
  return value;
  // const v = parseFloat(value);
  //
  // if (unit === 'KG') {
  //   return v.toFixed(3);
  // }
  //
  // return Math.round(v);
};

export default compose(
  withProps(props => ({
    title: props.product.name,
    pageActions: {
      create: null,
      toProduct: {
        type: 'link',
        to: `/products/${props.params.productId}`,
        icon: 'file',
        children: 'Открыть продукт',
      },
    },
    Header,
    Footer,
    actions: [],
    tableProps: {
      rowKey: 'rowId',
      rowClassName: row => (row.quantity > 0 ? s.receive : s.shipped),
      pagination: false,
    },
    columns: {
      rowId: {
        hidden: true,
      },
      warehouseId: {
        title: 'Склад',
        sorter: false,
        render: v => {
          const warehouse = props.allWarehouses.nodes.find(n => n.rowId === v);
          return warehouse ? getRelationLink({ path: '/warehouses' })(warehouse) : '–';
        },
      },
      eventedAt: {
        sorter: false,
        title: 'Дата',
        render: renderDate,
      },
      document: {
        sorter: false,
        title: 'Документ',
        render: (_, row) => {
          const doc = documents.find(d => row[d.key]);

          if (doc) {
            return (
              <Link to={`${doc.path}/${row[doc.key]}`}>
                {doc.label} #{row[doc.key]}
              </Link>
            );
          }

          return '???';
        },
      },
      remain: {
        sorter: false,
        title: 'Остаток до',
        align: 'right',
        render: v => formatQuantity(v, props.product.unit),
      },
      quantity: {
        sorter: false,
        title: 'Количество',
        align: 'right',
        render: v => formatQuantity(v, props.product.unit),
      },
      cost: {
        sorter: false,
        title: 'Стоимость',
        align: 'right',
        render: renderPrice,
      },
    },
  }))
);
