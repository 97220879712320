import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateSupplyContractorMutation($input: UpdateSupplyContractorInput!) {
    updateSupplyContractor(input: $input) {
      supplyContractor {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateSupplyContractor', 'supplyContractor');
