/**
 * @flow
 * @relayHash ce709ea232bb48b7be12de26e73a62de
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AuthQueryVariables = {||};
export type AuthQueryResponse = {|
  +viewer: ?{|
    +rowId: number,
    +role: string,
    +firstName: string,
    +lastName: string,
  |}
|};
export type AuthQuery = {|
  variables: AuthQueryVariables,
  response: AuthQueryResponse,
|};
*/


/*
query AuthQuery {
  viewer {
    rowId
    role
    firstName
    lastName
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "role",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "AuthQuery",
  "id": null,
  "text": "query AuthQuery {\n  viewer {\n    rowId\n    role\n    firstName\n    lastName\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "AuthQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Employee",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          v3
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "AuthQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Employee",
        "plural": false,
        "selections": [
          v0,
          v1,
          v2,
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4ad4c747027a5b66df2dc05be8ae8247';
module.exports = node;
