exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-___DataTable__table___3kG1a {\n}\n\n.src-components-___DataTable__action___21Op9 {\n  font-size: 18px;\n  cursor: pointer;\n  margin: 0 3px;\n}\n\n.src-components-___DataTable__actionColumn___2AgXW {\n  padding: 0 0.7rem !important;\n  text-align: right;\n}\n\n.src-components-___DataTable__number___30snf {\n  text-align: right;\n}\n\n\n@media print {\n  .src-components-___DataTable__actionColumn___2AgXW {\n    display: none;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"table": "src-components-___DataTable__table___3kG1a",
	"action": "src-components-___DataTable__action___21Op9",
	"actionColumn": "src-components-___DataTable__actionColumn___2AgXW",
	"number": "src-components-___DataTable__number___30snf"
};