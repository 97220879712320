/**
 * @flow
 * @relayHash 7cdc126ff0ec1c5eda86b51c7a35bc3b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShopOrderDocumentPaymentMethod = "BY_CARD" | "BY_CASH" | "DEPOSIT" | "EMPLOYEE" | "ONLINE" | "%future added value";
export type ShopOrderDocumentStatus = "CANCELED" | "COMPLETED" | "DELIVERED" | "PACKING" | "PENDING" | "SHIPPED" | "%future added value";
export type UpdateShopOrderDocumentByRowIdInput = {
  clientMutationId?: ?string,
  shopOrderDocumentPatch: ShopOrderDocumentPatch,
  rowId: number,
};
export type ShopOrderDocumentPatch = {
  rowId?: ?number,
  paymentMethod?: ?ShopOrderDocumentPaymentMethod,
  status?: ?ShopOrderDocumentStatus,
  eventedAt?: ?any,
  totalSumMultiplier?: ?any,
  discount?: ?any,
  itemSum?: ?any,
  deliveryCost?: ?any,
  totalWeight?: ?any,
  meta?: ?any,
  comment?: ?string,
  groupId?: ?number,
  owner?: ?string,
  deliveryAt?: ?any,
  deliveryMaxDelay?: ?IntervalInput,
  deliveryPoint?: ?PointInput,
  sourceUrl?: ?string,
  printed?: ?boolean,
  ready?: ?boolean,
  deliveryPointBlocked?: ?boolean,
  groupPosition?: ?number,
  change?: ?any,
  confirmedAmount?: ?any,
  userId?: ?string,
  shopId?: ?number,
  asap?: ?boolean,
  deliveryNoticeAt?: ?any,
  ecoPack?: ?boolean,
  withoutOverweight?: ?boolean,
  withGlassCoupon?: ?boolean,
  deliveredAt?: ?any,
  byCardPaymentConfirmed?: ?boolean,
  userRating?: ?number,
  userRatingAt?: ?any,
  userReview?: ?string,
  userReviewAt?: ?any,
  userReviewImages?: ?$ReadOnlyArray<?string>,
  userReviewSolvedEmployeeId?: ?number,
  userReviewSolveComment?: ?string,
  readyAt?: ?any,
  shippedAt?: ?any,
  mainWarehouseId?: ?number,
  manualConfirmedAmount?: ?any,
  packageCount?: ?number,
  checkEmployeeId?: ?number,
  clientPhone?: ?string,
  clientEmail?: ?string,
  lastSentEvent?: ?string,
};
export type IntervalInput = {
  seconds?: ?number,
  minutes?: ?number,
  hours?: ?number,
  days?: ?number,
  months?: ?number,
  years?: ?number,
};
export type PointInput = {
  x: number,
  y: number,
};
export type MarkPackingDocumentMutationVariables = {|
  input: UpdateShopOrderDocumentByRowIdInput
|};
export type MarkPackingDocumentMutationResponse = {|
  +updateShopOrderDocumentByRowId: ?{|
    +shopOrderDocument: ?{|
      +id: string,
      +status: ShopOrderDocumentStatus,
    |}
  |}
|};
export type MarkPackingDocumentMutation = {|
  variables: MarkPackingDocumentMutationVariables,
  response: MarkPackingDocumentMutationResponse,
|};
*/


/*
mutation MarkPackingDocumentMutation(
  $input: UpdateShopOrderDocumentByRowIdInput!
) {
  updateShopOrderDocumentByRowId(input: $input) {
    shopOrderDocument {
      id
      status
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateShopOrderDocumentByRowIdInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateShopOrderDocumentByRowId",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateShopOrderDocumentByRowIdInput!"
      }
    ],
    "concreteType": "UpdateShopOrderDocumentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "shopOrderDocument",
        "storageKey": null,
        "args": null,
        "concreteType": "ShopOrderDocument",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "status",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "MarkPackingDocumentMutation",
  "id": null,
  "text": "mutation MarkPackingDocumentMutation(\n  $input: UpdateShopOrderDocumentByRowIdInput!\n) {\n  updateShopOrderDocumentByRowId(input: $input) {\n    shopOrderDocument {\n      id\n      status\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "MarkPackingDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "MarkPackingDocumentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8e78a1400d399beef3db16279f4e9a76';
module.exports = node;
