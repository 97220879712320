import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroyWarehouseMovementDocumentMutation($input: DeleteWarehouseMovementDocumentInput!) {
    deleteWarehouseMovementDocument(input: $input) {
      deletedWarehouseMovementDocumentId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteWarehouseMovementDocument', 'deletedWarehouseMovementDocumentId');
