import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';
import PageForm from 'components/PageForm';

const WarehouseForm = ({ title, form, handleSubmit, submitting }) => (
  <PageForm onSubmit={handleSubmit} title={title}>
    <Form.Item label="Название">
      {form.getFieldDecorator('name', {
        rules: [
          {
            required: true,
            message: 'Введите название',
          },
        ],
      })(<Input />)}
    </Form.Item>
    <Form.Item>
      <Button disabled={submitting} type="primary" htmlType="submit">
        Сохранить
      </Button>
    </Form.Item>
  </PageForm>
);

WarehouseForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default WarehouseForm;
