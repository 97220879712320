import React from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay';
import { Form, Select, Button } from 'antd';
import { formatOnlyHours } from 'utils/format';
import { times } from 'lodash';
import { showSuccess, showErrors } from 'utils/notifications';
import LinkButton from 'components/LinkButton';
import UpdateDocumentMutation from 'mutations/UpdateShopOrderDocumentMutation';
import './OrderBalloon.css';

const OrderBalloon = ({ allGroups, relay, data }) => {
  const changeGroup = groupId => {
    UpdateDocumentMutation.commit(relay.environment, data.rowId, { groupId })
      .then(showSuccess)
      .catch(showErrors);
  };

  const changePosition = groupPosition => {
    UpdateDocumentMutation.commit(relay.environment, data.rowId, { groupPosition })
      .then(showSuccess)
      .catch(showErrors);
  };

  return (
    <div>
      <LinkButton
        icon="eye"
        target="_blank"
        rel="noopener noreferrer"
        size="small"
        type="primary"
        to={`/shop/order/documents/${data.rowId}`}
      />
      <a href={data.sourceUrl} target="_blank" rel="noopener noreferrer">
        <Button icon="eye" size="small" />
      </a>
      {data.groupId && (
        <Button onClick={() => changeGroup(null)} size="small">
          Снять с маршрута
        </Button>
      )}
      <h3 styleName="title">Выбрать текущий</h3>
      <Form layout="inline">
        <Form.Item>
          <Select size="small" style={{ width: 150 }} value={data.groupId} onChange={changeGroup}>
            {allGroups.nodes.filter(Boolean).map(group => (
              <Select.Option value={group.rowId} key={group.rowId}>
                #{group.rowId} {formatOnlyHours(group.deadlineAt)} {group.courier && group.courier.lastName}{' '}
                {group.courier && group.courier.firstName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Select value={data.groupPosition} onChange={changePosition} size="small" style={{ width: 80 }}>
            {times(10, i => (
              <Select.Option key={i} value={i + 1}>
                {(i + 1).toString()}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
};

OrderBalloon.propTypes = {
  allCouriers: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
  allGroups: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
  relay: PropTypes.shape({
    environment: PropTypes.object.isRequired,
  }).isRequired,
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  setLastGroupId: PropTypes.func.isRequired,
};

export default createFragmentContainer(
  OrderBalloon,
  graphql`
    fragment OrderBalloonShopOrderDocumentMap on ShopOrderDocument {
      rowId
      sourceUrl

      groupId
      groupPosition

      group: shopOrderGroupByGroupId {
        id
        rowId
        deadlineAt
        courierId
        comment
      }
    }

    fragment OrderBalloonShopOrderDocumentMap_allGroups on ShopOrderGroupsConnection {
      nodes {
        id
        rowId
        deadlineAt
        courierId
        comment

        courier: courierByCourierId {
          id
          firstName
          lastName
          hasDriversLicense
        }
      }
    }
  `
);
