import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroySupplyItemMutation($input: DeleteSupplyItemInput!) {
    deleteSupplyItem(input: $input) {
      deletedSupplyItemId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteSupplyItem', 'deletedSupplyItemId');
