import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroyProductGroupMutation($input: DeleteProductGroupInput!) {
    deleteProductGroup(input: $input) {
      deletedProductGroupId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteProductGroup', 'deletedProductGroupId');
