import moment from 'moment';
import transform from 'utils/transform';

export default (data) =>
  transform(
    'status.in',
    (v) => v || ['DELIVERED'],
    transform(
      'paymentMethod.in',
      (v) => v || ['BY_CASH', 'BY_CARD'],
      data,
    )
  );
