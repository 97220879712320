/**
 * @flow
 * @relayHash 4ee36ad0237b07ccd3d27d6893927633
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EmployeeSelect_items$ref = any;
export type NewSupplyContractorQueryVariables = {||};
export type NewSupplyContractorQueryResponse = {|
  +currentDate: ?any,
  +allEmployees: ?{|
    +nodes: $ReadOnlyArray<{|
      +$fragmentRefs: EmployeeSelect_items$ref
    |}>
  |},
|};
export type NewSupplyContractorQuery = {|
  variables: NewSupplyContractorQueryVariables,
  response: NewSupplyContractorQueryResponse,
|};
*/


/*
query NewSupplyContractorQuery {
  currentDate
  allEmployees {
    nodes {
      ...EmployeeSelect_items
      id
    }
  }
}

fragment EmployeeSelect_items on Employee {
  rowId
  firstName
  lastName
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currentDate",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "NewSupplyContractorQuery",
  "id": null,
  "text": "query NewSupplyContractorQuery {\n  currentDate\n  allEmployees {\n    nodes {\n      ...EmployeeSelect_items\n      id\n    }\n  }\n}\n\nfragment EmployeeSelect_items on Employee {\n  rowId\n  firstName\n  lastName\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NewSupplyContractorQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      v0,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allEmployees",
        "storageKey": null,
        "args": null,
        "concreteType": "EmployeesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "EmployeeSelect_items",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NewSupplyContractorQuery",
    "argumentDefinitions": [],
    "selections": [
      v0,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allEmployees",
        "storageKey": null,
        "args": null,
        "concreteType": "EmployeesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "rowId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5e4b12e4b73528b9f6b4216237f19f3f';
module.exports = node;
