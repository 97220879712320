import { graphql } from 'relay-runtime';

export default graphql`
  query SupplyDocumentsQuery(
    $first: Int!
    $offset: Int!
    $orderBy: [SupplyDocumentsOrderBy!]
    $filter: SupplyDocumentFilter
  ) {
    rows: allSupplyDocuments(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
      totalCount

      nodes {
        id
        rowId
        eventedAt
        totalSum
        comment

        contractor: supplyContractorByContractorId {
          rowId
          name
        }
      }
    }

    allContractors: allSupplyContractors {
      nodes {
        rowId
        name
      }
    }
  }
`;
