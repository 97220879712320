import { graphql } from 'relay-runtime';

export default graphql`
  query ControlProductsQuery {
    allProducts(orderBy: [CATEGORY_ID_ASC]) {
      nodes {
        id
        rowId
        categoryId
        unit
        name
        shopOrderPrices
      }
    }
  }
`;
