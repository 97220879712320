exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-containers-Auth-___SigninForm__root___1cXIm {\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-containers-Auth-___SigninForm__root___1cXIm"
};