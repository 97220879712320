/**
 * @flow
 * @relayHash 954d87ee8c4ea67f4d3851e1eafccc0a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteProductGroupsProductInput = {
  clientMutationId?: ?string,
  id: string,
};
export type DestroyProductGroupsProductMutationVariables = {|
  input: DeleteProductGroupsProductInput
|};
export type DestroyProductGroupsProductMutationResponse = {|
  +deleteProductGroupsProduct: ?{|
    +deletedProductGroupsProductId: ?string
  |}
|};
export type DestroyProductGroupsProductMutation = {|
  variables: DestroyProductGroupsProductMutationVariables,
  response: DestroyProductGroupsProductMutationResponse,
|};
*/


/*
mutation DestroyProductGroupsProductMutation(
  $input: DeleteProductGroupsProductInput!
) {
  deleteProductGroupsProduct(input: $input) {
    deletedProductGroupsProductId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteProductGroupsProductInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteProductGroupsProduct",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteProductGroupsProductInput!"
      }
    ],
    "concreteType": "DeleteProductGroupsProductPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedProductGroupsProductId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DestroyProductGroupsProductMutation",
  "id": null,
  "text": "mutation DestroyProductGroupsProductMutation(\n  $input: DeleteProductGroupsProductInput!\n) {\n  deleteProductGroupsProduct(input: $input) {\n    deletedProductGroupsProductId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DestroyProductGroupsProductMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DestroyProductGroupsProductMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9945d2063e02883fb9a4c61ef114b833';
module.exports = node;
