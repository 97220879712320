/**
 * @flow
 * @relayHash caf140fe7b9114aefd9c665d4e344291
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateCourierInput = {
  clientMutationId?: ?string,
  courier: CourierInput,
};
export type CourierInput = {
  rowId?: ?number,
  firstName: string,
  middleName?: ?string,
  lastName?: ?string,
  hasDriversLicense?: ?boolean,
  workPhone?: ?string,
  privateCar?: ?string,
  telegramToken?: ?string,
  telegramChatId?: ?any,
  lastPosition?: ?PointInput,
  lastPositionedAt?: ?any,
  hideContactsFromClients?: ?boolean,
  paymentTerminalId?: ?string,
  activeGroupId?: ?number,
  fired?: ?boolean,
  basePayoff?: ?any,
  extraPayoff?: ?any,
  mobileCashierId?: ?string,
};
export type PointInput = {
  x: number,
  y: number,
};
export type CreateCourierMutationVariables = {|
  input: CreateCourierInput
|};
export type CreateCourierMutationResponse = {|
  +createCourier: ?{|
    +courier: ?{|
      +rowId: number
    |}
  |}
|};
export type CreateCourierMutation = {|
  variables: CreateCourierMutationVariables,
  response: CreateCourierMutationResponse,
|};
*/


/*
mutation CreateCourierMutation(
  $input: CreateCourierInput!
) {
  createCourier(input: $input) {
    courier {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateCourierInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateCourierInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateCourierMutation",
  "id": null,
  "text": "mutation CreateCourierMutation(\n  $input: CreateCourierInput!\n) {\n  createCourier(input: $input) {\n    courier {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateCourierMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createCourier",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateCourierPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "courier",
            "storageKey": null,
            "args": null,
            "concreteType": "Courier",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateCourierMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createCourier",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateCourierPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "courier",
            "storageKey": null,
            "args": null,
            "concreteType": "Courier",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd900c42b2bbf475f3c37b280334913e6';
module.exports = node;
