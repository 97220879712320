/**
 * @flow
 * @relayHash a4749d563fb6ca4d4c9aa80527d619dd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EmployeeSelect_items$ref = any;
type SupplyContractorSelect_items$ref = any;
export type NewSupplyDocumentQueryVariables = {||};
export type NewSupplyDocumentQueryResponse = {|
  +currentDate: ?any,
  +allSupplyContractors: ?{|
    +nodes: $ReadOnlyArray<{|
      +$fragmentRefs: SupplyContractorSelect_items$ref
    |}>
  |},
  +allEmployees: ?{|
    +nodes: $ReadOnlyArray<{|
      +$fragmentRefs: EmployeeSelect_items$ref
    |}>
  |},
  +allOrganizations: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +name: string,
    |}>
  |},
  +viewer: ?{|
    +rowId: number
  |},
|};
export type NewSupplyDocumentQuery = {|
  variables: NewSupplyDocumentQueryVariables,
  response: NewSupplyDocumentQueryResponse,
|};
*/


/*
query NewSupplyDocumentQuery {
  currentDate
  allSupplyContractors {
    nodes {
      ...SupplyContractorSelect_items
      id
    }
  }
  allEmployees {
    nodes {
      ...EmployeeSelect_items
      id
    }
  }
  allOrganizations {
    nodes {
      rowId
      name
      id
    }
  }
  viewer {
    rowId
    id
  }
}

fragment SupplyContractorSelect_items on SupplyContractor {
  rowId
  name
}

fragment EmployeeSelect_items on Employee {
  rowId
  firstName
  lastName
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currentDate",
  "args": null,
  "storageKey": null
},
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = [
  v1,
  v2,
  v3
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "NewSupplyDocumentQuery",
  "id": null,
  "text": "query NewSupplyDocumentQuery {\n  currentDate\n  allSupplyContractors {\n    nodes {\n      ...SupplyContractorSelect_items\n      id\n    }\n  }\n  allEmployees {\n    nodes {\n      ...EmployeeSelect_items\n      id\n    }\n  }\n  allOrganizations {\n    nodes {\n      rowId\n      name\n      id\n    }\n  }\n  viewer {\n    rowId\n    id\n  }\n}\n\nfragment SupplyContractorSelect_items on SupplyContractor {\n  rowId\n  name\n}\n\nfragment EmployeeSelect_items on Employee {\n  rowId\n  firstName\n  lastName\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "NewSupplyDocumentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      v0,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allSupplyContractors",
        "storageKey": null,
        "args": null,
        "concreteType": "SupplyContractorsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyContractor",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "SupplyContractorSelect_items",
                "args": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allEmployees",
        "storageKey": null,
        "args": null,
        "concreteType": "EmployeesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "EmployeeSelect_items",
                "args": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allOrganizations",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": true,
            "selections": [
              v1,
              v2
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Employee",
        "plural": false,
        "selections": [
          v1
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "NewSupplyDocumentQuery",
    "argumentDefinitions": [],
    "selections": [
      v0,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allSupplyContractors",
        "storageKey": null,
        "args": null,
        "concreteType": "SupplyContractorsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyContractor",
            "plural": true,
            "selections": v4
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allEmployees",
        "storageKey": null,
        "args": null,
        "concreteType": "EmployeesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": true,
            "selections": [
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              },
              v3
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allOrganizations",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": true,
            "selections": v4
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Employee",
        "plural": false,
        "selections": [
          v1,
          v3
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5cef27df8871ac2aa86d2fca32bc7080';
module.exports = node;
