import { hotRouteConfig } from 'found';

import App from './containers/App';
import AppQuery from './containers/App/AppQuery';
import Home from './pages/Home';
import PackingMonitor from './pages/PackingMonitor';
import AllOrdersMap, { AllOrdersMapQuery, allOrdersMapPrepareVariables } from './pages/AllOrdersMap';

import paymasterRoutes from './pages/Paymaster/routes';
import productRoutes from './pages/Products/routes';
import productAuwoWriteOffsRoutes from './pages/ProductAutoWriteOffs/routes';
import supplyContractorRoutes from './pages/SupplyContractors/routes';
import supplyDocumentRoutes from './pages/SupplyDocuments/routes';
import warehouseRoutes from './pages/Warehouses/routes';
import writeOffCategoryRoutes from './pages/WriteOffCategories/routes';
import writeOffDocumentRoutes from './pages/WriteOffDocuments/routes';
import warehouseMovementDocumentRoutes from './pages/WarehouseMovementDocuments/routes';
import shopOrderDocumentRoutes from './pages/ShopOrderDocuments/routes';
import moveRoutes from './pages/Moves/routes';
import productGroupRoutes from './pages/ProductGroups/routes';
import employeeRoutes from './pages/Employees/routes';
import productCategoryRoutes from './pages/ProductCategories/routes';
import courierRoutes from './pages/Couriers/routes';
import scaleRoutes from './pages/Scales/routes';
import balanceDocumentRoutes from './pages/BalanceDocuments/routes';
import shopDepositReplenishmentRoutes from './pages/ShopDepositReplenishments/routes';
import yandexOrderRoutes from './pages/YandexOrders/routes';
// -- NEXT ROUTE IMPORT HERE

export default hotRouteConfig([
  {
    path: '/',
    Component: App,
    query: AppQuery,
    children: [
      {
        path: '/',
        exact: true,
        Component: Home,
      },
      {
        path: '/packing/monitor',
        Component: PackingMonitor,
      },
      {
        path: '/allordersmap',
        Component: AllOrdersMap,
        query: AllOrdersMapQuery,
        prepareVariables: allOrdersMapPrepareVariables,
      },
      ...paymasterRoutes,
      ...productGroupRoutes,
      ...productRoutes,
      ...productAuwoWriteOffsRoutes,
      ...supplyContractorRoutes,
      ...supplyDocumentRoutes,
      ...warehouseRoutes,
      ...warehouseRoutes,
      ...writeOffCategoryRoutes,
      ...writeOffDocumentRoutes,
      ...warehouseMovementDocumentRoutes,
      ...shopOrderDocumentRoutes,
      ...moveRoutes,
      ...employeeRoutes,
      ...productCategoryRoutes,
      ...courierRoutes,
      ...scaleRoutes,
      ...balanceDocumentRoutes,
      ...shopDepositReplenishmentRoutes,
      ...yandexOrderRoutes,
      // -- NEXT ROUTE HERE
    ],
  },
]);
