import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';

class TotalSumInput extends React.Component {
  state = {
    value: '',
  };

  handleChange = e => {
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }

    this.setState({
      value: e.target.value,
    });
  };

  render() {
    return <Input {...this.props} onChange={this.handleChange} value={this.state.value} />;
  }
}

TotalSumInput.propTypes = {
  onChange: PropTypes.func,
};

TotalSumInput.defaultProps = {
  onChange: null,
};

export default TotalSumInput;
