import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Input, Button } from 'antd';
import { getSelectItems } from 'components/helpers';
import PageForm from 'components/PageForm';
import EmployeeSelect from 'components/EmployeeSelect';
import statuses from './statuses';

const SupplyContractorForm = ({ title, form, handleSubmit, submitting, allEmployees }) => (
  <PageForm onSubmit={handleSubmit} title={title}>
    <Form.Item label="Название">
      {form.getFieldDecorator('name', {
        rules: [
          {
            required: true,
            message: 'Введите название',
          },
        ],
      })(<Input />)}
    </Form.Item>
    <Form.Item label="Статус">
      {form.getFieldDecorator('status', { initialValue: 'UNSET' })(<Select>{getSelectItems(statuses)}</Select>)}
    </Form.Item>
    <Form.Item label="Владелец">
      {form.getFieldDecorator('ownerId')(
        <EmployeeSelect
          items={allEmployees.nodes}
          showSearch
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        />
      )}
    </Form.Item>
    <Form.Item label="Описание">{form.getFieldDecorator('description')(<Input.TextArea rows={4} />)}</Form.Item>
    <Form.Item>
      <Button disabled={submitting} type="primary" htmlType="submit">
        Сохранить
      </Button>
    </Form.Item>
  </PageForm>
);

SupplyContractorForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  allEmployees: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default SupplyContractorForm;
