export default (state = null, action) => {
  if (action.type === 'CHANGE_TOKEN') {
    return action.value;
  }

  if (action.type === 'SIGNOUT') {
    return null;
  }

  return state;
};
