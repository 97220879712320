import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateSupplyDocumentMutation($input: CreateSupplyDocumentInput!) {
    createSupplyDocument(input: $input) {
      supplyDocument {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createSupplyDocument', 'supplyDocument');
