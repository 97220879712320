/**
 * @flow
 * @relayHash d591fcadd8ab883461e7997542be1eb3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateSupplyDocumentInput = {
  clientMutationId?: ?string,
  supplyDocument: SupplyDocumentInput,
};
export type SupplyDocumentInput = {
  rowId?: ?number,
  contractorId: number,
  eventedAt?: ?any,
  totalSum?: ?any,
  comment?: ?string,
  meta?: ?any,
  ownerId?: ?number,
  organizationId?: ?number,
};
export type CreateSupplyDocumentMutationVariables = {|
  input: CreateSupplyDocumentInput
|};
export type CreateSupplyDocumentMutationResponse = {|
  +createSupplyDocument: ?{|
    +supplyDocument: ?{|
      +rowId: number
    |}
  |}
|};
export type CreateSupplyDocumentMutation = {|
  variables: CreateSupplyDocumentMutationVariables,
  response: CreateSupplyDocumentMutationResponse,
|};
*/


/*
mutation CreateSupplyDocumentMutation(
  $input: CreateSupplyDocumentInput!
) {
  createSupplyDocument(input: $input) {
    supplyDocument {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateSupplyDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateSupplyDocumentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateSupplyDocumentMutation",
  "id": null,
  "text": "mutation CreateSupplyDocumentMutation(\n  $input: CreateSupplyDocumentInput!\n) {\n  createSupplyDocument(input: $input) {\n    supplyDocument {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateSupplyDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createSupplyDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateSupplyDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyDocument",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateSupplyDocumentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createSupplyDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateSupplyDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyDocument",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5ca3c6c2c84c4a2c946e86ba4e7f882b';
module.exports = node;
