import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateScaleMutation($input: CreateScaleInput!) {
    createScale(input: $input) {
      scale {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createScale', 'scale');
