import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateSupplyItemMutation($input: CreateSupplyItemInput!) {
    createSupplyItem(input: $input) {
      supplyItem {
        rowId
        warehouseId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createSupplyItem', 'supplyItem');
