/**
 * @flow
 * @relayHash eeb15de68c0a536d9dc4bfec9c3b0613
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShopOrderDocumentPaymentMethod = "BY_CARD" | "BY_CASH" | "DEPOSIT" | "EMPLOYEE" | "ONLINE" | "%future added value";
export type ShopOrderDocumentStatus = "CANCELED" | "COMPLETED" | "DELIVERED" | "PACKING" | "PENDING" | "SHIPPED" | "%future added value";
export type ShopOrderDocumentFilter = {
  rowId?: ?IntFilter,
  paymentMethod?: ?ShopOrderDocumentPaymentMethodFilter,
  status?: ?ShopOrderDocumentStatusFilter,
  eventedAt?: ?DatetimeFilter,
  totalSumMultiplier?: ?BigFloatFilter,
  discount?: ?BigFloatFilter,
  itemSum?: ?BigFloatFilter,
  deliveryCost?: ?BigFloatFilter,
  totalWeight?: ?BigFloatFilter,
  meta?: ?JSONFilter,
  comment?: ?StringFilter,
  groupId?: ?IntFilter,
  owner?: ?StringFilter,
  deliveryAt?: ?DatetimeFilter,
  deliveryMaxDelay?: ?IntervalFilter,
  sourceUrl?: ?StringFilter,
  printed?: ?BooleanFilter,
  ready?: ?BooleanFilter,
  deliveryPointBlocked?: ?BooleanFilter,
  groupPosition?: ?IntFilter,
  change?: ?BigFloatFilter,
  confirmedAmount?: ?BigFloatFilter,
  userId?: ?StringFilter,
  shopId?: ?IntFilter,
  asap?: ?BooleanFilter,
  deliveryNoticeAt?: ?DatetimeFilter,
  ecoPack?: ?BooleanFilter,
  withoutOverweight?: ?BooleanFilter,
  withGlassCoupon?: ?BooleanFilter,
  deliveredAt?: ?DatetimeFilter,
  byCardPaymentConfirmed?: ?BooleanFilter,
  userRating?: ?IntFilter,
  userRatingAt?: ?DatetimeFilter,
  userReview?: ?StringFilter,
  userReviewAt?: ?DatetimeFilter,
  userReviewImages?: ?StringListFilter,
  userReviewSolvedEmployeeId?: ?IntFilter,
  userReviewSolveComment?: ?StringFilter,
  readyAt?: ?DatetimeFilter,
  shippedAt?: ?DatetimeFilter,
  mainWarehouseId?: ?IntFilter,
  manualConfirmedAmount?: ?BigFloatFilter,
  packageCount?: ?IntFilter,
  checkEmployeeId?: ?IntFilter,
  clientPhone?: ?StringFilter,
  clientEmail?: ?StringFilter,
  lastSentEvent?: ?StringFilter,
  deadlineAt?: ?StringFilter,
  deliveryOn?: ?DateFilter,
  depositBalance?: ?BigFloatFilter,
  expectedTotalSum?: ?BigFloatFilter,
  heavy?: ?BooleanFilter,
  label?: ?StringFilter,
  owedSum?: ?BigFloatFilter,
  totalSum?: ?BigFloatFilter,
  and?: ?$ReadOnlyArray<ShopOrderDocumentFilter>,
  or?: ?$ReadOnlyArray<ShopOrderDocumentFilter>,
  not?: ?ShopOrderDocumentFilter,
};
export type IntFilter = {
  isNull?: ?boolean,
  equalTo?: ?number,
  notEqualTo?: ?number,
  distinctFrom?: ?number,
  notDistinctFrom?: ?number,
  in?: ?$ReadOnlyArray<number>,
  notIn?: ?$ReadOnlyArray<number>,
  lessThan?: ?number,
  lessThanOrEqualTo?: ?number,
  greaterThan?: ?number,
  greaterThanOrEqualTo?: ?number,
};
export type ShopOrderDocumentPaymentMethodFilter = {
  isNull?: ?boolean,
  equalTo?: ?ShopOrderDocumentPaymentMethod,
  notEqualTo?: ?ShopOrderDocumentPaymentMethod,
  distinctFrom?: ?ShopOrderDocumentPaymentMethod,
  notDistinctFrom?: ?ShopOrderDocumentPaymentMethod,
  in?: ?$ReadOnlyArray<ShopOrderDocumentPaymentMethod>,
  notIn?: ?$ReadOnlyArray<ShopOrderDocumentPaymentMethod>,
  lessThan?: ?ShopOrderDocumentPaymentMethod,
  lessThanOrEqualTo?: ?ShopOrderDocumentPaymentMethod,
  greaterThan?: ?ShopOrderDocumentPaymentMethod,
  greaterThanOrEqualTo?: ?ShopOrderDocumentPaymentMethod,
};
export type ShopOrderDocumentStatusFilter = {
  isNull?: ?boolean,
  equalTo?: ?ShopOrderDocumentStatus,
  notEqualTo?: ?ShopOrderDocumentStatus,
  distinctFrom?: ?ShopOrderDocumentStatus,
  notDistinctFrom?: ?ShopOrderDocumentStatus,
  in?: ?$ReadOnlyArray<ShopOrderDocumentStatus>,
  notIn?: ?$ReadOnlyArray<ShopOrderDocumentStatus>,
  lessThan?: ?ShopOrderDocumentStatus,
  lessThanOrEqualTo?: ?ShopOrderDocumentStatus,
  greaterThan?: ?ShopOrderDocumentStatus,
  greaterThanOrEqualTo?: ?ShopOrderDocumentStatus,
};
export type DatetimeFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type BigFloatFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type JSONFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
  contains?: ?any,
  containsKey?: ?string,
  containsAllKeys?: ?$ReadOnlyArray<string>,
  containsAnyKeys?: ?$ReadOnlyArray<string>,
  containedBy?: ?any,
};
export type StringFilter = {
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  equalToInsensitive?: ?string,
  notEqualToInsensitive?: ?string,
  distinctFromInsensitive?: ?string,
  notDistinctFromInsensitive?: ?string,
  inInsensitive?: ?$ReadOnlyArray<string>,
  notInInsensitive?: ?$ReadOnlyArray<string>,
  lessThanInsensitive?: ?string,
  lessThanOrEqualToInsensitive?: ?string,
  greaterThanInsensitive?: ?string,
  greaterThanOrEqualToInsensitive?: ?string,
};
export type IntervalFilter = {
  isNull?: ?boolean,
  equalTo?: ?IntervalInput,
  notEqualTo?: ?IntervalInput,
  distinctFrom?: ?IntervalInput,
  notDistinctFrom?: ?IntervalInput,
  in?: ?$ReadOnlyArray<IntervalInput>,
  notIn?: ?$ReadOnlyArray<IntervalInput>,
  lessThan?: ?IntervalInput,
  lessThanOrEqualTo?: ?IntervalInput,
  greaterThan?: ?IntervalInput,
  greaterThanOrEqualTo?: ?IntervalInput,
};
export type IntervalInput = {
  seconds?: ?number,
  minutes?: ?number,
  hours?: ?number,
  days?: ?number,
  months?: ?number,
  years?: ?number,
};
export type BooleanFilter = {
  isNull?: ?boolean,
  equalTo?: ?boolean,
  notEqualTo?: ?boolean,
  distinctFrom?: ?boolean,
  notDistinctFrom?: ?boolean,
  in?: ?$ReadOnlyArray<boolean>,
  notIn?: ?$ReadOnlyArray<boolean>,
  lessThan?: ?boolean,
  lessThanOrEqualTo?: ?boolean,
  greaterThan?: ?boolean,
  greaterThanOrEqualTo?: ?boolean,
};
export type StringListFilter = {
  isNull?: ?boolean,
  equalTo?: ?$ReadOnlyArray<?string>,
  notEqualTo?: ?$ReadOnlyArray<?string>,
  distinctFrom?: ?$ReadOnlyArray<?string>,
  notDistinctFrom?: ?$ReadOnlyArray<?string>,
  lessThan?: ?$ReadOnlyArray<?string>,
  lessThanOrEqualTo?: ?$ReadOnlyArray<?string>,
  greaterThan?: ?$ReadOnlyArray<?string>,
  greaterThanOrEqualTo?: ?$ReadOnlyArray<?string>,
  contains?: ?$ReadOnlyArray<?string>,
  containedBy?: ?$ReadOnlyArray<?string>,
  overlaps?: ?$ReadOnlyArray<?string>,
  anyEqualTo?: ?string,
  anyNotEqualTo?: ?string,
  anyLessThan?: ?string,
  anyLessThanOrEqualTo?: ?string,
  anyGreaterThan?: ?string,
  anyGreaterThanOrEqualTo?: ?string,
};
export type DateFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type CourierPrintShopOrderDocumentQueryVariables = {|
  filter: ShopOrderDocumentFilter
|};
export type CourierPrintShopOrderDocumentQueryResponse = {|
  +orders: ?{|
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +owner: ?string,
      +deliveryAt: ?any,
      +totalSum: ?any,
      +groupPosition: number,
      +groupId: ?number,
      +label: ?string,
      +asap: boolean,
      +comment: string,
      +paymentMethod: ShopOrderDocumentPaymentMethod,
      +confirmedAmount: any,
      +depositBalance: ?any,
      +meta: any,
      +deliveryMaxDelay: {|
        +minutes: ?number,
        +hours: ?number,
      |},
      +yandexOrders: {|
        +nodes: $ReadOnlyArray<{|
          +clientName: string,
          +address: string,
          +phone: string,
          +comment: string,
          +change: any,
        |}>
      |},
      +group: ?{|
        +courier: ?{|
          +firstName: string,
          +lastName: ?string,
        |}
      |},
    |}>
  |}
|};
export type CourierPrintShopOrderDocumentQuery = {|
  variables: CourierPrintShopOrderDocumentQueryVariables,
  response: CourierPrintShopOrderDocumentQueryResponse,
|};
*/


/*
query CourierPrintShopOrderDocumentQuery(
  $filter: ShopOrderDocumentFilter!
) {
  orders: allShopOrderDocuments(filter: $filter, orderBy: [GROUP_POSITION_ASC]) {
    nodes {
      id
      rowId
      owner
      deliveryAt
      totalSum
      groupPosition
      groupId
      label
      asap
      comment
      paymentMethod
      confirmedAmount
      depositBalance
      meta
      deliveryMaxDelay {
        minutes
        hours
      }
      yandexOrders: yandexOrdersByDocumentId(first: 1) {
        nodes {
          clientName
          address
          phone
          comment
          change
          id
        }
      }
      group: shopOrderGroupByGroupId {
        courier: courierByCourierId {
          firstName
          lastName
          id
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "ShopOrderDocumentFilter!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter",
    "type": "ShopOrderDocumentFilter"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": [
      "GROUP_POSITION_ASC"
    ],
    "type": "[ShopOrderDocumentsOrderBy!]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "owner",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deliveryAt",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalSum",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "groupPosition",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "groupId",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "asap",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "comment",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "paymentMethod",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "confirmedAmount",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "depositBalance",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "meta",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "deliveryMaxDelay",
  "storageKey": null,
  "args": null,
  "concreteType": "Interval",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "minutes",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hours",
      "args": null,
      "storageKey": null
    }
  ]
},
v17 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1,
    "type": "Int"
  }
],
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientName",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "address",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "change",
  "args": null,
  "storageKey": null
},
v22 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "CourierPrintShopOrderDocumentQuery",
  "id": null,
  "text": "query CourierPrintShopOrderDocumentQuery(\n  $filter: ShopOrderDocumentFilter!\n) {\n  orders: allShopOrderDocuments(filter: $filter, orderBy: [GROUP_POSITION_ASC]) {\n    nodes {\n      id\n      rowId\n      owner\n      deliveryAt\n      totalSum\n      groupPosition\n      groupId\n      label\n      asap\n      comment\n      paymentMethod\n      confirmedAmount\n      depositBalance\n      meta\n      deliveryMaxDelay {\n        minutes\n        hours\n      }\n      yandexOrders: yandexOrdersByDocumentId(first: 1) {\n        nodes {\n          clientName\n          address\n          phone\n          comment\n          change\n          id\n        }\n      }\n      group: shopOrderGroupByGroupId {\n        courier: courierByCourierId {\n          firstName\n          lastName\n          id\n        }\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CourierPrintShopOrderDocumentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "orders",
        "name": "allShopOrderDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "ShopOrderDocumentsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderDocument",
            "plural": true,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              v10,
              v11,
              v12,
              v13,
              v14,
              v15,
              v16,
              {
                "kind": "LinkedField",
                "alias": "yandexOrders",
                "name": "yandexOrdersByDocumentId",
                "storageKey": "yandexOrdersByDocumentId(first:1)",
                "args": v17,
                "concreteType": "YandexOrdersConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "nodes",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "YandexOrder",
                    "plural": true,
                    "selections": [
                      v18,
                      v19,
                      v20,
                      v11,
                      v21
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": "group",
                "name": "shopOrderGroupByGroupId",
                "storageKey": null,
                "args": null,
                "concreteType": "ShopOrderGroup",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": "courier",
                    "name": "courierByCourierId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Courier",
                    "plural": false,
                    "selections": [
                      v22,
                      v23
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CourierPrintShopOrderDocumentQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "orders",
        "name": "allShopOrderDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "ShopOrderDocumentsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderDocument",
            "plural": true,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              v10,
              v11,
              v12,
              v13,
              v14,
              v15,
              v16,
              {
                "kind": "LinkedField",
                "alias": "yandexOrders",
                "name": "yandexOrdersByDocumentId",
                "storageKey": "yandexOrdersByDocumentId(first:1)",
                "args": v17,
                "concreteType": "YandexOrdersConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "nodes",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "YandexOrder",
                    "plural": true,
                    "selections": [
                      v18,
                      v19,
                      v20,
                      v11,
                      v21,
                      v2
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": "group",
                "name": "shopOrderGroupByGroupId",
                "storageKey": null,
                "args": null,
                "concreteType": "ShopOrderGroup",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": "courier",
                    "name": "courierByCourierId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Courier",
                    "plural": false,
                    "selections": [
                      v22,
                      v23,
                      v2
                    ]
                  },
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6e31235a37640d3e272d2f3849e767b8';
module.exports = node;
