exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-Moves-___Header__warehouses___2psYZ {\n  display: flex;\n  flex-wrap: wrap;\n  padding-bottom: 20px;\n}\n\n.src-pages-Moves-___Header__active-warehouse___T-DB_,\n.src-pages-Moves-___Header__warehouse___200GK {\n  width: 150px;\n  border-radius: 3px;\n  border: 1px solid #e0e0e0;\n  padding: 10px 15px;\n  margin-right: 20px;\n  text-decoration: none;\n  transition: box-shadow 0.2s ease-in-out;\n}\n\n.src-pages-Moves-___Header__active-warehouse___T-DB_:hover,\n.src-pages-Moves-___Header__warehouse___200GK:hover {\n  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);\n}\n\n.src-pages-Moves-___Header__active-warehouse___T-DB_ {\n  border-color: #4caf50;\n}\n\n.src-pages-Moves-___Header__warehouse-title___STNgx {\n  font-weight: 500;\n  font-size: 120%;\n}\n\n.src-pages-Moves-___Header__warehouse-value___3g2NF {\n}\n", ""]);

// exports
exports.locals = {
	"warehouses": "src-pages-Moves-___Header__warehouses___2psYZ",
	"active-warehouse": "src-pages-Moves-___Header__active-warehouse___T-DB_",
	"warehouse": "src-pages-Moves-___Header__warehouse___200GK",
	"warehouse-title": "src-pages-Moves-___Header__warehouse-title___STNgx",
	"warehouse-value": "src-pages-Moves-___Header__warehouse-value___3g2NF"
};