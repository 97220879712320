/**
 * @flow
 * @relayHash 662066872504fa3db1faef2cfbe40ee3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EmployeeSelect_items$ref = any;
export type SupplyContractorStatus = "ARCHIVE" | "DANGEROUS" | "FRIENDS" | "UNSET" | "%future added value";
export type EditSupplyContractorQueryVariables = {|
  rowId: number
|};
export type EditSupplyContractorQueryResponse = {|
  +data: ?{|
    +id: string,
    +name: string,
    +description: string,
    +status: SupplyContractorStatus,
    +ownerId: ?number,
  |},
  +allEmployees: ?{|
    +nodes: $ReadOnlyArray<{|
      +$fragmentRefs: EmployeeSelect_items$ref
    |}>
  |},
|};
export type EditSupplyContractorQuery = {|
  variables: EditSupplyContractorQueryVariables,
  response: EditSupplyContractorQueryResponse,
|};
*/


/*
query EditSupplyContractorQuery(
  $rowId: Int!
) {
  data: supplyContractorByRowId(rowId: $rowId) {
    id
    name
    description
    status
    ownerId
  }
  allEmployees {
    nodes {
      ...EmployeeSelect_items
      id
    }
  }
}

fragment EmployeeSelect_items on Employee {
  rowId
  firstName
  lastName
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "LinkedField",
  "alias": "data",
  "name": "supplyContractorByRowId",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "rowId",
      "variableName": "rowId",
      "type": "Int!"
    }
  ],
  "concreteType": "SupplyContractor",
  "plural": false,
  "selections": [
    v1,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "name",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "description",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "status",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ownerId",
      "args": null,
      "storageKey": null
    }
  ]
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditSupplyContractorQuery",
  "id": null,
  "text": "query EditSupplyContractorQuery(\n  $rowId: Int!\n) {\n  data: supplyContractorByRowId(rowId: $rowId) {\n    id\n    name\n    description\n    status\n    ownerId\n  }\n  allEmployees {\n    nodes {\n      ...EmployeeSelect_items\n      id\n    }\n  }\n}\n\nfragment EmployeeSelect_items on Employee {\n  rowId\n  firstName\n  lastName\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditSupplyContractorQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v2,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allEmployees",
        "storageKey": null,
        "args": null,
        "concreteType": "EmployeesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "EmployeeSelect_items",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditSupplyContractorQuery",
    "argumentDefinitions": v0,
    "selections": [
      v2,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allEmployees",
        "storageKey": null,
        "args": null,
        "concreteType": "EmployeesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "rowId",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              },
              v1
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1597b7a936048353fe7e881cbd7ce720';
module.exports = node;
