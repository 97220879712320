import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateWarehouseMovementItemMutation($input: UpdateWarehouseMovementItemInput!) {
    updateWarehouseMovementItem(input: $input) {
      warehouseMovementItem {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateWarehouseMovementItem', 'warehouseMovementItem');
