import React from 'react';
import { graphql, createFragmentContainer } from 'react-relay';
import { withState } from 'recompact';
import { Input, Button, Icon, notification } from 'antd';
import { showErrors, showSuccess } from 'utils/notifications';
import Mutation from 'mutations/UpdateShopOrderDocumentManualConfirmedAmountMutation';

const PaymasterAmount = ({ data, value, setValue, relay }) => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const v = parseFloat(value) || null;

    Mutation.commit(relay.environment, data.id, { manualConfirmedAmount: v })
      .then(data => {
        if (data) {
          showSuccess(data);
        } else {
          notification.error({
            message: 'Ошибка',
            description: 'Не получилось поменять.',
          });
        }
      })
      .catch(showErrors);
  };

  const submitDisabled = data.manualConfirmedAmount === (value || null);
  const warning = data.manualConfirmedAmount !== data.totalSum;

  return (
    <form style={{ display: 'flex', alignItems: 'center', }} onSubmit={handleSubmit}>
      <Icon type={warning ? 'warning' : 'check-circle'} style={{ color: warning ? 'red' : 'green' }} />
      <Input value={value} onChange={(e) => setValue(e.target.value)} size="small" style={{ margin: '0 10px' }} />
      <Button size="small" htmlType="submit" style={{ width: 80 }} disabled={submitDisabled}>
        Сохранить
      </Button>
    </form>
  );
};

export default createFragmentContainer(
  withState('value', 'setValue', (props) => props.data.manualConfirmedAmount || '')(PaymasterAmount),
  graphql`
    fragment PaymasterAmount on ShopOrderDocument {
      id
      totalSum
      manualConfirmedAmount
    }
  `
);
