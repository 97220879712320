import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Select, Icon, TimePicker, Table } from 'antd';
import { graphql, createFragmentContainer } from 'react-relay';
import { Link } from 'found';
import { compose, withState } from 'recompact';
import { showErrors, showSuccess } from 'utils/notifications';
import { MapRouteContext } from 'components/MapRoute';
import DebouncedInput from 'components/DebouncedInput';
import UpdateMutation from 'mutations/UpdateShopOrderGroupMutation';
import DestroyMutation from 'mutations/DestroyShopOrderGroupMutation';
import moment from 'moment';
import CourierSelect from './CourierSelect';
import s from './Groups.css';

const getDeliveryTime = (route, q) => {
  if (!route) {
    return null;
  }

  const driveTime = route.getJamsTime();
  const deliveryTime = 20 * q * 60;
  
  return `${moment.utc((driveTime + deliveryTime) * 1000).format('H ч mm мин.')}`
};

const Groups = ({ data, allCouriers, relay, setSelected, selectedIds, setSelectedOrderIds, status, setStatus }) => {
  const { routes } = useContext(MapRouteContext);

  const columns = [
    {
      title: '#',
      dataIndex: 'rowId',
      key: 'rowId',
      width: 60,
    },
    {
      title: 'Дедлайн, курьер',
      dataIndex: 'deadlineAt',
      key: 'deadlineAt',
      width: 180,
      render: (v, row) => (
        <React.Fragment>
          <TimePicker
            placeholder="Время"
            size="small"
            style={{
              width: 90,
            }}
            format="HH:mm"
            minuteStep={10}
            allowEmpty={false}
            value={moment(v).tz('Europe/Moscow')}
            onChange={value => {
              UpdateMutation.commit(relay.environment, row.id, {
                deadlineAt: value.format(),
              }).catch(showErrors);
            }}
          />
          <CourierSelect
            items={allCouriers.nodes}
            size="small"
            style={{
              width: 180,
              marginTop: 10,
            }}
            value={row.courierId}
            onChange={courierId => {
              UpdateMutation.commit(relay.environment, row.id, {
                courierId,
              }).catch(showErrors);
            }}
          />
        </React.Fragment>
      ),
    },
    {
      title: 'Комментарий, время доставки',
      dataIndex: 'comment',
      key: 'comment',
      render: (value, row) => (
        <React.Fragment>
          <DebouncedInput
            style={{
              width: '100%',
            }}
            size="small"
            value={value}
            onSave={comment => {
              UpdateMutation.commit(relay.environment, row.id, {
                comment,
              }).catch(showErrors);
            }}
          />
          <p row={row} styleName="delivery-time">
            {getDeliveryTime(routes[row.rowId], row.orders.nodes.length)}
          </p>
        </React.Fragment>
      ),
    },
    {
      dataIndex: 'orders',
      key: 'view',
      width: 20,
      render: (orders, row) => {
        if (orders.nodes.length === 0) {
          return '–';
        }

        return (
          <div>
            <Icon
              type="eye"
              onClick={() => {
                setSelectedOrderIds(orders.nodes.map(o => o.rowId));
                setSelected([row.rowId]);
              }}
            />
            <br />
            {row.courierId && (
              <Link to={`/couriers/${row.courierId}/transactions`} target="_blank">
                <Icon type="dollar" />
              </Link>
            )}
          </div>
        );
      },
    },
    {
      dataIndex: 'orders',
      key: 'print',
      width: 20,
      render: orders => {
        if (orders.nodes.length === 0) {
          return '–';
        }

        const to = {
          pathname: '/shop/order/documents/courier',
          query: { ids: orders.nodes.map(o => o.rowId) },
        };

        return (
          <Link to={to} target="_blank">
            <Icon type="file" />
          </Link>
        );
      },
    },
    {
      dataIndex: 'id',
      key: 'destroy',
      width: 20,
      render: id => (
        <Icon
          style={{ cursor: 'pointer' }}
          type="delete"
          onClick={() => {
            DestroyMutation.commit(relay.environment, id)
              .then(showSuccess)
              .catch(showErrors);
          }}
        />
      ),
    },
  ];

  const filters = {
    all: group => group,
    shipped: group =>
      group && group.orders.nodes.length > 0 && group.orders.nodes.every(o => o && o.status === 'SHIPPED'),
    ready: group =>
      group && group.orders.nodes.length > 0 && group.orders.nodes.every(o => o && o.ready && o.status !== 'SHIPPED'),
    pending: group =>
      group && group.orders.nodes.length > 0 && group.orders.nodes.every(o => o && !o.ready && o.status !== 'SHIPPED'),
  };

  const dataSource = data.nodes.filter(filters[status]).sort((a, b) => moment(a.deadlineAt).diff(b.deadlineAt));

  return (
    <div>
      <Select onChange={setStatus} value={status} style={{ width: 150 }}>
        <Select.Option value="all">Все</Select.Option>
        <Select.Option value="pending">В работе</Select.Option>
        <Select.Option value="ready">Готов</Select.Option>
        <Select.Option value="shipped">Отправлен</Select.Option>
      </Select>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowClassName={row => {
          if (status !== 'all') {
            return null;
          }

          if (row.orders.nodes.every(o => o && o.status === 'SHIPPED')) {
            return s.shipped;
          }

          if (row.orders.nodes.every(o => o && o.ready)) {
            return s.ready;
          }

          return null;
        }}
        idKey="rowId"
        pagination={false}
        scroll={{ y: 'calc(100vh - 155px)' }}
        rowKey="rowId"
        rowSelection={{
          selectedRowKeys: selectedIds,
          onChange: setSelected,
        }}
      />
    </div>
  );
};

Groups.propTypes = {
  data: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
  allCouriers: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
  relay: PropTypes.shape({
    environment: PropTypes.object.isRequired,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  setSelected: PropTypes.func.isRequired,
  setSelectedOrderIds: PropTypes.func.isRequired,
  selectedIds: PropTypes.array.isRequired,
  status: PropTypes.string.isRequired,
  setStatus: PropTypes.func.isRequired,
};

const enhance = compose(withState('status', 'setStatus', 'all'));

export default createFragmentContainer(
  enhance(Groups),
  graphql`
    fragment GroupsMapShopOrderDocument on ShopOrderGroupsConnection {
      nodes {
        id
        rowId
        deadlineAt
        courierId

        orders: shopOrderDocumentsByGroupId {
          nodes {
            rowId
            status
            ready
          }
        }
      }
    }

    fragment GroupsMapShopOrderDocument_allCouriers on CouriersConnection {
      nodes {
        ...CourierSelectShopOrderDocumentMap_items
      }
    }
  `,
);
