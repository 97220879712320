/**
 * @flow
 * @relayHash be1f2e7fa087b99a2a3dc109a8c88205
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShopDepositReplenishmentsOrderBy = "COMMENT_ASC" | "COMMENT_DESC" | "CREATED_AT_ASC" | "CREATED_AT_DESC" | "ID_ASC" | "ID_DESC" | "NATURAL" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "SELLER_ID_ASC" | "SELLER_ID_DESC" | "USER_ID_ASC" | "USER_ID_DESC" | "USER_OWNER_ASC" | "USER_OWNER_DESC" | "VALUE_ASC" | "VALUE_DESC" | "%future added value";
export type ShopDepositReplenishmentFilter = {
  rowId?: ?IntFilter,
  createdAt?: ?DatetimeFilter,
  userId?: ?StringFilter,
  userOwner?: ?StringFilter,
  value?: ?BigFloatFilter,
  comment?: ?StringFilter,
  sellerId?: ?IntFilter,
  and?: ?$ReadOnlyArray<ShopDepositReplenishmentFilter>,
  or?: ?$ReadOnlyArray<ShopDepositReplenishmentFilter>,
  not?: ?ShopDepositReplenishmentFilter,
};
export type IntFilter = {
  isNull?: ?boolean,
  equalTo?: ?number,
  notEqualTo?: ?number,
  distinctFrom?: ?number,
  notDistinctFrom?: ?number,
  in?: ?$ReadOnlyArray<number>,
  notIn?: ?$ReadOnlyArray<number>,
  lessThan?: ?number,
  lessThanOrEqualTo?: ?number,
  greaterThan?: ?number,
  greaterThanOrEqualTo?: ?number,
};
export type DatetimeFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type StringFilter = {
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  equalToInsensitive?: ?string,
  notEqualToInsensitive?: ?string,
  distinctFromInsensitive?: ?string,
  notDistinctFromInsensitive?: ?string,
  inInsensitive?: ?$ReadOnlyArray<string>,
  notInInsensitive?: ?$ReadOnlyArray<string>,
  lessThanInsensitive?: ?string,
  lessThanOrEqualToInsensitive?: ?string,
  greaterThanInsensitive?: ?string,
  greaterThanOrEqualToInsensitive?: ?string,
};
export type BigFloatFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type ShopDepositReplenishmentsQueryVariables = {|
  first: number,
  offset: number,
  orderBy?: ?$ReadOnlyArray<ShopDepositReplenishmentsOrderBy>,
  filter?: ?ShopDepositReplenishmentFilter,
|};
export type ShopDepositReplenishmentsQueryResponse = {|
  +rows: ?{|
    +totalCount: number,
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +userId: string,
      +createdAt: any,
      +userOwner: string,
      +value: any,
    |}>,
  |},
  +allEmployees: ?{|
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +firstName: string,
      +lastName: string,
    |}>
  |},
|};
export type ShopDepositReplenishmentsQuery = {|
  variables: ShopDepositReplenishmentsQueryVariables,
  response: ShopDepositReplenishmentsQueryResponse,
|};
*/


/*
query ShopDepositReplenishmentsQuery(
  $first: Int!
  $offset: Int!
  $orderBy: [ShopDepositReplenishmentsOrderBy!]
  $filter: ShopDepositReplenishmentFilter
) {
  rows: allShopDepositReplenishments(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
    totalCount
    nodes {
      id
      rowId
      userId
      createdAt
      userOwner
      value
    }
  }
  allEmployees {
    nodes {
      id
      rowId
      firstName
      lastName
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[ShopDepositReplenishmentsOrderBy!]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "ShopDepositReplenishmentFilter",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = [
  {
    "kind": "LinkedField",
    "alias": "rows",
    "name": "allShopDepositReplenishments",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter",
        "type": "ShopDepositReplenishmentFilter"
      },
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "offset",
        "variableName": "offset",
        "type": "Int"
      },
      {
        "kind": "Variable",
        "name": "orderBy",
        "variableName": "orderBy",
        "type": "[ShopDepositReplenishmentsOrderBy!]"
      }
    ],
    "concreteType": "ShopDepositReplenishmentsConnection",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "totalCount",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": null,
        "concreteType": "ShopDepositReplenishment",
        "plural": true,
        "selections": [
          v1,
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "userId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "userOwner",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "value",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  },
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "allEmployees",
    "storageKey": null,
    "args": null,
    "concreteType": "EmployeesConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": null,
        "concreteType": "Employee",
        "plural": true,
        "selections": [
          v1,
          v2,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "firstName",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "lastName",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ShopDepositReplenishmentsQuery",
  "id": null,
  "text": "query ShopDepositReplenishmentsQuery(\n  $first: Int!\n  $offset: Int!\n  $orderBy: [ShopDepositReplenishmentsOrderBy!]\n  $filter: ShopDepositReplenishmentFilter\n) {\n  rows: allShopDepositReplenishments(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {\n    totalCount\n    nodes {\n      id\n      rowId\n      userId\n      createdAt\n      userOwner\n      value\n    }\n  }\n  allEmployees {\n    nodes {\n      id\n      rowId\n      firstName\n      lastName\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ShopDepositReplenishmentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v3
  },
  "operation": {
    "kind": "Operation",
    "name": "ShopDepositReplenishmentsQuery",
    "argumentDefinitions": v0,
    "selections": v3
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8c4e2826ac181294bd8c4a6919f4aab1';
module.exports = node;
