import { compose, withProps } from 'recompact';
import withCreateEnhance from 'hoc/withCreateEnhance';
import mutation from 'mutations/CreateProductMutation';
import normalize from '../normalizeForm';

export default compose(
  withProps({
    title: 'Создать новый продукт',
  }),
  withCreateEnhance({
    normalize,
    mutation,
  })
);
