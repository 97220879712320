import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ProgressButton from 'components/ProgressButton';
import UpdateMutation from 'mutations/UpdateShopOrderDocumentMutation';
import { Link } from 'found';
import { Slider, Button, Icon, Table } from 'antd';
import { merge } from 'unchanged';
import { renderEnum } from 'components/helpers';
import Filters from './Filters';
import getLabel from '../getLabel';
import statuses from '../statuses';
import s from './Orders.css';

const Orders = ({
  orders,
  context,
  location,
  setTimeRange,
  timeRange,
  setMapState,
  mapState,
  setSelected,
  selectedIds,
  router,
}) => {
  const columns = [
    {
      title: '#',
      dataIndex: 'rowId',
      key: 'rowId',
      width: 110,
      sorter: true,
      sortOrder: location.query.sortColumn === 'rowId' && location.query.sortOrder,
      render: (_, row) => (
        <div>
          {getLabel(row)}{' '}
          {row.deliveryPoint && (
            <Icon
              type="eye"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setMapState({
                  ...mapState,
                  zoom: 15,
                  center: [row.deliveryPoint.x, row.deliveryPoint.y],
                });
              }}
            />
          )}
        </div>
      ),
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      width: 90,
      sorter: true,
      sortOrder: location.query.sortColumn === 'status' && location.query.sortOrder,
      render: renderEnum(statuses),
    },
    {
      title: 'Время',
      dataIndex: 'deliveryAt',
      key: 'deliveryAt',
      width: 100,
      sorter: true,
      sortOrder: location.query.sortColumn === 'deliveryAt' && location.query.sortOrder,
      render: (v, row) => (
        <span>
          {moment(v).format('HH:mm')}{' '}
          {row.asap && (
            <i className="material-icons" style={{ fontSize: 15, height: 14, overflow: 'hidden' }} title="ASAP">
              alarm
            </i>
          )}
        </span>
      ),
    },
    {
      title: 'Мз',
      dataIndex: 'deliveryMaxDelay.hours',
      key: 'deliveryMaxDelay',
      width: 70,
    },
    {
      title: 'Вес',
      dataIndex: 'totalWeight',
      width: 70,
      render: totalWeight => (totalWeight > 0 ? Math.round(totalWeight) : '-'),
    },
    {
      title: 'Маршрут',
      dataIndex: 'group.rowId',
      key: 'groupId',
      width: 100,
      sorter: true,
      sortOrder: location.query.sortColumn === 'groupId' && location.query.sortOrder,
      render: groupId => {
        const ids = orders.filter(o => o.groupId === groupId).map(o => o.rowId);

        if (ids.length === 0) {
          return '–';
        }

        return (
          <div>
            #{groupId} <Icon type="eye" onClick={() => setSelected(ids)} /> {ids.length}
          </div>
        );
      },
    },
  ];

  const handleChange = (pagination, filters, sorter) => {
    if (!sorter.column) {
      return;
    }

    const params = {
      sortColumn: sorter.column.key,
      sortOrder: sorter.order,
    };

    router.push(merge('query', params, location));
  };

  return (
    <div>
      <div styleName="header">
        <Link
          to={{
            pathname: '/shop/order/documents/courier',
            query: { ids: selectedIds.filter(id => orders.find(o => o.rowId === id)) },
          }}
        >
          Печать выделенного
        </Link>
        <ProgressButton
          style={{ marginLeft: 20 }}
          onClick={() =>
            Promise.all(
              selectedIds.filter(id => orders.find(o => o.rowId === id)).map(id => {
                UpdateMutation.commit(context.environment, id, {
                  status: 'PACKING',
                });

                return id;
              })
            ).then(ids => {
              window.open(`/shop/order/documents/print?${ids.map(id => `ids=${id}`).join('&')}`);
            })
          }
          ссsize="small"
        >
          На сборку
        </ProgressButton>
        <Button
          style={{ marginLeft: 20 }}
          onClick={() => setMapState({ center: [55.76, 37.64], zoom: 10 })}
          size="small"
        >
          Показать Москву
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          onClick={() => setSelected(orders.filter(o => o && !o.group).map(o => o.rowId))}
          size="small"
        >
          Без марш
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          onClick={() => {
            const count = {};
            const ids = [];

            orders.forEach(o => {
              count[o.groupId] = count[o.groupId] || 0;
              count[o.groupId] += 1;
            });

            Object.keys(count).forEach(groupId => {
              if (count[groupId] === 1) {
                const id = parseInt(groupId, 10);

                orders.forEach(o => {
                  if (o.groupId === id) {
                    ids.push(o.rowId);
                  }
                });
              }
            });

            setSelected(ids);
          }}
          size="small"
        >
          1
        </Button>
        <Slider range value={timeRange} min={0} max={23} onChange={setTimeRange} />
        <Filters />
      </div>
      <Table
        columns={columns}
        onChange={handleChange}
        dataSource={orders}
        pagination={false}
        rowClassName={row => row.ready && s.ready}
        scroll={{ y: 'calc(100vh - 370px)' }}
        rowKey="rowId"
        rowSelection={{
          selectedRowKeys: selectedIds,
          onChange: setSelected,
        }}
      />
    </div>
  );
};

Orders.propTypes = {
  orders: PropTypes.array.isRequired,
  handleSelect: PropTypes.func.isRequired,
  selectedRowKeys: PropTypes.array.isRequired,
  context: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  timeRange: PropTypes.array.isRequired,
  setTimeRange: PropTypes.func.isRequired,
  mapState: PropTypes.array.isRequired,
  setMapState: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  selectedIds: PropTypes.array.isRequired,
  router: PropTypes.array.isRequired,
};

export default Orders;
