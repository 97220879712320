import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroyProductAutoWriteOffMutation($input: DeleteProductAutoWriteOffInput!) {
    deleteProductAutoWriteOff(input: $input) {
      deletedProductAutoWriteOffId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteProductAutoWriteOff', 'deletedProductAutoWriteOffId');
