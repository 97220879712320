import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateEmployeeMutation($input: CreateEmployeeInput!) {
    createEmployee(input: $input) {
      employee {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createEmployee', 'employee');
