import React from 'react';
import Page from 'components/Page';
import LinkButton from 'components/LinkButton';

const NotFound = () => (
  <Page title="Страница не найдена">
    <div>
      Такая запись никогда не существовала или была удалена. <br /> Если вы уверены, что тут должно что-то быть -
      обратитесь к разработчикам.
    </div>
    <br />
    <LinkButton size="large" to="/" type="primary">
      Перейти на главную
    </LinkButton>
  </Page>
);

export default NotFound;
