import { graphql } from 'relay-runtime';

export default graphql`
  query NewProductQuery {
    allProductCategories {
      nodes {
        rowId
        name
      }
    }
  }
`;
