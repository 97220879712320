import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';
import SigninMutation from 'mutations/SigninMutation';
import './SigninForm.css';

class SigninForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      SigninMutation.commit(this.props.matchContext.environment, values).then(data => {
        if (data.signin.jwtToken) {
          this.props.changeToken(data.signin.jwtToken);
        } else {
          this.props.form.setFields({
            password: {
              errors: [new Error('Email или пароль неверные')],
            },
          });
        }
      });
    });
  };

  render() {
    const { form } = this.props;

    return (
      <div styleName="root">
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Email">
            {form.getFieldDecorator('email', {
              rules: [{ required: true, message: 'Введите email!' }],
            })(<Input placeholder="Email" />)}
          </Form.Item>
          <Form.Item label="Пароль">
            {form.getFieldDecorator('password', {
              rules: [{ required: true, message: 'Введите пароль!' }],
            })(<Input placeholder="Пароль" type="password" />)}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Войти
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

SigninForm.propTypes = {
  matchContext: PropTypes.object.isRequired,
  changeToken: PropTypes.func.isRequired,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
    setFields: PropTypes.func.isRequired,
    validateFields: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
  }).isRequired,
};

export default Form.create()(SigninForm);
