import { graphql } from 'relay-runtime';
import { makeCopyMutation } from './helpers';

const mutation = graphql`
  mutation CopyWarehouseMovementDocumentMutation($input: CopyWarehouseMovementDocumentInput!) {
    copyWarehouseMovementDocument(input: $input) {
      warehouseMovementDocument {
        rowId
      }
    }
  }
`;

export default makeCopyMutation(mutation, 'copyWarehouseMovementDocument', 'warehouseMovementDocument');
