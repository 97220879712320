/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type ShopOrderDocumentStatus = "CANCELED" | "COMPLETED" | "DELIVERED" | "PACKING" | "PENDING" | "SHIPPED" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type GroupsCouriersShow$ref: FragmentReference;
export type GroupsCouriersShow = $ReadOnlyArray<{|
  +deadlineAt: any,
  +orders: {|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +deliveryAt: ?any,
      +deliveryMaxDelay: {|
        +seconds: ?number,
        +minutes: ?number,
        +hours: ?number,
      |},
      +deliveredAt: ?any,
      +status: ShopOrderDocumentStatus,
      +label: ?string,
    |}>
  |},
  +$refType: GroupsCouriersShow$ref,
|}>;
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "GroupsCouriersShow",
  "type": "ShopOrderGroup",
  "metadata": {
    "plural": true
  },
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "deadlineAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "orders",
      "name": "shopOrderDocumentsByGroupId",
      "storageKey": "shopOrderDocumentsByGroupId(orderBy:\"GROUP_POSITION_ASC\")",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "GROUP_POSITION_ASC",
          "type": "[ShopOrderDocumentsOrderBy!]"
        }
      ],
      "concreteType": "ShopOrderDocumentsConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "nodes",
          "storageKey": null,
          "args": null,
          "concreteType": "ShopOrderDocument",
          "plural": true,
          "selections": [
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "rowId",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "deliveryAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": null,
              "name": "deliveryMaxDelay",
              "storageKey": null,
              "args": null,
              "concreteType": "Interval",
              "plural": false,
              "selections": [
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "seconds",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "minutes",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "hours",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "deliveredAt",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "status",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "label",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '170458aa4f40b172300b2555f210c3e2';
module.exports = node;
