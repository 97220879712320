import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateShopOrderDocumentMutation($input: UpdateShopOrderDocumentByRowIdInput!) {
    updateShopOrderDocumentByRowId(input: $input) {
      shopOrderDocument {
        rowId
        status
        groupId
        groupPosition

        group: shopOrderGroupByGroupId {
          id
          rowId
          deadlineAt
          courierId
          comment
        }
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateShopOrderDocument', 'shopOrderDocument', 'rowId');
