import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateProductMutation($input: CreateProductInput!) {
    createProduct(input: $input) {
      product {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createProduct', 'product');
