import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateSupplyProductionItemMutation($input: UpdateSupplyProductionItemInput!) {
    updateSupplyProductionItem(input: $input) {
      supplyProductionItem {
        rowId
        fixed
        quantity

        document: supplyDocumentByDocumentId {
          id

          supplyItemsByDocumentId {
            nodes {
              id
              quantity
            }
          }
        }
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateSupplyProductionItem', 'supplyProductionItem');
