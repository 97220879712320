// @flow
/* global $NonMaybeType, $ElementType */

import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import AttributeTable from 'components/AttributeTable';
import InlineInput from 'components/InlineInput';
import { Alert, Button } from 'antd';
import { getShowActions } from 'utils/crud';
import { chunk } from 'lodash';
import DataTable from 'components/DataTable';

import updateItemMutation from 'mutations/UpdateScaleProductMutation';
import destoyItemMutation from 'mutations/DestroyScaleProductMutation';
import SyncScaleMutation from 'mutations/SyncScaleMutation';

import type { PageProps } from 'types';
import type { ShowScaleQueryResponse } from './__generated__/ShowScaleQuery.graphql';
import NewItem from './NewItem';
import SyncAt from './SyncAt';
import './Show.css';

type Props = PageProps & {
  data: $NonMaybeType<$ElementType<ShowScaleQueryResponse, 'data'>>,
};

const attributeRows = [
  {
    dataKey: 'name',
    label: 'Название',
  },
  {
    dataKey: 'addr',
    label: 'Адрес',
  },
  {
    dataKey: 'syncedAt',
    label: 'Последняя синхронизация',
    render: (_, row) => <SyncAt data={row} />,
  },
  {
    dataKey: 'comment',
    label: 'Коментарий',
  },
];

const getFirstLine = row => {
  if (row.firstLine) {
    return row.firstLine;
  }

  const parts = row.product.name.split(' ');
  return parts[0] && parts[0].substr(0, 6);
};

const getLastLine = row => {
  if (row.lastLine) {
    return row.lastLine;
  }

  const parts = row.product.name.split(' ');
  const part = parts.find((p, i) => i > 0 && p.length > 3) || parts[1];

  return part && part.substr(0, 6);
};

const getActions = (props, items) => ({
  destroySelected: {
    type: 'confirmButton',
    componentType: 'danger',
    children: 'Удалить выбранные позиции',
    icon: 'delete',
    confirm: {
      title: 'Уверены, что хотите удалить это?',
      onConfirm: () =>
        Promise.all(props.selectedRowKeys.map(id => destoyItemMutation.commit(props.context.environment, id))).then(
          () => props.setSelectedRowKeys([])
        ),
    },
  },
  csv: {
    type: 'a',
    href: `/scale.csv?id=${props.params.rowId}`,
    icon: 'copy',
    children: 'CSV',
  },
  sync: {
    type: 'button',
    onClick: () => SyncScaleMutation.commit(props.context.environment, parseInt(props.params.rowId, 10)),
    children: 'Синхронизировать',
  },
  sort: {
    type: 'button',
    onClick: () => {
      const sortBy = (a, b) => {
        if (a.product.name > b.product.name) {
          return 1;
        }

        if (a.product.name < b.product.name) {
          return -1;
        }

        return 0;
      };

      const promises = items
        .slice()
        .sort(sortBy)
        .map((item, i) =>
          updateItemMutation.commit(props.context.environment, item.id, {
            position: i + 1,
          })
        );

      return Promise.all(promises);
    },
    children: 'Сортировать по алфативиту',
  },
  normalize: {
    type: 'button',
    onClick: () => {
      const promises = items.map((item, i) =>
        updateItemMutation.commit(props.context.environment, item.id, {
          position: i + 1,
        })
      );

      return Promise.all(promises);
    },
    children: 'Нормализовать',
  },
  ...getShowActions(props),
});

const Show = (props: Props) => {
  const items = props.data.items.nodes.filter(Boolean).sort((a, b) => a.position - b.position);
  const positions = items.map(i => i.position);
  // setTimeout(() => {
  //   props.router.push(set('query.regDate', Date.now(), props.location));
  // }, 2500);

  return (
    <div>
      <div styleName="sizes">
        <Button onClick={() => props.setMode(1)}>1</Button>
        <Button onClick={() => props.setMode(2)}>2</Button>
      </div>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Ubuntu+Mono:400,800&subset=latin,cyrillic" />
      <div styleName={`keyboard${props.mode}`}>
        {chunk(items.slice(0, 72), 8).map((row, i) => (
          <div styleName="row" key={i /* eslint-disable-line react/no-array-index-key */}>
            {row.map(item => (
              <div styleName="product" key={item.id}>
                <div>
                  <div styleName="type">{getFirstLine(item)}</div>
                  <div>{getLastLine(item)}</div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <Page
        title={`Весы ${props.data.name}`}
        actions={getActions(props, items)}
        styleName="page"
        backUrl={props.route.baseUrl}
      >
        <AttributeTable data={props.data} rows={attributeRows} />
        <NewItem context={props.context} additionalValues={{ scaleId: props.data.rowId }} />
        {positions.length !== [...new Set(positions)].length && (
          <Alert
            message="Некоторые позиции имеют дублирующийся порядковый номер. Требуется нормализация"
            type="error"
          />
        )}
        <h3 style={{ marginTop: 50 }}>Позиции</h3>
        <DataTable
          {...props}
          rowKey="id"
          onlyTable
          rows={{ nodes: items }}
          destroyMutation={destoyItemMutation}
          actions={['destroy']}
          tableProps={{
            rowSelection: {
              selectedRowKeys: props.selectedRowKeys,
              onChange: v => props.setSelectedRowKeys(v),
            },
          }}
          columns={{
            rowId: {
              hidden: true,
            },
            'product.name': {
              title: 'Позиция',
              sorter: false,
            },
            firstLine: {
              title: 'Первая линия',
              width: 200,
              render: (value, row) => (
                <InlineInput
                  saveAllValues
                  onUpdate={v => updateItemMutation.commitValue(props.context.environment, row.id, 'firstLine', v)}
                  value={value}
                />
              ),
            },
            lastLine: {
              title: 'Последняя линия',
              width: 200,
              render: (value, row) => (
                <InlineInput
                  saveAllValues
                  onUpdate={v => updateItemMutation.commitValue(props.context.environment, row.id, 'lastLine', v)}
                  value={value}
                />
              ),
            },
            position: {
              title: 'Порядок',
              width: 120,
              render: (value, row) => (
                <InlineInput
                  transfarmValue={v => v.replace(',', '.')}
                  onUpdate={v => updateItemMutation.commitValue(props.context.environment, row.id, 'position', v)}
                  value={value}
                />
              ),
            },
          }}
        />
      </Page>
    </div>
  );
};

Show.propTypes = {
  data: PropTypes.shape({
    rowId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }),
  }).isRequired,
  mode: PropTypes.number.isRequired,
  setMode: PropTypes.func.isRequired,
  selectedRowKeys: PropTypes.array.isRequired,
  setSelectedRowKeys: PropTypes.func.isRequired,
  context: PropTypes.shape({
    environment: PropTypes.object.isRequired,
  }).isRequired,
  route: PropTypes.shape({
    baseUrl: PropTypes.string.isRequired,
  }).isRequired,
};

export default Show;
