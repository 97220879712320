exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-Moves-___styles__receive___3lp-o td,\n.src-pages-Moves-___styles__shipped___348vk td {\n  padding: 3px 5px !important;\n}\n\n.src-pages-Moves-___styles__receive___3lp-o {\n  background: #4caf50;\n  color: #fff;\n}\n\n.src-pages-Moves-___styles__receive___3lp-o a {\n  color: #fff;\n}\n\n.src-pages-Moves-___styles__receive___3lp-o:hover td {\n  background: #4caf50 !important;\n}\n\n.src-pages-Moves-___styles__shipped___348vk {\n}\n", ""]);

// exports
exports.locals = {
	"receive": "src-pages-Moves-___styles__receive___3lp-o",
	"shipped": "src-pages-Moves-___styles__shipped___348vk"
};