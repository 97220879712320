import { graphql } from 'relay-runtime';

export default graphql`
  query EmployeesQuery($first: Int!, $offset: Int!, $orderBy: [EmployeesOrderBy!]) {
    rows: allEmployees(first: $first, offset: $offset, orderBy: $orderBy) {
      totalCount

      nodes {
        id
        rowId
        email
        firstName
        middleName
        lastName
        role
      }
    }
  }
`;
