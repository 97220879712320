import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button } from 'antd';
import ProductSelect from 'containers/ProductSelect';
import QuantityInput from 'components/QuantityInput';
import PageForm from 'components/PageForm';

const ProductAutoWriteOffForm = ({ title, form, handleSubmit, context, submitting }) => (
  <PageForm onSubmit={handleSubmit} title={title}>
    <Form.Item label="Источник">
      {form.getFieldDecorator('sourceId')(
        <ProductSelect environment={context.environment} showAction={['click', 'focus']} />
      )}
    </Form.Item>
    <Form.Item label="Что списать">
      {form.getFieldDecorator('targetId')(
        <ProductSelect environment={context.environment} showAction={['click', 'focus']} />
      )}
    </Form.Item>
    <Form.Item label="Количество">
      {form.getFieldDecorator('quantity')(<QuantityInput />, {
        rules: [{ required: true }],
      })}
    </Form.Item>
    <Form.Item>
      <Button disabled={submitting} type="primary" htmlType="submit">
        Сохранить
      </Button>
    </Form.Item>
  </PageForm>
);

ProductAutoWriteOffForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  context: PropTypes.any.isRequired,
};

export default ProductAutoWriteOffForm;
