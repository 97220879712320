/**
 * @flow
 * @relayHash 53792e58313af35dc9d37ff759f047a6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShopOrderItemReturnStatus = "REUSE" | "THROW" | "%future added value";
export type UpdateShopOrderItemInput = {
  clientMutationId?: ?string,
  id: string,
  shopOrderItemPatch: ShopOrderItemPatch,
};
export type ShopOrderItemPatch = {
  rowId?: ?number,
  documentId?: ?number,
  warehouseId?: ?number,
  productId?: ?number,
  orderedQuantity?: ?any,
  prepareQuantity?: ?any,
  quantity?: ?any,
  price?: ?any,
  priceMultiplier?: ?any,
  meta?: ?any,
  printed?: ?boolean,
  comment?: ?string,
  deficit?: ?boolean,
  weightMultiplier?: ?any,
  supplyProductionItemId?: ?number,
  returnStatus?: ?ShopOrderItemReturnStatus,
  supplyOrderedQuantity?: ?any,
  supplyDocumentId?: ?number,
  replacedByProductId?: ?number,
};
export type UpdateShopOrderItemMutationVariables = {|
  input: UpdateShopOrderItemInput
|};
export type UpdateShopOrderItemMutationResponse = {|
  +updateShopOrderItem: ?{|
    +shopOrderItem: ?{|
      +id: string,
      +rowId: number,
      +quantity: ?any,
      +orderedQuantity: any,
      +returnStatus: ?ShopOrderItemReturnStatus,
      +comment: ?string,
      +shopOrderDocumentByDocumentId: ?{|
        +id: string,
        +itemSum: any,
        +totalSum: ?any,
        +expectedTotalSum: ?any,
      |},
    |}
  |}
|};
export type UpdateShopOrderItemMutation = {|
  variables: UpdateShopOrderItemMutationVariables,
  response: UpdateShopOrderItemMutationResponse,
|};
*/


/*
mutation UpdateShopOrderItemMutation(
  $input: UpdateShopOrderItemInput!
) {
  updateShopOrderItem(input: $input) {
    shopOrderItem {
      id
      rowId
      quantity
      orderedQuantity
      returnStatus
      comment
      shopOrderDocumentByDocumentId {
        id
        itemSum
        totalSum
        expectedTotalSum
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateShopOrderItemInput!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateShopOrderItem",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateShopOrderItemInput!"
      }
    ],
    "concreteType": "UpdateShopOrderItemPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "shopOrderItem",
        "storageKey": null,
        "args": null,
        "concreteType": "ShopOrderItem",
        "plural": false,
        "selections": [
          v1,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rowId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "quantity",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "orderedQuantity",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "returnStatus",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "comment",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shopOrderDocumentByDocumentId",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderDocument",
            "plural": false,
            "selections": [
              v1,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "itemSum",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "totalSum",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "expectedTotalSum",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateShopOrderItemMutation",
  "id": null,
  "text": "mutation UpdateShopOrderItemMutation(\n  $input: UpdateShopOrderItemInput!\n) {\n  updateShopOrderItem(input: $input) {\n    shopOrderItem {\n      id\n      rowId\n      quantity\n      orderedQuantity\n      returnStatus\n      comment\n      shopOrderDocumentByDocumentId {\n        id\n        itemSum\n        totalSum\n        expectedTotalSum\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateShopOrderItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v2
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateShopOrderItemMutation",
    "argumentDefinitions": v0,
    "selections": v2
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8505c0864e5dcbc5caa63d35eb6ea490';
module.exports = node;
