import { graphql } from 'relay-runtime';

export default graphql`
  query EditBalanceDocumentQuery($rowId: Int!) {
    data: balanceDocumentByRowId(rowId: $rowId) {
      id
      rowId
    }
  }
`;
