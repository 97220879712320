/**
 * @flow
 * @relayHash 5fa2813a8af5bc4afe0bef32d5107300
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SupplyContractorStatus = "ARCHIVE" | "DANGEROUS" | "FRIENDS" | "UNSET" | "%future added value";
export type SupplyContractorsOrderBy = "DESCRIPTION_ASC" | "DESCRIPTION_DESC" | "ID_ASC" | "ID_DESC" | "NAME_ASC" | "NAME_DESC" | "NATURAL" | "OWNER_ID_ASC" | "OWNER_ID_DESC" | "PRIMARY_KEY_ASC" | "PRIMARY_KEY_DESC" | "STATUS_ASC" | "STATUS_DESC" | "SUPPLY_TOTAL_SUM_ASC" | "SUPPLY_TOTAL_SUM_DESC" | "%future added value";
export type SupplyContractorsQueryVariables = {|
  first: number,
  offset: number,
  orderBy?: ?$ReadOnlyArray<SupplyContractorsOrderBy>,
|};
export type SupplyContractorsQueryResponse = {|
  +rows: ?{|
    +totalCount: number,
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +name: string,
      +description: string,
      +status: SupplyContractorStatus,
      +supplyTotalSum: any,
      +owner: ?{|
        +firstName: string,
        +lastName: string,
      |},
    |}>,
  |}
|};
export type SupplyContractorsQuery = {|
  variables: SupplyContractorsQueryVariables,
  response: SupplyContractorsQueryResponse,
|};
*/


/*
query SupplyContractorsQuery(
  $first: Int!
  $offset: Int!
  $orderBy: [SupplyContractorsOrderBy!]
) {
  rows: allSupplyContractors(first: $first, offset: $offset, orderBy: $orderBy) {
    totalCount
    nodes {
      id
      rowId
      name
      description
      status
      supplyTotalSum
      owner: employeeByOwnerId {
        firstName
        lastName
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "offset",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "orderBy",
    "type": "[SupplyContractorsOrderBy!]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "offset",
    "variableName": "offset",
    "type": "Int"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy",
    "type": "[SupplyContractorsOrderBy!]"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalCount",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "description",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "supplyTotalSum",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstName",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastName",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SupplyContractorsQuery",
  "id": null,
  "text": "query SupplyContractorsQuery(\n  $first: Int!\n  $offset: Int!\n  $orderBy: [SupplyContractorsOrderBy!]\n) {\n  rows: allSupplyContractors(first: $first, offset: $offset, orderBy: $orderBy) {\n    totalCount\n    nodes {\n      id\n      rowId\n      name\n      description\n      status\n      supplyTotalSum\n      owner: employeeByOwnerId {\n        firstName\n        lastName\n        id\n      }\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SupplyContractorsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "rows",
        "name": "allSupplyContractors",
        "storageKey": null,
        "args": v1,
        "concreteType": "SupplyContractorsConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyContractor",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              {
                "kind": "LinkedField",
                "alias": "owner",
                "name": "employeeByOwnerId",
                "storageKey": null,
                "args": null,
                "concreteType": "Employee",
                "plural": false,
                "selections": [
                  v9,
                  v10
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SupplyContractorsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "rows",
        "name": "allSupplyContractors",
        "storageKey": null,
        "args": v1,
        "concreteType": "SupplyContractorsConnection",
        "plural": false,
        "selections": [
          v2,
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyContractor",
            "plural": true,
            "selections": [
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              {
                "kind": "LinkedField",
                "alias": "owner",
                "name": "employeeByOwnerId",
                "storageKey": null,
                "args": null,
                "concreteType": "Employee",
                "plural": false,
                "selections": [
                  v9,
                  v10,
                  v3
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '03656ba1e7878768ed7b87882e50a3bf';
module.exports = node;
