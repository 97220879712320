import React from 'react';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

class AutoReload extends React.Component {
  componentDidMount() {
    this.interval = setInterval(() => {
      this.props.refetch(omit(this.props, ['delay', 'refetch']));
    }, this.props.delay);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return null;
  }
}

AutoReload.propTypes = {
  refetch: PropTypes.func.isRequired,
  delay: PropTypes.number,
};

AutoReload.defaultProps = {
  delay: 5000,
};

export default AutoReload;
