import { graphql } from 'relay-runtime';

export default graphql`
  query EditCourierQuery($rowId: Int!) {
    data: courierByRowId(rowId: $rowId) {
      id
      rowId
      firstName
      lastName
      hideContactsFromClients
      privateCar
      telegramChatId
      middleName
      workPhone
      fired
      hasDriversLicense
      paymentTerminalId
      basePayoff
      extraPayoff
      mobileCashierId
    }
  }
`;
