/**
 * @flow
 * @relayHash 9964d360a255b14cc011dbb0a74bc79b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CopySupplyDocumentInput = {
  clientMutationId?: ?string,
  sourceDocumentId: number,
};
export type CopySupplyDocumentMutationVariables = {|
  input: CopySupplyDocumentInput
|};
export type CopySupplyDocumentMutationResponse = {|
  +copySupplyDocument: ?{|
    +supplyDocument: ?{|
      +rowId: number
    |}
  |}
|};
export type CopySupplyDocumentMutation = {|
  variables: CopySupplyDocumentMutationVariables,
  response: CopySupplyDocumentMutationResponse,
|};
*/


/*
mutation CopySupplyDocumentMutation(
  $input: CopySupplyDocumentInput!
) {
  copySupplyDocument(input: $input) {
    supplyDocument {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CopySupplyDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CopySupplyDocumentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CopySupplyDocumentMutation",
  "id": null,
  "text": "mutation CopySupplyDocumentMutation(\n  $input: CopySupplyDocumentInput!\n) {\n  copySupplyDocument(input: $input) {\n    supplyDocument {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CopySupplyDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "copySupplyDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "CopySupplyDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyDocument",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CopySupplyDocumentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "copySupplyDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "CopySupplyDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "supplyDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyDocument",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '49b86a4e24d2c8aa8697d5722c54bcf3';
module.exports = node;
