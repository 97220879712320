/**
 * @flow
 * @relayHash fb7983451d7d1390123f48238e0f4030
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditEmployeeQueryVariables = {|
  rowId: number
|};
export type EditEmployeeQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +firstName: string,
    +middleName: ?string,
    +lastName: string,
    +email: string,
    +role: string,
  |}
|};
export type EditEmployeeQuery = {|
  variables: EditEmployeeQueryVariables,
  response: EditEmployeeQueryResponse,
|};
*/


/*
query EditEmployeeQuery(
  $rowId: Int!
) {
  data: employeeByRowId(rowId: $rowId) {
    id
    rowId
    firstName
    middleName
    lastName
    email
    role
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": "data",
    "name": "employeeByRowId",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "rowId",
        "variableName": "rowId",
        "type": "Int!"
      }
    ],
    "concreteType": "Employee",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "id",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "rowId",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "firstName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "middleName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "lastName",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "email",
        "args": null,
        "storageKey": null
      },
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "role",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditEmployeeQuery",
  "id": null,
  "text": "query EditEmployeeQuery(\n  $rowId: Int!\n) {\n  data: employeeByRowId(rowId: $rowId) {\n    id\n    rowId\n    firstName\n    middleName\n    lastName\n    email\n    role\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditEmployeeQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "EditEmployeeQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8807902ace1724f124bc656f71dd444e';
module.exports = node;
