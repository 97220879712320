import { graphql } from 'relay-runtime';

export default graphql`
  query EditScaleQuery($rowId: Int!) {
    data: scaleByRowId(rowId: $rowId) {
      id
      rowId
      addr
      name
      comment
    }
  }
`;
