import { graphql } from 'relay-runtime';

export default graphql`
  query ProductWarehouseQuantityQuery($productId: Int!, $warehouseId: Int!) {
    quantity: productWarehouseQuantityByProductIdAndWarehouseId(productId: $productId, warehouseId: $warehouseId) {
      productId
      warehouseId
      value
      lastSupplyPrice
    }
  }
`;
