/**
 * @flow
 * @relayHash 3421672ce1e617b770a5ed42c6aff199
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductExternalStatus = "ALWAYS_AVAILABLE" | "AVAILABLE" | "BY_REQUEST" | "FROM_PARENT" | "HIDDEN" | "%future added value";
export type ProductStatus = "ACTIVE" | "ARCHIVE" | "DEPRECATED" | "%future added value";
export type ProductUnit = "KG" | "PCS" | "%future added value";
export type EditProductQueryVariables = {|
  rowId: number
|};
export type EditProductQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +name: string,
    +parentId: ?number,
    +categoryId: ?number,
    +unit: ProductUnit,
    +status: ProductStatus,
    +barcodes: $ReadOnlyArray<?string>,
    +packingTolerance: any,
    +pcsWeight: ?any,
    +requiresPackaging: boolean,
    +ownPackaging: boolean,
    +ownProduction: boolean,
    +externalStatus: ProductExternalStatus,
    +availableQuantityThreshold: ?any,
    +vat: ?number,
    +parent: ?{|
      +rowId: number,
      +name: string,
    |},
    +shelfLife: ?{|
      +hours: ?number,
      +days: ?number,
      +months: ?number,
      +years: ?number,
    |},
  |},
  +allProductCategories: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +name: string,
    |}>
  |},
|};
export type EditProductQuery = {|
  variables: EditProductQueryVariables,
  response: EditProductQueryResponse,
|};
*/


/*
query EditProductQuery(
  $rowId: Int!
) {
  data: productByRowId(rowId: $rowId) {
    id
    rowId
    name
    parentId
    categoryId
    unit
    status
    barcodes
    packingTolerance
    pcsWeight
    requiresPackaging
    ownPackaging
    ownProduction
    externalStatus
    availableQuantityThreshold
    vat
    parent: productByParentId {
      rowId
      name
      id
    }
    shelfLife {
      hours
      days
      months
      years
    }
  }
  allProductCategories {
    nodes {
      rowId
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "parentId",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "categoryId",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "unit",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "status",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "barcodes",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "packingTolerance",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "pcsWeight",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "requiresPackaging",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ownPackaging",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ownProduction",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "externalStatus",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "availableQuantityThreshold",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "vat",
  "args": null,
  "storageKey": null
},
v18 = [
  v3,
  v4
],
v19 = {
  "kind": "LinkedField",
  "alias": null,
  "name": "shelfLife",
  "storageKey": null,
  "args": null,
  "concreteType": "Interval",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "hours",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "days",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "months",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "years",
      "args": null,
      "storageKey": null
    }
  ]
},
v20 = [
  v3,
  v4,
  v2
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditProductQuery",
  "id": null,
  "text": "query EditProductQuery(\n  $rowId: Int!\n) {\n  data: productByRowId(rowId: $rowId) {\n    id\n    rowId\n    name\n    parentId\n    categoryId\n    unit\n    status\n    barcodes\n    packingTolerance\n    pcsWeight\n    requiresPackaging\n    ownPackaging\n    ownProduction\n    externalStatus\n    availableQuantityThreshold\n    vat\n    parent: productByParentId {\n      rowId\n      name\n      id\n    }\n    shelfLife {\n      hours\n      days\n      months\n      years\n    }\n  }\n  allProductCategories {\n    nodes {\n      rowId\n      name\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditProductQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "productByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "Product",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          v7,
          v8,
          v9,
          v10,
          v11,
          v12,
          v13,
          v14,
          v15,
          v16,
          v17,
          {
            "kind": "LinkedField",
            "alias": "parent",
            "name": "productByParentId",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": false,
            "selections": v18
          },
          v19
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allProductCategories",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductCategoriesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductCategory",
            "plural": true,
            "selections": v18
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditProductQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "productByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "Product",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          v7,
          v8,
          v9,
          v10,
          v11,
          v12,
          v13,
          v14,
          v15,
          v16,
          v17,
          {
            "kind": "LinkedField",
            "alias": "parent",
            "name": "productByParentId",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": false,
            "selections": v20
          },
          v19
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allProductCategories",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductCategoriesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductCategory",
            "plural": true,
            "selections": v20
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc9e6b76d45dad9ac5c57e574d7feb6c';
module.exports = node;
