import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateBalanceItemMutation($input: UpdateBalanceItemInput!) {
    updateBalanceItem(input: $input) {
      balanceItem {
        rowId
        warehouseId
        productId
        quantity
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateBalanceItem', 'balanceItem');
