import { Form } from 'antd';
import { compose, withProps, withState } from 'recompact';
import { showErrors } from 'utils/notifications';
import mapObject from 'fbjs/lib/mapObject';

const defaultOnCompleted = ({ rowId }, { router, route }) => {
  router.push(`${route.baseUrl}/${rowId}`);
};

export default ({ mutation, normalize, onCompleted = defaultOnCompleted, idKey = 'id' }) =>
  compose(
    Form.create({
      mapPropsToFields: (props) => mapObject(props.data, (value) => Form.createFormField({ value })),
    }),
    withState('submitting', 'setSubmitting', false),
    withProps((props) => ({
      handleSubmit: (e) => {
        e.preventDefault();
        props.setSubmitting(true);
        props.form.validateFieldsAndScroll(async (err, values) => {
          if (err) {
            props.setSubmitting(false);
            return;
          }

          try {
            const res = await mutation.commit(
              props.context.environment,
              props.data[idKey],
              normalize ? normalize(values) : values
            );

            if (onCompleted) {
              onCompleted(res, props);
            }
          } catch (requestError) {
            showErrors(requestError);
            props.setSubmitting(false);
          }
        });
      },
    }))
  );
