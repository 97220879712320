/* global ymaps */

import React from 'react';
import PropTypes from 'prop-types';
import { Clusterer as OriginalClusterer } from 'react-yandex-maps';
import { createPortal } from 'react-dom';

class Clusterer extends React.Component {
  static contextTypes = {
    ymaps: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const self = this;
    this.state = { portal: null };

    this.itemContentLayout = ymaps.templateLayoutFactory.createClass('<div></div>', {
      build() {
        this.constructor.superclass.build.call(this);
        const tree = this.getData().properties.get('reactBalloonContent');
        // eslint-disable-next-line no-underscore-dangle
        const el = this._element.querySelector('div');

        self.setState({
          portal: createPortal(tree, el),
        });
      },
    });
  }

  render() {
    const options = {
      ...this.props.options,
      clusterBalloonPanelMaxMapArea: 0,
      clusterBalloonContentLayoutWidth: 350,
      clusterBalloonLeftColumnWidth: 120,
      clusterBalloonItemContentLayout: this.itemContentLayout,
      clusterBalloonContentLayout: 'cluster#balloonAccordion',
    };

    return [<OriginalClusterer {...this.props} options={options} key="cluster" />, this.state.portal];
  }
}

Clusterer.propTypes = {
  options: PropTypes.object.isRequired,
};

export default Clusterer;
