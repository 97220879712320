import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateScaleProductMutation($input: UpdateScaleProductInput!) {
    updateScaleProduct(input: $input) {
      scaleProduct {
        id
        rowId
        firstLine
        lastLine
        position
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateScaleProduct', 'scaleProduct');
