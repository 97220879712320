// @flow

import { compose, withProps } from 'recompact';
import moment from 'moment';
import { renderPrice, getRelationLink } from 'components/helpers';
import destroyMutation from 'mutations/DestroySupplyDocumentMutation';
import Filters from './Filters';

export default compose(
  withProps({
    title: 'Поставки',
    destroyMutation,
    Header: Filters,
    columns: {
      contractorId: {
        title: 'Поставщик',
        render: (id, row) => getRelationLink({ path: '/supply/contractors' })(row.contractor),
      },
      comment: {
        title: 'Коментарий',
        width: '250px',
      },
      totalSum: {
        title: 'Сумма',
        width: '150px',
        render: renderPrice,
        align: 'right',
      },
      eventedAt: {
        title: 'Принято',
        render: row => moment(row).format('DD MMM YYYY HH[:]mm'),
        width: '150px',
        align: 'right',
      },
    },
    pageActions: {
      contractors: {
        type: 'link',
        to: '/supply/contractors',
        icon: 'team',
        children: 'Поставщики',
      },
    },
  })
);
