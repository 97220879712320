// @flow

import { compose, withProps, withState } from 'recompact';
import { renderPercentDiscount, renderEnum, renderPrice } from 'components/helpers';
import moment from 'moment';
import Filters from './Filters';
import paymentMethods from './paymentMethods';
import renderStatus from './renderStatus';

export default compose(
  withProps({
    title: 'Заказы с витрин',
    actions: ['show'],
    Header: Filters,
    columns: {
      label: {
        title: 'Мет',
        width: 70,
      },
      owner: {
        title: 'Владелец',
        width: 120,
      },
      status: {
        title: 'Статус',
        width: 120,
        render: renderStatus,
      },
      deliveryAt: {
        title: 'Дата',
        render: v =>
          moment(v)
            .tz('Europe/Moscow')
            .format('DD MMM YYYY HH:mm'),
      },
      deadlineAt: {
        title: 'Дедлайн',
        render: v =>
          moment(v)
            .tz('Europe/Moscow')
            .format('HH:mm'),
        align: 'right',
      },
      paymentMethod: {
        title: 'Метод оплаты',
        render: renderEnum(paymentMethods),
      },
      itemSum: {
        title: 'Сумма позиций',
        render: renderPrice,
        align: 'right',
      },
      discount: {
        title: 'Скидка',
        render: renderPrice,
        align: 'right',
      },
      totalSumMultiplier: {
        title: '% скидка',
        render: renderPercentDiscount,
        align: 'right',
      },
      deliveryCost: {
        title: 'Доставка',
        render: renderPrice,
        align: 'right',
      },
      totalSum: {
        title: 'Итого',
        render: renderPrice,
        align: 'right',
      },
      totalWeight: {
        title: 'Вес',
        render: v => (v ? `${parseFloat(v).toFixed(2)} кг` : '–'),
        sorter: false,
        align: 'right',
      },
    },
  }),
  withState('selectedRowKeys', 'setSelectedRowKeys', []),
  withProps(props => ({
    tableProps: {
      rowKey: 'rowId',
      rowSelection: {
        selectedRowKeys: props.selectedRowKeys,
        onChange: v => props.setSelectedRowKeys(v),
      },
    },
    pageActions: {
      print: props.selectedRowKeys.length > 0 && {
        type: 'link',
        to: {
          pathname: '/shop/order/documents/print',
          query: { ids: props.selectedRowKeys },
        },
        children: 'Печать',
        icon: 'file',
      },
      depositReplenishments: {
        type: 'link',
        to: '/shop/deposit/replenishments',
        children: 'Депозиты',
        icon: 'dollar',
      },
      couriers: {
        type: 'link',
        to: '/couriers',
        children: 'Курьеры',
        icon: 'user',
      },
      courierMap: {
        type: 'link',
        to: '/shop/order/documents/courierMap',
        children: 'Курьеры на карте',
        icon: 'global',
      },
      map: {
        type: 'link',
        to: '/shop/order/documents/map',
        children: 'Карта',
        icon: 'global',
      },
      create: null,
    },
  }))
);
