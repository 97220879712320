import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroyWriteOffItemMutation($input: DeleteWriteOffItemInput!) {
    deleteWriteOffItem(input: $input) {
      deletedWriteOffItemId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteWriteOffItem', 'deletedWriteOffItemId');
