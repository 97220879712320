import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Input } from 'antd';

class InlineInput extends React.Component {
  state = {
    value: this.props.value,
    hasError: false,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isEqual(nextProps.value, prevState.previousValue)) {
      return null;
    }

    return {
      value: nextProps.value,
      previousValue: nextProps.value,
    };
  }

  handleChange = e => {
    const value = this.props.transfarmValue(e.target.value);

    if (value.match(/\.$/)) {
      this.setState({ value, hasError: true });
      return;
    }

    this.setState({ value, hasError: false });

    if ((value !== '' && value !== '-') || this.props.saveAllValues) {
      this.props.onUpdate(value);
    }
  };

  focus = e => {
    if (this.props.autoSelect) {
      e.target.select();
    }
  };

  render() {
    const saved = !this.state.hasError && this.props.isEqual(this.props.value, this.state.value);

    return (
      <div className={this.state.hasError ? 'has-error' : ''}>
        <Input
          onChange={this.handleChange}
          value={this.state.value}
          suffix={saved && <Icon type="check" />}
          tabIndex={this.props.tabIndex}
          onFocus={this.focus}
          style={this.props.style}
        />
      </div>
    );
  }
}

InlineInput.propTypes = {
  transfarmValue: PropTypes.func,
  value: PropTypes.any,
  onUpdate: PropTypes.func.isRequired,
  tabIndex: PropTypes.any,
  autoSelect: PropTypes.bool,
  isEqual: PropTypes.func,
  style: PropTypes.object,
  saveAllValues: PropTypes.bool,
};

InlineInput.defaultProps = {
  saveAllValues: false,
  transfarmValue: v => v,
  tabIndex: undefined,
  style: undefined,
  autoSelect: false,
  isEqual: (a, b) => a === b,
  value: '',
};

export default InlineInput;
