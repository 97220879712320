import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateEmployeeMutation($input: UpdateEmployeeInput!) {
    updateEmployee(input: $input) {
      employee {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateEmployee', 'employee');
