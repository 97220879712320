import React from 'react';
import PropTypes from 'prop-types';
import { graphql, createFragmentContainer } from 'react-relay';
import InlineInput from 'components/InlineInput';
import updateItemMutation from 'mutations/UpdateShopOrderItemMutation';

const ItemCommentInput = ({ data, relay }) => (
  <InlineInput
    onUpdate={v => updateItemMutation.commitValue(relay.environment, data.id, 'comment', v)}
    value={data.comment}
  />
);

ItemCommentInput.propTypes = {
  data: PropTypes.object.isRequired,
  relay: PropTypes.object.isRequired,
};

export default createFragmentContainer(
  ItemCommentInput,
  graphql`
    fragment ItemCommentInput on ShopOrderItem {
      id
      comment
    }
  `
);
