import { graphql } from 'relay-runtime';

export default graphql`
  query PrintProductCategoriesQuery($categoriesFilter: ProductCategoryFilter!, $productsFilter: ProductFilter!) {
    allProductCategories(orderBy: [POSITION_ASC], filter: $categoriesFilter) {
      nodes {
        rowId
        name
        position
      }
    }

    allProducts(orderBy: [NAME_ASC], filter: $productsFilter) {
      nodes {
        id
        name
        categoryId
        unit

        quantities: productWarehouseQuantitiesByProductId(condition: { warehouseId: 2 }) {
          nodes {
            warehouseId
            value
          }
        }
      }
    }
  }
`;
