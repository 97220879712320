// @flow

import { compose, withProps } from 'recompact';
import destroyMutation from 'mutations/DestroyWarehouseMutation';

export default compose(
  withProps({
    title: 'Склады',
    destroyMutation,
    columns: {
      name: {
        title: 'Название',
      },
    },
  })
);
