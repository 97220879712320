/**
 * @flow
 * @relayHash 594a6315653f3dd93fc170988258daa0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DeficitPageShopOrderDocument_rows$ref = any;
export type DeficitShopOrderDocumentQueryVariables = {|
  day: any
|};
export type DeficitShopOrderDocumentQueryResponse = {|
  +rows: ?{|
    +$fragmentRefs: DeficitPageShopOrderDocument_rows$ref
  |}
|};
export type DeficitShopOrderDocumentQuery = {|
  variables: DeficitShopOrderDocumentQueryVariables,
  response: DeficitShopOrderDocumentQueryResponse,
|};
*/


/*
query DeficitShopOrderDocumentQuery(
  $day: Date!
) {
  rows: allDeficitShopOrderItems(day: $day) {
    ...DeficitPageShopOrderDocument_rows
  }
}

fragment DeficitPageShopOrderDocument_rows on ShopOrderItemsConnection {
  nodes {
    id
    orderedQuantity
    order: shopOrderDocumentByDocumentId {
      rowId
      deliveryAt
      owner
      label
      sourceUrl
      id
    }
    product: productByProductId {
      name
      unit
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "day",
    "type": "Date!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "day",
    "variableName": "day",
    "type": "Date!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DeficitShopOrderDocumentQuery",
  "id": null,
  "text": "query DeficitShopOrderDocumentQuery(\n  $day: Date!\n) {\n  rows: allDeficitShopOrderItems(day: $day) {\n    ...DeficitPageShopOrderDocument_rows\n  }\n}\n\nfragment DeficitPageShopOrderDocument_rows on ShopOrderItemsConnection {\n  nodes {\n    id\n    orderedQuantity\n    order: shopOrderDocumentByDocumentId {\n      rowId\n      deliveryAt\n      owner\n      label\n      sourceUrl\n      id\n    }\n    product: productByProductId {\n      name\n      unit\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DeficitShopOrderDocumentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "rows",
        "name": "allDeficitShopOrderItems",
        "storageKey": null,
        "args": v1,
        "concreteType": "ShopOrderItemsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "DeficitPageShopOrderDocument_rows",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DeficitShopOrderDocumentQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "rows",
        "name": "allDeficitShopOrderItems",
        "storageKey": null,
        "args": v1,
        "concreteType": "ShopOrderItemsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderItem",
            "plural": true,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "orderedQuantity",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": "order",
                "name": "shopOrderDocumentByDocumentId",
                "storageKey": null,
                "args": null,
                "concreteType": "ShopOrderDocument",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "rowId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "deliveryAt",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "owner",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "label",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "sourceUrl",
                    "args": null,
                    "storageKey": null
                  },
                  v2
                ]
              },
              {
                "kind": "LinkedField",
                "alias": "product",
                "name": "productByProductId",
                "storageKey": null,
                "args": null,
                "concreteType": "Product",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "name",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "unit",
                    "args": null,
                    "storageKey": null
                  },
                  v2
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6b7186ed2463bfb621fcaf9e9de01007';
module.exports = node;
