import { graphql } from 'relay-runtime';

export default graphql`
  query ShowWarehouseQuery($rowId: Int!) {
    data: warehouseByRowId(rowId: $rowId) {
      id
      rowId
      name

      quantities: productWarehouseQuantitiesByWarehouseId {
        nodes {
          value

          product: productByProductId {
            categoryId
            rowId
            name
          }
        }
      }
    }
  }
`;
