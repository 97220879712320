/**
 * @flow
 * @relayHash 737b2709be942f6767a0762e3e99c868
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteCourierInput = {
  clientMutationId?: ?string,
  id: string,
};
export type DestroyCourierMutationVariables = {|
  input: DeleteCourierInput
|};
export type DestroyCourierMutationResponse = {|
  +deleteCourier: ?{|
    +deletedCourierId: ?string
  |}
|};
export type DestroyCourierMutation = {|
  variables: DestroyCourierMutationVariables,
  response: DestroyCourierMutationResponse,
|};
*/


/*
mutation DestroyCourierMutation(
  $input: DeleteCourierInput!
) {
  deleteCourier(input: $input) {
    deletedCourierId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteCourierInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteCourier",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteCourierInput!"
      }
    ],
    "concreteType": "DeleteCourierPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedCourierId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DestroyCourierMutation",
  "id": null,
  "text": "mutation DestroyCourierMutation(\n  $input: DeleteCourierInput!\n) {\n  deleteCourier(input: $input) {\n    deletedCourierId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DestroyCourierMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DestroyCourierMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b712c0097e08eb22c87a38003a75bc9c';
module.exports = node;
