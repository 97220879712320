/**
 * @flow
 * @relayHash 0d0cabec7a7a8662fd1bd96338943fde
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ShowYandexOrderDocument$ref = any;
export type ShowYandexOrderQueryVariables = {|
  rowId: number
|};
export type ShowYandexOrderQueryResponse = {|
  +allYandexNomenclatures: ?{|
    +nodes: $ReadOnlyArray<{|
      +shop: string,
      +price: any,
      +grammar: any,
      +productId: number,
      +product: ?{|
        +name: string
      |},
    |}>
  |},
  +data: ?{|
    +rowId: number,
    +owner: ?string,
    +totalSum: ?any,
    +expectedTotalSum: ?any,
    +yandexOrdersByDocumentId: {|
      +nodes: $ReadOnlyArray<{|
        +externalId: string,
        +phone: string,
        +clientName: string,
        +address: string,
        +comment: string,
        +initialTotalSum: any,
      |}>
    |},
    +$fragmentRefs: ShowYandexOrderDocument$ref,
  |},
|};
export type ShowYandexOrderQuery = {|
  variables: ShowYandexOrderQueryVariables,
  response: ShowYandexOrderQueryResponse,
|};
*/


/*
query ShowYandexOrderQuery(
  $rowId: Int!
) {
  allYandexNomenclatures {
    nodes {
      shop
      price
      grammar
      productId
      product: productByProductId {
        name
        id
      }
      id
    }
  }
  data: shopOrderDocumentByRowId(rowId: $rowId) {
    rowId
    owner
    totalSum
    expectedTotalSum
    ...ShowYandexOrderDocument
    yandexOrdersByDocumentId {
      nodes {
        externalId
        phone
        clientName
        address
        comment
        initialTotalSum
        id
      }
    }
    id
  }
}

fragment ShowYandexOrderDocument on ShopOrderDocument {
  rowId
  owner
  mainWarehouseId
  items: shopOrderItemsByDocumentId(orderBy: [DEFICIT_DESC]) {
    nodes {
      rowId
      id
      printed
      deficit
      product: productByProductId {
        rowId
        name
        unit
        id
      }
      orderedQuantity
      quantity
      price
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "shop",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "price",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "grammar",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "productId",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId",
    "type": "Int!"
  }
],
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "owner",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "totalSum",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "expectedTotalSum",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "externalId",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "phone",
  "args": null,
  "storageKey": null
},
v13 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "clientName",
  "args": null,
  "storageKey": null
},
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "address",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "comment",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "initialTotalSum",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ShowYandexOrderQuery",
  "id": null,
  "text": "query ShowYandexOrderQuery(\n  $rowId: Int!\n) {\n  allYandexNomenclatures {\n    nodes {\n      shop\n      price\n      grammar\n      productId\n      product: productByProductId {\n        name\n        id\n      }\n      id\n    }\n  }\n  data: shopOrderDocumentByRowId(rowId: $rowId) {\n    rowId\n    owner\n    totalSum\n    expectedTotalSum\n    ...ShowYandexOrderDocument\n    yandexOrdersByDocumentId {\n      nodes {\n        externalId\n        phone\n        clientName\n        address\n        comment\n        initialTotalSum\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment ShowYandexOrderDocument on ShopOrderDocument {\n  rowId\n  owner\n  mainWarehouseId\n  items: shopOrderItemsByDocumentId(orderBy: [DEFICIT_DESC]) {\n    nodes {\n      rowId\n      id\n      printed\n      deficit\n      product: productByProductId {\n        rowId\n        name\n        unit\n        id\n      }\n      orderedQuantity\n      quantity\n      price\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ShowYandexOrderQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allYandexNomenclatures",
        "storageKey": null,
        "args": null,
        "concreteType": "YandexNomenclaturesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "YandexNomenclature",
            "plural": true,
            "selections": [
              v1,
              v2,
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": "product",
                "name": "productByProductId",
                "storageKey": null,
                "args": null,
                "concreteType": "Product",
                "plural": false,
                "selections": [
                  v5
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "shopOrderDocumentByRowId",
        "storageKey": null,
        "args": v6,
        "concreteType": "ShopOrderDocument",
        "plural": false,
        "selections": [
          v7,
          v8,
          v9,
          v10,
          {
            "kind": "FragmentSpread",
            "name": "ShowYandexOrderDocument",
            "args": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "yandexOrdersByDocumentId",
            "storageKey": null,
            "args": null,
            "concreteType": "YandexOrdersConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "YandexOrder",
                "plural": true,
                "selections": [
                  v11,
                  v12,
                  v13,
                  v14,
                  v15,
                  v16
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ShowYandexOrderQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allYandexNomenclatures",
        "storageKey": null,
        "args": null,
        "concreteType": "YandexNomenclaturesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "YandexNomenclature",
            "plural": true,
            "selections": [
              v1,
              v2,
              v3,
              v4,
              {
                "kind": "LinkedField",
                "alias": "product",
                "name": "productByProductId",
                "storageKey": null,
                "args": null,
                "concreteType": "Product",
                "plural": false,
                "selections": [
                  v5,
                  v17
                ]
              },
              v17
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "shopOrderDocumentByRowId",
        "storageKey": null,
        "args": v6,
        "concreteType": "ShopOrderDocument",
        "plural": false,
        "selections": [
          v7,
          v8,
          v9,
          v10,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "mainWarehouseId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": "items",
            "name": "shopOrderItemsByDocumentId",
            "storageKey": "shopOrderItemsByDocumentId(orderBy:[\"DEFICIT_DESC\"])",
            "args": [
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": [
                  "DEFICIT_DESC"
                ],
                "type": "[ShopOrderItemsOrderBy!]"
              }
            ],
            "concreteType": "ShopOrderItemsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "ShopOrderItem",
                "plural": true,
                "selections": [
                  v7,
                  v17,
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "printed",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "deficit",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": "product",
                    "name": "productByProductId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      v7,
                      v5,
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "unit",
                        "args": null,
                        "storageKey": null
                      },
                      v17
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "orderedQuantity",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "quantity",
                    "args": null,
                    "storageKey": null
                  },
                  v2
                ]
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "yandexOrdersByDocumentId",
            "storageKey": null,
            "args": null,
            "concreteType": "YandexOrdersConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "YandexOrder",
                "plural": true,
                "selections": [
                  v11,
                  v12,
                  v13,
                  v14,
                  v15,
                  v16,
                  v17
                ]
              }
            ]
          },
          v17
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0619d5342e207556cddfa56864a95f84';
module.exports = node;
