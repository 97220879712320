import { getRoutes } from 'utils/crud';

import Index from './index';
import New from './New';
import Edit from './Edit';
import Show from './Show';

import IndexQuery from './WriteOffCategoriesQuery';
import EditQuery from './Edit/EditWriteOffCategoryQuery';
import ShowQuery from './Show/ShowWriteOffCategoryQuery';

export default getRoutes({
  baseUrl: '/write-off/categories',
  Index,
  IndexQuery,
  Edit,
  EditQuery,
  Show,
  ShowQuery,
  New,
});
