import { connect } from 'react-redux';
import { changeToken } from 'redux/actions';
import SigninForm from './SigninForm';

const selector = state => ({
  token: state.token,
});

const identity = x => x;
const enhance = connect(
  selector,
  { changeToken }
);

export default enhance;
