exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-Warehouses-Show-___Show__map___rihOj {\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 30px;\n  max-width: 1600px;\n}\n\n.src-pages-Warehouses-Show-___Show__warning-dot___3d8RR,\n.src-pages-Warehouses-Show-___Show__alert-dot___1JW77,\n.src-pages-Warehouses-Show-___Show__dot___2TiPV {\n  width: 20px;\n  height: 20px;\n  background: #04de24;\n  margin: 5px;\n  border-radius: 50%;\n}\n\n.src-pages-Warehouses-Show-___Show__warning-dot___3d8RR {\n  background: #bbb;\n}\n\n.src-pages-Warehouses-Show-___Show__alert-dot___1JW77 {\n  background: red;\n}\n", ""]);

// exports
exports.locals = {
	"map": "src-pages-Warehouses-Show-___Show__map___rihOj",
	"warning-dot": "src-pages-Warehouses-Show-___Show__warning-dot___3d8RR",
	"alert-dot": "src-pages-Warehouses-Show-___Show__alert-dot___1JW77",
	"dot": "src-pages-Warehouses-Show-___Show__dot___2TiPV"
};