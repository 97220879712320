import { graphql } from 'relay-runtime';

export default graphql`
  query EditSupplyContractorQuery($rowId: Int!) {
    data: supplyContractorByRowId(rowId: $rowId) {
      id
      name
      description
      status
      ownerId
    }

    allEmployees {
      nodes {
        ...EmployeeSelect_items
      }
    }
  }
`;
