import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateShopDepositReplenishmentMutation($input: UpdateShopDepositReplenishmentInput!) {
    updateShopDepositReplenishment(input: $input) {
      shopDepositReplenishment {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateShopDepositReplenishment', 'shopDepositReplenishment');
