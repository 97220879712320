import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, DatePicker, Select, Input } from 'antd';
import { compose, omitProps, withProps } from 'recompact';
import moment from 'moment';
import CollectionSelect from './CollectionSelect';

const identity = x => x;

const clear = obj => {
  const res = {};

  Object.keys(obj).forEach(key => {
    if (obj[key] !== '') {
      res[key] = obj[key];
    }
  });

  return res;
};

const makeWrapper = extractValue =>
  compose(
    withProps(props => ({
      [props.valuePropName || 'value']:
        props[props.valuePropName || 'value'] && props[props.valuePropName || 'value'][props.filterType],
      onChange: value =>
        props.onChange(
          clear({
            ...props.value,
            [props.filterType]: extractValue(value),
          })
        ),
    })),
    omitProps('filterType')
  );

const dateEnhance = compose(
  withProps(props => ({
    value: props.value && props.value[props.filterType] && moment(props.value[props.filterType]),
    style: { width: '100%' },
    onChange: v =>
      props.onChange({
        ...props.value,
        [props.filterType]: v.format('YYYY-MM-DD'),
      }),
  })),
  omitProps('filterType')
);

export const FilterInput = makeWrapper(e => e.target.value)(Input);
export const FilterCheckbox = makeWrapper(e => e.target.checked)(Checkbox);
export const FilterSelect = makeWrapper(identity)(Select);
export const FilterDatePicker = dateEnhance(DatePicker);

export const FilterCollectionSelect = ({ items, ...props }) => {
  const value = props.value && (props.value.isNull ? -1 : props.value.equalTo);
  const onChange = v => {
    if (v === -1) {
      props.onChange({ isNull: true });
    } else if (v === 0) {
      props.onChange({});
    } else {
      props.onChange({ equalTo: v });
    }
  };

  const allItem = value && {
    rowId: 0,
    name: 'Все',
  };

  const withoutItem = {
    rowId: -1,
    name: 'Нет',
  };

  return (
    <CollectionSelect
      showSearch
      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      {...props}
      items={[withoutItem, allItem, ...items].filter(Boolean)}
      onChange={onChange}
      value={value}
    />
  );
};

FilterCollectionSelect.propTypes = {
  items: PropTypes.array.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

FilterCollectionSelect.defaultProps = {
  value: {},
  onChange: () => { },
};
