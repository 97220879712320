import { graphql } from 'react-relay';

export default graphql`
  query NewShopDepositReplenishmentQuery {
    allEmployees {
      nodes {
        id
        rowId
        firstName
        lastName
      }
    }
  }
`;
