import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';
import CollectionSelect from 'components/CollectionSelect';
import PageForm from 'components/PageForm';

const ProductGroupForm = ({ title, form, handleSubmit, allWarehouses, defaultWarehouseId, submitting }) => (
  <PageForm onSubmit={handleSubmit} title={title}>
    <Form.Item label="Название">
      {form.getFieldDecorator('name', {
        rules: [
          {
            required: true,
            message: 'Введите название',
          },
        ],
      })(<Input autoFocus />)}
    </Form.Item>
    <Form.Item label="Склад">
      {form.getFieldDecorator('warehouseId', { initialValue: defaultWarehouseId })(
        <CollectionSelect items={allWarehouses.nodes} />
      )}
    </Form.Item>
    <Form.Item>
      <Button disabled={submitting} type="primary" htmlType="submit">
        Сохранить
      </Button>
    </Form.Item>
  </PageForm>
);

ProductGroupForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  allWarehouses: PropTypes.object.isRequired,
  defaultWarehouseId: PropTypes.number,
  submitting: PropTypes.bool.isRequired,
};

ProductGroupForm.defaultProps = {
  defaultWarehouseId: null,
};

export default ProductGroupForm;
