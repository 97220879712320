import { createConnectedRouter, createRender } from 'found';
import { hot } from 'react-hot-loader';
import NotFound from './pages/NotFound';

const Router = createConnectedRouter({
  render: createRender({
    renderError: NotFound,
  }),
});

export default hot(module)(Router);
