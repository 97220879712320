// @flow
/* global $NonMaybeType, $ElementType */

import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import AttributeTable from 'components/AttributeTable';
import { getShowActions } from 'utils/crud';
import { renderDate } from 'components/helpers';

import copyMutation from 'mutations/CopyBalanceDocumentMutation';
import destroyMutation from 'mutations/DestroyBalanceDocumentMutation';

import type { PageProps } from 'types';
import type { ShowBalanceDocumentQueryResponse } from './__generated__/ShowBalanceDocumentQuery.graphql';

import NewItem from './NewItem';
import Items from './Items';

type Props = PageProps & {
  data: $NonMaybeType<$ElementType<ShowBalanceDocumentQueryResponse, 'data'>>,
};

const attributeRows = [
  {
    dataKey: 'eventedAt',
    label: 'Дата',
    render: renderDate,
  },
  {
    dataKey: 'comment',
    label: 'Коментарий',
  },
];

const actionMutations = {
  destroyMutation,
  copyMutation,
};

const Show = (props: Props) => (
  <Page title={`Выравнивания #${props.data.rowId}`} actions={getShowActions(props, actionMutations)}>
    <AttributeTable data={props.data} rows={attributeRows} />
    <NewItem
      context={props.context}
      allWarehouses={props.allWarehouses.nodes}
      additionalValues={{ documentId: props.data.rowId }}
    />
    <Items {...props} rows={props.data.items} />
  </Page>
);

Show.propTypes = {
  data: PropTypes.shape({
    rowId: PropTypes.number.isRequired,
    items: PropTypes.object.isRequired,
  }).isRequired,
  context: PropTypes.object.isRequired,
  allWarehouses: PropTypes.object.isRequired,
};

export default Show;
