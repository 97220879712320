// @flow

import { compose, withProps } from 'recompact';
import destroyMutation from 'mutations/DestroySupplyContractorMutation';
import { renderEnum, renderPrice } from 'components/helpers';
import statuses from './statuses';
import s from './style.css';

export default compose(
  withProps({
    title: 'Поставщики',
    destroyMutation,
    tableProps: {
      rowClassName: row => row.status === 'ARCHIVE' ? s.archive : '',
    },
    columns: {
      name: {
        title: 'Название',
        width: 250,
      },
      description: {
        title: 'Описание',
      },
      status: {
        title: 'Статус',
        width: 140,
        render: renderEnum(statuses),
        filters: Object.keys(statuses).map(key => ({ text: statuses[key], value: key })),
        onFilter: (value, record) => record.status === value,
      },
      supplyTotalSum: {
        title: 'Сумма поставок',
        width: 180,
        render: renderPrice,
      },
      owner: {
        title: 'Ответственный',
        width: 200,
        render: (_, row) => {
          if (row.owner) {
            return `${row.owner.firstName} ${row.owner.lastName}`;
          }
          return '';
        },
      },
    },
  })
);
