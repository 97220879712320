/**
 * @flow
 * @relayHash 31c477712ccfa513f7492959051fde2f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateWriteOffDocumentInput = {
  clientMutationId?: ?string,
  id: string,
  writeOffDocumentPatch: WriteOffDocumentPatch,
};
export type WriteOffDocumentPatch = {
  rowId?: ?number,
  categoryId?: ?number,
  eventedAt?: ?any,
  comment?: ?string,
  totalCost?: ?any,
};
export type UpdateWriteOffDocumentMutationVariables = {|
  input: UpdateWriteOffDocumentInput
|};
export type UpdateWriteOffDocumentMutationResponse = {|
  +updateWriteOffDocument: ?{|
    +writeOffDocument: ?{|
      +rowId: number
    |}
  |}
|};
export type UpdateWriteOffDocumentMutation = {|
  variables: UpdateWriteOffDocumentMutationVariables,
  response: UpdateWriteOffDocumentMutationResponse,
|};
*/


/*
mutation UpdateWriteOffDocumentMutation(
  $input: UpdateWriteOffDocumentInput!
) {
  updateWriteOffDocument(input: $input) {
    writeOffDocument {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateWriteOffDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateWriteOffDocumentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateWriteOffDocumentMutation",
  "id": null,
  "text": "mutation UpdateWriteOffDocumentMutation(\n  $input: UpdateWriteOffDocumentInput!\n) {\n  updateWriteOffDocument(input: $input) {\n    writeOffDocument {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateWriteOffDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateWriteOffDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateWriteOffDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "writeOffDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "WriteOffDocument",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateWriteOffDocumentMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateWriteOffDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateWriteOffDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "writeOffDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "WriteOffDocument",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '704d838fb9e89250d12629ba02e6c58e';
module.exports = node;
