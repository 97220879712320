import { graphql } from 'relay-runtime';

export default graphql`
  query queryCourierTransactionsQuery($rowId: Int!, $condition: CourierPaymentDetailCondition!) {
    transactions: allCourierPaymentDetails(condition: $condition) {
      nodes {
        day
        comment
        value
        employee
        sum
      }
    }

    data: courierByRowId(rowId: $rowId) {
      rowId
      firstName
      lastName
    }
  }
`;
