import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateProductGroupMutation($input: UpdateProductGroupInput!) {
    updateProductGroup(input: $input) {
      productGroup {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateProductGroup', 'productGroup');
