/**
 * @flow
 * @relayHash 495bb8d759a2aecddabe74172d553b16
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteSupplyDocumentInput = {
  clientMutationId?: ?string,
  id: string,
};
export type DestroySupplyDocumentMutationVariables = {|
  input: DeleteSupplyDocumentInput
|};
export type DestroySupplyDocumentMutationResponse = {|
  +deleteSupplyDocument: ?{|
    +deletedSupplyDocumentId: ?string
  |}
|};
export type DestroySupplyDocumentMutation = {|
  variables: DestroySupplyDocumentMutationVariables,
  response: DestroySupplyDocumentMutationResponse,
|};
*/


/*
mutation DestroySupplyDocumentMutation(
  $input: DeleteSupplyDocumentInput!
) {
  deleteSupplyDocument(input: $input) {
    deletedSupplyDocumentId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteSupplyDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteSupplyDocument",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteSupplyDocumentInput!"
      }
    ],
    "concreteType": "DeleteSupplyDocumentPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedSupplyDocumentId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DestroySupplyDocumentMutation",
  "id": null,
  "text": "mutation DestroySupplyDocumentMutation(\n  $input: DeleteSupplyDocumentInput!\n) {\n  deleteSupplyDocument(input: $input) {\n    deletedSupplyDocumentId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DestroySupplyDocumentMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DestroySupplyDocumentMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ddc5f6f6dab8fb5eae103a337bf611d3';
module.exports = node;
