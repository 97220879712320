/**
 * @flow
 * @relayHash 0faac463be65a5649497b9808c7ff093
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteWriteOffCategoryInput = {
  clientMutationId?: ?string,
  id: string,
};
export type DestroyWriteOffCategoryMutationVariables = {|
  input: DeleteWriteOffCategoryInput
|};
export type DestroyWriteOffCategoryMutationResponse = {|
  +deleteWriteOffCategory: ?{|
    +deletedWriteOffCategoryId: ?string
  |}
|};
export type DestroyWriteOffCategoryMutation = {|
  variables: DestroyWriteOffCategoryMutationVariables,
  response: DestroyWriteOffCategoryMutationResponse,
|};
*/


/*
mutation DestroyWriteOffCategoryMutation(
  $input: DeleteWriteOffCategoryInput!
) {
  deleteWriteOffCategory(input: $input) {
    deletedWriteOffCategoryId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteWriteOffCategoryInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteWriteOffCategory",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteWriteOffCategoryInput!"
      }
    ],
    "concreteType": "DeleteWriteOffCategoryPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedWriteOffCategoryId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DestroyWriteOffCategoryMutation",
  "id": null,
  "text": "mutation DestroyWriteOffCategoryMutation(\n  $input: DeleteWriteOffCategoryInput!\n) {\n  deleteWriteOffCategory(input: $input) {\n    deletedWriteOffCategoryId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DestroyWriteOffCategoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DestroyWriteOffCategoryMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '44c158a212727c10844da07e09e800fb';
module.exports = node;
