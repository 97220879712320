import React from 'react';
import PropTypes from 'prop-types';
import { Input, Icon, Modal } from 'antd';
import SendMessageMutation from 'mutations/SendCourierTelegramMessageMutation';

class SendMessage extends React.Component {
  state = {
    visible: false,
    blocked: false,
    text: '',
  };

  open = () => this.setState({ visible: true });

  close = () => this.setState({ visible: false });

  changeText = e => this.setState({ text: e.target.value });

  send = () => {
    if (this.state.blocked) {
      return;
    }

    this.setState({ blocked: true });

    SendMessageMutation.commit(this.props.environment, this.props.courierId, this.state.text)
      .then(() => this.setState({ visible: false, blocked: false, text: '' }))
      .catch(() => this.setState({ blocked: false }));
  };

  render() {
    return (
      <div>
        <Icon type="message" theme="outlined" onClick={this.open} />
        <Modal title="Отправить сообщение" visible={this.state.visible} onOk={this.send} onCancel={this.close}>
          <Input.TextArea rows={4} value={this.state.text} onChange={this.changeText} autoFocus />
        </Modal>
      </div>
    );
  }
}

SendMessage.propTypes = {
  courierId: PropTypes.number.isRequired,
  environment: PropTypes.object.isRequired,
};

export default SendMessage;
