import { get } from 'unchanged';
import moment from 'moment';

export const getIds = (match, path = 'location.query.ids') => {
  const ids = get(path, match);

  if (!ids) {
    return null;
  }

  return (Array.isArray(ids) ? ids : [ids]).map(Number);
};

export const getDate = (match, path) => {
  const value = get(path, match);
  return value || moment().format('YYYY-MM-YY');
};
