// @flow

import React from 'react';
import { compose, withProps } from 'recompact';
import { Link } from 'found';
import moment from 'moment';
import { Button, Tag } from 'antd';
import updateDocumentMutation from 'mutations/UpdateShopOrderDocumentMutation';
import renderStatus from '../ShopOrderDocuments/renderStatus';

export default compose(
  withProps(props => ({
    title: 'Яндекс заказы',
    actions: [],
    columns: {
      rowId: {
        hidden: true,
      },
      externalId: {
        title: 'Еда ID',
      },
      'document.deliveryAt': {
        title: 'Время',
        render: v =>
          moment(v)
            .tz('Europe/Moscow')
            .format('DD MMM YYYY HH:mm'),
      },
      'document.rowId': {
        title: 'Склад ID',
        render: (rowId, row) => (
          <div>
            <Link to={`/shop/order/documents/${rowId}`}>{rowId}</Link>
            <div>{row.document.owner} {row.document.owner.indexOf('-yandex') === -1 && row.document.owner.indexOf('-deliveryclub') === -1 && <Tag color="green">Наша доставка</Tag>}</div>
            <div>{row.document.label}</div>
            {row.document.warehouseByMainWarehouseId && (
              <Tag color="green">{row.document.warehouseByMainWarehouseId.name}</Tag>
            )}
          </div>
        ),
      },
      'document.totalSum': {
        title: 'Сумма',
      },
      'document.status': {
        title: 'Статус',
        render: (status, row) => (
          <div>
            {renderStatus(status)}
            {row.document.ready && status === 'PACKING' && <div><Tag color="green">Готов</Tag></div>}
          </div>
        ),
      },
      client: {
        title: 'Клиент',
        render: (_, row) => (
          <div>
            <div>{row.clientName}</div>
            <div>{row.phone}</div>
            {row.comment && <div>Комментарий: {row.comment}</div>}
          </div>
        ),
      },
      customActions: {
        title: '',
        render: (_, row) => {
          if (row.document.ready && row.discriminator === 'yandex' && row.document.status !== 'DELIVERED' && row.document.status !== 'CANCELED') {
            return (
              <Button
                size="small"
                onClick={() => {
                  // eslint-disable-next-line
                  updateDocumentMutation.commit(props.context.environment, row.document.rowId, {
                    status: 'DELIVERED',
                  });
                }}
              >
                Закрыть
              </Button>
            );
          }

          if (!row.document.ready) {
            return <Link to={`/yandex/orders/${row.document.rowId}`}>Открыть</Link>
          }

          return null;
        },
      },
    },
  }))
);
