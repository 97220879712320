import { getRoutes } from 'utils/crud';

import Index from './index';
import New from './New';
import Edit from './Edit';
import Show from './Show';

import IndexQuery from './WarehouseMovementDocumentsQuery';
import EditQuery from './Edit/EditWarehouseMovementDocumentQuery';
import ShowQuery from './Show/ShowWarehouseMovementDocumentQuery';

export default getRoutes({
  baseUrl: '/warehouse/movement/documents',
  Index,
  IndexQuery,
  Edit,
  EditQuery,
  Show,
  ShowQuery,
  New,
});
