import { graphql } from 'relay-runtime';

export default graphql`
  query WriteOffCategoriesQuery($first: Int!, $offset: Int!, $orderBy: [WriteOffCategoriesOrderBy!]) {
    rows: allWriteOffCategories(first: $first, offset: $offset, orderBy: $orderBy) {
      totalCount

      nodes {
        id
        rowId
        name
      }
    }
  }
`;
