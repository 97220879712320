import React from 'react';
import PropTypes from 'prop-types';
import { YMaps, Map } from 'react-yandex-maps';
import { createRefetchContainer, graphql } from 'react-relay';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import { compose, withState } from 'recompact';
import { showSuccess, showErrors } from 'utils/notifications';
import AutoReload from 'components/AutoReload';
import MapRoute, { MapRouteContextProvider } from 'components/MapRoute';
import ActivePlacemark from 'components/ActivePlacemark';
import UpdateDocumentMutation from 'mutations/UpdateShopOrderDocumentMutation';
import CreateGroupMutation from 'mutations/CreateShopOrderGroupMutation';
import moment from 'moment';
import Clusterer from './Clusterer';
import getLabel from '../getLabel';
import prepareVariables from './prepareVariables';
import OrderBalloon from './OrderBalloon';
import Orders from './Orders';
import Groups from './Groups';
import query from './MapShopOrderDocumentsQuery';
import './Map.css';

const clusterOptions = {
  preset: 'islands#invertedVioletClusterIcons',
  groupByCoordinates: false,
  clusterDisableClickZoom: true,
  clusterHideIconOnBalloonOpen: false,
  geoObjectHideIconOnBalloonOpen: false,
};

const isVisible = (order, selectedIds) => {
  if (!order.point) {
    return false;
  }

  if (selectedIds.length > 0) {
    return selectedIds.includes(order.rowId);
  }

  return true;
};

const openPrintCourierWindow = ids => {
  const search = ids.map(id => `ids=${id}`).join('&');
  window.open(`/shop/order/documents/courier?${search}`);
};

const MapPage = ({
  orders: originalOrders,
  currentWarehouseId,
  allCouriers,
  allGroups,
  location,
  router,
  timeRange,
  mapState,
  setTimeRange,
  setMapState,
  setLastGroupId,
  lastGroupId,
  selectedOrderIds,
  setSelectedOrderIds,
  selectedGroupIds,
  setSelectedGroupIds,
  ...props
}) => {
  const refetch = () => {
    props.relay.refetch(prepareVariables({}, props.match));
  };

  const orders = {
    nodes: originalOrders.nodes.filter(o => {
      if (currentWarehouseId === 2 || currentWarehouseId === null) {
        return o.mainWarehouseId === 2 || o.mainWarehouseId === null;
      }

      return o.mainWarehouseId === currentWarehouseId;
    }),
  };

  if (location.query.withoutMap) {
    return orders.nodes.map(order => (
      <OrderBalloon data={order} key={order.id} allCouriers={allCouriers} allGroups={allGroups} />
    ));
  }

  const mapProps = {
    width: 'calc(100vw - 650px)',
    height: 'calc(100vh - 52px)',
    state: mapState,
    onBoundsChange: e => {
      setMapState({
        center: e.get('newCenter'),
        zoom: e.get('newZoom'),
      });
    },
  };

  const getExpensiveIcon = order => {
    if (order.totalSum >= 7000) {
      return '<strong>$</strong>';
    }

    return '';
  };

  const filteredOrders = orders.nodes.filter(o => {
    if (!o) {
      return false;
    }

    const hour = parseInt(
      moment(o.deliveryAt)
        .tz('Europe/Moscow')
        .format('HH'),
      10,
    );

    return timeRange[0] <= hour && hour <= timeRange[1];
  });

  const getAsapIcon = order => {
    if (order.yandexOrdersByDocumentId.totalCount > 0) {
      return '<i class="material-icons" style="font-size: 15px; height: 14px; overflow: hidden" title="Яндекс.Еда; color: red">restaurant</i>';
    }

    if (order.asap && order.owner === 'moscowfresh') {
      return '<i class="material-icons" style="font-size: 15px; height: 14px; overflow: hidden; color: red" title="ASAP">alarm</i>';
    }

    if (order.asap) {
      return '<i class="material-icons" style="font-size: 15px; height: 14px; overflow: hidden" title="ASAP">alarm</i>';
    }

    return '';
  };

  const getIcon = order => {
    const group = order.groupId && allGroups.nodes.find(g => order.groupId === g.rowId);

    if (group && group.courier && !group.courier.hasDriversLicense) {
      return '<i class="material-icons" style="font-size: 20px; height: 14px; overflow: hidden">transfer_within_a_station</i>';
    }

    if (order.heavy) {
      return '<i class="material-icons" style="font-size: 16px; height: 14px; vertical-align: top; overflow: hidden">local_shipping</i>';
    }

    return '';
  };

  const now = new Date;
  const positionTTL = 1000 * 60 * 30;

  return (
    <YMaps>
      <MapRouteContextProvider>
        <div styleName="root">
          <AutoReload refetch={refetch} />
          <div styleName="map">
            <Map {...mapProps}>
              <div>
                <Clusterer options={clusterOptions}>
                  {filteredOrders.map(
                    order =>
                      isVisible(order, selectedOrderIds) && (
                        <ActivePlacemark
                          key={order.id}
                          geometry={{
                            coordinates: [order.point.x, order.point.y],
                          }}
                          reactBalloonContent={
                            <OrderBalloon
                              data={order}
                              allCouriers={allCouriers}
                              allGroups={allGroups}
                              location={location}
                              setLastGroupId={setLastGroupId}
                            />
                          }
                          options={{
                            // eslint-disable-next-line no-nested-ternary
                            preset: order.ready
                              ? 'islands#greenStretchyIcon'
                              : order.status === 'PACKING'
                              ? 'islands#orangeStretchyIcon'
                              : 'islands#blueStretchyIcon',
                            hideIconOnBalloonOpen: true,
                          }}
                          onClick={async e => {
                            if (!e.originalEvent.domEvent.originalEvent.ctrlKey) {
                              if (e.originalEvent.domEvent.originalEvent.shiftKey) {
                                e.stopPropagation();
                                e.preventDefault();

                                if (order.groupId) {
                                  openPrintCourierWindow(
                                    orders.nodes.filter(o => o && o.groupId === order.groupId).map(o => o.rowId),
                                  );
                                } else {
                                  openPrintCourierWindow([order.rowId]);
                                }
                              }

                              return;
                            }

                            e.stopPropagation();
                            e.preventDefault();

                            if (e.originalEvent.domEvent.originalEvent.shiftKey) {
                              const group = await CreateGroupMutation.commit(props.relay.environment, {
                                deadlineAt: order.deadlineAt,
                              });

                              setLastGroupId(group.rowId);
                              setSelectedGroupIds([group.rowId]);

                              UpdateDocumentMutation.commit(props.relay.environment, order.rowId, {
                                groupId: group.rowId,
                                groupPosition: 1,
                              })
                                .then(showSuccess)
                                .catch(showErrors);
                            } else if (lastGroupId) {
                              const maxPos = (c, i) => (i.groupId === lastGroupId ? i.groupPosition : 0);
                              const lastPosition = allGroups.nodes.reduce(maxPos, 1);
                              const totalCount = orders.nodes.reduce(
                                (t, o) => (o.groupId === lastGroupId && o.rowId !== order.rowId ? t + 1 : t),
                                0,
                              );

                              UpdateDocumentMutation.commit(props.relay.environment, order.rowId, {
                                groupId: lastGroupId,
                                groupPosition: Math.max(lastPosition + 1, totalCount + 1),
                              })
                                .then(showSuccess)
                                .catch(showErrors);
                            }
                          }}
                          properties={{
                            iconContent: `${getExpensiveIcon(order)} ${getAsapIcon(order)} ${getIcon(order)} ${
                              order.groupId ? `${order.groupId}-${order.groupPosition}` : ''
                            } ${getLabel(order)} ${moment(order.deliveryAt)
                              .tz('Europe/Moscow')
                              .format('HH:mm')}`,
                            clusterCaption: `${
                              order.groupId ? `${order.groupId}-${order.groupPosition}` : ''
                            } ${getLabel(order)} ${moment(order.deliveryAt)
                              .tz('Europe/Moscow')
                              .format('HH:mm')}`,
                            balloonContentBody: `
                            <a href="${order.sourceUrl}" target="_blank">Открыть на витрине</a><br />
                            <a href="/shop/order/documents/${order.rowId}" target="_blank">Открыть на складе</a>
                          `,
                          }}
                        />
                      ),
                  )}
                </Clusterer>
                {allGroups.nodes
                  .filter(g => g && selectedGroupIds.includes(g.rowId))
                  .map(group => {
                    const coords = orders.nodes
                      .filter(o => o && o.groupId === group.rowId)
                      .sort((a, b) => a.groupPosition - b.groupPosition)
                      .map(o => [o.deliveryPoint.x, o.deliveryPoint.y]);

                    coords.unshift([55.7334375, 37.549296]);

                    return <MapRoute key={group.rowId} rid={group.rowId} coords={coords} />;
                  })}
              </div>
            </Map>
          </div>
          <div styleName="sidebar">
            <Tabs>
              <Tabs.TabPane tab="Заказы" key="orders">
                <Orders
                  orders={filteredOrders}
                  location={location}
                  router={router}
                  timeRange={timeRange}
                  setTimeRange={setTimeRange}
                  mapState={mapState}
                  setMapState={setMapState}
                  selectedIds={selectedOrderIds}
                  setSelected={setSelectedOrderIds}
                  {...props}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Маршруты" key="groups">
                <Groups
                  selectedIds={selectedGroupIds}
                  setSelected={setSelectedGroupIds}
                  setSelectedOrderIds={setSelectedOrderIds}
                  data={allGroups}
                  allCouriers={allCouriers}
                  location={location}
                  router={router}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </MapRouteContextProvider>
    </YMaps>
  );
};

MapPage.propTypes = {
  relay: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  orders: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  allCouriers: PropTypes.object.isRequired,
  allGroups: PropTypes.object.isRequired,
  mapState: PropTypes.object.isRequired,
  setMapState: PropTypes.func.isRequired,
  timeRange: PropTypes.array.isRequired,
  setTimeRange: PropTypes.func.isRequired,
  lastGroupId: PropTypes.number,
  setLastGroupId: PropTypes.func.isRequired,
  selectedOrderIds: PropTypes.array.isRequired,
  setSelectedOrderIds: PropTypes.array.isRequired,
  selectedGroupIds: PropTypes.array.isRequired,
  setSelectedGroupIds: PropTypes.func.isRequired,
  currentWarehouseId: PropTypes.number,
};

MapPage.defaultProps = {
  lastGroupId: null,
  currentWarehouseId: null,
}

const enhance = compose(
  withState('selectedOrderIds', 'setSelectedOrderIds', []),
  withState('selectedGroupIds', 'setSelectedGroupIds', []),
  withState('mapState', 'setMapState', { zoom: 10, center: [55.76, 37.64] }),
  withState('timeRange', 'setTimeRange', [0, 23]),
  withState('lastGroupId', 'setLastGroupId', null),
);

const selector = state => ({ currentWarehouseId: state.defaultWarehouseId });

export default createRefetchContainer(
  enhance(connect(selector)(MapPage)),
  graphql`
    fragment MapShopOrderDocument_allGroups on ShopOrderGroupsConnection {
      nodes {
        rowId
        deadlineAt

        courier: courierByCourierId {
          hasDriversLicense
        }
      }

      ...OrderBalloonShopOrderDocumentMap_allGroups
      ...GroupsMapShopOrderDocument
    }

    fragment MapShopOrderDocument_allCouriers on CouriersConnection {
      nodes {
        id
        lastPosition { x, y }
        firstName
        lastName
        lastPositionedAt
        ...CourierSelectShopOrderDocumentMap_items
      }
    }

    fragment MapShopOrderDocument_orders on ShopOrderDocumentsConnection {
      nodes {
        ...OrderBalloonShopOrderDocumentMap

        id
        rowId
        deliveryAt
        deadlineAt
        ready
        owner
        label
        asap
        sourceUrl
        status
        heavy
        totalWeight
        totalSum
        mainWarehouseId

        groupId
        groupPosition

        yandexOrdersByDocumentId {
          totalCount
        }

        group: shopOrderGroupByGroupId {
          id
          rowId
          courierId
          deadlineAt
        }

        deliveryMaxDelay {
          hours
        }

        deliveryPoint {
          x
          y
        }

        point: deliveryPoint {
          x
          y
        }
      }
    }
  `,
  query,
);
