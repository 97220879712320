exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-SupplyContractors-___style__archive___3Gfu7 {\n    background-color: #ffe7e6;\n}\n", ""]);

// exports
exports.locals = {
	"archive": "src-pages-SupplyContractors-___style__archive___3Gfu7"
};