import { graphql } from 'relay-runtime';

export default graphql`
  query EditWriteOffCategoryQuery($rowId: Int!) {
    data: writeOffCategoryByRowId(rowId: $rowId) {
      id
      name
    }
  }
`;
