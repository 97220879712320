import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateWriteOffCategoryMutation($input: UpdateWriteOffCategoryInput!) {
    updateWriteOffCategory(input: $input) {
      writeOffCategory {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateWriteOffCategory', 'writeOffCategory');
