/**
 * @flow
 * @relayHash 38721c89ca4c051c14ceff9297b588c9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateProductCategoryInput = {
  clientMutationId?: ?string,
  id: string,
  productCategoryPatch: ProductCategoryPatch,
};
export type ProductCategoryPatch = {
  rowId?: ?number,
  name?: ?string,
  position?: ?number,
};
export type UpdateProductCategoryMutationVariables = {|
  input: UpdateProductCategoryInput
|};
export type UpdateProductCategoryMutationResponse = {|
  +updateProductCategory: ?{|
    +productCategory: ?{|
      +id: string,
      +rowId: number,
      +position: number,
    |}
  |}
|};
export type UpdateProductCategoryMutation = {|
  variables: UpdateProductCategoryMutationVariables,
  response: UpdateProductCategoryMutationResponse,
|};
*/


/*
mutation UpdateProductCategoryMutation(
  $input: UpdateProductCategoryInput!
) {
  updateProductCategory(input: $input) {
    productCategory {
      id
      rowId
      position
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProductCategoryInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateProductCategory",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateProductCategoryInput!"
      }
    ],
    "concreteType": "UpdateProductCategoryPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "productCategory",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductCategory",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rowId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "position",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateProductCategoryMutation",
  "id": null,
  "text": "mutation UpdateProductCategoryMutation(\n  $input: UpdateProductCategoryInput!\n) {\n  updateProductCategory(input: $input) {\n    productCategory {\n      id\n      rowId\n      position\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateProductCategoryMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateProductCategoryMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9bce1cb5035bc3eaa8619118fd8f75c8';
module.exports = node;
