import { graphql } from 'relay-runtime';
import { makeCreateMutation } from './helpers';

const mutation = graphql`
  mutation CreateWriteOffItemMutation($input: CreateWriteOffItemInput!) {
    createWriteOffItem(input: $input) {
      writeOffItem {
        rowId
      }
    }
  }
`;

export default makeCreateMutation(mutation, 'createWriteOffItem', 'writeOffItem');
