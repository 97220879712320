import { getRoutes } from 'utils/crud';
import { preparePaginationVariables } from 'utils/pagination';

import Index from './index';
import New from './New';
import Edit from './Edit';
import Show from './Show';

import IndexQuery from './ProductsQuery';
import NewQuery from './New/NewProductQuery';
import EditQuery from './Edit/EditProductQuery';
import ShowQuery from './Show/ShowProductQuery';

import Control from './Control';
import ControlQuery from './Control/ControlProductsQuery';

import Sustain from './Sustain';
import SustainQuery from './Sustain/SustainProductsQuery';

import Print from './Print';
import PrintQuery from './Print/PrintProductsQuery';
import preparePrintVariables from './Print/prepareVariables';

export default [
  {
    path: '/products/control',
    Component: Control,
    query: ControlQuery,
  },
  {
    path: '/products/sustain',
    Component: Sustain,
    query: SustainQuery,
    prepareVariables: preparePaginationVariables,
  },
  {
    path: '/products/print',
    Component: Print,
    query: PrintQuery,
    prepareVariables: preparePrintVariables,
  },
  ...getRoutes({
    baseUrl: '/products',
    Index,
    IndexQuery,
    Edit,
    EditQuery,
    Show,
    ShowQuery,
    New,
    NewQuery,
  }),
];
