import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateShopOrderItemMutation($input: UpdateShopOrderItemInput!) {
    updateShopOrderItem(input: $input) {
      shopOrderItem {
        id
        rowId
        quantity
        orderedQuantity
        returnStatus
        comment

        shopOrderDocumentByDocumentId {
          id
          itemSum
          totalSum
          expectedTotalSum
        }
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateShopOrderItem', 'shopOrderItem');
