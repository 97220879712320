// @flow
/* global $NonMaybeType, $ElementType */

import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import AttributeTable from 'components/AttributeTable';
import { getShowActions } from 'utils/crud';
import { Link } from 'found';

import type { PageProps } from 'types';
import type { ShowWarehouseQueryResponse } from './__generated__/ShowWarehouseQuery.graphql';

import './Show.css';

type Props = PageProps & {
  data: $NonMaybeType<$ElementType<ShowWarehouseQueryResponse, 'data'>>,
  location: Location,
};

const attributeRows = [
  {
    dataKey: 'name',
    label: 'Название',
  },
];

const byCategory = (a, b) => {
  const v = a.categoryId - b.categoryId;

  if (v === 0) {
    if (a.name > b.name) {
      return 1;
    }

    if (a.name < b.name) {
      return -1;
    }
  }

  return v;
};

const getStyleName = value => {
  if (value < 0) {
    return 'alert-dot';
  }

  if (value < 2) {
    return 'warning-dot';
  }

  return 'dot';
};

const Show = (props: Props) => (
  <Page title={`Склад #${props.data.rowId}`} actions={getShowActions(props)}>
    <AttributeTable data={props.data} rows={attributeRows} />
    <div styleName="map">
      {props.data.quantities.nodes
        .filter(Boolean)
        .sort(byCategory)
        .map(q => (
          <Link
            key={q.product.rowId}
            styleName={getStyleName(q.value)}
            to={`/products/${q.product.rowId}`}
            title={q.product.name}
          />
        ))}
    </div>
  </Page>
);

Show.propTypes = {
  data: PropTypes.shape({
    rowId: PropTypes.number.isRequired,
    quantities: PropTypes.object.isRequired,
  }).isRequired,
};

export default Show;
