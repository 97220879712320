// @flow

import React from 'react';
import { Link } from 'found';
import { compose, withProps } from 'recompact';
import { formatQuantity } from 'utils/format';
import moment from 'moment';
import destroyMutation from 'mutations/DestroySupplyContractorMutation';
import getLabel from '../getLabel';

export default compose(
  withProps({
    title: 'Недостача',
    destroyMutation,
    actions: [],
    pageActions: {
      create: null,
    },
    columns: {
      rowId: {
        hidden: true,
      },
      'order.rowId': {
        title: 'Заказ',
        width: 150,
        sorter: false,
        render: (id, row) => <Link to={`/shop/order/documents/${id}`}>{getLabel(row.order)}</Link>,
      },
      'product.name': {
        title: 'Позиция',
        sorter: false,
        width: 250,
      },
      orderedQuantity: {
        title: 'Количество',
        sorter: false,
        render: (v, row) => formatQuantity(v, row.product.unit),
      },
      'order.deliveryAt': {
        title: 'Время доставки',
        width: 150,
        sorter: false,
        render: v =>
          moment(v)
            .tz('Europe/Moscow')
            .format('HH:mm'),
      },
      'order.owner': {
        title: 'Владелец',
        sorter: false,
        width: 120,
      },
      'order.sourceUrl': {
        title: 'Ссылка на витрину',
        sorter: false,
        render: (url, row) => {
          if (!url) {
            if (row.order.owner.match(/-yandex$/)) {
              return <Link to={`/yandex/orders/${row.order.rowId}`}>Открыть</Link>;
            }

            return null;
          }

          if (row.order.owner === 'myfresh' || row.order.owner === 'myfresh') {
            const m = url.match(/(\d+)$/);

            if (m) {
              return (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {m[1]}
                </a>
              );
            }
          }

          return (
            <a href={url} target="_blank" rel="noopener noreferrer">
              ???
            </a>
          );
        },
      },
    },
  })
);
