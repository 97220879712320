import React from 'react';
import PropTypes from 'prop-types';
import CollectionSelect from 'components/CollectionSelect';
import Header from './Header';
import AutoReload from './AutoReload';
import './App.css';

const App = ({
  children,
  allWarehouses,
  changeDefaultWarehouseId,
  defaultWarehouseId,
  currentWarehouse,
  context,
  signout,
}) => {
  return (
    <div styleName="root">
      <Header logoText={currentWarehouse && currentWarehouse.name} onSignout={signout} viewer={context.viewer}>
        <AutoReload url="/index.html" />
        <CollectionSelect
          items={allWarehouses.nodes}
          style={{ width: 180 }}
          placeholder="Склад по-умолчанию"
          onChange={changeDefaultWarehouseId}
          value={defaultWarehouseId}
        />
      </Header>
      {children}
    </div>
  );
};

App.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.any,
  allWarehouses: PropTypes.shape({
    nodes: PropTypes.array.isRequired,
  }).isRequired,
  changeDefaultWarehouseId: PropTypes.func.isRequired,
  signout: PropTypes.func.isRequired,
  defaultWarehouseId: PropTypes.number,
  currentWarehouse: PropTypes.object,
  context: PropTypes.object.isRequired,
};

App.defaultProps = {
  defaultWarehouseId: null,
  currentWarehouse: null,
};

export default App;
