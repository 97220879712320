import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateShopOrderDocument2Mutation($input: UpdateShopOrderDocumentInput!) {
    updateShopOrderDocument(input: $input) {
      shopOrderDocument {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateShopOrderDocument', 'shopOrderDocument');
