import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroyCourierMutation($input: DeleteCourierInput!) {
    deleteCourier(input: $input) {
      deletedCourierId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteCourier', 'deletedCourierId');
