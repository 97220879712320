import React from 'react';
import { Icon, Select } from 'antd';
import { Link } from 'found';
import moment from 'moment';
import { flatten } from 'lodash';
import { formatPrice, formatQuantity } from 'utils/format';
import { intervalFieldKeys, intervalFields } from './IntervalInput';

const LONG_DASH = '–';

export const renderIcon = props => value => (value ? <Icon {...props} /> : null);

export const getSelectItems = map =>
  Object.keys(map).map(key => (
    <Select.Option key={key} value={key}>
      {map[key]}
    </Select.Option>
  ));

export const getRelationLink = ({ path }) => data =>
  data ? (
    <Link to={`${path}/${data.rowId}`} key={data.rowId}>
      {data.name}
    </Link>
  ) : (
    LONG_DASH
  );
export const getRelationLinks = options => nodes =>
  flatten(nodes.map((node, i) => [i > 0 ? ', ' : null, getRelationLink(options)(node)]));

export const renderEnum = values => v => values[v] || v || LONG_DASH;

export const renderDate = v => (v ? moment(v).format('DD MMM YYYY') : LONG_DASH);

export const renderPrice = v => formatPrice(v);
export const renderQuantity = (v, row) => formatQuantity(v, row.unit);
export const renderPercentDiscount = v => (parseFloat(v) === 1 ? '–' : `${((1 - v) * 100).toFixed(2)}%`);

export const renderText = v => v || LONG_DASH;

export const renderInterval = interval =>
  interval &&
  intervalFieldKeys
    .map(k => interval[k] && `${interval[k]} ${intervalFields[k].substr(0, 1).toLowerCase()}.`)
    .filter(Boolean)
    .join(' ');
