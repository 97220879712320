/**
 * @flow
 * @relayHash f1765f984d4ed8becf0f3927d0970c79
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductUnit = "KG" | "PCS" | "%future added value";
export type ShopOrderDocumentPaymentMethod = "BY_CARD" | "BY_CASH" | "DEPOSIT" | "EMPLOYEE" | "ONLINE" | "%future added value";
export type ShopOrderDocumentStatus = "CANCELED" | "COMPLETED" | "DELIVERED" | "PACKING" | "PENDING" | "SHIPPED" | "%future added value";
export type ShopOrderDocumentFilter = {
  rowId?: ?IntFilter,
  paymentMethod?: ?ShopOrderDocumentPaymentMethodFilter,
  status?: ?ShopOrderDocumentStatusFilter,
  eventedAt?: ?DatetimeFilter,
  totalSumMultiplier?: ?BigFloatFilter,
  discount?: ?BigFloatFilter,
  itemSum?: ?BigFloatFilter,
  deliveryCost?: ?BigFloatFilter,
  totalWeight?: ?BigFloatFilter,
  meta?: ?JSONFilter,
  comment?: ?StringFilter,
  groupId?: ?IntFilter,
  owner?: ?StringFilter,
  deliveryAt?: ?DatetimeFilter,
  deliveryMaxDelay?: ?IntervalFilter,
  sourceUrl?: ?StringFilter,
  printed?: ?BooleanFilter,
  ready?: ?BooleanFilter,
  deliveryPointBlocked?: ?BooleanFilter,
  groupPosition?: ?IntFilter,
  change?: ?BigFloatFilter,
  confirmedAmount?: ?BigFloatFilter,
  userId?: ?StringFilter,
  shopId?: ?IntFilter,
  asap?: ?BooleanFilter,
  deliveryNoticeAt?: ?DatetimeFilter,
  ecoPack?: ?BooleanFilter,
  withoutOverweight?: ?BooleanFilter,
  withGlassCoupon?: ?BooleanFilter,
  deliveredAt?: ?DatetimeFilter,
  byCardPaymentConfirmed?: ?BooleanFilter,
  userRating?: ?IntFilter,
  userRatingAt?: ?DatetimeFilter,
  userReview?: ?StringFilter,
  userReviewAt?: ?DatetimeFilter,
  userReviewImages?: ?StringListFilter,
  userReviewSolvedEmployeeId?: ?IntFilter,
  userReviewSolveComment?: ?StringFilter,
  readyAt?: ?DatetimeFilter,
  shippedAt?: ?DatetimeFilter,
  mainWarehouseId?: ?IntFilter,
  manualConfirmedAmount?: ?BigFloatFilter,
  packageCount?: ?IntFilter,
  checkEmployeeId?: ?IntFilter,
  clientPhone?: ?StringFilter,
  clientEmail?: ?StringFilter,
  lastSentEvent?: ?StringFilter,
  deadlineAt?: ?StringFilter,
  deliveryOn?: ?DateFilter,
  depositBalance?: ?BigFloatFilter,
  expectedTotalSum?: ?BigFloatFilter,
  heavy?: ?BooleanFilter,
  label?: ?StringFilter,
  owedSum?: ?BigFloatFilter,
  totalSum?: ?BigFloatFilter,
  and?: ?$ReadOnlyArray<ShopOrderDocumentFilter>,
  or?: ?$ReadOnlyArray<ShopOrderDocumentFilter>,
  not?: ?ShopOrderDocumentFilter,
};
export type IntFilter = {
  isNull?: ?boolean,
  equalTo?: ?number,
  notEqualTo?: ?number,
  distinctFrom?: ?number,
  notDistinctFrom?: ?number,
  in?: ?$ReadOnlyArray<number>,
  notIn?: ?$ReadOnlyArray<number>,
  lessThan?: ?number,
  lessThanOrEqualTo?: ?number,
  greaterThan?: ?number,
  greaterThanOrEqualTo?: ?number,
};
export type ShopOrderDocumentPaymentMethodFilter = {
  isNull?: ?boolean,
  equalTo?: ?ShopOrderDocumentPaymentMethod,
  notEqualTo?: ?ShopOrderDocumentPaymentMethod,
  distinctFrom?: ?ShopOrderDocumentPaymentMethod,
  notDistinctFrom?: ?ShopOrderDocumentPaymentMethod,
  in?: ?$ReadOnlyArray<ShopOrderDocumentPaymentMethod>,
  notIn?: ?$ReadOnlyArray<ShopOrderDocumentPaymentMethod>,
  lessThan?: ?ShopOrderDocumentPaymentMethod,
  lessThanOrEqualTo?: ?ShopOrderDocumentPaymentMethod,
  greaterThan?: ?ShopOrderDocumentPaymentMethod,
  greaterThanOrEqualTo?: ?ShopOrderDocumentPaymentMethod,
};
export type ShopOrderDocumentStatusFilter = {
  isNull?: ?boolean,
  equalTo?: ?ShopOrderDocumentStatus,
  notEqualTo?: ?ShopOrderDocumentStatus,
  distinctFrom?: ?ShopOrderDocumentStatus,
  notDistinctFrom?: ?ShopOrderDocumentStatus,
  in?: ?$ReadOnlyArray<ShopOrderDocumentStatus>,
  notIn?: ?$ReadOnlyArray<ShopOrderDocumentStatus>,
  lessThan?: ?ShopOrderDocumentStatus,
  lessThanOrEqualTo?: ?ShopOrderDocumentStatus,
  greaterThan?: ?ShopOrderDocumentStatus,
  greaterThanOrEqualTo?: ?ShopOrderDocumentStatus,
};
export type DatetimeFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type BigFloatFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type JSONFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
  contains?: ?any,
  containsKey?: ?string,
  containsAllKeys?: ?$ReadOnlyArray<string>,
  containsAnyKeys?: ?$ReadOnlyArray<string>,
  containedBy?: ?any,
};
export type StringFilter = {
  isNull?: ?boolean,
  equalTo?: ?string,
  notEqualTo?: ?string,
  distinctFrom?: ?string,
  notDistinctFrom?: ?string,
  in?: ?$ReadOnlyArray<string>,
  notIn?: ?$ReadOnlyArray<string>,
  lessThan?: ?string,
  lessThanOrEqualTo?: ?string,
  greaterThan?: ?string,
  greaterThanOrEqualTo?: ?string,
  includes?: ?string,
  notIncludes?: ?string,
  includesInsensitive?: ?string,
  notIncludesInsensitive?: ?string,
  startsWith?: ?string,
  notStartsWith?: ?string,
  startsWithInsensitive?: ?string,
  notStartsWithInsensitive?: ?string,
  endsWith?: ?string,
  notEndsWith?: ?string,
  endsWithInsensitive?: ?string,
  notEndsWithInsensitive?: ?string,
  like?: ?string,
  notLike?: ?string,
  likeInsensitive?: ?string,
  notLikeInsensitive?: ?string,
  equalToInsensitive?: ?string,
  notEqualToInsensitive?: ?string,
  distinctFromInsensitive?: ?string,
  notDistinctFromInsensitive?: ?string,
  inInsensitive?: ?$ReadOnlyArray<string>,
  notInInsensitive?: ?$ReadOnlyArray<string>,
  lessThanInsensitive?: ?string,
  lessThanOrEqualToInsensitive?: ?string,
  greaterThanInsensitive?: ?string,
  greaterThanOrEqualToInsensitive?: ?string,
};
export type IntervalFilter = {
  isNull?: ?boolean,
  equalTo?: ?IntervalInput,
  notEqualTo?: ?IntervalInput,
  distinctFrom?: ?IntervalInput,
  notDistinctFrom?: ?IntervalInput,
  in?: ?$ReadOnlyArray<IntervalInput>,
  notIn?: ?$ReadOnlyArray<IntervalInput>,
  lessThan?: ?IntervalInput,
  lessThanOrEqualTo?: ?IntervalInput,
  greaterThan?: ?IntervalInput,
  greaterThanOrEqualTo?: ?IntervalInput,
};
export type IntervalInput = {
  seconds?: ?number,
  minutes?: ?number,
  hours?: ?number,
  days?: ?number,
  months?: ?number,
  years?: ?number,
};
export type BooleanFilter = {
  isNull?: ?boolean,
  equalTo?: ?boolean,
  notEqualTo?: ?boolean,
  distinctFrom?: ?boolean,
  notDistinctFrom?: ?boolean,
  in?: ?$ReadOnlyArray<boolean>,
  notIn?: ?$ReadOnlyArray<boolean>,
  lessThan?: ?boolean,
  lessThanOrEqualTo?: ?boolean,
  greaterThan?: ?boolean,
  greaterThanOrEqualTo?: ?boolean,
};
export type StringListFilter = {
  isNull?: ?boolean,
  equalTo?: ?$ReadOnlyArray<?string>,
  notEqualTo?: ?$ReadOnlyArray<?string>,
  distinctFrom?: ?$ReadOnlyArray<?string>,
  notDistinctFrom?: ?$ReadOnlyArray<?string>,
  lessThan?: ?$ReadOnlyArray<?string>,
  lessThanOrEqualTo?: ?$ReadOnlyArray<?string>,
  greaterThan?: ?$ReadOnlyArray<?string>,
  greaterThanOrEqualTo?: ?$ReadOnlyArray<?string>,
  contains?: ?$ReadOnlyArray<?string>,
  containedBy?: ?$ReadOnlyArray<?string>,
  overlaps?: ?$ReadOnlyArray<?string>,
  anyEqualTo?: ?string,
  anyNotEqualTo?: ?string,
  anyLessThan?: ?string,
  anyLessThanOrEqualTo?: ?string,
  anyGreaterThan?: ?string,
  anyGreaterThanOrEqualTo?: ?string,
};
export type DateFilter = {
  isNull?: ?boolean,
  equalTo?: ?any,
  notEqualTo?: ?any,
  distinctFrom?: ?any,
  notDistinctFrom?: ?any,
  in?: ?$ReadOnlyArray<any>,
  notIn?: ?$ReadOnlyArray<any>,
  lessThan?: ?any,
  lessThanOrEqualTo?: ?any,
  greaterThan?: ?any,
  greaterThanOrEqualTo?: ?any,
};
export type PrintShopOrderDocumentsQueryVariables = {|
  filter?: ?ShopOrderDocumentFilter
|};
export type PrintShopOrderDocumentsQueryResponse = {|
  +orders: ?{|
    +nodes: $ReadOnlyArray<{|
      +id: string,
      +rowId: number,
      +owner: ?string,
      +label: ?string,
      +deadlineAt: ?string,
      +deliveryAt: ?any,
      +comment: string,
      +asap: boolean,
      +ecoPack: boolean,
      +withoutOverweight: boolean,
      +withGlassCoupon: ?boolean,
      +yandexOrders: {|
        +nodes: $ReadOnlyArray<{|
          +externalId: string
        |}>
      |},
      +items: {|
        +nodes: $ReadOnlyArray<{|
          +id: string,
          +orderedQuantity: any,
          +warehouseId: number,
          +comment: ?string,
          +productId: number,
          +product: ?{|
            +name: string,
            +categoryId: ?number,
            +unit: ProductUnit,
            +productGroups: {|
              +nodes: $ReadOnlyArray<{|
                +id: string,
                +groupId: number,
                +group: ?{|
                  +id: string,
                  +warehouseId: number,
                |},
              |}>
            |},
          |},
        |}>
      |},
    |}>
  |},
  +allProductCategories: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +name: string,
      +position: number,
    |}>
  |},
  +allProductGroups: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +name: string,
      +position: number,
    |}>
  |},
|};
export type PrintShopOrderDocumentsQuery = {|
  variables: PrintShopOrderDocumentsQueryVariables,
  response: PrintShopOrderDocumentsQueryResponse,
|};
*/


/*
query PrintShopOrderDocumentsQuery(
  $filter: ShopOrderDocumentFilter
) {
  orders: allShopOrderDocuments(filter: $filter) {
    nodes {
      id
      rowId
      owner
      label
      deadlineAt
      deliveryAt
      comment
      asap
      ecoPack
      withoutOverweight
      withGlassCoupon
      yandexOrders: yandexOrdersByDocumentId(first: 1) {
        nodes {
          externalId
          id
        }
      }
      items: shopOrderItemsByDocumentId {
        nodes {
          id
          orderedQuantity
          warehouseId
          comment
          productId
          product: productByProductId {
            name
            categoryId
            unit
            productGroups: productGroupsProductsByProductId {
              nodes {
                id
                groupId
                group: productGroupByGroupId {
                  id
                  warehouseId
                }
              }
            }
            id
          }
        }
      }
    }
  }
  allProductCategories(orderBy: [POSITION_ASC]) {
    nodes {
      rowId
      name
      position
      id
    }
  }
  allProductGroups {
    nodes {
      rowId
      name
      position
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "filter",
    "type": "ShopOrderDocumentFilter",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter",
    "type": "ShopOrderDocumentFilter"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "owner",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "label",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deadlineAt",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "deliveryAt",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "comment",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "asap",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "ecoPack",
  "args": null,
  "storageKey": null
},
v11 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "withoutOverweight",
  "args": null,
  "storageKey": null
},
v12 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "withGlassCoupon",
  "args": null,
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1,
    "type": "Int"
  }
],
v14 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "externalId",
  "args": null,
  "storageKey": null
},
v15 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "orderedQuantity",
  "args": null,
  "storageKey": null
},
v16 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "warehouseId",
  "args": null,
  "storageKey": null
},
v17 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "productId",
  "args": null,
  "storageKey": null
},
v18 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v19 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "categoryId",
  "args": null,
  "storageKey": null
},
v20 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "unit",
  "args": null,
  "storageKey": null
},
v21 = {
  "kind": "LinkedField",
  "alias": "productGroups",
  "name": "productGroupsProductsByProductId",
  "storageKey": null,
  "args": null,
  "concreteType": "ProductGroupsProductsConnection",
  "plural": false,
  "selections": [
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "nodes",
      "storageKey": null,
      "args": null,
      "concreteType": "ProductGroupsProduct",
      "plural": true,
      "selections": [
        v2,
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "groupId",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "LinkedField",
          "alias": "group",
          "name": "productGroupByGroupId",
          "storageKey": null,
          "args": null,
          "concreteType": "ProductGroup",
          "plural": false,
          "selections": [
            v2,
            v16
          ]
        }
      ]
    }
  ]
},
v22 = [
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": [
      "POSITION_ASC"
    ],
    "type": "[ProductCategoriesOrderBy!]"
  }
],
v23 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "position",
  "args": null,
  "storageKey": null
},
v24 = [
  v3,
  v18,
  v23
],
v25 = [
  v3,
  v18,
  v23,
  v2
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "PrintShopOrderDocumentsQuery",
  "id": null,
  "text": "query PrintShopOrderDocumentsQuery(\n  $filter: ShopOrderDocumentFilter\n) {\n  orders: allShopOrderDocuments(filter: $filter) {\n    nodes {\n      id\n      rowId\n      owner\n      label\n      deadlineAt\n      deliveryAt\n      comment\n      asap\n      ecoPack\n      withoutOverweight\n      withGlassCoupon\n      yandexOrders: yandexOrdersByDocumentId(first: 1) {\n        nodes {\n          externalId\n          id\n        }\n      }\n      items: shopOrderItemsByDocumentId {\n        nodes {\n          id\n          orderedQuantity\n          warehouseId\n          comment\n          productId\n          product: productByProductId {\n            name\n            categoryId\n            unit\n            productGroups: productGroupsProductsByProductId {\n              nodes {\n                id\n                groupId\n                group: productGroupByGroupId {\n                  id\n                  warehouseId\n                }\n              }\n            }\n            id\n          }\n        }\n      }\n    }\n  }\n  allProductCategories(orderBy: [POSITION_ASC]) {\n    nodes {\n      rowId\n      name\n      position\n      id\n    }\n  }\n  allProductGroups {\n    nodes {\n      rowId\n      name\n      position\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "PrintShopOrderDocumentsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "orders",
        "name": "allShopOrderDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "ShopOrderDocumentsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderDocument",
            "plural": true,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              v10,
              v11,
              v12,
              {
                "kind": "LinkedField",
                "alias": "yandexOrders",
                "name": "yandexOrdersByDocumentId",
                "storageKey": "yandexOrdersByDocumentId(first:1)",
                "args": v13,
                "concreteType": "YandexOrdersConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "nodes",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "YandexOrder",
                    "plural": true,
                    "selections": [
                      v14
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": "items",
                "name": "shopOrderItemsByDocumentId",
                "storageKey": null,
                "args": null,
                "concreteType": "ShopOrderItemsConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "nodes",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ShopOrderItem",
                    "plural": true,
                    "selections": [
                      v2,
                      v15,
                      v16,
                      v8,
                      v17,
                      {
                        "kind": "LinkedField",
                        "alias": "product",
                        "name": "productByProductId",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Product",
                        "plural": false,
                        "selections": [
                          v18,
                          v19,
                          v20,
                          v21
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allProductCategories",
        "storageKey": "allProductCategories(orderBy:[\"POSITION_ASC\"])",
        "args": v22,
        "concreteType": "ProductCategoriesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductCategory",
            "plural": true,
            "selections": v24
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allProductGroups",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductGroupsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductGroup",
            "plural": true,
            "selections": v24
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PrintShopOrderDocumentsQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "orders",
        "name": "allShopOrderDocuments",
        "storageKey": null,
        "args": v1,
        "concreteType": "ShopOrderDocumentsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderDocument",
            "plural": true,
            "selections": [
              v2,
              v3,
              v4,
              v5,
              v6,
              v7,
              v8,
              v9,
              v10,
              v11,
              v12,
              {
                "kind": "LinkedField",
                "alias": "yandexOrders",
                "name": "yandexOrdersByDocumentId",
                "storageKey": "yandexOrdersByDocumentId(first:1)",
                "args": v13,
                "concreteType": "YandexOrdersConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "nodes",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "YandexOrder",
                    "plural": true,
                    "selections": [
                      v14,
                      v2
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "alias": "items",
                "name": "shopOrderItemsByDocumentId",
                "storageKey": null,
                "args": null,
                "concreteType": "ShopOrderItemsConnection",
                "plural": false,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "nodes",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "ShopOrderItem",
                    "plural": true,
                    "selections": [
                      v2,
                      v15,
                      v16,
                      v8,
                      v17,
                      {
                        "kind": "LinkedField",
                        "alias": "product",
                        "name": "productByProductId",
                        "storageKey": null,
                        "args": null,
                        "concreteType": "Product",
                        "plural": false,
                        "selections": [
                          v18,
                          v19,
                          v20,
                          v21,
                          v2
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allProductCategories",
        "storageKey": "allProductCategories(orderBy:[\"POSITION_ASC\"])",
        "args": v22,
        "concreteType": "ProductCategoriesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductCategory",
            "plural": true,
            "selections": v25
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allProductGroups",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductGroupsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "ProductGroup",
            "plural": true,
            "selections": v25
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f73aa15190f5d0a702e1f5f42b752912';
module.exports = node;
