import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateWarehouseMovementDocumentMutation($input: UpdateWarehouseMovementDocumentInput!) {
    updateWarehouseMovementDocument(input: $input) {
      warehouseMovementDocument {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateWarehouseMovementDocument', 'warehouseMovementDocument');
