// @flow

import React from 'react';
import { Select } from 'antd';
import { createFragmentContainer, graphql } from 'react-relay';
// eslint-disable-next-line camelcase
import type { SupplyContractorSelect_items } from './__generated__/SupplyContractorSelect_items.graphql';

type Props = {
  // eslint-disable-next-line camelcase
  items: SupplyContractorSelect_items,
};

const SupplyContractorSelect = ({ items, ...props }: Props) => (
  <Select {...props}>
    {items.map(item => (
      <Select.Option key={item.rowId} value={item.rowId}>
        {item.name}
      </Select.Option>
    ))}
  </Select>
);

export default createFragmentContainer(
  SupplyContractorSelect,
  graphql`
    fragment SupplyContractorSelect_items on SupplyContractor @relay(plural: true) {
      rowId
      name
    }
  `
);
