// @flow
/* global $NonMaybeType, $ElementType */

import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import AttributeTable from 'components/AttributeTable';
import DataTable from 'components/DataTable';
import { Link } from 'found';
import { Row, Col, Button } from 'antd';
import { getShowActions } from 'utils/crud';
import destroyProductGroupsProductMutation from 'mutations/DestroyProductGroupsProductMutation';
import destroyProductAutoWriteOffMutation  from 'mutations/DestroyProductAutoWriteOffMutation';

import {
  renderDate,
  renderPrice,
  renderQuantity,
  renderEnum,
  renderInterval,
  getRelationLinks,
  getRelationLink,
} from 'components/helpers';

import type { PageProps } from 'types';
import type { ShowProductQueryResponse } from './__generated__/ShowProductQuery.graphql';
import NewGroup from './NewGroup';

import statuses from '../statuses';
import externalStatuses from '../externalStatuses';
import units from '../units';

type Props = PageProps & {
  data: $NonMaybeType<$ElementType<ShowProductQueryResponse, 'data'>>,
};

const attributeRows = [
  {
    dataKey: 'name',
    label: 'Название',
  },
  {
    dataKey: 'status',
    label: 'Статус',
    render: renderEnum(statuses),
  },
  {
    dataKey: 'externalStatus',
    label: 'Внешний статус',
    render: renderEnum(externalStatuses),
  },
  {
    dataKey: 'shelfLife',
    label: 'Срок годности',
    render: renderInterval,
  },
  {
    dataKey: 'unit',
    label: 'Единица измерения',
    render: renderEnum(units),
  },
  {
    dataKey: 'parent',
    label: 'Родитель',
    render: getRelationLink({ path: '/products' }),
  },
  {
    dataKey: 'children.nodes',
    label: 'Дети',
    render: getRelationLinks({ path: '/products' }),
  },
];

const getActions = props => ({
  moves: {
    type: 'link',
    to: `/moves/${props.params.rowId}`,
    icon: 'calculator',
    children: 'Движения',
  },
  barcode: {
    type: 'link',
    to: `https://myfresh.ru/tools/stock_products/${props.params.rowId}/barcode`,
    target: '_blank',
    icon: 'barcode',
    children: 'Штрихкод',
  },
  ...getShowActions(props),
});

const Show = (props: Props) => (
  <Page title={`Продукт #${props.data.rowId}`} actions={getActions(props)}>
    <Row>
      <Col span={12}>
        <AttributeTable data={props.data} rows={attributeRows} />
      </Col>
      <Col span={12}>
        <h4>Группы</h4>
        <DataTable
          {...props}
          rowKey="id"
          onlyTable
          rows={props.data.groups}
          destroyMutation={destroyProductGroupsProductMutation}
          actions={['destroy']}
          columns={{
            rowId: {
              hidden: true,
            },
            'productGroup.name': {
              title: 'Название',
            },
          }}
        />
        <NewGroup
          groups={props.allProductGroups.nodes}
          context={props.context}
          additionalValues={{ productId: props.data.rowId }}
        />
        <h4>Автосписания</h4>
        <DataTable
          {...props}
          rowKey="id"
          onlyTable
          route={{ baseUrl: '/product-auto-writeoffs' }}
          rows={props.data.productAutoWriteOffsBySourceId}
          destroyMutation={destroyProductAutoWriteOffMutation}
          actions={['edit', 'destroy']}
          columns={{
            rowId: {
              hidden: true,
            },
            'target.name': {
              title: 'Название',
            },
            quantity: {
              title: 'Количество',
            },
          }}
        />
        <Link to={`/product-auto-writeoffs/new?sourceId=${props.data.rowId}`} style={{ marginTop: 10, display: 'inline-block' }}>
          <Button size="small">Добавить</Button>
        </Link>
      </Col>
    </Row>
    <h3 style={{ marginTop: 50 }}>Поставки</h3>
    <DataTable
      {...props}
      rowKey="id"
      onlyTable
      rows={props.data.supplyItems}
      actions={[]}
      columns={{
        rowId: {
          hidden: true,
        },
        warehouse: {
          title: 'Склад',
          sorter: false,
          render: getRelationLink({ path: '/supply/warehouses' }),
          width: 200,
        },
        'document.eventedAt': {
          title: 'Дата',
          sorter: false,
          render: renderDate,
          width: 120,
        },
        'document.contractor': {
          title: 'Поставщик',
          sorter: false,
          render: getRelationLink({ path: '/supply/contractors' }),
          width: 200,
        },
        quantity: {
          title: 'Количество',
          sorter: false,
          render: v => renderQuantity(v, props.data),
          width: 150,
        },
        price: {
          title: 'Цена',
          sorter: false,
          render: renderPrice,
          width: 150,
        },
        'document.comment': {
          sorter: false,
          title: 'Коментарий',
        },
        document: {
          sorter: false,
          title: '',
          width: 130,
          render: d => <Link to={`/supply/documents/${d.rowId}`}>Открыть #{d.rowId}</Link>,
        },
      }}
    />
  </Page>
);

Show.propTypes = {
  data: PropTypes.shape({
    rowId: PropTypes.number.isRequired,
    supplyItems: PropTypes.shape({
      nodes: PropTypes.array.isRequired,
    }).isRequired,
    groups: PropTypes.object.isRequired,
  }).isRequired,
  allProductGroups: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
};

export default Show;
