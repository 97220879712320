/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
export type ProductUnit = "KG" | "PCS" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ShowYandexOrderDocument$ref: FragmentReference;
export type ShowYandexOrderDocument = {|
  +rowId: number,
  +owner: ?string,
  +mainWarehouseId: ?number,
  +items: {|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +id: string,
      +printed: boolean,
      +deficit: boolean,
      +product: ?{|
        +rowId: number,
        +name: string,
        +unit: ProductUnit,
      |},
      +orderedQuantity: any,
      +quantity: ?any,
      +price: any,
    |}>
  |},
  +$refType: ShowYandexOrderDocument$ref,
|};
*/


const node/*: ConcreteFragment*/ = (function(){
var v0 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Fragment",
  "name": "ShowYandexOrderDocument",
  "type": "ShopOrderDocument",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    v0,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "owner",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "mainWarehouseId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": "items",
      "name": "shopOrderItemsByDocumentId",
      "storageKey": "shopOrderItemsByDocumentId(orderBy:[\"DEFICIT_DESC\"])",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": [
            "DEFICIT_DESC"
          ],
          "type": "[ShopOrderItemsOrderBy!]"
        }
      ],
      "concreteType": "ShopOrderItemsConnection",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "alias": null,
          "name": "nodes",
          "storageKey": null,
          "args": null,
          "concreteType": "ShopOrderItem",
          "plural": true,
          "selections": [
            v0,
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "id",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "printed",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "deficit",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "LinkedField",
              "alias": "product",
              "name": "productByProductId",
              "storageKey": null,
              "args": null,
              "concreteType": "Product",
              "plural": false,
              "selections": [
                v0,
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "name",
                  "args": null,
                  "storageKey": null
                },
                {
                  "kind": "ScalarField",
                  "alias": null,
                  "name": "unit",
                  "args": null,
                  "storageKey": null
                }
              ]
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "orderedQuantity",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "quantity",
              "args": null,
              "storageKey": null
            },
            {
              "kind": "ScalarField",
              "alias": null,
              "name": "price",
              "args": null,
              "storageKey": null
            }
          ]
        }
      ]
    }
  ]
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c5ad1ea7f47f56b09a44b72950fcef75';
module.exports = node;
