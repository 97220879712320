import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import withFiltersEnhance from 'hoc/withFiltersEnhance';
import FilterForm from 'components/FilterForm';
import { FilterInput, FilterCheckbox } from 'components/filterControls';

const Filters = ({ form, handleSubmit, handleClear }) => (
  <FilterForm onSubmit={handleSubmit} onClear={handleClear}>
    <Form.Item label="Имя">
      {form.getFieldDecorator('firstName')(<FilterInput filterType="includesInsensitive" />)}
    </Form.Item>
    <Form.Item label="Фамилия">
      {form.getFieldDecorator('lastName')(<FilterInput filterType="includesInsensitive" />)}
    </Form.Item>
    <Form.Item label="Рабочий телефон">
      {form.getFieldDecorator('workPhone')(<FilterInput filterType="includesInsensitive" />)}
    </Form.Item>
    <Form.Item label="Личная машина">
      {form.getFieldDecorator('privateCar')(<FilterInput filterType="includesInsensitive" />)}
    </Form.Item>
    <Form.Item label="Уволен">
      {form.getFieldDecorator('fired', { valuePropName: 'checked' })(
        <FilterCheckbox filterType="equalTo" valuePropName="checked" />
      )}
    </Form.Item>
  </FilterForm>
);

Filters.propTypes = {
  form: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClear: PropTypes.func,
};

Filters.defaultProps = {
  handleClear: null,
};

export default withFiltersEnhance(['firstName', 'lastName', 'workPhone', 'privateCar'])(Filters);
