// @flow

import React from 'react';
import { compose, withProps, withState } from 'recompact';
import destroyMutation from 'mutations/DestroyProductMutation';
import updateMutation from 'mutations/UpdateProductMutation';
import createScaleProductMutation from 'mutations/CreateScaleProductMutation';
import { showSuccess, showErrors } from 'utils/notifications';
import { set } from 'unchanged';
import transform from 'utils/transform';
import { getRelationLink } from 'components/helpers';
import type { ProductsQueryResponse } from './__generated__/ProductsQuery.graphql';
import statuses from './statuses';
import units from './units';
import Filters from './Filters';
import Actions from './Actions';
import renderName from './renderName';
import externalStatuses from './externalStatuses';

const getWarehouseColumn = warehouse => ({
  key: warehouse.id,
  title: warehouse.name,
  width: 120,
  render: (_, { quantities }) => {
    const quantity = quantities.nodes.find(n => n.warehouseId === warehouse.rowId);
    return quantity ? quantity.value : '–';
  },
});

export default compose(
  withProps({
    title: 'Товары',
    destroyMutation,
    columns: {
      category: {
        title: 'Категория',
        render: getRelationLink({ path: '/product/categories' }),
      },
      name: {
        title: 'Название',
        render: renderName,
      },
      status: {
        title: 'Статус',
        width: 100,
        render: v => statuses[v] || v,
      },
      externalStatus: {
        title: 'Внешний статус',
        width: 100,
        render: v => externalStatuses[v] || v,
      },
      quantities: {
        hidden: true,
      },
    },
    transformColumns: ({ allWarehouses }: ProductsQueryResponse, columns) => {
      if (!allWarehouses) {
        return columns;
      }

      return [
        ...columns,
        ...allWarehouses.nodes.filter(Boolean).map(getWarehouseColumn),
        {
          key: 'unit',
          dataIndex: 'unit',
          title: 'Ед.',
          width: 80,
          render: v => units[v] || v,
        },
      ];
    },
    // TODO: need refactoring
    Header: p => [
      <Filters {...p} key="filters" />,
      p.location.query.showGroupActions === 'yes' && (
        <Actions
          {...p}
          categoryId={p.categoryId}
          setCategoryId={p.setCategoryId}
          scaleId={p.scaleId}
          setScaleId={p.setScaleId}
          onToCategory={() => {
            Promise.all(
              p.selectedRowKeys.map(id =>
                updateMutation.commit(p.context.environment, id, { categoryId: p.categoryId })
              )
            )
              .then(showSuccess)
              .then(() => {
                p.setSelectedRowKeys([]);
                p.router.push(set('query.reload', +new Date(), p.location));
              })
              .catch(showErrors);
          }}
          onToScale={() => {
            p.selectedRowKeys.forEach(id => {
              const product = p.rows.nodes.find(n => n && n.id === id);
              const data = {
                productId: product.rowId,
                scaleId: p.scaleId,
              };

              createScaleProductMutation
                .commit(p.context.environment, data)
                .then(showSuccess)
                .catch(showErrors);
            });
          }}
          key="actions"
        />
      ),
    ],
  }),
  withState('selectedRowKeys', 'setSelectedRowKeys', []),
  withState('categoryId', 'setCategoryId', null),
  withState('scaleId', 'setScaleId', null),
  withProps(props => ({
    pageActions: {
      warehouses: {
        type: 'link',
        to: '/warehouses',
        children: 'Склады',
        icon: 'home',
      },
      productGroups: {
        type: 'link',
        to: '/product/groups',
        children: 'Места хранения',
        icon: 'appstore-o',
      },
      productCategories: {
        type: 'link',
        to: '/product/categories',
        children: 'Категории',
        icon: 'appstore-o',
      },
      groupActions: {
        type: 'button',
        children: 'Групповые действия',
        onClick: () => {
          props.router.push(transform('query.showGroupActions', v => (v === 'yes' ? 'no' : 'yes'), props.location));
        },
        icon: 'appstore-o',
      },
    },
    tableProps: {
      rowSelection: props.location.query.showGroupActions === 'yes' && {
        selectedRowKeys: props.selectedRowKeys,
        onChange: v => props.setSelectedRowKeys(v),
      },
    },
  }))
);
