/**
 * @flow
 * @relayHash 608e777b4353cf51796cb94743e0d106
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductExternalStatus = "ALWAYS_AVAILABLE" | "AVAILABLE" | "BY_REQUEST" | "FROM_PARENT" | "HIDDEN" | "%future added value";
export type ProductStatus = "ACTIVE" | "ARCHIVE" | "DEPRECATED" | "%future added value";
export type ProductUnit = "KG" | "PCS" | "%future added value";
export type CreateProductInput = {
  clientMutationId?: ?string,
  product: ProductInput,
};
export type ProductInput = {
  rowId?: ?number,
  parentId?: ?number,
  name: string,
  barcodes?: ?$ReadOnlyArray<?string>,
  unit?: ?ProductUnit,
  status?: ?ProductStatus,
  pcsWeight?: ?any,
  shelfLife?: ?IntervalInput,
  meta?: ?any,
  packingTolerance?: ?any,
  requiresPackaging?: ?boolean,
  ownPackaging?: ?boolean,
  tags?: ?$ReadOnlyArray<?string>,
  categoryId?: ?number,
  ownProduction?: ?boolean,
  externalStatus?: ?ProductExternalStatus,
  availableQuantityThreshold?: ?any,
  country?: ?string,
  syncId?: ?any,
  vat?: ?number,
};
export type IntervalInput = {
  seconds?: ?number,
  minutes?: ?number,
  hours?: ?number,
  days?: ?number,
  months?: ?number,
  years?: ?number,
};
export type CreateProductMutationVariables = {|
  input: CreateProductInput
|};
export type CreateProductMutationResponse = {|
  +createProduct: ?{|
    +product: ?{|
      +rowId: number
    |}
  |}
|};
export type CreateProductMutation = {|
  variables: CreateProductMutationVariables,
  response: CreateProductMutationResponse,
|};
*/


/*
mutation CreateProductMutation(
  $input: CreateProductInput!
) {
  createProduct(input: $input) {
    product {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "CreateProductInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "CreateProductInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "CreateProductMutation",
  "id": null,
  "text": "mutation CreateProductMutation(\n  $input: CreateProductInput!\n) {\n  createProduct(input: $input) {\n    product {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "CreateProductMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createProduct",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateProductPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "product",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "CreateProductMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "createProduct",
        "storageKey": null,
        "args": v1,
        "concreteType": "CreateProductPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "product",
            "storageKey": null,
            "args": null,
            "concreteType": "Product",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1e8d8effa1c3b611c4cfea7aa05fcec1';
module.exports = node;
