// @flow
/* global $NonMaybeType, $ElementType */

import React from 'react';
import PropTypes from 'prop-types';
import Page from 'components/Page';
import AttributeTable from 'components/AttributeTable';
import { getShowActions } from 'utils/crud';

import type { PageProps } from 'types';
import type { ShowWriteOffCategoryQueryResponse } from './__generated__/ShowWriteOffCategoryQuery.graphql';

type Props = PageProps & {
  data: $NonMaybeType<$ElementType<ShowWriteOffCategoryQueryResponse, 'data'>>,
};

const attributeRows = [
  {
    dataKey: 'name',
    label: 'Название',
  },
];

const Show = (props: Props) => (
  <Page title={`Причина списания #${props.data.rowId}`} actions={getShowActions(props)} backUrl={props.route.baseUrl}>
    <AttributeTable data={props.data} rows={attributeRows} />
  </Page>
);

Show.propTypes = {
  route: PropTypes.object.isRequired,
  data: PropTypes.shape({
    rowId: PropTypes.number.isRequired,
  }).isRequired,
};

export default Show;
