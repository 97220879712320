import { graphql } from 'relay-runtime';
import commitMutation from 'relay-commit-mutation-promise';

const mutation = graphql`
  mutation ResetWarehouseMovementItemQuantitiesMutation($input: ResetWarehouseMovementItemQuantitiesInput!) {
    resetWarehouseMovementItemQuantities(input: $input) {
      warehouseMovementItems {
        id
        quantity
      }
    }
  }
`;

const commit = (environment, targetDocumentId) =>
  commitMutation(environment, {
    mutation,
    variables: {
      input: { targetDocumentId },
    },
  });

export default { commit };
