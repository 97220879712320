import React from 'react';
import { compose, withProps } from 'recompact';
import { Link } from 'found';
import moment from 'moment';
import { Button, Tag } from 'antd';
import { renderEnum, renderPrice } from 'components/helpers';
import paymentMethods from '../ShopOrderDocuments/paymentMethods';
import renderStatus from '../ShopOrderDocuments/renderStatus';
import Filters from './Filters';
import PaymasterAmount from './PaymasterAmount';

export default compose(
  withProps((props) => ({
    title: 'Касса',
    actions: [],
    Header: Filters,
    columns: {
      paymentMethod: {
        title: 'Метод оплаты',
        width: 150,
        render: renderEnum(paymentMethods),
      },
      courierId: {
        title: 'Курьер',
        width: 150,
        render: (_, row) => {
          if (row.group && row.group.courier) {
            return `${row.group.courier.firstName} ${row.group.courier.lastName}`;
          }
          return null;
        },
      },
      status: {
        title: 'Статус',
        render: renderStatus,
        width: 150,
      },
      groupId: {
        title: 'Маршрут',
        width: 150,
        render: (id) => {
          if (!id) {
            return null;
          }

          const { location: loc } = props;
          const filter = { groupId: { equalTo: id } };

          return <Link to={{ pathname: loc.pathname, query: { filter: JSON.stringify(filter) } }}>#{id}</Link>;
        },
      },
      totalSum: {
        title: 'Итого',
        render: renderPrice,
        align: 'right',
        width: 150,
      },
      space: {
        title: '',
        sorter: false,
      },
      manualConfirmedAmount: {
        sorter: false,
        title: 'Сдано в кассу',
        width: 300,
        render: (_, row) => <PaymasterAmount data={row} />,
      },
    },
  }))
);
