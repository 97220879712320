exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-containers-CurrentQuantity-___render__hint___BEUcr {\n    height: 20px;\n    line-height: 20px;\n  }\n  ", ""]);

// exports
exports.locals = {
	"hint": "src-containers-CurrentQuantity-___render__hint___BEUcr"
};