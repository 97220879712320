import { Environment, RecordSource, Store } from 'relay-runtime';
import {
  RelayNetworkLayer,
  authMiddleware,
  urlMiddleware,
  loggerMiddleware,
  errorMiddleware,
} from 'react-relay-network-modern';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { notification } from 'antd';
import { changeToken } from './redux/actions';

export default store => {
  let lastMutationPromise = Promise.resolve();

  const network = new RelayNetworkLayer([
    urlMiddleware({
      url: process.env.GRAPHQL_ENDPOINT || '/graphql',
    }),
    process.env.NODE_ENV !== 'production' ? loggerMiddleware() : null,
    process.env.NODE_ENV !== 'production' ? errorMiddleware() : null,
    authMiddleware({
      token: () => store.getState().token,
    }),
    next => async req => {
      const group = (req.operation && req.operation.operationKind) || 'default';
      store.dispatch(showLoading(group));

      try {
        if (req.operation.operationKind === 'mutation') {
          await lastMutationPromise;
          lastMutationPromise = next(req);
          const res = await lastMutationPromise;
          store.dispatch(hideLoading(group));
          return res;
        }

        const res = await next(req);
        store.dispatch(hideLoading(group));
        return res;
      } catch (e) {
        if (e.res && e.res.status === 401) {
          store.dispatch(changeToken(null));
          window.location.reload();
          return null;
        }

        notification.error({
          message: 'Сетевая ошибка',
          description: e.message,
        });

        // eslint-disable-next-line no-console
        console.error(e);

        if (window.Raven) {
          window.Raven.captureException(e);
        }
      }

      return null;
    },
  ]);

  const source = new RecordSource();
  const environment = new Environment({
    network,
    store: new Store(source),
  });

  return environment;
};
