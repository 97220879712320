exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-___Page__root___pdLAi {\n  min-width: 1000px;\n}\n\n.src-components-___Page__header___3FQZR {\n  background: #fafafa;\n  margin-bottom: 2rem;\n  border-bottom: 1px solid #eee;\n  display: flex;\n  align-items: center;\n  padding: 0 30px;\n}\n\n.src-components-___Page__title___2AbvP {\n  flex: 1;\n  font-weight: 400;\n  font-size: 2rem;\n  line-height: 6rem;\n}\n\n.src-components-___Page__content___1dR4d {\n  margin: 0 30px;\n}\n\n.src-components-___Page__actions___j6NoB {\n  display: flex;\n}\n\n.src-components-___Page__action___wxk4n {\n  margin: 0 5px;\n}\n\n@media print {\n  .src-components-___Page__actions___j6NoB {\n    display: none;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-components-___Page__root___pdLAi",
	"header": "src-components-___Page__header___3FQZR",
	"title": "src-components-___Page__title___2AbvP",
	"content": "src-components-___Page__content___1dR4d",
	"actions": "src-components-___Page__actions___j6NoB",
	"action": "src-components-___Page__action___wxk4n"
};