import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateBalanceDocumentMutation($input: UpdateBalanceDocumentInput!) {
    updateBalanceDocument(input: $input) {
      balanceDocument {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateBalanceDocument', 'balanceDocument');
