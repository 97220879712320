/**
 * @flow
 * @relayHash bfc3f418ccb90674b479389b48c109e0
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type EmployeeSelect_items$ref = any;
type SupplyContractorSelect_items$ref = any;
export type EditSupplyDocumentQueryVariables = {|
  rowId: number
|};
export type EditSupplyDocumentQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +contractorId: number,
    +eventedAt: any,
    +comment: string,
    +ownerId: ?number,
    +organizationId: ?number,
  |},
  +allSupplyContractors: ?{|
    +nodes: $ReadOnlyArray<{|
      +$fragmentRefs: SupplyContractorSelect_items$ref
    |}>
  |},
  +allOrganizations: ?{|
    +nodes: $ReadOnlyArray<{|
      +rowId: number,
      +name: string,
    |}>
  |},
  +allEmployees: ?{|
    +nodes: $ReadOnlyArray<{|
      +$fragmentRefs: EmployeeSelect_items$ref
    |}>
  |},
  +viewer: ?{|
    +rowId: number
  |},
  +currentDate: ?any,
|};
export type EditSupplyDocumentQuery = {|
  variables: EditSupplyDocumentQueryVariables,
  response: EditSupplyDocumentQueryResponse,
|};
*/


/*
query EditSupplyDocumentQuery(
  $rowId: Int!
) {
  data: supplyDocumentByRowId(rowId: $rowId) {
    id
    rowId
    contractorId
    eventedAt
    comment
    ownerId
    organizationId
  }
  allSupplyContractors {
    nodes {
      ...SupplyContractorSelect_items
      id
    }
  }
  allOrganizations {
    nodes {
      rowId
      name
      id
    }
  }
  allEmployees {
    nodes {
      ...EmployeeSelect_items
      id
    }
  }
  viewer {
    rowId
    id
  }
  currentDate
}

fragment SupplyContractorSelect_items on SupplyContractor {
  rowId
  name
}

fragment EmployeeSelect_items on Employee {
  rowId
  firstName
  lastName
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "LinkedField",
  "alias": "data",
  "name": "supplyDocumentByRowId",
  "storageKey": null,
  "args": [
    {
      "kind": "Variable",
      "name": "rowId",
      "variableName": "rowId",
      "type": "Int!"
    }
  ],
  "concreteType": "SupplyDocument",
  "plural": false,
  "selections": [
    v1,
    v2,
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "contractorId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "eventedAt",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "comment",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "ownerId",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "organizationId",
      "args": null,
      "storageKey": null
    }
  ]
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "currentDate",
  "args": null,
  "storageKey": null
},
v6 = [
  v2,
  v4,
  v1
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "EditSupplyDocumentQuery",
  "id": null,
  "text": "query EditSupplyDocumentQuery(\n  $rowId: Int!\n) {\n  data: supplyDocumentByRowId(rowId: $rowId) {\n    id\n    rowId\n    contractorId\n    eventedAt\n    comment\n    ownerId\n    organizationId\n  }\n  allSupplyContractors {\n    nodes {\n      ...SupplyContractorSelect_items\n      id\n    }\n  }\n  allOrganizations {\n    nodes {\n      rowId\n      name\n      id\n    }\n  }\n  allEmployees {\n    nodes {\n      ...EmployeeSelect_items\n      id\n    }\n  }\n  viewer {\n    rowId\n    id\n  }\n  currentDate\n}\n\nfragment SupplyContractorSelect_items on SupplyContractor {\n  rowId\n  name\n}\n\nfragment EmployeeSelect_items on Employee {\n  rowId\n  firstName\n  lastName\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "EditSupplyDocumentQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      v3,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allSupplyContractors",
        "storageKey": null,
        "args": null,
        "concreteType": "SupplyContractorsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyContractor",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "SupplyContractorSelect_items",
                "args": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allOrganizations",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": true,
            "selections": [
              v2,
              v4
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allEmployees",
        "storageKey": null,
        "args": null,
        "concreteType": "EmployeesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": true,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "EmployeeSelect_items",
                "args": null
              }
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Employee",
        "plural": false,
        "selections": [
          v2
        ]
      },
      v5
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "EditSupplyDocumentQuery",
    "argumentDefinitions": v0,
    "selections": [
      v3,
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allSupplyContractors",
        "storageKey": null,
        "args": null,
        "concreteType": "SupplyContractorsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "SupplyContractor",
            "plural": true,
            "selections": v6
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allOrganizations",
        "storageKey": null,
        "args": null,
        "concreteType": "OrganizationsConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Organization",
            "plural": true,
            "selections": v6
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "allEmployees",
        "storageKey": null,
        "args": null,
        "concreteType": "EmployeesConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "nodes",
            "storageKey": null,
            "args": null,
            "concreteType": "Employee",
            "plural": true,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "firstName",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "lastName",
                "args": null,
                "storageKey": null
              },
              v1
            ]
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "viewer",
        "storageKey": null,
        "args": null,
        "concreteType": "Employee",
        "plural": false,
        "selections": [
          v2,
          v1
        ]
      },
      v5
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f66d65d1fb33c4029e3291c8f11c41d';
module.exports = node;
