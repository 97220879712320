/* eslint-disable react/prop-types */
// @flow

import React from 'react';
import { compose, withProps } from 'recompact';
import { getRelationLink } from 'components/helpers';
import destroyMutation from 'mutations/DestroyProductGroupMutation';
import InlineInput from 'components/InlineInput';
import updateMutation from 'mutations/UpdateProductGroupMutation';

export default compose(
  withProps(props => ({
    title: 'Места хранения',
    destroyMutation,
    columns: {
      warehouse: {
        title: 'Склад',
        width: 200,
        render: getRelationLink({ path: '/warehouses' }),
      },
      name: {
        title: 'Название',
      },
      position: {
        title: 'Позиция',
        width: 100,
        render: (value, row, i) => (
          <InlineInput
            tabIndex={i + 1}
            autoSelect
            onUpdate={v => updateMutation.commitValue(props.context.environment, row.id, 'position', v)}
            value={value}
          />
        ),
      },
    },
  }))
);
