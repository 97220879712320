import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Checkbox, Form, Input, Button } from 'antd';
import TextInput from 'components/TextInput';
import QuantityInput from 'components/QuantityInput';
import MobileCashierSelect from 'components/MobileCashierSelect';
import PageForm from 'components/PageForm';

const CourierForm = ({ title, form, handleSubmit, submitting }) => (
  <PageForm onSubmit={handleSubmit} title={title}>
    <Row gutter={24}>
      <Col span={12}>
        <Form.Item label="Фамилию">{form.getFieldDecorator('lastName')(<Input />)}</Form.Item>
        <Form.Item label="Имя">
          {form.getFieldDecorator('firstName', {
            rules: [
              {
                required: true,
                message: 'Введите имя',
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label="Отчество">{form.getFieldDecorator('middleName')(<Input />)}</Form.Item>
        <Form.Item label="Рабочий телефон">{form.getFieldDecorator('workPhone')(<Input />)}</Form.Item>
        <Form.Item label="Telegram чат ид">{form.getFieldDecorator('telegramChatId')(<TextInput />)}</Form.Item>
        <Form.Item label="Личная машина">{form.getFieldDecorator('privateCar')(<TextInput />)}</Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Водительские права">
          {form.getFieldDecorator('hasDriversLicense', { valuePropName: 'checked' })(<Checkbox />)}
        </Form.Item>
        <Form.Item label="Уволен">
          {form.getFieldDecorator('fired', { valuePropName: 'checked' })(<Checkbox />)}
        </Form.Item>
        <Form.Item label="Контакты курьера не отправляются">
          {form.getFieldDecorator('hideContactsFromClients', { valuePropName: 'checked' })(<Checkbox />)}
        </Form.Item>
        <Form.Item label="Базовая выплата за день">{form.getFieldDecorator('basePayoff')(<QuantityInput />)}</Form.Item>
        <Form.Item label="Выплата за переработку">{form.getFieldDecorator('extraPayoff')(<QuantityInput />)}</Form.Item>
        <Form.Item label="Терминал мобильного кассира">{form.getFieldDecorator('mobileCashierId')(<MobileCashierSelect />)}</Form.Item>
      </Col>
    </Row>
    <Form.Item>
      <Button disabled={submitting} type="primary" htmlType="submit">
        Сохранить
      </Button>
    </Form.Item>
  </PageForm>
);

CourierForm.propTypes = {
  title: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default CourierForm;
