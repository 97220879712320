/**
 * @flow
 * @relayHash 4964ca777453185398770a984b8146bd
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AllShopDepositTransactionCondition = {
  userId?: ?string,
  userOwner?: ?string,
  createdAt?: ?any,
  value?: ?any,
  documentId?: ?number,
  replenishmentId?: ?number,
  stepValue?: ?any,
  shopId?: ?number,
};
export type DepositTransactionsQueryVariables = {|
  condition: AllShopDepositTransactionCondition
|};
export type DepositTransactionsQueryResponse = {|
  +allAllShopDepositTransactions: ?{|
    +nodes: $ReadOnlyArray<{|
      +createdAt: ?any,
      +value: ?any,
      +stepValue: ?any,
      +documentId: ?number,
      +replenishmentId: ?number,
    |}>
  |}
|};
export type DepositTransactionsQuery = {|
  variables: DepositTransactionsQueryVariables,
  response: DepositTransactionsQueryResponse,
|};
*/


/*
query DepositTransactionsQuery(
  $condition: AllShopDepositTransactionCondition!
) {
  allAllShopDepositTransactions(condition: $condition) {
    nodes {
      createdAt
      value
      stepValue
      documentId
      replenishmentId
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "condition",
    "type": "AllShopDepositTransactionCondition!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "allAllShopDepositTransactions",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "condition",
        "variableName": "condition",
        "type": "AllShopDepositTransactionCondition"
      }
    ],
    "concreteType": "AllShopDepositTransactionsConnection",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "nodes",
        "storageKey": null,
        "args": null,
        "concreteType": "AllShopDepositTransaction",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "createdAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "value",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "stepValue",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "documentId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "replenishmentId",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DepositTransactionsQuery",
  "id": null,
  "text": "query DepositTransactionsQuery(\n  $condition: AllShopDepositTransactionCondition!\n) {\n  allAllShopDepositTransactions(condition: $condition) {\n    nodes {\n      createdAt\n      value\n      stepValue\n      documentId\n      replenishmentId\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DepositTransactionsQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DepositTransactionsQuery",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '37f9f74b75112d96662563fc0e162768';
module.exports = node;
