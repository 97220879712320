import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateWarehouseMutation($input: UpdateWarehouseInput!) {
    updateWarehouse(input: $input) {
      warehouse {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateWarehouse', 'warehouse');
