import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateScaleMutation($input: UpdateScaleInput!) {
    updateScale(input: $input) {
      scale {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateScale', 'scale');
