import { graphql } from 'relay-runtime';
import { makeUpdateMutation } from './helpers';

const mutation = graphql`
  mutation UpdateProductMutation($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      product {
        rowId
      }
    }
  }
`;

export default makeUpdateMutation(mutation, 'updateProduct', 'product');
