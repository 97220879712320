import moment from 'moment';

export default (nextVariables, match) => {
  const limit = moment().subtract(30, 'minutes');

  return {
    filter: {
      lastPositionedAt: {
        greaterThanOrEqualTo: limit,
      },
    },
  }
}
