import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroySupplyContractorMutation($input: DeleteSupplyContractorInput!) {
    deleteSupplyContractor(input: $input) {
      deletedSupplyContractorId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteSupplyContractor', 'deletedSupplyContractorId');
