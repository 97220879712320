import React from 'react';
import PropTypes from 'prop-types';
import { graphql, createFragmentContainer } from 'react-relay';
import { Select } from 'antd';

const filter = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

const CourierSelect = ({ items, ...props }) => (
  <Select placeholder="Курьер" showSearch optionFilterProp="children" filterOption={filter} {...props}>
    {items.map(courier => (
      <Select.Option value={courier.rowId} key={courier.rowId}>{`${courier.lastName} ${
        courier.firstName
      }`}</Select.Option>
    ))}
  </Select>
);

CourierSelect.propTypes = {
  items: PropTypes.array.isRequired,
};

export default createFragmentContainer(
  CourierSelect,
  graphql`
    fragment CourierSelectShopOrderDocumentMap_items on Courier @relay(plural: true) {
      rowId
      firstName
      lastName
      hasDriversLicense
    }
  `
);
