/**
 * @flow
 * @relayHash 7223969f9b49966be58bcc1ed145d256
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SyncAt$ref = any;
export type SyncAtQueryVariables = {|
  rowId: number
|};
export type SyncAtQueryResponse = {|
  +data: ?{|
    +$fragmentRefs: SyncAt$ref
  |}
|};
export type SyncAtQuery = {|
  variables: SyncAtQueryVariables,
  response: SyncAtQueryResponse,
|};
*/


/*
query SyncAtQuery(
  $rowId: Int!
) {
  data: scaleByRowId(rowId: $rowId) {
    ...SyncAt
    id
  }
}

fragment SyncAt on Scale {
  rowId
  syncedAt
  syncError
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId",
    "type": "Int!"
  }
];
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "SyncAtQuery",
  "id": null,
  "text": "query SyncAtQuery(\n  $rowId: Int!\n) {\n  data: scaleByRowId(rowId: $rowId) {\n    ...SyncAt\n    id\n  }\n}\n\nfragment SyncAt on Scale {\n  rowId\n  syncedAt\n  syncError\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "SyncAtQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "scaleByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "Scale",
        "plural": false,
        "selections": [
          {
            "kind": "FragmentSpread",
            "name": "SyncAt",
            "args": null
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "SyncAtQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "scaleByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "Scale",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "rowId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "syncedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "syncError",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3cc8d4a5e38813e72ec19bd194679b95';
module.exports = node;
