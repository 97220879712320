import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { graphql, createRefetchContainer } from 'react-relay';
import AutoReload from 'components/AutoReload';

const SyncAt = ({ data, relay }) => (
  <React.Fragment>
    <AutoReload refetch={() => relay.refetch(data.rowId)} />
    <div>{moment(data.syncedAt).fromNow()}</div>
    {data.syncError && <div>Ошибка: {data.syncError}</div>}
  </React.Fragment>
);

SyncAt.propTypes = {
  data: PropTypes.shape({}).isRequired,
  relay: PropTypes.object.isRequired,
};

export default createRefetchContainer(
  SyncAt,
  graphql`
    fragment SyncAt on Scale {
      rowId
      syncedAt
      syncError
    }
  `,
  graphql`
    query SyncAtQuery($rowId: Int!) {
      data: scaleByRowId(rowId: $rowId) {
        ...SyncAt
      }
    }
  `
);
