import { graphql } from 'relay-runtime';
import commitMutation from 'relay-commit-mutation-promise';

const mutation = graphql`
  mutation SendCourierTelegramMessageMutation($input: SendCourierTelegramMessageInput!) {
    sendCourierTelegramMessage(input: $input) {
      clientMutationId
    }
  }
`;

const commit = (environment, courierId, text, options) => {
  const config = {
    mutation,
    variables: {
      input: { courierId, text },
    },
    ...options,
  };

  return commitMutation(environment, config);
};

export default { commit };
