exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-___StatusTag__root___1udxv {\n  display: inline-block;\n  padding: 2px 6px;\n  margin: -2px -6px;\n  border-radius: 3px;\n}\n\n.src-components-___StatusTag__success___12Q5R {\n  background: green;\n  color: white;\n}\n\n.src-components-___StatusTag__failed___FaUes {\n  background: #ff6c6c;\n  color: white;\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-components-___StatusTag__root___1udxv",
	"success": "src-components-___StatusTag__success___12Q5R",
	"failed": "src-components-___StatusTag__failed___FaUes"
};