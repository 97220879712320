import { Form } from 'antd';
import { compose, withProps, withState } from 'recompact';
import { isFunction } from 'lodash';
import { showErrors } from 'utils/notifications';

const defaultOnCompleted = ({ rowId }, { router, route }) => {
  router.push(`${route.baseUrl}/${rowId}`);
};

export default ({ mutation, normalize, onCompleted = defaultOnCompleted, formOptions, mutationOptions }) =>
  compose(
    Form.create(formOptions),
    withState('submitting', 'setSubmitting', false),
    withProps(props => ({
      handleSubmit: e => {
        e.preventDefault();
        props.setSubmitting(true);
        props.form.validateFieldsAndScroll(async (err, values) => {
          if (err) {
            // TODO: add error handler
            props.setSubmitting(false);
            return;
          }

          const variables = {
            ...props.additionalValues,
            ...values,
          };

          try {
            const data = await mutation.commit(
              props.context.environment,
              normalize ? normalize(variables) : variables,
              isFunction(mutationOptions) ? mutationOptions(props) : mutationOptions
            );

            if (onCompleted) {
              onCompleted(data, props);
            }
          } catch (requestError) {
            showErrors(requestError);
            props.setSubmitting(false);
          }
        });
      },
    }))
  );
