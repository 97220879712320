exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-___AttributeTable__label___SNvHx,\n.src-components-___AttributeTable__value___2WRTf {\n  font-size: 13px;\n  border-bottom: 1px solid #ccc;\n  padding: 0.5rem 0.7rem;\n}\n\n.src-components-___AttributeTable__label___SNvHx {\n  padding-right: 20px;\n  min-width: 200px;\n}\n", ""]);

// exports
exports.locals = {
	"label": "src-components-___AttributeTable__label___SNvHx",
	"value": "src-components-___AttributeTable__value___2WRTf"
};