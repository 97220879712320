import { graphql } from 'relay-runtime';

export default graphql`
  query ShowCourierQuery($rowId: Int!, $showGroupCondition: ShopOrderGroupCondition!) {
    data: courierByRowId(rowId: $rowId) {
      id
      rowId
      firstName
      lastName
      privateCar
      middleName
      telegramChatId
      workPhone
      hasDriversLicense
    }

    groups: allShopOrderGroups(condition: $showGroupCondition) {
      nodes {
        ...GroupsCouriersShow
      }
    }
  }
`;
