import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import InlineForm from 'components/InlineForm';
import ProductSelect from 'containers/ProductSelect';
import './NewItem.css';

const NewItem = ({ form, context, handleSubmit, saveRef }) => (
  <div styleName="root">
    <h3 style={{ marginTop: 50 }}>Добавить позицию</h3>
    <InlineForm onSubmit={handleSubmit}>
      <Form.Item label="Позиция">
        {form.getFieldDecorator('productId')(
          <ProductSelect environment={context.environment} showAction={['click', 'focus']} autoFocus ref={saveRef} />
        )}
      </Form.Item>
    </InlineForm>
  </div>
);

NewItem.propTypes = {
  form: PropTypes.shape({
    getFieldValue: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
    setFieldsValue: PropTypes.func.isRequired,
  }).isRequired,
  context: PropTypes.shape({
    environment: PropTypes.object.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  saveRef: PropTypes.func.isRequired,
};

export default NewItem;
