import { getRoutes } from 'utils/crud';

import Index from './index';
import New from './New';
import Edit from './Edit';
import Show from './Show';

import IndexQuery from './ProductGroupsQuery';
import NewQuery from './New/NewProductGroupQuery';
import EditQuery from './Edit/EditProductGroupQuery';
import ShowQuery from './Show/ShowProductGroupQuery';

export default getRoutes({
  baseUrl: '/product/groups',
  Index,
  IndexQuery,
  Edit,
  EditQuery,
  Show,
  ShowQuery,
  New,
  NewQuery,
});
