/**
 * @flow
 * @relayHash 8f60d0625f30f6d77d507703755f92f4
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ShopOrderDocumentPaymentMethod = "BY_CARD" | "BY_CASH" | "DEPOSIT" | "EMPLOYEE" | "ONLINE" | "%future added value";
export type ShopOrderDocumentStatus = "CANCELED" | "COMPLETED" | "DELIVERED" | "PACKING" | "PENDING" | "SHIPPED" | "%future added value";
export type UpdateShopOrderDocumentInput = {
  clientMutationId?: ?string,
  id: string,
  shopOrderDocumentPatch: ShopOrderDocumentPatch,
};
export type ShopOrderDocumentPatch = {
  rowId?: ?number,
  paymentMethod?: ?ShopOrderDocumentPaymentMethod,
  status?: ?ShopOrderDocumentStatus,
  eventedAt?: ?any,
  totalSumMultiplier?: ?any,
  discount?: ?any,
  itemSum?: ?any,
  deliveryCost?: ?any,
  totalWeight?: ?any,
  meta?: ?any,
  comment?: ?string,
  groupId?: ?number,
  owner?: ?string,
  deliveryAt?: ?any,
  deliveryMaxDelay?: ?IntervalInput,
  deliveryPoint?: ?PointInput,
  sourceUrl?: ?string,
  printed?: ?boolean,
  ready?: ?boolean,
  deliveryPointBlocked?: ?boolean,
  groupPosition?: ?number,
  change?: ?any,
  confirmedAmount?: ?any,
  userId?: ?string,
  shopId?: ?number,
  asap?: ?boolean,
  deliveryNoticeAt?: ?any,
  ecoPack?: ?boolean,
  withoutOverweight?: ?boolean,
  withGlassCoupon?: ?boolean,
  deliveredAt?: ?any,
  byCardPaymentConfirmed?: ?boolean,
  userRating?: ?number,
  userRatingAt?: ?any,
  userReview?: ?string,
  userReviewAt?: ?any,
  userReviewImages?: ?$ReadOnlyArray<?string>,
  userReviewSolvedEmployeeId?: ?number,
  userReviewSolveComment?: ?string,
  readyAt?: ?any,
  shippedAt?: ?any,
  mainWarehouseId?: ?number,
  manualConfirmedAmount?: ?any,
  packageCount?: ?number,
  checkEmployeeId?: ?number,
  clientPhone?: ?string,
  clientEmail?: ?string,
  lastSentEvent?: ?string,
};
export type IntervalInput = {
  seconds?: ?number,
  minutes?: ?number,
  hours?: ?number,
  days?: ?number,
  months?: ?number,
  years?: ?number,
};
export type PointInput = {
  x: number,
  y: number,
};
export type UpdateShopOrderDocument2MutationVariables = {|
  input: UpdateShopOrderDocumentInput
|};
export type UpdateShopOrderDocument2MutationResponse = {|
  +updateShopOrderDocument: ?{|
    +shopOrderDocument: ?{|
      +rowId: number
    |}
  |}
|};
export type UpdateShopOrderDocument2Mutation = {|
  variables: UpdateShopOrderDocument2MutationVariables,
  response: UpdateShopOrderDocument2MutationResponse,
|};
*/


/*
mutation UpdateShopOrderDocument2Mutation(
  $input: UpdateShopOrderDocumentInput!
) {
  updateShopOrderDocument(input: $input) {
    shopOrderDocument {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateShopOrderDocumentInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateShopOrderDocumentInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateShopOrderDocument2Mutation",
  "id": null,
  "text": "mutation UpdateShopOrderDocument2Mutation(\n  $input: UpdateShopOrderDocumentInput!\n) {\n  updateShopOrderDocument(input: $input) {\n    shopOrderDocument {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateShopOrderDocument2Mutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateShopOrderDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateShopOrderDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shopOrderDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderDocument",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateShopOrderDocument2Mutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateShopOrderDocument",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateShopOrderDocumentPayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "shopOrderDocument",
            "storageKey": null,
            "args": null,
            "concreteType": "ShopOrderDocument",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '84c8c0d9f300b92f93fe0cfb0838fef5';
module.exports = node;
