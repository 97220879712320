import { graphql } from 'relay-runtime';

export default graphql`
  query ShowScaleQuery($rowId: Int!) {
    data: scaleByRowId(rowId: $rowId) {
      id
      rowId
      name
      addr
      comment

      ...SyncAt

      items: scaleProductsByScaleId {
        nodes {
          id
          position

          firstLine
          lastLine

          product: productByProductId {
            rowId
            name
          }
        }
      }
    }
  }
`;
