/* eslint-disable react/prop-types */
// @flow

import React from 'react';
import { compose, withProps } from 'recompact';
import InlineInput from 'components/InlineInput';
import updateMutation from 'mutations/UpdateProductWarehouseQuantityMutation';
import Filters from './Filters';

const getWarehouseColumn = context => warehouse => ({
  key: warehouse.id,
  title: warehouse.name,
  width: 120,
  render: (_, { quantities }) => {
    const quantity = quantities.nodes.find(n => n.warehouseId === warehouse.rowId);

    if (!quantity) {
      return '–';
    }

    return (
      <InlineInput
        autoSelect
        isEqual={(a, b) => parseFloat(a) === parseFloat(b)}
        onUpdate={v => updateMutation.commitValue(context.environment, quantity.id, 'sustainableValue', v)}
        value={quantity.sustainableValue}
      />
    );
  },
});

export default compose(
  withProps({
    title: 'Поддерживаемые остатки',
    actions: [],
    Header: Filters,
    columns: {
      name: {
        title: 'Название',
      },
    },
    transformColumns: ({ allWarehouses, context }, columns) => {
      if (!allWarehouses) {
        return columns;
      }

      return [...columns, ...allWarehouses.nodes.filter(Boolean).map(getWarehouseColumn(context))];
    },
    pageActions: { create: null },
  })
);
