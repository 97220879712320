// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'unchanged';
import './AttributeTable.css';

type Row = {
  key?: string,
  dataKey?: string,
  label?: string,
  render?: Function,
};

type Props = {
  data: Object,
  rows: Row[],
};

const AttributeTable = ({ data, rows, ...props }: Props) => (
  <table>
    <tbody>
      {rows.map(row => {
        const value = get(row.dataKey || row.key, data);

        return (
          <tr key={row.dataKey || row.key}>
            <th styleName="label">{row.label || row.dataKey}</th>
            <td styleName="value">{row.render ? row.render(value, data, props) : value}</td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

AttributeTable.propTypes = {
  data: PropTypes.object.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      dataKey: PropTypes.string,
      label: PropTypes.string,
      render: PropTypes.function,
    }).isRequired
  ).isRequired,
};

export default AttributeTable;
