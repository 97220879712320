import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { compose, withProps } from 'recompact';
import withFiltersEnhance from 'hoc/withFiltersEnhance';
import FilterForm from 'components/FilterForm';
import { FilterInput, FilterCheckbox, FilterDatePicker, FilterSelect } from 'components/filterControls';
import { getSelectItems } from 'components/helpers';
import statuses from '../ShopOrderDocuments/statuses';
import transformFilter from './defaultFilter';

const Filters = ({ form, handleSubmit, handleClear }) => (
  <FilterForm onSubmit={handleSubmit} onClear={handleClear}>
    <Form.Item label="Номер">{form.getFieldDecorator('rowId')(<FilterInput filterType="equalTo" />)}</Form.Item>
    <Form.Item label="Маршрут">{form.getFieldDecorator('groupId')(<FilterInput filterType="equalTo" />)}</Form.Item>
    <Form.Item label="Ид курьера">{form.getFieldDecorator('courierId')(<FilterInput filterType="equalTo" />)}</Form.Item>
    <Form.Item label="Метод оплаты">
      {form.getFieldDecorator('paymentMethod')(
        <FilterSelect form={form} submit={handleSubmit} filterType="in" mode="multiple">
          {getSelectItems({
            BY_CARD: 'Картой',
            BY_CASH: 'Наличные',
          })}
        </FilterSelect>
      )}
    </Form.Item>
    <Form.Item label="Статус">
      {form.getFieldDecorator('status')(
        <FilterSelect form={form} submit={handleSubmit} filterType="in" mode="multiple">
          {getSelectItems(statuses)}
        </FilterSelect>
      )}
    </Form.Item>
    <Form.Item label="Дата">
      {form.getFieldDecorator('deliveryOn')(<FilterDatePicker filterType="equalTo" />)}
    </Form.Item>
  </FilterForm>
);

Filters.propTypes = {
  form: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClear: PropTypes.func,
};

Filters.defaultProps = {
  handleClear: null,
};

const enhance = compose(
  withProps({ transformFilter }),
  withFiltersEnhance(['rowId', 'paymentMethod', 'status', 'deliveryOn', 'groupId']),
);

export default enhance(Filters);
