import { graphql } from 'relay-runtime';

export default graphql`
  query ShowSupplyDocumentQuery($rowId: Int!, $orderBy: [SupplyItemsOrderBy!]) {
    data: supplyDocumentByRowId(rowId: $rowId) {
      id
      rowId
      eventedAt
      totalSum
      comment

      contractor: supplyContractorByContractorId {
        rowId
        name
      }

      organization: organizationByOrganizationId {
        rowId
        name
      }

      owner: employeeByOwnerId {
        firstName
        lastName
      }

      productionItems: supplyProductionItemsByDocumentId {
        nodes {
          id
          quantity
          overrideQuantity
          fixed
          product: productByProductId {
            rowId
            name
            unit
          }
        }
      }

      items: supplyItemsByDocumentId(orderBy: $orderBy) {
        nodes {
          id

          productId
          warehouseId

          product: productByProductId {
            rowId
            name
          }

          willBeSelledAt
          quantity
          price
        }
      }
    }

    allWarehouses {
      nodes {
        rowId
        name
      }
    }
  }
`;
