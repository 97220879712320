/**
 * @flow
 * @relayHash b1ca140e74dbfba6c04c7f7243f00392
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateProductWarehouseQuantityInput = {
  clientMutationId?: ?string,
  id: string,
  productWarehouseQuantityPatch: ProductWarehouseQuantityPatch,
};
export type ProductWarehouseQuantityPatch = {
  productId?: ?number,
  warehouseId?: ?number,
  value?: ?any,
  sustainableValue?: ?any,
};
export type UpdateProductWarehouseQuantityMutationVariables = {|
  input: UpdateProductWarehouseQuantityInput
|};
export type UpdateProductWarehouseQuantityMutationResponse = {|
  +updateProductWarehouseQuantity: ?{|
    +productWarehouseQuantity: ?{|
      +id: string,
      +sustainableValue: any,
      +value: any,
    |}
  |}
|};
export type UpdateProductWarehouseQuantityMutation = {|
  variables: UpdateProductWarehouseQuantityMutationVariables,
  response: UpdateProductWarehouseQuantityMutationResponse,
|};
*/


/*
mutation UpdateProductWarehouseQuantityMutation(
  $input: UpdateProductWarehouseQuantityInput!
) {
  updateProductWarehouseQuantity(input: $input) {
    productWarehouseQuantity {
      id
      sustainableValue
      value
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateProductWarehouseQuantityInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateProductWarehouseQuantity",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "UpdateProductWarehouseQuantityInput!"
      }
    ],
    "concreteType": "UpdateProductWarehouseQuantityPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "productWarehouseQuantity",
        "storageKey": null,
        "args": null,
        "concreteType": "ProductWarehouseQuantity",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "sustainableValue",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "value",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateProductWarehouseQuantityMutation",
  "id": null,
  "text": "mutation UpdateProductWarehouseQuantityMutation(\n  $input: UpdateProductWarehouseQuantityInput!\n) {\n  updateProductWarehouseQuantity(input: $input) {\n    productWarehouseQuantity {\n      id\n      sustainableValue\n      value\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateProductWarehouseQuantityMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateProductWarehouseQuantityMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e5c23dbd258d7ed7d34cc67f0c1ccae1';
module.exports = node;
