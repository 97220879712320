import { getIds } from 'utils/prepareVariables';

export default (nextVariables, match) => ({
  ...nextVariables,
  filter: {
    rowId: {
      in: getIds(match) || [],
    },
  },
});
