import { graphql } from 'relay-runtime';

export default graphql`
  query ShowShopOrderDocumentQuery($rowId: Int!) {
    data: shopOrderDocumentByRowId(rowId: $rowId) {
      id
      rowId
      eventedAt
      deliveryAt
      itemSum
      owner
      paymentMethod
      discount
      deliveryCost
      deliveryPoint { x, y }
      totalSumMultiplier
      totalSum
      confirmedAmount
      totalWeight
      expectedTotalSum
      sourceUrl
      status
      label
      comment
      ...MkbPayments_order
      ...DepositTransactions_order

      warehouseByMainWarehouseId {
        name
      }

      group: shopOrderGroupByGroupId {
        courier: courierByCourierId {
          rowId
          firstName
          lastName
          privateCar
        }
      }

      ...MkbPayments_order

      items: shopOrderItemsByDocumentId(orderBy: [DEFICIT_DESC]) {
        nodes {
          id
          rowId
          warehouseId
          printed
          deficit
          ...ItemCommentInput

          product: productByProductId {
            rowId
            name
            unit
            vat
          }

          orderedQuantity
          quantity

          price
          priceMultiplier

          returnStatus
        }
      }
    }
  }
`;
