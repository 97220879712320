/**
 * @flow
 * @relayHash 6825d615c5c2c6aa01118d77a85fb6c9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SyncAt$ref = any;
export type ShowScaleQueryVariables = {|
  rowId: number
|};
export type ShowScaleQueryResponse = {|
  +data: ?{|
    +id: string,
    +rowId: number,
    +name: string,
    +addr: ?string,
    +comment: string,
    +items: {|
      +nodes: $ReadOnlyArray<{|
        +id: string,
        +position: number,
        +firstLine: ?string,
        +lastLine: ?string,
        +product: ?{|
          +rowId: number,
          +name: string,
        |},
      |}>
    |},
    +$fragmentRefs: SyncAt$ref,
  |}
|};
export type ShowScaleQuery = {|
  variables: ShowScaleQueryVariables,
  response: ShowScaleQueryResponse,
|};
*/


/*
query ShowScaleQuery(
  $rowId: Int!
) {
  data: scaleByRowId(rowId: $rowId) {
    id
    rowId
    name
    addr
    comment
    ...SyncAt
    items: scaleProductsByScaleId {
      nodes {
        id
        position
        firstLine
        lastLine
        product: productByProductId {
          rowId
          name
          id
        }
      }
    }
  }
}

fragment SyncAt on Scale {
  rowId
  syncedAt
  syncError
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "rowId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "addr",
  "args": null,
  "storageKey": null
},
v6 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "comment",
  "args": null,
  "storageKey": null
},
v7 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "position",
  "args": null,
  "storageKey": null
},
v8 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "firstLine",
  "args": null,
  "storageKey": null
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastLine",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ShowScaleQuery",
  "id": null,
  "text": "query ShowScaleQuery(\n  $rowId: Int!\n) {\n  data: scaleByRowId(rowId: $rowId) {\n    id\n    rowId\n    name\n    addr\n    comment\n    ...SyncAt\n    items: scaleProductsByScaleId {\n      nodes {\n        id\n        position\n        firstLine\n        lastLine\n        product: productByProductId {\n          rowId\n          name\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment SyncAt on Scale {\n  rowId\n  syncedAt\n  syncError\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ShowScaleQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "scaleByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "Scale",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "FragmentSpread",
            "name": "SyncAt",
            "args": null
          },
          {
            "kind": "LinkedField",
            "alias": "items",
            "name": "scaleProductsByScaleId",
            "storageKey": null,
            "args": null,
            "concreteType": "ScaleProductsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "ScaleProduct",
                "plural": true,
                "selections": [
                  v2,
                  v7,
                  v8,
                  v9,
                  {
                    "kind": "LinkedField",
                    "alias": "product",
                    "name": "productByProductId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      v3,
                      v4
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ShowScaleQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "data",
        "name": "scaleByRowId",
        "storageKey": null,
        "args": v1,
        "concreteType": "Scale",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          v6,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "syncedAt",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "syncError",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": "items",
            "name": "scaleProductsByScaleId",
            "storageKey": null,
            "args": null,
            "concreteType": "ScaleProductsConnection",
            "plural": false,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "nodes",
                "storageKey": null,
                "args": null,
                "concreteType": "ScaleProduct",
                "plural": true,
                "selections": [
                  v2,
                  v7,
                  v8,
                  v9,
                  {
                    "kind": "LinkedField",
                    "alias": "product",
                    "name": "productByProductId",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Product",
                    "plural": false,
                    "selections": [
                      v3,
                      v4,
                      v2
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f5979aad7c10a7e082797052747d604';
module.exports = node;
