import React from 'react';
import PropTypes from 'prop-types';
import ProgressButton from 'components/ProgressButton';
import UpdateMutation from 'mutations/UpdateShopOrderDocumentMutation';
import { Icon } from 'antd';
import moment from 'moment';
import User from './User';
import paymentMethods from '../paymentMethods';
import getLabel from '../getLabel';
import './CourierPrint.css';

const getCourierName = (order) => {
  const courier = order.group && order.group.courier;

  if (!courier) {
    return '-';
  }

  return `${courier.firstName} ${courier.lastName}`;
};

const YandexInfo = ({ data }) => (
  <div>
    <div>Имя: {data.clientName}</div>
    <div>Телефон: {data.phone}</div>
    <div>Адрес: {data.address}</div>
    <div>Комментарий: {data.comment}</div>
    {data.change > 0 && <div>Сдача с {data.change}</div>}
  </div>
);

YandexInfo.propTypes = {
  data: PropTypes.object.isRequired,
};

const shopLabels = {
  myfresh: 'ФРЭШ',
  moscowfresh: 'MoscowFresh - Москоуфрэш',
  familyfriend: 'FamilyFriend - Фэмилифренд',
  edoque: 'Edoque - Едок',
};

const CourierPrint = ({ orders, context }) => (
  <div styleName="root">
    <ProgressButton
      styleName="button"
      onClick={() =>
        Promise.all(
          orders.nodes.map((order) =>
            UpdateMutation.commit(context.environment, order.rowId, {
              status: 'SHIPPED',
            })
          )
        )
      }
    >
      Закрыть
    </ProgressButton>
    {orders.nodes.map((order) => (
      <div key={order.rowId}>
        <div styleName="header">
          <div styleName="number">
            Заказ {order.asap && <Icon type="clock-circle" style={{ fontSize: 30 }} />}
            <br />№{getLabel(order)} (маршрут #{order.groupId})
            <br />
            <span
              style={{
                fontSize: 30,
                border: '2px solid #666',
                padding: 10,
                marginBottom: 20,
              }}
            >
              {shopLabels[order.owner] || '???'}
            </span>
          </div>
          {order.groupPosition !== null && <div styleName="position">{order.groupPosition}</div>}
          <div styleName="label">
            {order.label}
            <div styleName="current-time">
              {moment(new Date())
                .tz('Europe/Moscow')
                .format('HH:mm')}
            </div>
            <img
              styleName="barcode"
              src={`${process.env.BARCODE_ENDPOINT}/code128/200/80/order${order.rowId}`}
              alt={order.rowId}
              width={200}
              height={80}
            />
          </div>
        </div>
        <div>
          Доставить с:{' '}
          {moment(order.deliveryAt)
            .tz('Europe/Moscow')
            .format('HH:mm')}{' '}
          до
          {moment(order.deliveryAt)
            .add(order.deliveryMaxDelay.hours, 'hours')
            .add(order.deliveryMaxDelay.minutes, 'minutes')
            .tz('Europe/Moscow')
            .format('HH:mm')}
        </div>
        <div>Курьер: {getCourierName(order)}</div>
        <div>Дата: {moment(order.deliveryAt).format('DD.MM.YYYY')}</div>
        <div>
          Способ оплаты: {paymentMethods[order.paymentMethod]}
          {order.paymentMethod === 'DEPOSIT' && order.depositBalance >= 0 && <strong> (подтверждено)</strong>}
          {order.paymentMethod === 'ONLINE' && order.confirmedAmount >= order.totalSum && (
            <strong> (подтверждено)</strong>
          )}
          {order.yandexOrders.nodes.length != 0 && <string>Заказ ЯНДЕКС.ЕДА. Не требовать деньги.</string>}
        </div>
        <div>
          <strong>Сумма: {order.totalSum} руб</strong>
        </div>
        <div>
          <strong>Комментарий менеджера: {order.comment}</strong>
        </div>
        {order.yandexOrders.nodes.length === 0 ? (
          <User stockId={order.rowId} owner={order.owner} />
        ) : (
          <YandexInfo data={order.yandexOrders.nodes[0]} />
        )}
        <div styleName="footer" />
      </div>
    ))}
  </div>
);

CourierPrint.propTypes = {
  orders: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired,
};

export default CourierPrint;
