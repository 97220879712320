import { graphql } from 'relay-runtime';

export default graphql`
  query ProductGroupsQuery($first: Int!, $offset: Int!, $orderBy: [ProductGroupsOrderBy!]) {
    rows: allProductGroups(first: $first, offset: $offset, orderBy: $orderBy) {
      totalCount

      nodes {
        id
        rowId
        warehouse: warehouseByWarehouseId {
          rowId
          name
        }
        name
        position
      }
    }
  }
`;
