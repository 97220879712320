export const changeDefaultWarehouseId = value => ({
  type: 'CHANGE_DEFAULT_WAREHOUSE_ID',
  value,
});

export const changeToken = value => ({
  type: 'CHANGE_TOKEN',
  value,
});

export const signout = () => ({
  type: 'SIGNOUT',
});
