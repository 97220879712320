import { graphql } from 'relay-runtime';

export default graphql`
  query EditWriteOffDocumentQuery($rowId: Int!) {
    data: writeOffDocumentByRowId(rowId: $rowId) {
      id
    }

    allCategories: allWriteOffCategories {
      nodes {
        rowId
        name
      }
    }
  }
`;
