import { compose, withProps } from 'recompact';
import { Form } from 'antd';
import withCreateEnhance from 'hoc/withCreateEnhance';
import mutation from 'mutations/CreateShopDepositReplenishmentMutation';
import mapObject from 'fbjs/lib/mapObject';

export default compose(
  withProps({
    title: 'Создать новый вклад',
  }),
  withCreateEnhance({
    mutation,
    onCompleted: (_, { router }) => router.push('/shop/deposit/replenishments'),
    formOptions: {
      mapPropsToFields: props => mapObject(props.location.query, value => Form.createFormField({ value })),
    },
  })
);
