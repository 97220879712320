import { graphql } from 'relay-runtime';

export default graphql`
  query ShopOrderDocumentsQuery(
    $first: Int!
    $offset: Int!
    $orderBy: [ShopOrderDocumentsOrderBy!]
    $filter: ShopOrderDocumentFilter
  ) {
    rows: allShopOrderDocuments(first: $first, offset: $offset, orderBy: $orderBy, filter: $filter) {
      totalCount

      nodes {
        id
        rowId
        deliveryAt
        deadlineAt
        itemSum
        printed
        label
        status
        paymentMethod
        discount
        deliveryCost
        totalSumMultiplier
        totalWeight
        totalSum
        owner
      }
    }
  }
`;
