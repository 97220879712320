// @flow

import { preparePaginationVariables } from 'utils/pagination';
import type { PageProps } from 'types';

export const prepareVariables = preparePaginationVariables;
export const defaultPrepareShowVariables = (_, match) => ({ rowId: parseInt(match.params.rowId, 10), addedItemId: match.location.query.addedItemId });

type RouteData = {
  baseUrl: string,
  Index?: Function,
  IndexQuery: Function,
  Show?: Function,
  ShowQuery: Function,
  Edit?: Function,
  EditQuery: Function,
  New?: Function,
  NewQuery?: Function,
  indexPrepareVariables: Function,
  prepareShowVariables: Function,
};

export const getRoutes = ({
  baseUrl,
  Index,
  IndexQuery,
  Edit,
  EditQuery,
  New,
  NewQuery,
  Show,
  ShowQuery,
  indexPrepareVariables,
  prepareShowVariables,
}: RouteData) =>
  [
    Index && {
      path: baseUrl,
      Component: Index,
      query: IndexQuery,
      prepareVariables: indexPrepareVariables || prepareVariables,
      baseUrl,
    },
    New && {
      path: `${baseUrl}/new`,
      Component: New,
      query: NewQuery,
      baseUrl,
    },
    Edit && {
      path: `${baseUrl}/:rowId/edit`,
      Component: Edit,
      query: EditQuery,
      baseUrl,
      prepareVariables: defaultPrepareShowVariables,
    },
    Show && {
      path: `${baseUrl}/:rowId`,
      Component: Show,
      query: ShowQuery,
      prepareVariables: prepareShowVariables || defaultPrepareShowVariables,
      baseUrl,
    },
  ].filter(Boolean);

type ShowProps = PageProps & {
  data: Object,
};

type ShowMutations = {
  destroyMutation?: {
    commit: Function,
  },
  resetMutation?: {
    commit: Function,
  },
  copyMutation?: {
    commit: Function,
  },
};

export const getShowActions = (props: ShowProps, mutations: ShowMutations = {}) => {
  const actions = {};

  if (mutations.destroyMutation) {
    const { commit } = mutations.destroyMutation;

    actions.destroy = {
      type: 'confirmButton',
      componentType: 'danger',
      children: 'Удалить',
      icon: 'delete',
      confirm: {
        title: 'Уверены, что хотите удалить это?',
        onConfirm: () =>
          commit(props.context.environment, props.data.id).then(() => props.router.push(props.route.baseUrl)),
      },
    };
  }

  if (mutations.resetMutation) {
    const { commit } = mutations.resetMutation;

    actions.reset = {
      type: 'confirmButton',
      componentType: 'danger',
      children: 'Сбросить',
      icon: 'warning',
      confirm: {
        title: 'Уверены, что хотите сбросить все количества?',
        onConfirm: () => commit(props.context.environment, parseInt(props.params.rowId, 10)),
      },
    };
  }

  if (mutations.copyMutation && props.location.query.hideCopy === undefined) {
    const { commit } = mutations.copyMutation;

    actions.copy = {
      type: 'button',
      onClick: () =>
        commit(props.context.environment, parseInt(props.params.rowId, 10)).then(data =>
          props.router.push(`${props.route.baseUrl}/${data.rowId}?hideCopy`)
        ),
      icon: 'copy',
      children: 'Скопировать',
    };
  }

  actions.update = {
    type: 'link',
    to: `${props.location.pathname}/edit`,
    icon: 'edit',
    children: 'Изменить',
  };

  return actions;
};
