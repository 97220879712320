/**
 * @flow
 * @relayHash 085ab5f87e9c9221ff005eb03b6c5f2d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateScaleInput = {
  clientMutationId?: ?string,
  id: string,
  scalePatch: ScalePatch,
};
export type ScalePatch = {
  rowId?: ?number,
  name?: ?string,
  comment?: ?string,
  addr?: ?string,
  syncError?: ?string,
  syncedAt?: ?any,
};
export type UpdateScaleMutationVariables = {|
  input: UpdateScaleInput
|};
export type UpdateScaleMutationResponse = {|
  +updateScale: ?{|
    +scale: ?{|
      +rowId: number
    |}
  |}
|};
export type UpdateScaleMutation = {|
  variables: UpdateScaleMutationVariables,
  response: UpdateScaleMutationResponse,
|};
*/


/*
mutation UpdateScaleMutation(
  $input: UpdateScaleInput!
) {
  updateScale(input: $input) {
    scale {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateScaleInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateScaleInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateScaleMutation",
  "id": null,
  "text": "mutation UpdateScaleMutation(\n  $input: UpdateScaleInput!\n) {\n  updateScale(input: $input) {\n    scale {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateScaleMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateScale",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateScalePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "scale",
            "storageKey": null,
            "args": null,
            "concreteType": "Scale",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateScaleMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateScale",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateScalePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "scale",
            "storageKey": null,
            "args": null,
            "concreteType": "Scale",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '50bc02060f53b7617336757ae18086a8';
module.exports = node;
