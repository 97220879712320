import React from 'react'
import { renderEnum } from 'components/helpers';
import statuses from './statuses';
import './styles.css';

const renderStatus = renderEnum(statuses);

export default value => {
    const label = renderStatus(value);
    return value === 'CANCELED' ? <div styleName='canceled'>{label}</div> : label
}