import { compose } from 'recompact';
import { withRouter } from 'found';
import { connect } from 'react-redux';
import { merge } from 'unchanged';
import { showSuccess } from 'utils/notifications';
import withRef from 'hoc/withRef';
import withCreateEnhance from 'hoc/withCreateEnhance';
import mutation from 'mutations/CreateWarehouseMovementItemMutation';

const selector = (state, props) => ({
  defaultWarehouseId: parseInt(props.match.location.query.warehouseId || state.defaultWarehouseId, 10),
});

export default compose(
  withRouter,
  connect(selector),
  withRef,
  withCreateEnhance({
    mutation,
    normalize: data => ({
      ...data,
      productId: parseInt(data.productId, 10),
    }),
    onCompleted: ({ rowId, warehouseId }, { router, match, applyToRef, form }) => {
      router.push(merge('query', { addedItemId: rowId, warehouseId }, match.location));
      applyToRef(ref => ref.focus());
      showSuccess();
      form.resetFields();
    },
  })
);
