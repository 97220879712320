import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

class MapRoute extends React.Component {
  static contextTypes = {
    ymaps: PropTypes.object,
    parent: PropTypes.object,
  };

  state = {
    route: null,
  };

  componentDidMount() {
    this.buildRoute(this.props.coords, this.props.rid);
  }

  componentWillReceiveProps(props) {
    if (!isEqual(this.props.coords, props.coords)) {
      this.buildRoute(props.coords, props.rid);
    }
  }

  componentWillUnmount() {
    const { parent } = this.context;
    const { route } = this.state;

    if (route === null) {
      return;
    }

    if (typeof parent.remove === 'function') {
      parent.remove(route.getPaths());
    } else {
      parent.geoObjects.remove(route.getPaths());
    }
  }

  buildRoute(coords, rid) {
    const { parent, ymaps } = this.context;
    const { updateRoute } = this.props;

    if (this.state.route) {
      if (typeof parent.remove === 'function') {
        parent.add(this.state.route.getPaths());
      } else {
        parent.geoObjects.remove(this.state.route.getPaths());
      }
    }

    if (coords.length === 0) {
      this.setState({ route: null });
      return;
    }

    ymaps
      .route(coords)
      .then(route => {
        this.setState({ route });

        route.getPaths().options.set({
          strokeColor: this.props.color,
          strokeWidth: 5,
          opacity: 0.9,
        });

        updateRoute(this.props.rid, route);

        if (typeof parent.add === 'function') {
          parent.add(route.getPaths());
        } else {
          parent.geoObjects.add(route.getPaths());
        }

        return route;
      })
      .catch(error => {
        console.error(error);
      });
  }

  render() {
    return null;
  }
}

export const MapRouteContext = React.createContext();

export const MapRouteContextProvider = props => {
  const [routes, setRoutes] = React.useState({});

  const update = (id, route) =>
    setRoutes({
      ...routes,
      [id]: route,
    });

  return <MapRouteContext.Provider value={{ routes, update }} {...props} />;
};

MapRoute.propTypes = {
  coords: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
};

const EnhancedMapRoute = props => (
  <MapRouteContext.Consumer>{({ update }) => <MapRoute {...props} updateRoute={update} />}</MapRouteContext.Consumer>
);

export default EnhancedMapRoute;
