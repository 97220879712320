// @flow

import { graphql, commitMutation } from 'relay-runtime';
import type { IEnvironment } from 'relay-runtime';
import type { MarkPackingDocumentMutationResponse as Response } from './__generated__/MarkPackingDocumentMutation.graphql';

const mutation = graphql`
  mutation MarkPackingDocumentMutation($input: UpdateShopOrderDocumentByRowIdInput!) {
    updateShopOrderDocumentByRowId(input: $input) {
      shopOrderDocument {
        id
        status
      }
    }
  }
`;

const commit = (environment: IEnvironment, rowId: number): Promise<Response> =>
  new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables: {
        input: {
          rowId,
          shopOrderDocumentPatch: {
            status: 'PACKING',
          },
        },
      },
      onCompleted: (data, errors) => {
        if (errors) {
          reject(errors);
          return;
        }

        resolve(data);
      },
      onError: reject,
    });
  });

export default { commit };
