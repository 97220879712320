import { graphql } from 'relay-runtime';
import { makeDestroyMutation } from './helpers';

const mutation = graphql`
  mutation DestroyScaleMutation($input: DeleteScaleInput!) {
    deleteScale(input: $input) {
      deletedScaleId
    }
  }
`;

export default makeDestroyMutation(mutation, 'deleteScale', 'deletedScaleId');
