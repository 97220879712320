// @flow

import React from 'react';
import { QueryRenderer, graphql } from 'react-relay';
import type { IEnvironment } from 'relay-runtime';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import Documents from './Documents';
import type { State } from './Types';
import type { DocumentLoaderQueryResponse } from './__generated__/DocumentLoaderQuery.graphql';

type Props = State & {
  environment: IEnvironment,
  day: ?string,
};

type RenderProps = {
  error: Error,
  props: DocumentLoaderQueryResponse,
};

const addCurrentWarehouseFilter = (id, filter) => {
  if (id === 2 || !id) {
    return {
      or: [
        {
          ...filter,
          mainWarehouseId: {
            isNull: true,
          },
        },
        {
          ...filter,
          mainWarehouseId: {
            equalTo: 2,
          },
        },
      ],
    };
  }

  return {
    ...filter,
    mainWarehouseId: {
      equalTo: id,
    },
  };
};

const DocumentLoader = ({ environment, day, currentWarehouseId, ...rest }: Props) => (
  <QueryRenderer
    environment={environment}
    query={graphql`
      query DocumentLoaderQuery($filter: ShopOrderDocumentFilter!) {
        allShopOrderDocuments(filter: $filter) {
          nodes {
            ...Documents
          }
        }
      }
    `}
    variables={{
      filter: addCurrentWarehouseFilter(currentWarehouseId, {
        owner: {
          in: [
            'myfresh',
            'moscowfresh',
            'myfresh-yandex',
            'moscowfresh-yandex',
            'familyfriend',
            'familyfriend-yandex',
            'myfresh-deliveryclub',
            'moscowfresh-deliveryclub',
            'familyfriend-deliveryclub',
            'sbermarket-myfresh',
            'edoque',
            'edoque-yandex',
          ],
        },
        deliveryOn: {
          equalTo: format(day ? parseISO(day) : new Date(), 'yyyy-MM-dd'),
        },
        status: {
          in: rest.statuses,
        },
      }),
    }}
    render={({ error, props }: RenderProps) => {
      if (error) {
        throw error;
      }

      if (!props) {
        return <div>Loading</div>;
      }

      if (!props.allShopOrderDocuments) {
        throw new Error('Не удалось загрузить данные');
      }

      return <Documents data={props.allShopOrderDocuments.nodes} day={day} {...rest} />;
    }}
  />
);

const selector = state => ({ currentWarehouseId: state.defaultWarehouseId });

export default connect(selector)(DocumentLoader);
