import { compose } from 'recompact';
import { withRouter } from 'found';
import { connect } from 'react-redux';
import { merge } from 'unchanged';
import { showSuccess } from 'utils/notifications';
import withRef from 'hoc/withRef';
import withCreateEnhance from 'hoc/withCreateEnhance';
import mutation from 'mutations/CreateWriteOffItemMutation';

const selector = (state, props) => ({
  defaultWarehouseId: parseInt(props.match.location.query.warehouseId || state.defaultWarehouseId, 10),
});

export default compose(
  withRouter,
  connect(selector),
  withRef,
  withCreateEnhance({
    mutation,
    normalize: ({ barcode, ...values }) => {
      if (!barcode) {
        return values;
      }

      // не весы
      if (barcode[0] !== '2' || barcode[1] !== '1') {
        return values;
      }

      const productId = parseInt(barcode.substr(2, 5), 10);
      const quantity = parseInt(barcode.substr(7, 5), 10) / 1000;

      return { ...values, productId, quantity };
    },
    onCompleted: ({ rowId, warehouseId }, { router, match, applyToRef, form }) => {
      router.push(merge('query', { addedItemId: rowId, warehouseId }, match.location));

      if (match.location.query.barcode !== 'yes') {
        applyToRef(ref => ref.focus());
      }

      showSuccess();
      form.resetFields();
    },
  })
);
