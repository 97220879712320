import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { set } from 'unchanged';

const QuantityInput = ({ onChange, ...props }) => {
  const handle = e => {
    if (onChange) {
      onChange(set('target.value', e.target.value.replace(',', '.').replace(/\s/g, ''), e));
    }
  };

  return <Input {...props} onChange={handle} />;
};

QuantityInput.propTypes = {
  onChange: PropTypes.func,
};

QuantityInput.defaultProps = {
  onChange: null,
};

export default QuantityInput;
