import { graphql } from 'relay-runtime';

export default graphql`
  query AllOrdersMapQuery($filter: ShopOrderDocumentFilter) {
    documents: allShopOrderDocuments(filter: $filter) {
      totalCount

      nodes {
        id
        rowId
        deliveryPoint { x, y }
      }
    }
  }
`;
