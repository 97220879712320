import { getRoutes } from 'utils/crud';
import { preparePaginationVariables } from 'utils/pagination';
import transformFilter from './defaultFilter';

import Index from './index';
import IndexQuery from './PaymasterQuery';

export default getRoutes({
  baseUrl: '/paymaster',
  Index,
  IndexQuery,
  indexPrepareVariables: (nextValue, match) => {
    const values = preparePaginationVariables(nextValue, match);
    return {
      orderBy: 'GROUP_POSITION',
      ...values,
      filter: transformFilter(values.filter || {}),
    };
  },
});
