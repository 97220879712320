import { withProps } from 'recompact';
import transform from 'utils/transform';

/*
 * Позже этот фикс нужно вырезать.
 * После destroy мутации изчезает запись из relay store,
 * но мутирует сам массив, поэтому не происходит
 * повторного рендера таблицы и она просто исчезает.
 *
 * Видно, к примеру, на странице SupplyDocuments/Show
 * Тут просто копируется массив и заодно выкидываются null,
 * которые оставляет relay.
 */

export default name => withProps(transform(`data.${name}.nodes`, nodes => nodes.filter(Boolean)));
