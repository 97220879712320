import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import withFiltersEnhance from 'hoc/withFiltersEnhance';
import FilterForm from 'components/FilterForm';
import { FilterCollectionSelect, FilterInput } from 'components/filterControls';

const Filters = ({ form, handleSubmit, handleClear, allProductCategories }) => (
  <FilterForm onSubmit={handleSubmit} onClear={handleClear}>
    <Form.Item label="Категория">
      {form.getFieldDecorator('categoryId')(
        <FilterCollectionSelect filterType="equalTo" placeholder="Категори" items={allProductCategories.nodes} />
      )}
    </Form.Item>
    <Form.Item label="Название">
      {form.getFieldDecorator('name')(
        <FilterInput filterType="includesInsensitive" placeholder="Любая часть названия" autoFocus />
      )}
    </Form.Item>
  </FilterForm>
);

Filters.propTypes = {
  form: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClear: PropTypes.func,
  allProductCategories: PropTypes.object.isRequired,
};

Filters.defaultProps = {
  handleClear: null,
};

export default withFiltersEnhance(['name'])(Filters);
