import { getRoutes } from 'utils/crud';

import New from './New';
import Edit from './Edit';
import EditQuery from './Edit/Query';

export default getRoutes({
  baseUrl: '/product-auto-writeoffs',
  Edit,
  EditQuery,
  New,
});
