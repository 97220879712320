import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import Page from './Page';

const PageForm = ({ onSubmit, children, ...props }) => (
  <Page {...props}>
    <Form onSubmit={onSubmit}>{children}</Form>
  </Page>
);

PageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.any,
};

export default PageForm;
