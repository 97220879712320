/**
 * @flow
 * @relayHash e72d6ed29dfd96a8bce97f539cee6e3c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteBalanceItemInput = {
  clientMutationId?: ?string,
  id: string,
};
export type DestroyBalanceItemMutationVariables = {|
  input: DeleteBalanceItemInput
|};
export type DestroyBalanceItemMutationResponse = {|
  +deleteBalanceItem: ?{|
    +deletedBalanceItemId: ?string
  |}
|};
export type DestroyBalanceItemMutation = {|
  variables: DestroyBalanceItemMutationVariables,
  response: DestroyBalanceItemMutationResponse,
|};
*/


/*
mutation DestroyBalanceItemMutation(
  $input: DeleteBalanceItemInput!
) {
  deleteBalanceItem(input: $input) {
    deletedBalanceItemId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteBalanceItemInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteBalanceItem",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input",
        "type": "DeleteBalanceItemInput!"
      }
    ],
    "concreteType": "DeleteBalanceItemPayload",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "alias": null,
        "name": "deletedBalanceItemId",
        "args": null,
        "storageKey": null
      }
    ]
  }
];
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "DestroyBalanceItemMutation",
  "id": null,
  "text": "mutation DestroyBalanceItemMutation(\n  $input: DeleteBalanceItemInput!\n) {\n  deleteBalanceItem(input: $input) {\n    deletedBalanceItemId\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DestroyBalanceItemMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": v1
  },
  "operation": {
    "kind": "Operation",
    "name": "DestroyBalanceItemMutation",
    "argumentDefinitions": v0,
    "selections": v1
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1ed295a7f4ae5b081e28217d25081961';
module.exports = node;
