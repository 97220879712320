import { getRoutes } from 'utils/crud';

import Index from './index';
import New from './New';
import Edit from './Edit';
import Show from './Show';

import IndexQuery from './SupplyContractorsQuery';
import NewQuery from './New/NewSupplyContractorQuery';
import EditQuery from './Edit/EditSupplyContractorQuery';
import ShowQuery from './Show/ShowSupplyContractorQuery';

export default getRoutes({
  baseUrl: '/supply/contractors',
  Index,
  IndexQuery,
  Edit,
  EditQuery,
  Show,
  ShowQuery,
  New,
  NewQuery,
});
