import { graphql } from 'relay-runtime';

export default graphql`
  query BalanceDocumentsQuery($first: Int!, $offset: Int!, $orderBy: [BalanceDocumentsOrderBy!]) {
    rows: allBalanceDocuments(first: $first, offset: $offset, orderBy: $orderBy) {
      totalCount

      nodes {
        id
        rowId
        eventedAt
        comment
      }
    }
  }
`;
