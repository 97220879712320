/**
 * @flow
 * @relayHash b9c607191933abe3eb872f49ba78648d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductWarehouseQuantityQueryVariables = {|
  productId: number,
  warehouseId: number,
|};
export type ProductWarehouseQuantityQueryResponse = {|
  +quantity: ?{|
    +productId: number,
    +warehouseId: number,
    +value: any,
    +lastSupplyPrice: ?any,
  |}
|};
export type ProductWarehouseQuantityQuery = {|
  variables: ProductWarehouseQuantityQueryVariables,
  response: ProductWarehouseQuantityQueryResponse,
|};
*/


/*
query ProductWarehouseQuantityQuery(
  $productId: Int!
  $warehouseId: Int!
) {
  quantity: productWarehouseQuantityByProductIdAndWarehouseId(productId: $productId, warehouseId: $warehouseId) {
    productId
    warehouseId
    value
    lastSupplyPrice
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "productId",
    "type": "Int!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "warehouseId",
    "type": "Int!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "productId",
    "variableName": "productId",
    "type": "Int!"
  },
  {
    "kind": "Variable",
    "name": "warehouseId",
    "variableName": "warehouseId",
    "type": "Int!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "productId",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "warehouseId",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "value",
  "args": null,
  "storageKey": null
},
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "lastSupplyPrice",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ProductWarehouseQuantityQuery",
  "id": null,
  "text": "query ProductWarehouseQuantityQuery(\n  $productId: Int!\n  $warehouseId: Int!\n) {\n  quantity: productWarehouseQuantityByProductIdAndWarehouseId(productId: $productId, warehouseId: $warehouseId) {\n    productId\n    warehouseId\n    value\n    lastSupplyPrice\n    id\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ProductWarehouseQuantityQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "quantity",
        "name": "productWarehouseQuantityByProductIdAndWarehouseId",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProductWarehouseQuantity",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ProductWarehouseQuantityQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": "quantity",
        "name": "productWarehouseQuantityByProductIdAndWarehouseId",
        "storageKey": null,
        "args": v1,
        "concreteType": "ProductWarehouseQuantity",
        "plural": false,
        "selections": [
          v2,
          v3,
          v4,
          v5,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b003d6fdbdf3d81b7d68144a28a1c68d';
module.exports = node;
