import { compose, withProps } from 'recompact';
import mutation from 'mutations/UpdateCourierMutation';
import withUpdateEnhance from 'hoc/withUpdateEnhance';
import withNotFound from 'hoc/withNotFound';

export default compose(
  withNotFound(),
  withProps({
    title: 'Изменить',
  }),
  withUpdateEnhance({
    normalize: values => ({
      ...values,
      test: console.log(values),
      telegramChatId: parseInt(values.telegramChatId, 10),
      basePayoff: parseFloat(values.basePayoff || '2500'),
      extraPayoff: parseFloat(values.extraPayoff || '150'),
    }),
    mutation,
  })
);
