/**
 * @flow
 * @relayHash 6082c02e890c7647366c16866aaa21a6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateWarehouseInput = {
  clientMutationId?: ?string,
  id: string,
  warehousePatch: WarehousePatch,
};
export type WarehousePatch = {
  rowId?: ?number,
  name?: ?string,
};
export type UpdateWarehouseMutationVariables = {|
  input: UpdateWarehouseInput
|};
export type UpdateWarehouseMutationResponse = {|
  +updateWarehouse: ?{|
    +warehouse: ?{|
      +rowId: number
    |}
  |}
|};
export type UpdateWarehouseMutation = {|
  variables: UpdateWarehouseMutationVariables,
  response: UpdateWarehouseMutationResponse,
|};
*/


/*
mutation UpdateWarehouseMutation(
  $input: UpdateWarehouseInput!
) {
  updateWarehouse(input: $input) {
    warehouse {
      rowId
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateWarehouseInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input",
    "type": "UpdateWarehouseInput!"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "rowId",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "mutation",
  "name": "UpdateWarehouseMutation",
  "id": null,
  "text": "mutation UpdateWarehouseMutation(\n  $input: UpdateWarehouseInput!\n) {\n  updateWarehouse(input: $input) {\n    warehouse {\n      rowId\n      id\n    }\n  }\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "UpdateWarehouseMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateWarehouse",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateWarehousePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "warehouse",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": false,
            "selections": [
              v2
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "UpdateWarehouseMutation",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "updateWarehouse",
        "storageKey": null,
        "args": v1,
        "concreteType": "UpdateWarehousePayload",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "warehouse",
            "storageKey": null,
            "args": null,
            "concreteType": "Warehouse",
            "plural": false,
            "selections": [
              v2,
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "id",
                "args": null,
                "storageKey": null
              }
            ]
          }
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b5712b25fc7e8988b04480ddcfc0076a';
module.exports = node;
