exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-pages-ProductCategories-Print-___Print__root___2Gv4V {\n}\n\n.src-pages-ProductCategories-Print-___Print__table___ys_0l {\n  width: 100%;\n}\n\n.src-pages-ProductCategories-Print-___Print__table___ys_0l {\n  page-break-inside: auto;\n}\n\n.src-pages-ProductCategories-Print-___Print__table___ys_0l tr {\n  page-break-inside: avoid;\n  page-break-after: auto;\n}\n\n.src-pages-ProductCategories-Print-___Print__table___ys_0l td {\n  padding: 3px;\n  font-size: 14px;\n  border: 1px solid #333;\n}\n\n.src-pages-ProductCategories-Print-___Print__category-title___1vKh5 {\n  font-size: 22px;\n  margin: 0;\n  padding: 0;\n  margin-top: 20px;\n  margin-bottom: 5px;\n}\n\n.src-pages-ProductCategories-Print-___Print__footer___1V1dk {\n  page-break-before: always;\n}\n", ""]);

// exports
exports.locals = {
	"root": "src-pages-ProductCategories-Print-___Print__root___2Gv4V",
	"table": "src-pages-ProductCategories-Print-___Print__table___ys_0l",
	"category-title": "src-pages-ProductCategories-Print-___Print__category-title___1vKh5",
	"footer": "src-pages-ProductCategories-Print-___Print__footer___1V1dk"
};